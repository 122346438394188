<template>
  <div class="steps">
    <ul>
      <li class="stepItem" v-for="(stepItem, index) in stepInfo.list" :key="index">
        <!-- 步骤名称  -->
        <p :class="stepInfo.step >= index + 1 ? 'name name_act' : 'name'">{{ stepItem.status }}</p>
        <!-- 模拟步骤条的节点，此处为圆圈 -->
        <div :class="stepInfo.step >= index + 1 ? 'icon active' : 'icon'"></div>
        <!-- 模拟步骤条连接线，动态显示  -->
        <div :class="stepInfo.step >= index + 2 ? 'line line_act' : 'line'" v-show="index !== stepInfo.list.length - 1"></div>
        <!-- 步骤时间  -->
        <p :class="stepInfo.step >= index + 1 ? 'time time_act' : 'time'">{{ stepItem.statusTime.slice(0, 5) }} <br /> {{ stepItem.statusTime.slice(6, 11) }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "step",
  props: {
    // 传递步骤参数
    stepInfo: {
      type: Object,
      default: function () {
        return {
          list: [],
          step: 0
        }
      },
    }
  }
};
</script>

<style lang="scss" scoped>
.steps {
  width: 100%; height: 22vw; margin: 0 auto; display: flex; justify-content: center;
  .stepItem {
    position: relative; width: 19.8vw; height: 14vw; text-align: center; font-size: 3.6vw; float: left;
    .name {width: 100%; height: 5vw; line-height: 5vw; text-align: center; color: #9A999E;}
    .name_act {color: #F66D2A;}
    .icon {position: relative; width: 2.4vw; height: 2.4vw; margin: 2vw auto; background: #9A999E; border-radius: 50%; z-index: 9999;}
    .active {background-color: #F66D2A;}
    .line {position: absolute; width: 20vw; top: 8vw; left: 50%; border-bottom: #F5F5F5 solid 1px; z-index: 111;}
    .line_act {border-bottom: #F66D2A solid 1px;}
    .time {width: 100%; height: auto; line-height: 4vw; margin: 0 auto; text-align: center; color: #9A999E; font-size: 3.2vw;}
    .time_act {color: #F66D2A;}
  }
}
</style>
