<template>
  <div class="box bg">
    <!-- 顶部栏 -->
    <van-nav-bar title="我的盲盒" @click-left="xz_go('mine')" left-arrow :border="false" z-index="1000" class="while" />
    <div class="tabs">未开启</div>
    <div class="tab_box">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div class="good_list">
            <div class="item" v-for="(item, index) in boxList" :key="index" @click="toDetail(item)">
              <div class="img" v-if="item && item.get_box"><img :src="item.get_box.image" /></div>
              <div class="name" v-if="item && item.get_box">{{ getSubString(item.get_box.name) }}</div>
              <div class="num" v-if="item && item.get_box"><span>持有个数: {{ item.blind_box_count }}</span></div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div style="height: 3vw"></div>
    <div class="empty" v-if="boxList.length == 0"><van-empty description="盲盒里里没有东西哟" /></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "0", //切换登录注册
      list: [],
      boxList: [],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 0,
      limit: 10,
      total: 0 // 总页数
    }
  },
  created() {},
  methods: {
    // 上拉加载
    onLoad() {
      this.pageIndex += 1;
      setTimeout(this.getBoxList(this.pageIndex, 10), 1000)
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.boxList = [];
      this.loading = true;
      this.isLoading = true;
      this.finished = false;
      this.onLoad();
    },
    // 盲盒列表
    getBoxList(pageIndex, limit) {
      this.$get({
        url: "/app/blind_box_orders/myBlindOrders",
        data: { page: pageIndex, limit: limit, status: 1 },
        success: (res) => {
          if (res.code == 1) {
            this.isLoading = false;
            this.loading = false;
            if (res.data.data.length == 0) {
              this.finished = true; // 没有数据的话，加载完成
            } else {
              this.boxList = this.boxList.concat(res.data.data);
              this.total = res.data.total;
              if (this.boxList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          } 
        },
        tip: () => {}
      })
    },
    // 盲盒详情
    toDetail(item) {
      this.$router.push({ path: '/details', query: { id: item.blind_box_id, type: 5, ordersn: item.order_sn, is_open: 0 } })
    },
    // 截取字符串
    getSubString(item) {
      if (item.length > 8) {
        return item.substring(0, 7) + '...';
      } else {
        return item;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(.van-pull-refresh) {min-height: calc((100vh - 176vw) / 2);}
.bg {width: 100%; height: auto; background: #F7F7F9 ;}
.while {background: #fff;}
.tabs {width: 100%; height: 8vw; line-height: 8vw; margin: 2px auto; text-align: center; background: #fff; color: #333; font-size: 3.4vw;}
.tab_box {width: 96%; height: auto; margin: 2vw 2%; --van-tabs-bottom-bar-width: 180vw; --van-tabs-bottom-bar-height: 12vw; text-align:left ;}
.good_list {
  width: 98%; margin: 0 1%; justify-content: space-between; flex-wrap: wrap;
  .item {
    width: 45vw; height: 58vw; margin: 1.5vw 1vw; background: #fff; float: left;
    .img {width: 100%; height: 40vw; img {width: 100%; height: 100%;}}
    .name {width: 92%; height: 5vw; margin: 2vw 4%; color: #000; font-size: 4vw;}
    .num {width: 92%; height: 10vw; margin: 1.2vw 4%; span {width: 16.5vw; height: 4.3vw; text-align: left; background: #FFF7EA; color: #FF9E19; font-size: 3vw;  padding: 1vw 2vw;}}
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
