<template>
  <div class="box">
    <van-nav-bar title="登录" :border="false" z-index="1000" />
    <div class="hello"><img src="@/assets/img/logo.png" alt="logo" class="img_logo"><span>登录道拍</span></div>
    <!-- from表单 -->
    <div class="from_box">
      <form>
        <!-- 手机输入框 -->
        <van-field clearable type="digit" left-icon="smile" v-model="tell" maxlength="11" placeholder="请输入手机号">
          <template #left-icon><img src="@/assets/img/icon01.png" alt=""></template>
        </van-field>
        <!-- 密码输入框 -->
        <van-field clearable autocomplete v-model="password" left-icon="smile" :type="pass_type" :disabled="!tell" placeholder="请输入密码" 
        :right-icon="pass_type == 'password' ? 'closed-eye' : 'eye'" @click-right-icon="eye_fun(pass_type)">
          <template #left-icon><img src="@/assets/img/icon03.png" alt=""></template>
        </van-field>
        <div><!--留一个div保证最后一个输入框有下划线--></div>
      </form>
    </div>
    <div style="height: 4vw"></div>
    <!-- //左侧按钮 -->
    <div class="btn_box"><van-button size="large" type="success" :disabled="disabled" @click="save">登录</van-button></div>
    <div class="register_btn"><div class="reg" @click="toRegister">注册</div><router-link to="password">忘记密码</router-link></div>
    <div class="treaty"><van-checkbox v-model="checked"></van-checkbox><div>&nbsp;用户登录表示同意</div><router-link to="agreement" class="treaty_text">《用户使用条款》</router-link></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tell: "",
      password: "",
      logo: "",
      passwordType: "password",
      pass_type: "password", //input类型
      disabled: false,
      checked: true,
      invite_code: '',
      merId: ''
    }
  },
  created() {
    this.merId = this.$cookies.get("merchantId");
    this.invite_code = this.$route.query.code;
    var xz_tell = this.xz_get("xz_tell")
    var xz_pass = this.xz_get("xz_pass")
    if (xz_tell && xz_pass) {
      this.tell = xz_tell
      this.password = xz_pass
    }
    console.log("invite_code:", this.invite_code);
  },
  methods: {
    //密码输入框右侧图标
    eye_fun() {
      this.pass_type == "password" ? (this.pass_type = "text ") : (this.pass_type = "password")
    },
    save() {
      if (!this.tell) return this.$toast("请输入手机号")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.tell))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.password) return this.$toast("请输入登录密码")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.password))
        return this.$toast("不可输入+-和空格和特殊符号")
      //    if (!this.checked) return this.$toast("请勾选用户使用条款")
      if (this.disabled == true) {
        return false
      }
      this.$toast.loading({ message: "验证中...", forbidClick: true, duration: 0 })
      this.disabled = true
      this.$post({
        url: "/app/auth/login", 
        noToken: true,
        data: { phone: this.tell, password: this.password },
        success: (res) => {
          this.disabled = true
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("xz_tell", this.tell)
          localStorage.setItem("xz_pass", this.password)
          localStorage.setItem("userId", res.data.id)
          console.log("-userId:", res.data.id);
          setTimeout(() => {
            this.$toast("登录成功");
            this.disabled = false;
            this.$cookies.remove("invite_code");
            if (this.merId && this.merId !== 'null') {
              this.$router.push({ path: "/", query: { merchantId: this.merId } })
            } else {
              this.$router.replace("/")
            }
          }, 1000)
        },
        tip: () => { setTimeout(() => { this.disabled = false }, 1500) },
      })
    },
    // 去注册
    toRegister() {
      this.$router.push({ path: "register", query: { code: this.invite_code } }) 
    }
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; padding: 0; min-height: 100vh; background: #fff; overflow: hidden;}
//欢迎语
.hello {margin: 90%; height: auto; margin: 10vw 5%; text-align: center; color: #000; font-size: 4vw; line-height: 2vw;}
.img_logo {width: 18vw; height: 18vw; border-radius: 3vw; margin-right: 5vw;}
//表单
.from_box {width: 100%; padding: 0 2vw; --van-field-input-text-color: #848484; img {width: 5vw; height: 5vw; margin-right: 3vw; display: block;}}
.img_icon {width: 4vw; height: 4vw; display: block; img {width: 100%; height: 100%; display: block;}}
//单元格
:deep(.van-cell) {margin-bottom: 4vw; padding: 4vw 4vw;}
.btn_box {.van-button--success {width: 86%; height: 14vw; margin: 5vw 7%; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; border-radius: 2vw;}}
.register_btn {width: 86%; margin: 0 7%; display: flex; justify-content: space-between; font-size: 4vw;}
.register_btn a {color: #848484;}
//用户协议
.treaty {position: absolute; bottom: 2vw; width: 90%; display: flex; font-size: 3vw; color: #848484; left: 20%; --van-checkbox-checked-icon-color: #eb8e36; --van-checkbox-size: 4vw;}
.treaty_text{color: #000; line-height: 2;}
.reg {color: #848484;}
</style>
