<template>
  <van-nav-bar title="市场列表" left-arrow @click-left="toBack" :border="false" z-index="1000" />
  <div class="box"> 
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" ref="contentData">
      <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad" :offset="50">
        <div class="good_box">
          <div class="good_list">
            <div class="item" v-for="(item, index) in markList" :key="index" @click="toDetail(item)">
              <div class="pay" v-if="item.status == 1">支付中...</div>
              <div class="img"><img :src="item.product_image + '?x-oss-process=image/resize,w_300/quality,q_90'" /></div>
              <div class="name one">{{ item.product_name }}</div>
              <div class="tag_box">
                <div class="tago"><van-tag type="success" v-if="item.goods_level != '' && item.goods_level == 'N'">{{ item.goods_level }}</van-tag></div>
                <div class="tagt"><van-tag type="success" v-if="item.goods_level != '' && item.goods_level == 'R'">{{ item.goods_level }}</van-tag></div>
                <div class="tagf"><van-tag type="success" v-if="item.goods_level != '' && item.goods_level == 'SR'">{{ item.goods_level }}</van-tag></div>
                <div class="tags"><van-tag type="success" v-if="item.goods_level != '' && item.goods_level == 'SSR'">{{ item.goods_level }}</van-tag></div>
              </div>
              <div class="role">
                <div class="conrole"><div class="avatar"><img :src="item.head_img + '?x-oss-process=image/resize,w_50/quality,q_90'" /></div><div class="nick">{{ item.nickname }}</div></div>
                <div class="price one"><span>¥{{ item.price }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div style="height: 15vw"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goods_id: '',
      mer_id: '',
      seriesId: '',
      field: '',
      sort: 'asc',
      markList: [],
      value1: '',
      value3: '',
      value4: '',
      level: '',
      keywords: '',
      refreshing: false,
      loading: false,
      finished: false,
      pageIndex: 0, // 当前页
      limit: 10, // 分页数
      total: 0, // 总页数
      finishedText: '',
      timer: null
    }
  },
  created() {
    this.goods_id = this.$route.query.id;
    this.mer_id = this.$route.query.mer_id;
    this.seriesId = this.$route.query.series_id;
    this.value1 = parseInt(this.$route.query.value1);
    this.value4 = parseInt(this.$route.query.value4);
    this.level = this.$route.query.level;
    this.field = 'price';
    this.field1 = this.$route.query.field;
    this.sort = this.$route.query.sort;
    this.value3 = this.$route.query.value3;
    this.keywords = this.$route.query.keywords;
    this.backTop();
  },
  methods: {
    // 返回顶部
    backTop () {
      document.documentElement.scrollTop = 0;
    },
    // 上拉加载
    onLoad() {
      this.pageIndex += 1;
      setTimeout(this.getMarkList(this.pageIndex, 10), 100);
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.markList = [];
      this.loading = true;
      this.refreshing = true;
      this.finished = false;
      this.finishedText = "";
      setTimeout(this.onLoad(), 1000)
    },
    // 市场列表
    getMarkList(pageIndex, limit) {
      let sentData = { product_id: this.goods_id, series_id: this.seriesId, field: this.field, sort: this.sort, page: pageIndex, limit: limit }
      this.$get({
        url: "/app/goods_rent/allNumber",
        data: sentData,
        success: ((res) => {
          if (res.code == 1) {
            this.loading = false;
            this.refreshing = false;
            if (res.data.data.length == 0) {
              this.finished = true; // 没有数据的话，加载完成
            } else {
              this.markList = this.markList.concat(res.data.data);
              this.total = res.data.total;
              if (this.markList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        })
      })
    },
    // 支付状态
    toDetail(item) {
      if (item.status == 1) {
        this.$toast("该藏品正在支付中，请稍后再试")
      } else {
        this.$router.push({ 
          path: "/details", 
          query: { id: item.id, type: 3, mer_id: this.mer_id, series_id: this.seriesId, value1: this.value1, level: this.level, field: this.field, sort: this.sort, value3: this.value3, 
          value4: this.value4, keywords: this.keywords }
        })
      }
    },
    // 返回市场
    toBack() {
      this.$router.push({ 
        path: "/good", 
        query: { id: this.goods_id, mer_id: this.mer_id, series_id: this.seriesId, value1: this.value1, level: this.level, field: this.field1, sort: this.sort, value3: this.value3, 
        value4: this.value4, keywords: this.keywords }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.good_box {width: 100%; height: auto; padding: 3vw 0; background: #F5F5F5;}
.good_list {width: 94%; margin: 0 3%; display: flex; justify-content: space-between; flex-wrap: wrap; 
  .item {
    padding: 1vw; width: 45vw; margin-bottom: 20px; background: #fff;
    .pay {position: absolute; background: rgba(0, 0, 0, 0.719); color: #fff; font-size: 12px; padding: 0 15px; display: flex; align-items: center; margin: 1vw 0 0 1vw; }
    .img {width: 44vw; height: 33vw; img {width: 100%; height: 100%; object-fit: cover;}}
    .name {height: 8vw; line-height: 2; color: #000; font-size: 4vw; padding: 5px 10px;}
    .tag_box {
      display: flex; padding-left: 10px;
      .tago {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tagt {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tagf {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tags {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
    }
    .role {
      width: 100%; position: relative; display: flex; align-items: center; justify-content: space-between; padding-top: 5px;
      .conrole {
        display: flex; align-items: center; padding-left: 10px;
        .avatar {width: 4vw; height: 4vw; border-radius: 50%; overflow: hidden; margin-right: 10px; flex-shrink: 0; img {width: 100%; height: 100%; display: block;}}
      }
      .nick {color: #9A999E; font-size: 3.2vw;}
      .price {font-size: 4vw; color: #000; font-weight: 800; padding-top: 5px;}
    }
  }
}
</style>