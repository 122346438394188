<template></template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.toDown();
  },
  methods: {
    // 下载页面
    toDown() {
      this.$router.push({ path: "/app_down", query: { version: '1.21' } })
    },
  },
};
</script>

<style lang="scss" scoped></style>
