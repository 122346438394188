<template>
  <div><div id="nc" ref='mycha'></div></div>
</template>

<script>
export default {
  // 验证成功后，服务端报错（如账号/密码等错误），需要重置滑块
  props: {
    reload: { type: Boolean, default: false },
  },
  data() {
    return {
      ic: "" // noCaptcha实例
    }
  },
  watch: {
    reload: {
      handler(newV) {
        console.log(newV)
        if (newV) {
          this.nc.reset() // 重置滑块
        }
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init() // 初始化方法
    })
  },
  // mounted() {
  //   this.init() // 初始化方法
  // },
  methods: {
    init() {
      console.log("打印")
      this.$refs.mycha.innerHTML = ""
      //   window.addEventListener("touchmove", func, { passive: false })
      const self = this // 实例化nc // eslint-disable-next-line no-undef
      AWSC.use("nc", function (state, module) {
        // 初始化
        self.nc = module.init({
          // 您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: "FFFF0N0000000000AE13", // 您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: "nc_other_h5", // 滑块渲染的DOM id。
          renderTo: "nc", // 您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success: function (data) {
            data.scene = "nc_other_h5"
            self.$emit("slideCallback", data)
            // setTimeout(() => { self.nc.reset() // 重置滑块  }, 1000)
            // 重置方法  api
            self.nc.reset() // 重置滑块
          }, // 滑动验证失败时触发该回调参数。
          fail: function (failCode) {
            self.$toast(`滑动验证失败，请刷新重试`)
            console.log(failCode)
            // setTimeout(() => { self.nc.reset() // 重置滑块 }, 1000)
            self.nc.reset() // 重置滑块
          }, // 验证码加载出现异常时触发该回调参数。
          error: function (errorCode) {
            self.$toast(`滑动验证失败，请刷新重试`)
            console.log(errorCode)
            self.$emit("slideCallback", { cls: false })
            // setTimeout(() => { self.nc.reset() // 重置滑块 }, 1000)
          },
        })
      })
    }
  }
}
</script>

<style lang="scss"  scoped="scoped">
// #nc {width: 100%; display: contents;}
// /deep/.nc-container #nc_1_wrapper {width: 100%; height: 36px; line-height: 36px; #nc_1_n1t, #nc_1__bg, #nc_1_n1z, #nc_1__scale_text, .nc-lang-cnt {height: 36px; line-height: 36px;} }
</style>