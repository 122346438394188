<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="修改银行卡" left-arrow @click-left="xz_go('banka_list')" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <!-- 表单 -->
    <div class="from_box">
      <van-field :border="false" clearable label="姓名" readonly v-model="name" type="text" placeholder="请输入姓名"></van-field>
      <van-field clearable :border="false" label="银行卡号" v-model="bank" type="text" placeholder="请输入银行卡号"></van-field>
      <van-field clearable :border="false" label="开户行" v-model="banks" type="text" placeholder="请输入开户行"></van-field>
      <van-field label="银行名称" :border="false" clearable v-model="bankname" type="tell" placeholder="请输入银行名称"></van-field>
      <van-field clearable :border="false" label="支付宝姓名" readonly v-model="apiname" type="text" placeholder="请输入支付宝姓名"></van-field>
      <van-field clearable :border="false" label="支付宝账号" v-model="apicard" type="text" placeholder="请输入支付宝账号"></van-field>
      <div><!--留一个div保证最后一个输入框有下划线--></div>
    </div>
    <div class="switch"><div class="switcho"><div class="switcht">是否使用</div><div><van-switch v-model="checked" /></div></div></div>
    <div style="height: 8vw"></div>
    <div class="btn_box"><van-button size="large" type="success" @click="fix">确 定</van-button></div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      name: "",
      bank: "",
      banks: "",
      bankname: "",
      apicard: "",
      apiname: "",
      id: this.$route.query.id,
      checked: false
    }
  },
  created() {
    this.getBankInfo();
  },
  methods: {
    // 获取银行信息
    getBankInfo() {
      this.$get({
        url: "/app/member_bank/getBank",
        data: { id: this.id },
        success: (res) => {
          this.name = res.data.member_name;
          this.bank = res.data.bank_account;
          this.banks = res.data.bank_name;
          this.bankname = res.data.bank;
          this.apiname = res.data.ali_name;
          this.apicard = res.data.ali_account;
          this.checked = res.data.is_default == 0 ? (this.checked = false) : (this.checked = true);
        },
        tip: () => {}
      })
    },
    // 提交
    fix() {
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.name))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.name) return this.$toast("请输入姓名")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.bank))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.bank) return this.$toast("请输入银行卡号")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.banks))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.banks) return this.$toast("请输入开户行")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.bankname))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.bankname) return this.$toast("请输入银行名称")
      this.$get({
        url: "/app/member_bank/editBank",
        data: {
          member_name: this.name,
          bank: this.bankname,
          bank_account: this.bank,
          bank_name: this.banks,
          ali_name: this.apiname,
          ali_account: this.apicard,
          id: this.id,
          is_default: this.checked ? "1" : "0",
        },
        success: () => {
          this.$toast("修改成功")
          this.$router.go(-1)
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
//单元格
:deep(.van-cell) {background: #F5F5F5; margin-bottom: 4vw; .van-cell__value {flex: none;}}
//表单
.from_box {width: 100%; padding: 0 2vw; --van-field-label-color: #000; --van-field-input-text-color: #000;}
.switch {
  padding: 0 16px; :deep(.van-switch) {margin-top: 15px;}
  .switcho {width: 100%; display: flex; justify-content: space-between; background: #F5F5F5; align-items: center; padding: 0 30px; color: #000;}
}
.btn_box {padding: 3vw; .van-button--success {background: #2A7AF0; background-size: 100% 100%; overflow: hidden; border-radius: 20vw;}}
:deep(.van-button--warning) {border: 1px solid #01fdbd !important;}
:deep(.van-field__button) {display: flex;}
</style>
