<template>
  <div class="box content">
    <van-nav-bar title="我的" :border="false" z-index="1000" class="bg">
      <template #right>
        <div class="news" @click="xz_go('news')">
          <div class="img_news"><van-badge dot v-if="isRead == 0"><img src="~@/assets/img/news01.png" /></van-badge><img src="~@/assets/img/news01.png" v-else /></div>
        </div>
      </template>
    </van-nav-bar>
    <div class="m_top">
      <div style="height: 6vw;"></div>
      <div class="mt_lt"><img :src="info.head_img" class="avatar" /></div>
      <div class="mt_rt"><div class="name">{{ info.nickname }}</div><div class="address">{{ geSubString(info.eth_address) }}</div></div>
    </div>
    <div class="goods" @click="$router.push('orderlist')">
      <van-cell :title="order_name" :border="false" />
      <van-grid :border="false" style="margin-top: 1.5vw;">
        <van-grid-item :icon="require('@/assets/img/icon11.png')" text="全部" /><van-grid-item :icon="require('@/assets/img/icon12.png')" text="待付款" />
        <van-grid-item :icon="require('@/assets/img/icon13.png')" text="已取消" /><van-grid-item :icon="require('@/assets/img/icon14.png')" text="已完成" />
      </van-grid>
    </div>
    <div class="market">
      <div class="mark_li" @click="xz_go('markets')" v-if="minId === null || minId === 0"><img width="28" height="28" src="~@/assets/img/icon15.png" /> 市场订单</div>
      <div class="mark_li" @click="xz_go('blind_list')"><img width="28" height="28" src="~@/assets/img/icon16.png" /> 盲盒订单</div>
      <div class="mark_li" @click="xz_go('resale')"><img width="28" height="28" src="~@/assets/img/icon17.png" /> 转售订单</div>
    </div>
    <div class="invite"><img src="~@/assets/img/mine_bg01.png" @click="toInvite" /></div>
    <div class="serve">
      <van-cell title="其他服务" :border="false" />
      <van-grid :border="false" style="margin-top: 2vw;">
        <van-grid-item :icon="require('@/assets/img/na01.png')" text="我的钱包" @click="toWallet" />
        <van-grid-item :icon="require('@/assets/img/na02.png')" text="我的盲盒" @click="toBlindBox" />
        <van-grid-item :icon="require('@/assets/img/na03.png')" :text="good_name" @click="toCollection" />
        <van-grid-item :icon="require('@/assets/img/na08.png')" text="积分商城" @click="toMall" />
      </van-grid>
      <div style="height: 4vw;"></div>
      <van-grid :border="false">
        <van-grid-item :icon="require('@/assets/img/na06.png')" text="转赠记录" @click="toSendRecord" />
        <van-grid-item :icon="require('@/assets/img/na04.png')" text="联系客服" @click="toCustomService"/>
        <van-grid-item :icon="require('@/assets/img/na07.png')" text="进入道拍" @click="toDaopai" v-if="daopai == 1"/>
        <van-grid-item :icon="require('@/assets/img/na05.png')" text="设置" @click="xz_go('set')" />
      </van-grid>
    </div>
    <div style="height: 20vw"></div>
    <van-dialog v-model:show="show" message="欢迎来到道拍，根据我国相关法律法规，您必须进行实名认证后才可体验本APP完整内容" show-cancel-button @confirm="confirm"></van-dialog>
    <van-overlay v-model:show="shows" @click="shows = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="nini"><van-icon name="close" size="30" @click="shows = false" /></div>
          <div class="imgbox"><p class="name">请使用微信扫码联系客服</p><img :src="kefuimg" class="ke_img" /></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      is_real: "",
      show: false,
      shows: false,
      kefuimg: "",
      isWeixin: false,
      isRead: '',
      merId: '',
      daopai: 0,
      minId: 0,
      order_name: '藏品订单',
      good_name: '合成藏品'
    }
  },
  created() {
    this.merId = this.$cookies.get("merchantId");
    console.log("mine_merId:", this.merId);
    if (this.merId && this.merId !== 'null') {
      this.daopai = 1
    }
    this.minId = this.$cookies.get("minId");
    if (this.minId == 1) {
      this.order_name = '商品订单';
      this.good_name = '商品合成';
    }
    console.log("minId:", this.minId);
    this.getMemberInfo()
    this.getCustomServiceInfo();
  },
  methods: {
    // 个人信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.info = res.data;
            this.is_real = res.data.is_real;
            this.isRead = res.data.is_read;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 截取字符串
    geSubString(txt) {
      if (txt) {
        return ( txt.substring(0, 8) + "********" + txt.substr(txt.length - 8) );
      } else {
        return ''
      }
    },
    // 我的钱包
    toWallet() {
      if (this.is_real == 0) {
        this.show = true;
      } else {
        this.$router.push({ path: "/wallet" });
      }
    },
    // 我的盲盒
    toBlindBox() {
      if (this.is_real == 0) {
        this.show = true;
      } else {
        this.$router.push({ path: "/mybox" });
      }
    },
    // 合成藏品
    toCollection() {
      if (this.is_real == 0) {
        this.show = true;
      } else {
        this.$router.push({ path: "/synth_list" });
      }
    },
    // 积分商城
    toMall() {
      this.$router.push({ path: "/mall" });
    },
    // 转赠记录
    toSendRecord() {
      this.$router.push({ path: "/send_record" });
    },
    // 邀请好友
    toInvite() {
      if (this.is_real == 0) {
        this.show = true;
      } else {
        this.xz_go('invite');
      }
    },
    // 客服
    toCustomService() {
      if (this.is_real == 0) {
        this.show = true;
      } else {
        this.shows = true;
      }
    },
    // 实名提示确认
    confirm() {
      this.$router.push("/real");
    },
    // 客服信息(客服二维码)
    getCustomServiceInfo() {
      this.$get({
        url: "/app/member/getCustomer",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.kefuimg = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 进入道拍
    toDaopai() {
      this.$cookies.remove("merchantId");
      this.daopai = 0;
      this.$router.push({ path: '/', query: { isReload: 1 } })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {width: 100%; height: auto; background: #F5F5F5;}
.bg {background: linear-gradient(180deg, #E2EEFC, #F1E5F6 100%);}
.img_news {width: 5vw; height: 5vw; line-height: 1; img {width: 100%; height: 100%; display: block; line-height: 1;}}
.m_top {
  width: 100%; height: 32vw; background: url("~@/assets/img/mine_bg.png") no-repeat; background-size: 100% 100%; display: flex; flex-wrap: nowrap; flex-direction: row;
  .mt_lt {flex: 2; height: 20vw; line-height: 20vw; margin: 5vw 2%; text-align: center; .avatar {width: 20vw; height: 100%; vertical-align: middle; border-radius: 50% }} 
  .mt_rt {flex: 6; height: 20vw; margin: 5vw auto; .name {color: #000; font-size: 5vw;} .address {font-size: 3vw; color: #999;}}
}
.goods {width: 92%; height: 32vw; margin: 0 4%; background: #fff; border-radius: 8px; --van-cell-text-color: #000; --van-grid-item-content-padding: 0;
--van-grid-item-text-color: #000; --van-grid-item-text-font-size: 3.6vw;}
.market {
  width: 94%; height: 14vw; margin: 5vw 3% 2vw 3%; display: flex; flex-wrap: nowrap; flex-direction: row;
  .mark_li {flex: 1; height: 14vw; line-height: 14vw; margin: 0 1%; text-align: center; background: #fff; color: #222; font-size: 3.4vw; border-radius: 2vw;}
}
.invite {width: 100%; height: auto; margin: 0 auto; img {width: 100%; height: 25vw; vertical-align: middle;}}
.serve {width: 92%; height: 52vw; margin: 0 4%; background: #fff; border-radius: 3vw; --van-cell-text-color: #000; --van-cell-font-size: 4.2vw; --van-grid-item-text-color: #000; 
--van-grid-item-text-font-size: 3.6vw; --van-grid-item-content-padding: 0; margin-top: -1.5vw;}
.imgbox {
  width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 30vw 10px 0;
  .name {width: 100%; color: #000000; font-size: 32px;}
  p {width: 60%; text-align: center; color: #4a90e2; font-size: 28px; padding: 20px 0; font-weight: 550;}
  .ke_img {width: 70%; height: 70%; vertical-align: middle;}
}
.wrapper {display: flex; align-items: center; justify-content: center; height: 100%;}
.block {position: relative; width: 83vw; height: 112vw; background-image: url("~@/assets/img/di@2x.png") !important; background-size: 100% 100% !important; 
.nini {position: absolute; top: 15vw; right: 3vw;} }
</style>
