<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="我的藏品" left-arrow @click-left="$router.go(-1)" :border="false" z-index="1000" />
    <div style="height: 20px"></div>
    <div class="tab_box">
      <div style="height: 3vw"></div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="good_list">
          <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/collection_details', query: { id: item.id } })">
            <div v-if="item.status == 1" class="buy">已转售</div>
            <div v-if="item.status == 2" class="buy">已合成</div>
            <div v-if="item.status == 3" class="buy">已转赠</div>
            <div class="img"><img :src="item.good.goods_image" /></div>
            <div class="name">{{ item.good.goods_name }}</div>
            <div class="id"> # {{ item.goods_number}}</div>
          </div>
        </div>
      </van-list>
    </div>
    <div style="height: 3vw"></div>
    <div class="empty" v-if="list && list.length == 0"><van-empty description="暂时没有任何信息哟" /></div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      active: "0", //切换登录注册
      list: [],
      loading: false,
      finished: false,
      page: 1,
    }
  },
  created() {},

  methods: {
    // 注册
    open() {
      this.show = true
      console.log("开启盲盒")
    },
    onLoad() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      var page = this.page++
      this.$get({
        url: "/app/member_space/goodsList",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.tab_box {
  width: 90vw;
  height: 12vw;
  margin: 0 auto;
  --van-tabs-bottom-bar-width: 180vw;
  --van-tabs-bottom-bar-color: linear-gradient(
    to right,
    rgba(0, 30, 52, 0.5),
    rgba(0, 238, 166, 0.8),
    rgba(0, 30, 52, 0.5)
  );

  --van-tabs-bottom-bar-height: 12vw;
  :deep(.van-tab) {
    z-index: 10;
    width: 100%;
  }
  :deep(.van-tabs__line) {
    top: 0;
  }
}

.good_list {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  .item {
    padding: 1vw;
    width: 43vw;
    height: 59vw;
    border: 0.5vw solid #9ce9cb;
    box-sizing: border-box;
    margin-bottom: 3vw;
    position: relative;
    .buy {
      position: absolute;
      transform: translate(0, 0);
      background: rgba(255, 255, 255, 0.5);
      font-size: 28px;
      font-weight: 400;
      color: #db0601;
      padding: 0 20px;
    }
    .icon {
      width: 0;
      height: 0;
      border-top: 60px solid #00fbc0;
      border-left: 60px solid transparent;
      position: absolute;
      top: 0;
      right: 0;
      :deep(.van-icon) {
        position: absolute;
        transform: translate(-100%, -180%);
        color: #799c53;
      }
    }
    .img {
      width: 40vw;
      height: 33vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      line-height: 9vw;
      font-size: 3vw;
      padding: 1vw 2vw;
    }
    .id {
      padding: 1vw 2vw;
      border: 1px solid #01fdbd;
      line-height: 5vw;
      font-size: 3vw;
    }
    .tag_box {
      .van-tag--success {
        --van-tag-font-size: 3vw;
        --van-tag-padding: 3vw 5vw 3vw 3vw;
        height: 5vw;
        --van-tag-success-color: linear-gradient(to bottom, #0dafda, #58ffa1);
        margin-right: 3vw;
      }
    }
    .role {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 3vw;
      .avatar {
        width: 3vw;
        height: 3vw;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 3vw;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .text {
        font-size: 3vw;
        display: flex;
        .name {
          color: #bbb9b9;
          line-height: 1.5;
        }
        .time {
          color: #bbb9b9;
          line-height: 1.5;
        }
      }
      .price {
        font-size: 4vw;
        display: flex;
        align-items: flex-end;
        background-image: linear-gradient(#0dafda, #58ffa1);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 800;

        span {
          font-size: 3vw;
          line-height: 9vw;
        }
        div {
          height: 9vw;
          line-height: 9vw;
        }
      }
    }
  }
}
.btn_box {
  width: 92%;
  position: fixed;
  margin: 0 auto;
  margin-bottom: 3vw;
  bottom: 15vw;
  // padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}
.btn_boxs {
  width: 100%;
  position: fixed;
  margin: 0 auto;
  margin-bottom: 3vw;
  bottom: 15vw;
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
