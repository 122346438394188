<template>
  <div class="box">
    <van-nav-bar :title="blind.name" left-arrow @click-left="toBack" :border="false" z-index="1000" />
    <div class="top" v-if="blind">
      <img :src="blind.image" class="blind_img" /><div class="blind_name">{{ blind.name }}</div>
      <div class="bling_num">
        <div class="bn_box"><div class="sell_lab">发售总量 </div> <div class="sell_num">{{ blind.num }}份</div></div>
        <!-- <div class="bn_box" style="margin-left: 3vw;"><div class="sell_lab">已售量</div><div class="sell_num">{{ blind.sale }}份</div></div> -->
      </div>
    </div>
    <div class="brand">
      <div class="brand_head"><img :src="blind.mer_image" class="img_br"/></div><div class="brand_name"><div class="name">{{ blind.mer_name }}</div><div class="role">创作者</div></div>
    </div>
    <div class="main">
      <div class="m_top"><div class="mt_lt">作品描述</div><div class="mt_rt">您可以抽到以下作品中的1个</div></div>
      <div class="m_mid" v-for="(item, index) in blind.box_product" :key="index">
        <div class="mm_lt"><div class="mm_name">{{ getSubString(item.product_name) }}</div> <div class="mm_price">￥ {{ item.initial_price }}</div></div>
        <div class="mm_rt">概率：{{ item.probability }}%</div>
      </div>
    </div>
    <div class="blind_det"><div style="height: 1vw;"></div><div class="h2">作品故事</div><div class="info" v-html="blind.content"></div></div>
    <div class="about"><div style="height: 1vw;"></div><div class="h2">关于数字盲盒</div><div class="txt" v-html="blind.digital_blind_box"></div></div>
    <div class="about"><div style="height: 1vw;"></div><div class="h2">关于数字藏品</div><div class="txt" v-html="blind.cangpin"></div></div>
    <div class="main" v-if="blind.box_order">
      <div class="m_top">
        <div class="mt_lt">抽取记录</div><div class="mt_rt" @click="toRecord(blind.id)" v-if="blind.box_order.length > 4">更多抽取记录 <img src="~@/assets/img/go.png" class="go" /></div></div>
      <div class="m_mid" v-if="blind.box_order.length == 0"><div class="empty">暂无抽取记录！</div></div>
      <div class="m_mid" v-for="(item, index) in blind.box_order.slice(0, 5)" :key="index" v-else>
        <div class="mm_lt"><div class="mm_name">{{ item.member_nick_name }}</div> <div class="mm_price">{{ item.create_time }}</div></div>
        <div class="mm_rt">抽中{{ getNameSubString(item.product_name) }}</div>
      </div>
    </div>
    <div class="about"><div style="height: 1vw;"></div><div class="h2">购买须知</div><div class="txt" v-html="buy_notes"></div></div>
    <div style="height: 15vw;"></div>
    <div class="submits" v-if="blind.status == 5"><van-submit-bar button-text="已售罄"><template #default><div class="default">￥{{ blind.price }}</div></template></van-submit-bar></div>
    <div class="submit" v-else>
      <van-submit-bar :disabled="disabled" button-text="我想要" @click="toBuy"><template #default><div class="default">￥{{ blind.price }}</div></template></van-submit-bar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      blind: '',
      disabled: false,
      buy_notes: ''
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getBlindDetail();
    this.backTop();
  },
  methods: {
    // 返回顶部
    backTop () {
      document.documentElement.scrollTop = 0;
    },
    // 返回首页
    toBack() {
      this.$router.push({ path: "/", query: { tabIndex: 1 } })
    },
    // 盲盒详情
    getBlindDetail() {
      this.$get({
        url: '/app/blind_box/blindBoxDetail',
        data: { id: this.id },
        success: (res) => {
          console.log("盲盒详情：", res);
          if (res.code == 1) {
            this.blind = res.data;
            let txt = res.data.buy;
            this.buy_notes = txt.replace(/\n/g,'<br>');
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 截取字符串
    getSubString(item) {
      if (item.length > 8) {
        return item.substring(0, 8) + '...';
      } else {
        return item;
      }
    },
    // 截取字符串
    getNameSubString(item) {
      if (item.length > 8) {
        return item.substring(0, 6) + '...';
      } else {
        return item;
      }
    },
    // 抽取记录
    toRecord(id) {
      this.$router.push({ path: "/blind_record", query: { id: id } });
    },
    // 购买
    toBuy() {
      this.$get({
        url: '/app/blind_box_orders/createOrder',
        data: { box_id: this.id },
        success: (res) => {
          console.log("盲盒订单：", res);
          if (res.code == 1) {
            this.$router.push({ path: "/blind_order", query: { ordersn: res.data.order_sn, blind_num: 1 } })
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; margin: 0 auto; background: #F7F7F9;}
.top {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 2vw;
  .blind_img {width: 100%; max-height: 100%; object-fit: cover;}
  .blind_name {width: 96%; height: auto; line-height: 5vw; margin: 2vw 2%; color: #222; font-size: 4vw; font-weight: 600;}
  .bling_num {
    width: 96%; height: 6vw; margin: 2vw 2%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .bn_box {
      width: 40%; height: 6vw; display: flex; flex-wrap: nowrap; flex-direction: row; font-size: 3.2vw;
      .sell_lab {width: 18vw; height: 6vw; line-height: 6vw; text-align: center; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; font-size: 3.2vw;}
      .sell_num {width: 20vw; height: 6vw; line-height: 6vw; text-align: center; background: #FFF3EF; color: #FC201F;}
      .sold_lab {width: 18vw; height: 6vw; line-height: 6vw; text-align: center; background: #ABA8BA; color: #fff; font-size: 3.2vw;}
      .sold_num {width: 20vw; height: 6vw; line-height: 6vw; text-align: center; background: #F7F7F9; color: #9592A3;}
    }
  }
}
.brand {
  width: 100%; height: 16vw; margin: 2vw auto; background: #fff; display: flex; flex-wrap: nowrap; flex-direction: row;
  .brand_head {flex: 2; height: 12vw; margin: 2vw auto; text-align: center; .img_br {width: 12vw; height: 100%; vertical-align: middle; border-radius: 50%;}}
  .brand_name {flex: 10; height: 12vw; line-height: 1.6; margin: 2vw auto; .name {color: #000; font-size: 4vw;} .role {color: #999; font-size: 3.2vw;}}
}
.main {
  width: 100%; height: auto; margin: 2vw auto; background: #fff; padding-bottom: 1.5vw;
  .m_top {
    width: 96%; height: 10vw; margin: 0 2%; display: flex;
    .mt_lt {flex: 1; height: 10vw; line-height: 10vw; color: #000; font-size: 4.2vw; font-weight: 600;}
    .mt_rt {flex: 1; height: 10vw; line-height: 3.2; text-align: right; color: #000; font-size: 3.6vw; .go {width: 4vw; height: 4vw; vertical-align: middle; margin-top: -5px;}}
  }
  .m_mid {
    width: 96%; height: auto; margin: 2vw 2%; background: #F7F7F9; display: flex;
    .empty {width: 96%; height: 8vw; line-height: 8vw; margin: 2vw 2%; text-align: center; color: #666;}
    .mm_lt {
      flex: 1; height: 16vw; margin: 0 auto; padding: 2vw;
      .mm_name {height: 5vw; line-height: 5vw; margin: 1vw auto; color: #000; font-size: 4.2vw; font-weight: 500;}
      .mm_price {height: 5vw; line-height: 5vw; margin: 1.5vw auto; color: #666; font-size: 3.6vw;}
    }
    .mm_rt {flex: 1; height: 6vw; line-height: 6vw; margin: 4vw auto; text-align: right; color: #999; padding: 2vw;}
  }
}
.h2 {width: 96%; height: 5vw; line-height: 5vw; margin: 2vw 2%; color: #000; font-size: 4.2vw; font-weight: 600;}
.blind_det {width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 2vw; .info {width: 96%; height: auto; margin: 0 2%; color: #222;}}
.about {width: 100%; height: auto; margin: 2vw auto; background: #fff; .txt {width: 96%; height: auto; margin: 0 2%; color: #222;}}
.submit {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {
    justify-content: space-between !important; .default {font-size: 48px; color: #000;} 
    .van-submit-bar__button {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);}
  }
}
.submits {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {justify-content: space-between !important; .default {font-size: 48px; background-clip: text; color: #000;} .van-submit-bar__button {background: #ABABB2;}}
}
</style>