<template>
  <van-nav-bar title="抽签规则" left-arrow  @click-left="xz_back" />
  <div class="box">
    <div class="content">
      <div class="top">
        <h2>抽签码获取门槛如下：</h2>
        <div class="top_txt">“您的抽签码计算规则：您当前拥有该藏品的数量/持有数量 X 可获抽签码”</div>
      </div>
      <table class="table">
        <tr class="th"><th>拥有藏品</th><th>持有数量</th><th>可获抽签码</th><th>您的抽签码</th></tr>
        <tr class="td" v-for="(item, index) in ruleList" :key="index">
          <td height="70"><img :src="item.product_image" class="img" /><div class="txt">{{ item.product_name }}</div> </td><td>x{{ item.num }}</td>
          <td>{{ item.code_num }}</td><td class="num">{{ item.codeSum }}</td>
        </tr>
      </table>
      <div class="tb_bot">您的抽签码合计：<span>{{ total }}</span></div>
      <div class="show" v-if="status >= 4"><div class="show_li" v-for="(item, index) in codeList" :key="index">{{ item.name }}</div></div>
      <div class="mid">
        <div class="mid_tit">注意：</div>
        <div class="mid_txt">在抽签期间，抽签码的数量将会随着您拥有的藏品满足抽签门槛的次数而实时变化。我们将会在抽签结束时锁定您的抽签码数量并发放抽签码。<br/>
        PS:我们最终将根据抽签码来进行抽签，可能会存在同一个用户的多个抽签码同时中签的情况，快购买藏品吧~</div>
      </div>
      <div style="height: 3vw"></div>
    </div>
    <div class="bot">
      <h2>购买须知</h2>
      <div class="bot_txt">若您未中签，则无法购买该产品；若您已中签，每个中签码可购买产品{{ limit_num }}个</div>
    </div>
    <div style="height: 10vw"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      ruleList: [],
      total: 0,
      codeList: [],
      status: 0,
      limit_num: 0
    }
  },
  created() {
    this.id = this.$route.query.id;
    console.log("id:", this.id);
    this.getRuleList();
  },
  methods: {
    // 规则列表
    getRuleList() {
      this.$get({
        url: "/app/goods/goodsInfo",
        data: { id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.ruleList = res.data.draw.conditions;
            this.total = res.data.draw.codeNum;
            this.status = res.data.draw.status;
            this.limit_num = res.data.limit_num;
            let list = res.data.draw.codes;
            let objList = Object.keys(list); 
            for (let i = 0; i < objList.length; i++) {
              let obj = {};
              obj.id = i;
              obj.name = objList[i];
              this.codeList.push(obj);
            }
            console.log("ruleList:", this.ruleList);
            console.log("codeList:", this.codeList);
          } else {
            this.$toast(res.msg);
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.box {background: #F7F7F9;}
.content {width: 100%; height: auto; margin: 0 auto; background: #FFFFFF;}
.top {width: 92%; height: auto; margin: 4vw 4%; h2 {color: #222222; font-size: 3.4vw; font-weight: 500;}}
.top_txt {width: 100%; height: 13vw; line-height: 1.6; margin: 2vw auto; background: #FFF7EF; color: #FC9C32; font-size: 3.2vw; padding: 2vw;}
.table {width: 94%; height: auto; margin: 4vw 3%; color: #222222;}
.th {width: 100%; height: 10.2vw; line-height: 10.2vw; margin: 2vw auto; background: #F5F5F5; font-size: 3.2vw;}
.td {
  height: 25vw; text-align: center; margin: 5vw auto;
  .img {width: 14.6vw; height: 14.6vw; line-height: 14.6vw; text-align: center; vertical-align: middle; margin-top: 2vw;}
  .txt {width: 100%; height: 10vw; line-height: 10vw; margin: 0 auto; text-align: center; font-size: 3.2vw;}
}
.num {height: 25vw; line-height: 25vw; color: #FC9C32; font-weight: 500;}
.tb_bot {width: 94%; height: 10.2vw; line-height: 10.2vw; margin: 2vw 3%; text-align: right; background: #F5F5F5; color: #222222; font-size: 3.2vw; padding-right: 5vw;
span {color: #FC9C32;}}
.mid {width: 96%; height: auto; margin: 4vw 2%;}
.mid_tit {width: 94%; height: 8vw; line-height: 8vw; margin: 0 3%; color: #222222; font-size: 3.5vw;}
.mid_txt {width: 94%; height: auto; line-height: 1.5; margin: 0 3%; color: #9A999E; font-size: 3.2vw;}
.show {width: 94%; height: auto; margin: 3vw 3%; border: #FC9C32 solid 1px; display: flex; flex-wrap: wrap; flex-direction: row; padding: 3vw 0;}
.show_li {width: 20%; height: auto; line-height: 5vw; text-align: center; color: #FC9C32; font-size: 3.6vw;}
.bot {width: 94%; height: auto; margin: 4vw 3%; h2 {width: 96%; height: 10vw; line-height: 10vw; margin: 0 2%; color: #000000; font-size: 4.2vw;}}
.bot_txt {width: 96%; height: auto; margin: 0 2%; color: #999999; font-size: 3.7vw;}
</style>
