<template>
  <div class="box">
    <van-nav-bar title="订单详情" left-arrow @click-left="toBack()" :border="false" z-index="1000" />
    <div style="height: 4vw"></div>
    <div class="congood"><div class="good"><div class="goodo"><img src="~@/assets/img/check.png" /></div><div class="goodt">交易成功</div></div></div>
    <div v-if="type == 1">
      <div class="card" v-if="info && info.good">
        <van-card :thumb="info.good.product_image">
          <template #title>
            <div class="title" v-if="info && info.good">{{ info.good.product_name }}</div><div class="titleo">￥{{ info.price }}</div><div class="titlet">X {{ info.goods_num }}</div>
          </template>
        </van-card>
      </div>
      <div class="condetail">
        <div class="detail">
          <div class="detailo">订单信息</div>
          <van-cell title="订单编号" :value="ordersn" />
          <van-cell title="创作者" v-if="info && info.good" :value="info.good.author_name" />
          <van-cell title="卖家" v-if="info && info.good" :value="info.good.mer_name" />
          <van-cell title="买家" :value="name" />
          <van-cell title="支付时间" :value="info.create_time" />
          <van-cell v-if="radio == 1" title="支付方式" value="微信支付" />
          <van-cell v-if="radio == 2" title="支付方式" value="支付宝支付" />
          <van-cell v-if="radio == 3" title="支付方式" value="余额支付" />
        </div>
      </div>
    </div>
    <div v-if="type == 2">
      <div class="card" v-if="blind && blind.good">
        <van-card :thumb="blind.blind_box.image">
          <template #title><div class="title" v-if="blind && blind.good">{{ blind.blind_box.name }}</div><div class="titleo">￥{{ blind.price }}</div><div class="titlet">X1</div></template>
        </van-card>
      </div>
      <div class="condetail">
        <div class="detail">
          <div class="detailo">订单信息</div>
          <van-cell title="订单编号" :value="ordersn" />
          <van-cell title="创作者" v-if="blind && blind.good" :value="blind.blind_box.author_name" />
          <van-cell title="卖家" v-if="blind && blind.good" :value="blind.blind_box.mer_name" />
          <van-cell title="买家" :value="name" />
          <van-cell title="支付时间" :value="blind.create_time" />
          <van-cell v-if="radio == 1" title="支付方式" value="微信支付" />
          <van-cell v-if="radio == 2" title="支付方式" value="支付宝支付" />
          <van-cell v-if="radio == 3" title="支付方式" value="余额支付" />
        </div>
      </div>
    </div>
    <div v-if="type == 3">
      <div class="card" v-if="mark && mark.product">
        <van-card :thumb="mark.product.product_image">
          <template #title><div class="title" v-if="mark && mark.product">{{ mark.product.product_name }}</div><div class="titleo">￥{{ mark.price }}</div><div class="titlet">X1</div></template>
        </van-card>
      </div>
      <div class="condetail">
        <div class="detail">
          <div class="detailo">订单信息</div>
          <van-cell title="订单编号" :value="ordersn" />
          <van-cell title="创作者" v-if="mark && mark.product" :value="mark.product.author_name" />
          <van-cell title="卖家" v-if="mark && mark.product" :value="mark.product.mer_name" />
          <van-cell title="买家" :value="name" />
          <van-cell title="支付时间" :value="mark.create_time" />
          <van-cell v-if="radio == 1" title="支付方式" value="微信支付" />
          <van-cell v-if="radio == 2" title="支付方式" value="支付宝支付" />
          <van-cell v-if="radio == 3" title="支付方式" value="余额支付" />
        </div>
      </div>
    </div>
    <div class="bot"><van-button type="default" class="bot_btn" @click="toBack()">返 回</van-button></div>
    <div style="height: 4vw"></div>
  </div>
</template>

<script>
  export default {
    name: "TianqiOptorder",
    data() {
      return {
        ordersn: this.$route.query.ordersn,
        type: this.$route.query.type,
        radio: this.$route.query.radio,
        info: {},
        name: "",
        blind: {},
        mark: {}
      }
    },
    created() {
      if (this.type == 1) {
        this.getOrderInfo()
      } else if (this.type == 2) {
        this.getBlindOrderInfo()
      } else if (this.type == 3) {
        this.getMarkOrderInfo()
      }
      this.getUserInfo()
    },
    mounted() {},
    methods: {
      // 返回上一页
      toBack() {
        if (this.type == 1) {
          this.$router.push({path: "/"});
        } else if (this.type == 2) {
          this.xz_go('blinds');
        } else if (this.type == 3) {
          this.xz_go('good');
        }
      },
      // 个人信息
      getUserInfo() {
        this.$get({
          url: "/app/member/memberInfo",
          data: {},
          success: (res) => {
            console.log(res)
            this.name = res.data.nickname
          },
          tip: () => { },
        })
      },
      // 首页订单
      getOrderInfo() {
        this.$get({
          url: "/app/goods_orders/orderInfo",
          data: { order_sn: this.ordersn },
          success: (res) => {
            console.log(res)
            this.info = res.data
          },
          tip: () => {},
        })
      },
      // 盲盒订单
      getBlindOrderInfo() {
        this.$get({
          url: "/app/blind_box_orders/orderInfo",
          data: { order_sn: this.ordersn },
          success: (res) => {
            console.log(res)
            this.blind = res.data
          },
          tip: () => {},
        })
      },
      //市场藏品订单详情
      getMarkOrderInfo() {
        this.$get({
          url: "/app/rent_orders/orderInfo",
          data: { order_sn: this.ordersn },
          success: (res) => {
            if (res.code == 1) {
              this.mark = res.data
            } else {
              this.$toast(res.msg)
            }
          },
          tip: () => {},
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.box {width: 100%; height: auto; background: #F7F7F9; --van-nav-bar-background-color: #fff;}
.congood {
  padding: 0 40px; margin-bottom: 30px;
  .good {
    background: #fff; padding: 60px 0;
    .goodo {width: 52px; height: 52px; margin: 0 auto; img {width: 100%; height: 100%;}} .goodt {font-size: 28px; font-weight: 400; color: #000; text-align: center; padding-top: 20px;}
  }
}
.card {
  width: 90%; margin: 0 auto; padding: 10px 0; background: #fff; margin-bottom: 30px;
  .title {font-size: 32px; font-weight: 500; color: #000; padding-left: 20px; line-height: 1.5;}
  .titleo {font-size: 5vw; font-weight: 500; background: #F53F3F; background-clip: text; -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  padding-left: 12px;}
  .titlet {font-size: 3.2vw; font-weight: 400; color: #000; padding-left: 20px;}
  :deep(.van-card) {background: transparent;}
}
.condetail {
  padding: 0 40px;
  .detail {
    background: #fff; padding-bottom: 60px;
    .detailo {padding: 20px 0 30px 30px; color: #000;}
    :deep(.van-cell:after) {border-bottom: none;}
    :deep(.van-cell) {padding-bottom: 0; .van-cell__title {font-size: 20px; font-weight: 400; color: #000;} .van-cell__value {font-size: 24px; font-weight: 400; color: #000;}}
  }
}
.bot {
  width: 96%; height: 15vw; margin: 5vw 2%;
  :deep(.van-button--default) {width: 92%; height: 12vw; margin: 0.5vw 4%; text-align: center; background: linear-gradient(180deg, #FFCF4E 0%, #FF1F1F 100%); color: #fff; border-radius: 3vw;}
}
</style>
