<template>
  <div class="VantCss3d">
    <div class="frame_bg"></div>
    <!-- <div class="frame_title_box">
      <div class="left_icon"><img src="~@/assets/img/adorn.png" alt=""></div><div class="frame_title">{{title}}</div><div class="right_icon"><img src="~@/assets/img/adorn.png"></div>
    </div> -->
    <div class="frame">
      <div class="scene_new" v-if="type != 2">
        <!-- 正方形 -->
        <div class="square">
          <div class="border"><div class="borders"><div class="front face"><img :src="src"></div></div></div>
          <div class="back face" :style="`background-image: url(${src});`"></div>
          <div class="right face"></div>
          <div class="left face"></div>
          <div class="top face"></div>
          <div class="bottom face"></div>
        </div>
      </div>
      <div class="scene" v-if="type == 2">
        <!-- 立方形 -->
        <div class="cubic">
          <div class="front face" :style="`background-image: url(${src});`"></div>
          <div class="back face" :style="`background-image: url(${src});`"></div>
          <div class="right face" :style="`background-image: url(${src});`"></div>
          <div class="left face" :style="`background-image: url(${src});`"></div>
          <div class="top face" :style="`background-image: url(${src});`"></div>
          <div class="bottom face" :style="`background-image: url(${src});`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VantCss3d",
  props: { src: String, scene: String, phone: String, type: String, title: String },
  data() {
    return {
      is_time: true,
      scend: 60
    }
  },
  created() {
    console.log("加载VantCss3d组件，组件地址为VantCss3d");
  },
  methods: {
    hClick() { alert("失败") },
    sendsms() {
      //防抖 监听输入框
      var that = this
      if (this.timeout) { clearTimeout(this.timeout); }
      this.timeout = setTimeout(() => {
        that.$post({
          url: that.url,
          data: { phone: that.phone, scene: that.scene },
          success: (res) => {
            that.$toast({ message: "发送成功" });
            that.is_time = false;
            var timer = setInterval(() => {
              that.scend--;
              if (that.scend == 0) {
                clearInterval(timer);
                that.is_time = true;
                that.scend = 60;
              }
            }, 1000)
          },
          tip: (val) => {}
        })
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped="scoped">
.VantCss3d {position: relative; width: 100vw; height: 110vw; overflow: hidden; top: 8vw;
  .frame_bg {position: absolute; filter: blur(7vw); background-repeat: no-repeat; background-size: cover; background-position: center 0; width: 100vw; height: 100vw; z-index: 0;}
  .frame_title_box {position: absolute; z-index: 2; width: 100%; bottom: 0; display: flex; justify-content: center; align-items: center;
    .left_icon {width: 12vw; height: 6vw; img {width: 100%; height: 100%; display: block;} }
    .frame_title {padding: 0 2vw; color: #fff; font-weight: 800; font-size: 5vw;}
    .right_icon {transform: rotateY(180deg); width: 12vw; height: 6vw; img {width: 100%; height: 100%; display: block;} }
  }
  .frame {z-index: 1; position: relative; width: 100vw; height: 104vw; background-image: url("~@/assets/img/base.png"); background-repeat: no-repeat; background-size: 82% 100%;
    background-position: center 0;}
  .frame *, .frame *::after, .frame *::before {margin: 0; padding: 0; box-sizing: border-box; user-select: none; transform-style: preserve-3d; -webkit-tap-highlight-color: transparent;
    user-select: none; outline: none; appearance: none;}
  .scene_new {position: absolute; width: 100vw; height: 100vw; transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(0deg) translateX(0vw) translateZ(-20vw) translateY(0vw);
    transition: all 1s ease-in-out; animation: turn_new 25s 1s linear infinite; top: -30px;}
  @keyframes turn_new {
    0% {transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(0deg) translateX(0vw) translateZ(-20vw) translateY(0vw);}
    50% {transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(360deg) translateX(0vw) translateZ(-20vw) translateY(0vw);}
    100% {transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(720deg) translateX(0vw) translateZ(-20vw) translateY(0vw);}
  }
  .scene {position: absolute; width: 100vw; height: 100vw; transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(0deg) translateX(0vw) translateZ(-20vw) translateY(0vw);
    transition: all 1s ease-in-out; animation: turn 10s 1s linear infinite;}
  @keyframes turn {
    0% {transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(0deg) translateX(0vw) translateZ(-20vw) translateY(0vw);}
    50% {transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(180deg) translateX(0vw) translateZ(-20vw) translateY(0vw);}
    100% {transform: perspective(100vw) rotateX(0deg) rotateX(90deg) rotateZ(360deg) translateX(0vw) translateZ(-20vw) translateY(0vw);}
  }
  .face {position: absolute;}
  .floor {position: absolute; top: 0vw; left: 0vw; width: 100vw; height: 100vw;}
  .floor__bottom {width: 100vw; height: 100vw; transform-origin: top left; background: linear-gradient(60deg, rgb(63, 195, 225) 0,rgb(73, 250, 201) 100%);
  transform: rotateY(180deg) translateX(-100vw);}
  /***************/
  .square {position: absolute; top: 0vw; left: 0vw; width: 54vw; height: 5vw; transform: translateX(23vw) translateY(45vw) translateZ(0vw);
    .border {background: transparent; width: 54vw; height: 54vw; padding: 1.5vw; box-sizing: border-box; border: 5px solid #fff; background-size: 100% 100%; transform-origin: top left;
      transform: rotateX(-90deg) rotateY(180deg) translateX(-54vw) translateY(-50vw) translateZ(-5vw); margin: 0 auto; border-radius: 30px;
      .front {background: #888888; width: 50vw; height: 50vw; background-size: 100% 100%; transform-origin: bottom left;
        transform: rotateX(0deg) rotateY(0deg) translateX(0vw) translateY(0vw) translateZ(0vw); border-radius: 30px;
        img {width: 100%; height: 100%; object-fit: cover; border-radius: 30px;}
      }
    }
  }
  @keyframes big2 {
    0% {transform: translateX(30vw) translateY(30vw) translateZ(0vw) scale3d(0.01, 0.01, 0.01) rotate(0deg);}
    50% {transform: translateX(30vw) translateY(30vw) translateZ(0vw) scale3d(0.5, 0.5, 0.5) rotate(360deg);}
    100% {transform: translateX(30vw) translateY(30vw) translateZ(0vw) scale3d(1, 1, 1) rotate(720deg);}
  }
  .cubic {position: absolute; top: 0vw; left: 0vw; width: 40vw; height: 40vw; transform: translateX(30vw) translateY(30vw) translateZ(0vw); animation: big2 1s linear forwards;
    .front {background: #636363; width: 40vw; height: 40vw; background-size: 100% 100%; transform-origin: bottom left; 
    transform: rotateX(-90deg) translateX(0vw) translateY(0vw) translateZ(-40vw);}
    .back {background: rgba(19, 255, 232, 0.5); background-size: 100% 100%; width: 40vw; height: 40vw; transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-40vw) translateY(-40vw) translateZ(-40vw);}
    .right {background: rgba(19, 255, 232, 0.5); background-size: 100% 100%; width: 40vw; height: 40vw; transform-origin: top left; transform: rotateY(90deg) rotateZ(-90deg) translateZ(40vw)
    translateX(-40vw) translateY(-40vw);}
    .left {background: rgba(19, 255, 232, 0.5); background-size: 100% 100%; width: 40vw; height: 40vw; transform-origin: top left; 
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-40vw) translateX(0vw);}
    .top {background: rgba(19, 255, 232, 0.5); background-size: 100% 100%; width: 40vw; height: 40vw; transform-origin: top left; transform: translateZ(40vw) translateX(0vw) translateY(0);}
    .bottom {background: rgba(19, 255, 232, 0.5); background-size: 100% 100%; width: 40vw; height: 40vw; transform-origin: top left; transform: rotateY(180deg) translateX(-40vw) translateY(0);}
  }
}
</style>
