<template>
  <div class="box">
    <van-nav-bar title="实名认证" left-arrow @click-left="xz_back" :border="false"></van-nav-bar>
    <div style="height: 15vw"></div>
    <div class="imgg"><img src="@/assets/img/d@2x.png" alt=""></div>
    <div style="height: 13vw"></div>
    <div class="from_box">
      <van-field input-align="left" v-model="real_name" type="text" placeholder="请输入您的真实姓名" clearable border :center="true" />
      <van-field input-align="left" v-model="id_card" type="text" placeholder="请输入您的身份证号" clearable border :center="true" />
    </div>
    <div style="height: 30vw"></div>
    <div class="btn_box"><van-button size="large" type="success" @click="showPopup">确 定</van-button></div>
    <van-popup v-model:show="show_botto" position="bottom" :style="{ height: '30%' }">
      <div class="slider_box"><noCaptcha @slideCallback="finishSlide" :reload="reload" /></div>
    </van-popup>
  </div>
</template>

<script>
import noCaptcha from "@/components/noCaptcha.vue"
export default {
  components: { noCaptcha },
  data() {
    return {
      loginForm: { session_id: "", verify_token: "", sig: "" },
      reload: true,
      bank_num: "",
      phone: "",
      real_name: "", //姓名
      id_card: "", //身份证
      disabled: false,
      show_botto: false
    }
  },
  created() {},
  methods: {
    showPopup() {
      if (!this.real_name) return this.$toast("请输入姓名")
      if (!this.id_card) return this.$toast("请输入身份证号")
      this.$post({
        url: "/app/member_real/submit",
        data: { real_name: this.real_name, id_num: this.id_card, phone: this.phone, bank_num: this.bank_num },
        success: (res) => {
          this.$toast.clear(res.msg)
          this.xz_go("/")
        },
        tip: () => { setTimeout(() => {}, 1000) },
      })
    },
    // 完成滑动
    finishSlide(data) {
      // 按需使用返回值
      if (data.cls === false) {
        //验证加载失败
        this.loginForm.cls = false
      }
      if (data.sessionId) {
        //非人机操作
        this.loginForm.scene = data.scene
        this.loginForm.ncToken = data.token
        this.loginForm.sig = data.sig
        this.loginForm.sessionId = data.sessionId
        delete this.loginForm.cls
      }
      //1 为抢购藏品
      this.save()
    },
  }
}
</script>

<style lang="scss" scoped>
//大盒子
.box {width: 100%; padding: 0; min-height: 100vh;}
:deep(.van-dialog) {background: #333;}
.imgg {width: 62vw; height: 47vw; margin: 0 auto;}
.slider_box {width: 100%; height: 100%; background: #d9fdff; display: flex; align-items: center; .sm-pop-inner {display: flex; justify-content: center;}}
//单元格
:deep(.van-cell) {margin-bottom: 4vw;}
//表单
.from_box {width: 100%; padding: 0 2vw; --van-field-input-text-color: #000;}
.btn_box {padding: 3vw;  .van-button--success {height: 15vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); background-position: 0px 0px;}}
</style>
