<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="修改银行卡" left-arrow @click-left="xz_go('bankb_list')" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <!-- 表单 -->
    <div class="from_box">
      <van-field :border="false" clearable label="姓名" readonly v-model="name" type="text" placeholder="请输入姓名"></van-field>
      <van-field clearable :border="false" label="银行卡号" v-model="bank" type="text" placeholder="请输入银行卡号"></van-field>
      <van-field clearable :border="false" label="预留手机号" v-model="phone" type="text" placeholder="请输入预留手机号"></van-field>
      <div><!--留一个div保证最后一个输入框有下划线--></div>
    </div>
    <div style="height: 1vw"></div>
    <div class="cont"><van-checkbox v-model="checked">同意 <span class="txt">《吉吉数藏快捷支付服务协议》</span></van-checkbox></div>
    <div class="btn_box"><van-button size="normal" type="success" @click="fix">提 交</van-button></div>
    <div style="height: 4vw"></div>
    <div class="tit"><div class="h2">绑卡说明</div><div class="tit_txt">工商银行、农业银行、招商银行、兴业银行、部分地区商业银行等不支持充值，仅支持提现。</div></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: "",
      bank: "",
      banks: "",
      bankname: "",
      apicard: "",
      apiname: "",
      id: this.$route.query.id,
      checked: false,
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$get({
        url: "/app/member_bank/getBank",
        data: { id: this.id },
        success: (res) => {
          this.name = res.data.member_name
          this.bank = res.data.bank_account
          this.banks = res.data.bank_name
          this.bankname = res.data.bank
          this.apiname = res.data.ali_name
          this.apicard = res.data.ali_account
          this.checked = res.data.is_default == 0 ? (this.checked = false) : (this.checked = true)
        },
        tip: () => {},
      })
    },
    fix() {
      if (
        !/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(
          this.name
        )
      )
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.name) return this.$toast("请输入姓名")
      if (
        !/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(
          this.bank
        )
      )
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.bank) return this.$toast("请输入银行卡号")
      if (
        !/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(
          this.banks
        )
      )
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.banks) return this.$toast("请输入开户行")
      if (
        !/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(
          this.bankname
        )
      )
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.bankname) return this.$toast("请输入银行名称")
      this.$get({
        url: "/app/member_bank/editBank",
        data: {
          member_name: this.name,
          bank: this.bankname,
          bank_account: this.bank,
          bank_name: this.banks,
          ali_name: this.apiname,
          ali_account: this.apicard,
          id: this.id,
          is_default: this.checked ? "1" : "0",
        },
        success: () => {
          this.$toast("修改成功")
          this.$router.go(-1)
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style lang="scss" scoped>
//单元格
:deep(.van-cell) { margin-bottom: 4vw; background: #F5F5F5; .van-cell__value {flex: none;}}
.from_box {width: 100%; padding: 0 2vw; --van-field-label-color: #000; --van-field-input-text-color: #000;}
.cont {width: 92%; height: 20vw; margin: 4vw 4%; --van-checkbox-size: 4vw;}
.txt {color: #2A7AF0; font-size: 4vw;}
.btn_box { .van-button--success {width: 92%; height: 14.2vw; margin: 2vw 4%; background: #2A7AF0; border-radius: 20vw;} }
:deep(.van-button--warning) {border: 1px solid #01fdbd !important;}
:deep(.van-field__button) {display: flex;}
.field { :deep(.van-cell) { .van-cell__value {flex: 1; color: #000;} .van-field__label {display: flex; align-items: center;}}}
.tit {width: 92%; height: auto; margin: 2vw 4%;}
.h2 {color: #000; font-size: 3.7vw;}
.tit_txt {color: #959595; font-size: 3.2vw;}
</style>
