<template>
  <div class="box">
    <van-nav-bar title="消息" right-text="清除未读" left-arrow @click-left="xz_back" @click-right="toClearAll" :border="false" z-index="1000" />
    <div style="height: 2vw"></div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" ref="contentData">
      <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad" :offset="50">
        <div class="news" v-for="(item, index) in newsList" :key="index">
          <div v-if="item.is_read == 0" @click="toClear(item.id)">
            <div class="top">{{ item.create_time }}</div>
            <div class="mid">
              <div class="mid_lt">
                <div class="mid_img">
                  <van-badge dot v-if="item.is_read == 0"><img src="~@/assets/img/new.png" class="img" /></van-badge><img src="~@/assets/img/new.png" class="img" v-else />
                </div>
              </div>
              <div class="mid_rt"><div class="tit">系统通知</div><div class="con">{{ item.remark }}</div></div>
            </div>
          </div>
          <div v-if="item.is_read == 1">
            <div class="top">{{ item.create_time }}</div>
            <div class="mid">
              <div class="mid_lt">
                <div class="mid_img">
                  <van-badge dot v-if="item.is_read == 0"><img src="~@/assets/img/new.png" class="img" @click="toClear(item.id)" /></van-badge><img src="~@/assets/img/new.png" class="img" v-else />
                </div>
              </div>
              <div class="mid_rt"><div class="tit">系统通知</div><div class="con">{{ item.remark }}</div></div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="empty" v-if="newsList.length == 0"><van-empty description="暂时没有任何消息哟" /></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [],
      refreshing: false,
      loading: false,
      finished: false,
      pageIndex: 0, // 当前页
      limit: 10, // 分页数
      total: 0, // 总页数
      finishedText: ''
    };
  },
  created() {},
  methods: {
     // 上拉加载
     onLoad() {
      this.pageIndex += 1;
      setTimeout(this.getNewsList(this.pageIndex, 10), 0);
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.markList = [];
      this.loading = true;
      this.refreshing = true;
      this.finished = false;
      this.finishedText = "";
      setTimeout(this.onLoad(), 1000)
    },
    // 消息列表
    getNewsList(pageIndex, limit) {
      this.$get({
        url: "app/member/getMessage",
        data: { page: pageIndex, limit: limit },
        success: (res) => {
          if (res.code == 1) {
            this.loading = false;
            this.refreshing = false;
            if (res.data.data.length == 0) {
              this.finished = true; // 没有数据的话，加载完成
            } else {
              this.newsList = this.newsList.concat(res.data.data);
              this.total = res.data.total;
              if (this.newsList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        }
      })
    },
    // 消息已读
    toClear(newId) {
      this.$post({
        url: "app/member/setRead",
        data: { id: newId },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("消息已读！");
            this.$router.go(0);
          } else {
            this.$toast(res.msg);
          }
        }
      })
    },
    // 清除未读
    toClearAll() {
      let ids = [];
      let arr = [];
      for (let i = 0; i < this.newsList.length; i++) {
        if (this.newsList[i].is_read == 0) {
          arr = this.newsList[i].id;
          ids.push(arr);
        } 
      }
      let sentData = { id: ids.join(',') }
      this.$post({
        url: "app/member/setRead",
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            this.$toast("消息已读！");
            this.$router.go(0);
          } else {
            this.$toast(res.msg);
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.box {width: 100%; min-height: 100vh; background: #F7F7F9; --van-nav-bar-background-color: #fff; --van-nav-bar-text-color: #000;}
.news {width: 94%; height: auto; margin: 2vw 3%;}
.top {width: 100%; height: 5vw; line-height: 5vw; margin: 2vw auto; text-align: center; color: #666; font-size: 4vw;}
.mid {width: 98%; height: auto; margin: 4vw 1%; background: #fff; border-radius: 2vw; display: flex; padding: 2vw 0;}
.mid_lt {flex: 2;}
.mid_img {width: 100%; height: 16vw; text-align: center; margin-top: 4vw;}
.img{width: 14vw; height: 14vw;}
.mid_rt {flex: 6; width: 94%; height: auto; margin: 0 3%;}
.tit {color: #000; font-size: 4.05vw; font-weight: 600;}
.con {line-height: 1.2; color: #666; font-size: 3.2vw;}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
