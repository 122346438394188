<template>
  <div class="box">
    <van-nav-bar title="实名认证" left-arrow @click-left="xz_back" :border="false"></van-nav-bar>
    <div style="height: 20vw"></div>
    <div class="btn_box">
      <van-button size="large" type="success" :loading='loading'>验证中...</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      is_app: false,
    }
  },
  created() {
    this.getUrlKey()
  },
  methods: {
    getUrlKey() {
      let url = window.location.href
      console.log(url, "这是路径")
      // let auth_code = url.split("?auth_code=")[1].split("&app_id=")[0]
      // let cert_verify_id = url.split("&cert_verify_id=")[1]
      let auth_code = this.$route.query.auth_code
      let cert_verify_id = this.$route.query.cert_verify_id
      if (auth_code && cert_verify_id) {
        this.$toast.loading({
          message: "认证中...",
          forbidClick: true,
          duration: 0,
        })
        this.$get({
          url: "/app/ali_real/check_authentication",
          data: {
            type: 2,
            code_data: auth_code,
            verify_id: cert_verify_id,
          },
          success: (res) => {
            this.$toast.clear()
            console.log("是否实名", res)
            this.$toast(res.msg)
            setTimeout(() => {
              let path = `${this.httpPath}/#/mine`
              window.location.href = path
            }, 1500)
          },
          tip: () => {},
        })
      } else {
        this.$toast("实名链接返回错误")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
//大盒子
.box {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  // background: #121212;
  background: url(~@/assets/img/login_bg.png);
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  background-position: 0px 0px;
}
:deep(.van-dialog) {
  background: #333;
}
//欢迎语
.hello {
  padding-left: 7vw;
  // width: 37vw;
  font-size: 7vw;
  line-height: 11vw;
  color: #fff;
}

.remarks {
  padding-left: 7vw;
  font-size: 3vw;
  line-height: 11vw;
  color: #fff;
}

//单元格
:deep(.van-cell) {
  margin-bottom: 4vw;
}

//表单
.from_box {
  width: 100%;
  padding: 0 2vw;
}
.btn_box {
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}
</style>
