<template>
  <div class="box content">
    <!-- 顶部栏 -->
    <van-nav-bar title="市场详情" left-arrow @click-left="toBackGood" :border="false" z-index="1000" class="bg" />
    <div class="head">
      <van-image width="100%" height="100vw" :src="mark.good.product_image" v-if="mark && mark.good" />
      <div class="head_name" v-if="mark && mark.good">{{ mark.good.product_name }}</div>
      <div class="head_num"><div class="hn_box"><div class="hn_lab">发售总量 </div> <div class="hn_num" v-if="mark && mark.good">{{ mark.good.total_num || mark.good.num }}份</div></div></div>
    </div>
    <div style="height: 3vw"></div>
    <div class="tsftop2">
      <div class="cell_box">
        <div class="role">
          <div class="avatar"><img :src="mark.head_img" /></div> 
          <div class="text"><div class="name">{{ mark.nickname }}</div> <div class="time">持有者</div></div>
        </div>
        <div class="cell_btn" @click="toNumAll(mark.product_id, mark.good.total_num || mark.good.num )">全部编号 <img src="~@/assets/img/go.png" class="img_go" /></div> 
      </div>
      <div style="height: 3vw"></div>
      <div class="notice"><div class="good_tab"><div class="title"> <span>作品故事</span></div></div><div class="notice_text" v-if="mark && mark.good" v-html="mark.good.info"></div></div>
      <div style="height: 3vw"></div>
      <div class="panel_box">
        <div class="panel_list">
          <div class="panel_title">区块信息</div>
          <div class="ctitle"><van-cell title="链上标识" :border="false"><template #value><div>{{ getChain(mark.hash) }}</div></template></van-cell></div>
          <van-cell title="查看合约" :border="false" />
          <van-cell title="认证标准" :border="false" value="ERC-721" />
          <van-cell title="认证网络" :border="false" value="文昌生态链" />
        </div>
        <van-grid direction="horizontal" :column-num="4" icon-size="5vw" :border="false">
          <van-grid-item :icon="require('@/assets/img/shield.png')" text="唯一编号" />
          <van-grid-item :icon="require('@/assets/img/shield.png')" text="可信记录" />
          <van-grid-item :icon="require('@/assets/img/shield.png')" text="永久存证" />
          <van-grid-item :icon="require('@/assets/img/shield.png')" text="不可篡改" />
        </van-grid>
      </div>
      <div style="height: 3vw"></div>
      <!-- //发行方 -->
      <div class="issuer">
        <div style="height: 2vw"></div>
        <div class="iu_top">
          <div class="iu_name" v-if="mark && mark.good">{{ mark.good.issuer }}</div>
          <div class="iu_more" @click="toBarnd(mark.good.mer_id)">更多品牌作品 <img src="~@/assets/img/go02.png" class="img" /></div>
        </div> 
        <div class="iu_bot" v-if="mark && mark.good" v-html="mark.good.meta_info"></div>
        <div style="height: 2vw"></div>
      </div>
      <div style="height: 3vw"></div>
      <!-- •购买须知 -->
      <div class="notice"><div class="good_tab"><div class="title"><span>购买须知</span></div></div><div class="notice_text" v-if="mark && mark.good" v-html="buy_notes"></div></div>
    </div>
    <div class="bsn"><img src="~@/assets/img/bsn.png" /></div>
    <div style="height: 20vw"></div>
    <div class="submit">
      <van-submit-bar label=" " :disabled="disabled" button-text="我想要" @click="showPopup"><template #default><div class="default">￥{{ mark.price }}</div></template></van-submit-bar>
    </div>
    <van-popup v-model:show="show_detail" position="bottom" :style="{ height: '88%' }" style="background: #fff">
      <div class="det_top"><div class="top_lt">藏品列表</div><div class="top_rt" @click="toClose">X</div></div>
      <div class="det_mid">
        <div class="mid_lt">寄售列表</div>
        <div class="mid_rt">
          <ul class="nav">
            <li :class="curId == 0 ? 'cur' : ''" @click="changeTab(0)" class="tab_opt">按价格升序</li>
            <li :class="curId == 1 ? 'cur' : ''" @click="changeTab(1)" class="tab_opt">按价格降序</li>
          </ul>
        </div>
      </div>
      <div class="nav_txt" v-show="curId === 0" v-for="(item, index) in numList" :key="index" @click="toBack(item.id)">
        <div class="nav_lt">
          <ul>
            <li><span class="nav_icon"><img :src="item.head_img" class="nav_img" /> {{item.nickname}}</span> <span class="nav_tit">寄售</span></li> 
            <li><span class="nav_num"># {{ item.goods_number }}/{{ total_num }}</span></li> 
            <li class="small">链上标识： {{ item.hash_s }}</li>
          </ul>
        </div>
        <div class="nav_mm"><ul><li class="nav_price">￥ {{ item.price }}</li> <li class="small" style="text-align: right;">售价</li></ul></div>
        <div class="nav_rt"><img src="~@/assets/img/go.png" class="go_img" /></div>
      </div>
      <div class="nav_txt" v-show="curId === 1" v-for="(item, index) in numList" :key="index" @click="toBack(item.id)">
        <div class="nav_lt" >
          <ul>
            <li><span class="nav_icon"><img :src="item.head_img" class="nav_img" /> {{item.nickname}}</span> <span class="nav_tit">寄售</span></li> 
            <li><span class="nav_num"># {{ item.goods_number }}/{{ total_num }}</span></li> 
            <li class="small">链上标识： {{ item.hash_s }}</li>
          </ul>
        </div>
        <div class="nav_mm"><ul><li class="nav_price">￥ {{ item.price }}</li> <li class="small" style="text-align: right;">售价</li></ul></div>
        <div class="nav_rt"><img src="~@/assets/img/go.png" class="go_img" /></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import modelGltf from "vue-3d-model/src/model-gltf.vue"
import noCaptcha from "@/components/noCaptcha.vue"
export default {
  components: { modelGltf, noCaptcha },
  data() {
    return {
      loginForm: { session_id: "", verify_token: "", sig: "" },
      reload: true,
      show_botto: false,
      classifty: this.$route.query.type,
      ordersn: this.$route.query.ordersn,
      id: this.$route.query.id,
      classify: "",
      show_loading: true,
      text: "0xDD92F6922d59581c87E42Ecb06245F8ef393bb4C", //合约地址
      drawtime: "",
      drawtimeo: "",
      drawtimet: "",
      drawtimes: "",
      position: { x: 0, y: 0, z: -0.5 },
      rotation: { x: 0, y: 0, z: 0 },
      list: {},
      time: "",
      times: 30 * 60 * 60 * 1000,
      btime: "",
      blind: {},
      bbox: {}, //盲盒内的信息
      turn: {}, //开盲盒详情
      mark: {}, //市场详情
      shows: false,
      isLoading: false,
      luck: [], //抽签记录
      disabled: false,
      show: false,
      show_detail: false,
      checked: true,
      curId: 1,
      goods_id: '',
      numList: [],
      sort_val: '',
      total_num: '',
      total_page: '',
      series_id: '',
      level: '',
      field: '',
      sort: '',
      seriesId: '',
      keywords: '',
      val: '',
      bid: '',
      brand: 0,
      buy_notes: ''
    }
  },
  created() {
    this.seriesId = this.$route.query.series_id;
    this.value1 = this.$route.query.value1;
    this.level = this.$route.query.level;
    this.field = this.$route.query.field;
    this.sort = this.$route.query.sort;
    this.value3 = this.$route.query.value3;
    this.keywords = this.$route.query.keywords;
    this.bid = this.$route.query.bid;
    this.brand = this.$route.query.brand;
    this.get_item();
    window.scrollTo(0, 0)
  },
  methods: {
    // 返回市场
    toBackGood() {
      if (!this.brand) {
        this.$router.push({ 
          path: "/good_list", 
          query: { id: this.goods_id, series_id: this.seriesId, value1: this.value1, level: this.level, field: this.field, sort: this.sort, value3: this.value3, keywords: this.keywords } 
        })
      } else {
        if (this.brand == 1) {
          this.$router.push({ path: "/brand", query: { id: this.bid } })
        } else if (this.brand == 2) {
          this.$router.push({ path: "/brand", query: { id: this.bid } })
        }
      }
    },
    showPopup() {
      this.market()
    },
    join() {
      this.show = true
    },
    confirm() {
      this.$get({
        url: "/app/goods_draw/doawPrice",
        data: { id: this.id },
        success: () => {
          this.$toast("参与成功")
          this.getdraw()
        },
        tip: () => {},
      })
    },
    get_item() {
      this.getmark()
    },
    heyue() {
      window.location.href = "https://hecoinfo.com/address/0xDD92F6922d59581c87E42Ecb06245F8ef393bb4C"
    },
    geTel(text) {
      return ( text.substring(0, 10) + "***********" + text.substr(text.length - 10) )
    },
    // 截取合约
    getChain(text) {
      if (text.substring(0, 5) === "avata") {
        return text;
      } else {
        return (text.substring(0, 10) + "***********" + text.substr(text.length - 10));
      }
    },
    onLoad() {
      this.show_loading = false
      this.rotate()
    },
    rotate() {
      this.rotation.y += 0.01
      requestAnimationFrame(this.rotate)
    },
    //市场
    getmark() {
      this.$get({
        url: "/app/goods_rent/info",
        data: { id: this.id },
        success: (res) => {
          this.mark = res.data;
          this.buy_notes = res.data.purchase_info.replace(/\n/g,'<br>');
          this.series_id = res.data.series_id;
          this.goods_id = res.data.product_id;
        },
        tip: () => {},
      })
    },
    // 完成滑动
    finishSlide(data) {
      if (data.cls === false) {
        //验证加载失败
        this.loginForm.cls = false
      }
      if (data.sessionId) {
        //非人机操作
        this.loginForm.scene = data.scene
        this.loginForm.ncToken = data.token
        this.loginForm.sig = data.sig
        this.loginForm.sessionId = data.sessionId
        delete this.loginForm.cls
      }
      this.market();
    },
    //市场支付
    market() {
      this.disabled = true
      this.$get({
        url: "/app/rent_orders/createOrder",
        data: {
          rent_id: this.mark.id,
          session_id: this.loginForm.sessionId,
          verify_token: this.loginForm.ncToken,
          sig: this.loginForm.sig,
        },
        success: (res) => {
          console.log(res)
          setTimeout(() => {
            this.disabled = false
            this.$router.push({ path: "markbox", query: { ordersn: res.data.order_sn, form: "市场藏品", type: 3, id: this.mark.id } })
          }, 500)
          this.show_botto = false
          //this.reload = true // 验证通过后，重置滑块设置为false
        },
        tip: () => {
          this.$toast("活动太火爆了，请稍后再试")
          setTimeout(() => {
            this.disabled = false
            this.show_botto = false
            //this.reload = true // 验证通过后，重置滑块设置为false.
          }, 1000)
        },
      })
    },
    // 更多品牌
    toBarnd(id) {
      this.$router.push({ path: "brand", query: { id: id } })
    },
    // 全部编号弹窗
    toNumAll(id, num) {
      this.goods_id = id;
      this.total_num = num;
      this.show_detail = true;
      this.changeTab(0)
    },
    // 关闭全部编号弹窗
    toClose() {
      this.show_detail = false;
    },
    changeTab(index){
      this.curId = index;
      if (this.curId == 0) {
        this.sort_val = 'asc';
      } else {
        this.sort_val = 'desc';
      }
      this.getNumberList(this.sort_val);
    },
    // 获取全部编号
    getNumberList() {
      this.$get({
        url: "/app/goods_rent/allNumber",
        data: { series_id: this.seriesId, product_id: this.goods_id, field: 'price', sort: this.sort_val, page: 1, limit:this.total_num },
        success: ((res) => {
          if (res.code == 1) {
            this.numList = res.data.data;
          }
        })
      })
    },
    // 返回
    toBack(id) {
      this.$router.push({ 
        path: "/details", query: { id: id, type: 3, series_id: this.seriesId, level: this.level, field: this.field, sort: this.sort, value3: this.value3, keywords: this.keywords }
      })
      this.show_detail = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.box {--van-nav-bar-icon-color:#fff; --van-nav-bar-title-text-color: #fff;}
.content {width: 100%; background: #F7F7F9;}
.bg {background: #000;}
.bg_img {background: url("~@/assets/img/gg_bg.png") no-repeat; background-size: 100% 100%; padding-top: 10vw;}
.head {
  width: 100%; height: 120vw; margin: 0 auto; background: #fff;
  .head_top {width: 96%; height: 5vw; line-height: 5vw; margin: 0 2%; color: #9997A7; font-size: 3.2vw; img {width: 4vw; height: 4vw; vertical-align: middle; margin: 0 1vw;}}
  .head_name {width: 96%; height: 5vw; line-height: 5vw; margin: 1vw 2%; color: #222222; font-size: 4.2vw; font-weight: 600;}
  .head_num {
    width: 96%; height: 6vw; margin: 2vw 2%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .hn_box {
      width: 40%; height: 6vw; display: flex; flex-wrap: nowrap; flex-direction: row; font-size: 3.2vw;
      .hn_lab {width: 18vw; height: 6vw; line-height: 6vw; text-align: center; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; font-size: 3.2vw;}
      .hn_num {width: 20vw; height: 6vw; line-height: 6vw; text-align: center; background: #000;}
    }
  }
}
.tsftop2 {position: relative; z-index: 99;}
.gangf {
  position: relative; height: 2px; display: flex; justify-content: space-between; transform: translateY(8vw); border: #139990 1px solid;
  .dian {width: 1.5vw; height: 1.5vw; transform: translateY(-1vw); border-radius: 50%; background: #09704b; display: inline-block; margin-right: 0.5vw;} 
}
.slider_box {width: 100%; height: 100%; background: #222; display: flex; align-items: center;}
.details_title {display: flex; align-items: center; justify-content: center; .turn {width: 6vw; height: 6vw; img {width: 100%; height: 100%; display: block;} } }
.tag {
  display: flex; justify-content: center; --van-tag-primary-color: linear-gradient(#4ba57b, #1f7e5e); --van-tag-border-radius: 0; --van-tag-padding: 1vw 2vw;
  .van-tag--primary {color: #fff; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); border-radius: 0; padding: 1vw 2vw;}
  .van-tag--success {color: #fff; background: #383736; border-radius: 0; padding: 1vw 2vw;} 
}
.draw {padding: 0 50px; 
  .drawo {font-size: 35px; font-weight: 500; color: #fff; padding-bottom: 20px;}
  .drawt {display: flex; justify-content: space-between; align-items: center; }
  .dinduct {padding-top: 15px; font-size: 30px; color: #4ba57b;}
}
.mark_box {display: flex; align-items: center; justify-content: center;
  .mark {display: inline-block; padding: 2vw 2vw; background: rgba(0, 0, 0, 0.5); font-size: 3vw; color: #00ff9b;
    .van-icon {margin-right: 2vw;} :deep(.van-icon__image) {display: inline-block; margin-top: -5px;} .over {color: #db0601;} }
  .downtime {display: flex; align-items: center; font-size: 3vw; color: #00ff9b; 
    .down { .block {background: #00ff9b; margin-right: 5px; padding: 6px;} .colon {color: #fff; margin-right: 5px;} } }
}
.cell_box {width: 100%; height: 22vw; margin: 0 auto; display: flex; justify-content: space-between; background: #fff; padding: 4vw 0 0 5vw;
  .role {position: relative; display: flex; align-items: center; margin-bottom: 3vw;
    .avatar {width: 11vw; height: 11vw; border-radius: 50%; overflow: hidden; margin-right: 3vw; img {width: 100%; height: 100%; display: block;} }
    .text {.name {color: #000; font-size: 4vw; line-height: 1.5;} .time {color: #848484; font-size: 3.2vw; line-height: 1.5;} }
  }
  .cell_name {font-size: 32px; font-weight: 600; color: #ffffff; padding-top: 15px;}
  .cell_btn {line-height: 4.6; color: #ABA8BA; font-size: 3.2vw; margin-right: 1.5vw;}
  .img_go {width: 2.5vw; height: 3vw; vertical-align: middle; margin-top: -1vw;}
}
.main {width: 100%; height: auto; margin: 0 auto; background: #fff;}
.h2 {width: 95%; height: 5vw; line-height: 5vw; margin: 2vw 2.5%; color: #000; font-size: 4.2vw; font-weight: 600; padding: 3.5vw 0; margin-bottom: 4vw;}
.main_con {width: 95%; height: auto; margin: 2.5vw 2.5%;}
.panel_box {
  padding: 2vw 1.5vw; margin: 0 auto; width: 100%; padding-bottom: 20px; color: #000; background: #fff;
  .panel_list {padding: 0 2vw;} .panel_title {font-size: 4.28vw; font-weight: 600;} .ctitle {font-size: 3.5vw; :deep(.van-cell__title) { flex: none; } }
  :deep(.van-grid-item__text) {color: #FF2222  !important;}
  --van-cell-vertical-padding: 1vw; --van-cell-horizontal-padding: 0; --van-cell-text-color: #000; --van-cell-value-color: #000; --van-grid-item-content-padding: 3vw 0;
  --van-grid-item-content-background-color: rgba(0, 238, 166, 0);
}
.issuer {
  width: 100%; height: auto; margin: 0 auto; background: #fff;
  .iu_top {
    width: 96%; height: 8vw; margin: 0 2%; color: #000; display: flex; flex-wrap: nowrap;
    .iu_name {flex: 1; line-height: 8vw; text-align: left; font-size: 4.2vw; font-weight: 600;} 
    .iu_more {flex: 1; line-height: 8vw; font-size: 4vw; text-align: right; .img {width: 3.7vw; height: 3.7vw; vertical-align: middle; margin-top: -1vw;}} }
  .iu_bot {width: 96%; height: 6vw; margin: 0 2%; color: #000; font-size: 3.4vw;}
}
.notice {
  width: 100%; margin: 0 auto; padding: 1vw 3vw; 
  .title {color: #000; font-size: 4vw; font-weight: 600;}
  .notice_text {
    color: #999; background: transparent !important;
    /deep/p {
      color: #999 !important; background: transparent !important;
      img {width: auto !important; height: auto !important; max-width: 100% !important; max-height: 100% !important; object-fit: contain;}
      video {width: auto !important; height: auto !important; max-width: 100% !important; max-height: 100% !important; object-fit: contain;} 
    }
    /deep/ * {color: #999 !important; background: transparent !important; word-break: break-word; max-width: 100% !important; width: auto !important;}
  }
}
.submit {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {justify-content: space-between !important;
    .default {font-size: 48px; background-clip: text; color: #000;}
    .van-submit-bar__button {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);}
  }
}
.submits {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {justify-content: space-between !important;
    .default {font-size: 48px; background-clip: text; color: #000;}
    .van-submit-bar__button {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);}
  }
}
.good_tab {display: flex; .title {margin-right: 3vw; margin-bottom: 2.4vw; color: #000; span {line-height: 8vw; display: inline-block; height: 8vw;} } }
.good_tabs {padding: 0 50px; margin-bottom: 30px; .title { span {display: inline-block;}} }
// 底部图标
.bsn {width: 92%; height: 14vw; margin: 4vw 4%; text-align: center; img {width: 50vw; height: 14vw; vertical-align: middle;}}
// 全部编号
.det_top {width: 96%; height: 42px; line-height: 42px; margin: 20px 2%; color: #000; display: flex;}
.top_lt {flex: 4; color: #222222; font-size: 4.8vw; font-weight: 500; }
.top_rt {flex: 2; width: 2.67vw; height: 2.67vw; text-align: right; color: #000; font-size: 2.7vw; opacity: 1;}
.det_mid {width: 96%; height: 60px; line-height: 50px; margin: 2vw 2%; display: flex;}
.mid_lt {flex: 4; width: 100%; height: 60px; line-height: 60px; color: #ABABB2;}
.mid_rt {flex: 6; width: 100%; height: 60px; text-align: right; color: #8A8B8F ;}
.nav li {display: inline; width: 96%; text-decoration: none; padding: 15px 20px; background: #F5F6F7; font-size: 3vw;}
.nav .cur {width: 80%; height: 50px; background: #ffffff; color: #45474A; font-size: 3.2vw; font-weight: 700;}
.nav_txt{width: 95%; height: 18vw; margin: 5vw 2.5%; display: flex; border-bottom: #E8E8E8 solid 1px;}
.nav_txt li {width: 96%; height: 40px; line-height: 40px;}
.nav_icon {height: 20px; line-height: 20px; color: #000; font-size: 3.2vw;}
.nav_img {width: 4.5vw; height: 4.5vw; vertical-align: middle; border-radius: 50%;}
.nav_num {height: 20px; line-height: 20px; color: #000; font-size: 4.4vw;}
.small {color: #A2A2A8; font-size: 3.2vw;}
.nav_lt {flex: 6;}
.nav_mm {flex: 3.5; text-align: right;}
.nav_rt {flex: 0.5; text-align: left; line-height: 60px;}
.ck {width: 32px; height: 32px; margin-top: 5px;}
.go_img {width: 32px; height: 28px; vertical-align: middle; margin-top: 10px;}
.nav_tit {width: 90%; height: 30px; background: #FFECEA; color: #FC493B; font-size: 20px; padding: 5px 10px; margin-top: -25px;}
.nav_price {color: #26221D;}

</style>
