"use strict";
// var vue = require('vue');
// var devtoolsApi = require('@vue/devtools-api');
// export default {
//     // 接收两个参数
//     // app: 应用上下文的实例
//     // options：插件输入的选项
//     install: (app, options) => { 
//         console.log('app', app);
//         console.log('options', options);
//         // 做一些处理
//         // ……
//     }
// }

export default function xz_fun(app) {
  // console.log("app", app);
  // console.log("options", options);
  const _this = app.config.globalProperties;
  app.config.globalProperties.xz_back = function () {
    //滚动到页面顶部
    window.scrollTo(0, 0);
    _this.isBack = true;
    // console.log(_this.isBack, "------");
    _this.$router.go(-1);
  };

  //跳转页面
  app.config.globalProperties.xz_go = function (name, val) {
    //滚动到页面顶部
    window.scrollTo(0, 0);
    //设置不是返回上一页
    _this.isBack = false;
    //如果是传的对象 传参需要 {变量名:参数,变量名:参数}
    if (typeof val == "object") {
      _this.$router.push({ path: name, query: val });
    } else if (typeof val == "number" || typeof val == "string") {
      _this.$router.push({ path: name, query: { id: val } });
    } else {
      _this.$router.push({ path: name });
    }
  };
  app.config.globalProperties.xz_set = function (key, value) {
    //存储到本地
    localStorage.setItem(key, value);
  };

  app.config.globalProperties.xz_get = function (key) {
    //获取本地数据
    let the_key = localStorage.getItem(key);
    if (the_key) {
      localStorage.removeItem(key);
      return the_key;
    }
  };

  //转圈圈
  app.config.globalProperties.xz_load = function (name) {
    this.$toast.loading({
      message: name,
      forbidClick: true,
      duration: 0,
    });
  };

  // app.config.globalProperties.xz_url = function () {
  //   //获取本地数据
  //   let the_key = localStorage.getItem(key);
  //   return the_key;
  // };

  // Vue.prototype.qt_data = function () {
  //   console.log("桥通获取路由参数")
  //   return router.app._route.query;
  // }
}

// import router from "@/router/index";
// // import Router from "vue-router";
// import { ImagePreview } from "vant";
// // import vm from  'vue';

// //小政提取的公共方法
// export default {
//   install(Vue) {
//     //转圈圈
//     Vue.prototype.qt_load = function (name) {
//       this.$toast.loading({ message: name, forbidClick: true, duration: 0 });
//     };

//     Vue.prototype.qt_look = function (name) {
//       ImagePreview({ images: [name], closeable: true });
//     };

//     //复制文本方法
//     Vue.prototype.qt_copy = function (name) {
//       this.$copyText(name).then(
//         function (e) { Vue.prototype.$toast("复制成功"); },
//         function (e) { Vue.prototype.$toast("复制失败"); }
//       );
//     };

//     (Vue.prototype.qt_tel = function (ph) { window.location.href = "tel://" + ph; }),
//       (Vue.prototype.qt_cut = function (num, cut) {
//         console.log("小政截取金额");
//         return Number(num).toFixed(cut + 1).slice(0, -1);
//       });

//     //1.跳转路由带参数和不带参数,带参数请将要跳转的页面名称传入
//     //如何拿去参数
//     // created() {
//     //   console.log("获取的id" + this.$route.query.id);
//     // },
//     Vue.prototype.qt_go = function (name, val) {
//       window.scrollTo(0, 0);
//       if (typeof val == "object") {
//         router.push({ path: name, query: val });
//       } else if (typeof val == "number" || typeof val == "string") {
//         router.push({ path: name, query: { id: val } });
//       } else {
//         router.push({ path: name });
//       }
//       console.log("桥通跳转路由");
//     };
//     Vue.prototype.qt_id = function () {
//       console.log("桥通获取路由参数");
//       return router.app._route.query.id;
//     };
//     Vue.prototype.qt_data = function () {
//       console.log("桥通获取路由参数");
//       return router.app._route.query;
//     };
//     //如何拿去参数
//     Vue.prototype.qt_goto = function (name, item) {
//       window.scrollTo(0, 0);
//       router.push({ path: name, query: { item: JSON.stringify(item) } });
//       console.log("桥通跳转路由JOSN格式");
//     };

//     Vue.prototype.qt_item = function () {
//       console.log("桥通获取JOSN格式数据");
//       return JSON.parse(router.app._route.query.item);
//       // JSON.stringify();
//       // JSON.parse();
//     };

//     Vue.prototype.qt_goin = function (name) {
//       window.scrollTo(0, 0);
//       router.isBack = true;
//       router.replace({ path: name });
//       console.log("桥通关闭页面跳转路由");
//     };
//     //2.返回上一页
//     Vue.prototype.qt_back = function () {
//       window.scrollTo(0, 0);
//       router.isBack = true;
//       router.go(-1);
//       console.log("桥通返回上一页");
//     };
//     Vue.prototype.qt_back2 = function () {
//       window.scrollTo(0, 0);
//       router.isBack = true;
//       router.go(-2);
//       console.log("桥通返回上二页");
//     };

//     // qt_back(){
//     //   this.$router.isBack = true;
//     //   this.$router.go(-1);
//     // },

//     // 3.本地存储封装
//     Vue.prototype.qt_set = function (key, value) {
//       localStorage.setItem(key, value);
//       console.log("桥通保存到本地操作");
//     };

//     Vue.prototype.qt_get = function (key) {
//       console.log("桥通获取本地数据");
//       return localStorage.getItem(key);
//     };

//     Vue.prototype.qt_del = function (key) {
//       console.log("桥通删除本地数据");
//       localStorage.removeItem(key);
//     };

//     Vue.prototype.qt_say = function (val) {
//       //页面滚动到顶部
//       console.log("说话了");
//       Vue.prototype.$toast(val);
//     };
//     Vue.prototype.qt_top = function () {
//       //页面滚动到顶部
//       console.log("回到顶部");
//       window.scrollTo(0, 0);
//     };
//     // 注册一个全局自定义指令 `v-focus`
//     Vue.directive("focus", {
//       // 当被绑定的元素插入到 DOM 中时……
//       inserted: function (el) {
//         // 聚焦元素
//         el.focus(); // 页面加载完成之后自动让输入框获取到焦点的小功能
//       },
//     });

//     //防抖函数 防抖常用于 input 输入 变化触发接口 和 点击按钮 防止连点
//     // Vue.prototype.qt_click=function(fn, wait){
//     //   let timeout = null;
//     //   wait = wait || 600;
//     //   return function () {
//     //     let that = this;
//     //     if(timeout !== null)   clearTimeout(timeout);
//     //     timeout = setTimeout(() => {
//     //       console.log('触发防抖函数');
//     //       fn.apply(that);
//     //     }, wait);
//     //     }
//     //   }
//   },
// };
