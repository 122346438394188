import { createRouter, createWebHashHistory } from "vue-router";

import children from "./modules/tabbar";
import pages from "./modules/pages";

const routes = [
  ...pages,
  {
    path: "/",
    component: () => import("@/views/tabbar"),
    children,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

//路由拦截器
// Router.beforeEach((to, from, next) => {
//     console.log(to);
//     // to.name 为无需使用token的页面 可追加
//     if (localStorage.getItem('token') || to.name="") { next(); } else { next({ name: "" }) }
// })

// export default Router;
