<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar
      title="支付密码"
      left-arrow
      @click-left="xz_back"
      :border="false"
      z-index="1000"
    />

    <!-- //输入充值数量 -->
    <div class="input_box">
      <div class="cell">
        <van-cell title="请输入支付密码" />
      </div>
      <div style="height: 5vw"></div>
      <van-cell-group inset>
        <van-field
          v-model="password"
          type="password"
          maxlength="6"
          clearable
          placeholder="请输入支付密码"
        />
      </van-cell-group>
      <div style="height: 2vw"></div>
      <div class="text">
        <div></div>
        <div class="red" @click="$router.push('/updatepass')">忘记密码</div>
      </div>
    </div>

    <div class="btn_box">
      <!-- 登录按钮 -->
      <van-button size="large" type="success" :disabled="disabled" @click="save"
        >确认提现</van-button
      >

      <div class="text">工作时间2小时内到账</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      password: "",
    };
  },
  created() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.input_box {
  align-items: center;
  margin: 0 auto;
  padding: 2vw;
  width: 90vw;
  border: 1px solid;
  --van-cell-value-color: #ff5454;
  border-image: linear-gradient(
      to right,
      rgba(156, 233, 203, 0.3),
      rgba(195, 242, 223, 0.3),
      rgba(20, 182, 213, 0.3),
      rgba(255, 255, 255, 0.3)
    )
    1 1;
  background: rgba(0, 0, 0, 0.5);
  .cell {
    :deep(.van-cell) {
      padding: 0;
      .van-badge__wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .van-cell-group {
    width: 80vw;
    height: 12vw;
    background: linear-gradient(to right, #454545, #242424);
    border: 1px solid;
    border-image: linear-gradient(
        to right,
        rgba(156, 233, 203, 1),
        rgba(195, 242, 223, 1),
        rgba(20, 182, 213, 1),
        rgba(255, 255, 255, 1)
      )
      1 1;

    :deep(.van-cell__title) {
      width: 3vw;
      font-size: 3vw;
      background-image: linear-gradient(#0dafda, #58ffa1);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 800;
    }
    :deep(.van-field__control) {
      // font-size: 6vw;
      // display: flex;
      // align-items: flex-end;
      background-image: linear-gradient(#0dafda, #58ffa1);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 800;

      // span {
      //   font-size: 3vw;
      //   line-height: 7vw;
      // }
      // div {
      //   height: 9vw;
      //   line-height: 9vw;
      // }
    }
  }
  .text {
    width: 83vw;
    margin: 0 auto;
    font-size: 3vw;
    display: flex;
    justify-content: space-between;
    padding-left: 3vw;
    .red {
      color: #ff5454;
    }
  }
}

//确认按钮
.btn_box {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3vw;
  bottom: 0;
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
  .text {
    color: #ff5c5c;
    font-size: 3vw;
    text-align: center;
  }
}
</style>
