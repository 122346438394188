<template>
  <div class="box">
    <van-nav-bar title="密码支付" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div style="height: 20px"></div>
    <div class="set">
      <div class="seto">
        <div class="sett">请输入支付密码</div>
        <div class="setf">
          <van-field v-model="password" type="password" placeholder="请输入支付密码" />
        </div>
        <div class="forget" @click="$router.push('/updatepass')">忘记密码</div>
      </div>
    </div>
    <div class="popup">
      <van-popup v-model:show="show" :close-on-click-overlay="false">
        <div class="popupo">
          <div class="buy">购买成功</div>
          <div class="buyo"><img src="~@/assets/img/set01.png" /></div>
          <div class="buys">
            <van-button size="large" v-if="type == 1" type="success" :disabled="disabled" @click="$router.push('/orderlist')">前往查看订单</van-button>
            <van-button size="large" v-if="type == 2" type="success" :disabled="disabled" @click="$router.push('/blinds')">前往查看订单</van-button>
            <van-button size="large" v-if="type == 3" type="success" :disabled="disabled" @click="$router.push('/market')">前往查看订单</van-button>
          </div>
        </div>
      </van-popup>
    </div>
    <div class="height"></div>
    <div class="btn_box">
      <van-button size="large" v-if="type == 1" type="success" :loading="load" @click="open">立即支付</van-button>
      <van-button size="large" v-if="type == 2" type="success" :loading="load" @click="border">立即支付</van-button>
      <van-button size="large" v-if="type == 3" type="success" :loading="load" @click="morder">立即支付</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      show: false,
      load: false,
      ordersn: this.$route.query.ordersn,
      type: this.$route.query.type,
      radio: this.$route.query.radio,
    }
  },

  mounted() {},

  methods: {
    //藏品
    open() {
      if (!this.password) return this.$toast("请输入支付密码")
      this.load = true
      this.$get({
        url: "/app/goods_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 3 },
        success: (res) => {
          console.log(res)
          setTimeout(() => {
            this.load = false
            this.$router.push({
              path: "/optorder",
              query: {
                ordersn: this.ordersn,
                type: 1,
                radio: this.radio,
              },
            })
          }, 2000)
        },
        tip: () => { this.load = false },
      })
    },
    //盲盒
    border() {
      if (!this.password) return this.$toast("请输入支付密码")
      this.load = true
      this.$post({
        url: "/app/blind_box_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 3 },
        success: (res) => {
          console.log(res)
          setTimeout(() => {
            this.load = false
            this.$router.push({
              path: "/optorder",
              query: {
                ordersn: this.ordersn,
                type: 2,
                radio: this.radio,
              },
            })
          }, 2000)
        },
        tip: () => {
          this.load = false
        },
      })
    },
    //市场
    morder() {
      if (!this.password) return this.$toast("请输入支付密码")
      this.load = true
      this.$post({
        url: "/app/rent_orders/pay ",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 3 },
        success: (res) => {
          console.log(res)
          setTimeout(() => {
            this.$router.push({
              path: "/optorder",
              query: {
                ordersn: this.ordersn,
                type: 3,
                radio: this.radio,
              },
            })
            this.load = false
          }, 2000)
        },
        tip: () => {
          this.load = false
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  // position: relative;
}
.set {
  padding: 0 30px;
  .seto {
    // border: 1px solid #fff;
    background: #000;
    padding: 20px 0 50px 20px;
    .sett {
      font-size: 28px;
      font-weight: 500;
      color: #ffffff;
    }
    .setf {
      width: 90%;
      padding-top: 30px;
      :deep(.van-cell) {
        //   border: 1px solid #fff;
        background: rgb(70, 70, 70);
      }
    }
    .forget {
      font-size: 24px;
      font-weight: 400;
      color: #14702f;
      text-align: right;
      padding-top: 10px;
      padding-right: 70px;
    }
  }
}
.popup {
  :deep(.van-popup) {
    background: transparent;
    border: none;
  }
  .popupo {
    width: 618px;
    height: 658px;
    background-image: url("~@/assets/img/s08.png");
    background-size: 618px 658px;
    background-repeat: no-repeat;
    text-align: center;
    .buy {
      font-size: 32px;
      font-weight: 400;
      color: #333;
      padding-top: 58px;
      padding-bottom: 46px;
    }
    .buyo {
      width: 298px;
      height: 286px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .buys {
      padding: 30px 60px 0;
      .van-button--success {
        background: url(~@/assets/img/set02.png);
        background-size: 100% 100%;
        overflow: hidden;
        background-position: 0px 0px;
        color: #fff;
      }
    }
  }
}
.height {
  width: 100%;
  height: 50vw;
}
.btn_box {
  width: 100%;
  margin: 0 auto;
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}
</style>
