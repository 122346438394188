<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="注册" left-arrow @click-left="xz_back" fixed :border="false" z-index="1000" />
    <div class="hello"><img src="@/assets/img/logo.png" alt=""><div> 欢迎来到道拍</div></div>
    <!-- 表单 -->
    <div class="frombox">
      <van-field clearable v-model="phone" type="digit" maxlength="11" placeholder="请输入手机号"><template #left-icon><img src="@/assets/img/icon01.png" alt=""></template></van-field>
      <!-- 获取手机验证码按钮 封装 位置 在 vant-extend ===》 添加了防抖防止连点-->
      <van-field clearable v-model="code" type="digit" placeholder="请输入验证码">
        <template #button><vant-code url="/app/auth/sendSms" scene="register" :phone="phone"></vant-code></template>
        <template #left-icon><img src="@/assets/img/icon02.png" alt=""></template>
      </van-field>
      <van-field clearable v-model="password" :type="pass_type" :readonly="phone == '' ? true : false" placeholder="请输入6～12位数字+字母密码" 
       :right-icon="pass_type == 'password' ? 'closed-eye' : 'eye'" @click-right-icon="pass_type == 'password' ? (pass_type = 'text ') : (pass_type = 'password')">
        <template #left-icon><img src="@/assets/img/icon03.png" alt=""></template>
      </van-field>
      <van-field clearable v-model="second_password" :type="second_type" :maxlength="6" :readonly="phone == '' ? true : false" placeholder="请输入6位交易密码" 
       :right-icon="second_type == 'password' ? 'closed-eye' : 'eye'" @click-right-icon="second_type == 'password' ? (second_type = 'text ') : (second_type = 'password')">
        <template #left-icon><img src="@/assets/img/icon03.png" alt=""></template>
      </van-field>
      <van-field clearable v-model="referral_code" type="text" :readonly="readonly" placeholder="推荐码选填">
        <template #left-icon><img src="@/assets/img/icon04.png" alt=""></template>
      </van-field>
      <div><!--留一个div保证最后一个输入框有下划线--></div>
    </div>
    <div class="treaty"><div>用户登录表示同意</div><router-link to="agreement" class="treaty_text">《用户使用条款》</router-link></div>
    <div style="height: 6vw"></div>
    <div class="btn_box"><van-button size="large" type="success" @click="save">注册</van-button></div>
    <div class="reg_btn"><div @click="toLogin" class="btn_a">已有账号，<span class="red">去登录</span></div></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      checked: true, // 用户协议状态
      phone: "", // 手机号
      password: "", // 密码
      second_password: "", // 交易密码
      referral_code: "", // 推荐码
      code: "", // 验证码
      pass_type: "password", // input类型
      second_type: "password", // 交易密码input类型
      readonly: false // 只读
    }
  },
  created() {
    // 从url上面获取参数
    if (this.$route.query.code) {
      this.referral_code = this.$route.query.code
      this.readonly = true
    }
    //获取是否勾选
    this.checked = this.xz_get("checked");
    if (this.referral_code && this.referral_code != 'dfgx13') {
      this.getCountVisitNum(); 
    }
    console.log("code:", this.referral_code);
  },
  methods: {
    // 统计已访问次数
    getCountVisitNum() {
      let sentData = { code: this.referral_code }
      this.$get({
        url: '/app/homes/qrlog',
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            console.log("统计加一！");
          } else {
            this.$toast(res.msg);
          }
        }
      })
    },
    // 注册
    save() {
      if (!this.phone) return this.$toast("请输入手机号")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.phone))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.code) return this.$toast("请输入验证码")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.code))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.password) return this.$toast("请输入登录密码")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.password))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.second_password) return this.$toast("请输入交易密码")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.second_password))
        return this.$toast("不可输入+-和空格和特殊符号")
      this.$post({
        url: "/app/auth/register",
        noToken: true,
        data: {
          phone: this.phone, // 手机号
          password: this.password, // 密码
          re_password: this.password, // 重复密码
          second_password: this.second_password, // 交易密码
          re_second_password: this.second_password, // 重复交易密码
          sms_code: this.code, // 验证码
          invite_code: this.referral_code ? this.referral_code : "dfgx13" // 推荐码
        },
        success: () => {
          this.$toast.clear();
          this.$toast("注册成功")
          setTimeout(() => { this.$router.push("login") }, 1500)
        },
        tip: () => { setTimeout(() => {}, 1500) }
      })
    },
    // 去登录
    toLogin() {
      this.$router.push({ path: "login", query: { code: this.invite_code } })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; min-height: 100vh; background: #fff; overflow: hidden;}
//欢迎语
.hello {width: 96%; line-height: 10vw; margin: 15vw 2%; text-align: center; background: #fff; font-size: 7vw; color: #000; img {width: 20vw; height: 20vw; border-radius: 3vw;}}
.frombox {width: 100%; padding: 0 2vw; --van-field-input-text-color: #848484; img {width: 5vw; height: 5vw; margin-right: 3vw; display: block;} }
//单元格
:deep(.van-cell) {margin-bottom: 3.2vw; padding: 2vw;}
//用户协议
.treaty {display: flex; padding: 2vw 6vw; font-size: 3vw;  div {line-height: 4vw; color: rgb(177, 177, 177);} 
.treaty_text {color: #000; --van-checkbox-size: 4vw; line-height: 4vw;} :deep(.van-checkbox__label) {--van-checkbox-size: 4vw;} --van-checkbox-size: 4vw;}
.btn_box {width: 92%; margin: 5px 4%; border-radius: 8px; .van-button--success {height: 15vw; color: #fff; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);}}
.reg_btn {width: 92%; height: 10vw; line-height: 10vw; margin: 3vw 4%; display: flex; color: #fff; justify-content: center; font-size: 3vw;}
.btn_a {color: #848484;}
.red {color: #FF2222;}
</style>
