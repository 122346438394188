<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="搜索" left-arrow @click-left="toBack" :border="false" z-index="1000" />
    <van-search v-model="text" show-action shape="round" placeholder="请输入您感兴趣的NFT"><template #action><div @click="toSearch">搜索</div></template></van-search>
    <div class="history">
      <div class="top"><div class="top_lt">历史搜索</div><div class="top_rt"><img src="~@/assets/img/delete.png" class="img_del" @click="toClear"/></div></div>
      <div class="mid"><div class="mid_li" v-for="(item, index) in histList" :key="index" @click="toSearchDetail(item)"><span>{{ item }}</span></div></div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        text: '',
        histList: []
      }
    },
    mounted() {
      //如果本地存储的数据histList有值，直接赋值给data中的histList
      if (JSON.parse(localStorage.getItem("histList"))) {
        this.histList = JSON.parse(localStorage.getItem("histList"));
      }
    },
    created() {},
    methods: {
      // 返回市场
      toBack() {
        this.$router.push({ path: "/good" })
      },
      // 搜索
      toSearch() {
        if (this.text == '') {
          this.$toast('请输入搜索内容');
          return;
        } else {
          // 没有搜索记录，把搜索值push进数组首位，存入本地
          if (!this.histList.includes(this.text)) {
            this.histList.unshift(this.text);
            localStorage.setItem("histList", JSON.stringify(this.histList));
          }else{
            //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
            let i =this.histList.indexOf(this.text);
            this.histList.splice(i,1)
            this.histList.unshift(this.text);
            localStorage.setItem("histList", JSON.stringify(this.histList));
          }
          this.$router.push({ path: "/good", query: { keywords: this.text, sort: "asc", field: "price" } })
        }
      },
      // 历史搜索
      toSearchDetail(item) {
        this.$router.push({ path: "/good",  query: { keywords: item, sort: "asc", field: "price" } });
      },
      //清空历史搜索记录
      toClear(){
        this.$toast.success('清空历史搜索成功!');
        localStorage.removeItem('histList');
        this.histList = [];
      }
    }
  }
</script>

<style lang="scss" scoped>
.box {--van-field-label-color: #000; --van-field-input-text-color: #000;}
.history {width: 92%; height: auto; margin: 2vw 4%; color: #ccc;}
.top {width: 100%; height: 5vw; line-height: 5vw; margin: 2vw auto; display: flex;}
.top_lt {flex: 6; color: #000; font-size: 3.6vw;}
.top_rt {flex: 6; text-align: right; padding-right: 1vw;}
.img_del {width: 5.2vw; height: 12vw;}
.mid {width: 100%; height: auto; margin: 4vw auto;}
.mid_li {width: 20%; height: auto; margin: 0 auto; float: left; span {text-align: center; background: #F0F2F5; color: #686868; font-size: 3vw; border-radius: 1.2vw; padding: 2px 10px;}}
</style>
