<template>
  <div class="box">
    <van-nav-bar title="选择钱包" left-arrow @click-left="xz_go('mine')" :border="false" z-index="1000"/>
    <div class="main">
      <van-list finished-text="没有更多了">
        <div class="item bg_a"> 
          <div class="open obga" @click="go_WalletA()">
            <div class="open_lt"><div class="icon"><img src="@/assets/img/icon_a.png" /></div></div><div class="open_rt"><div class="namea">易宝</div><div class="statea">暂未开放</div></div>
          </div>
        </div>
        <div class="item bg_b"> 
          <div class="open obgb" @click="go_WalletB()" v-if="isOpenLL != 0">
            <div class="open_lt"><div class="icon"><img src="@/assets/img/icon_b.png" /></div></div><div class="open_rt"><div class="nameb">连连钱包</div><div class="stateb">已开通</div></div>
          </div>
          <div class="ul" v-else>
            <div class="li"><div class="wallet"><img src="@/assets/img/icon_b.png" class="icon_img"/><span class="txt_b">连连钱包</span></div></div>
            <div class="divb"></div>
            <div class="li">
              <div class="togo"><div class="tg_ltb">（免费开通，更快更便捷）</div><div class="tg_rtb"><van-button round type="primary" class="go_b" @click="to_OpenB">去开通</van-button></div></div>
            </div>
          </div>
        </div>
        <div class="item bg_c" v-if="flag == 1"> 
          <div class="open obgc" @click="go_WalletC()" v-if="(isOpenTT != 0)">
            <div class="open_lt"><div class="icon"><img src="@/assets/img/icon_c.png" /></div></div>
            <div class="open_rt"><div class="namec">统统支付</div><div class="statec">已开通（3月22日起将终止服务）</div></div>
          </div>
          <div class="ul" v-else>
            <div class="li"><div class="wallet"><img src="@/assets/img/icon_c.png" class="icon_img"/><span class="txt_c">统统支付</span></div></div>
            <div class="divc"></div>
            <div class="li">
              <div class="togo"><div class="tg_ltc" style="font-size: 4vw;">3月22日起将终止服务</div></div>
              <!-- <div class="togo"><div class="tg_ltc">（免费开通，更快更便捷）</div><div class="tg_rtc">
              <van-button round type="primary" class="go_c" @click="to_OpenC">去开通</van-button></div></div> -->
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import returnUrl from '@/utils/return_url.js';
export default {
  data() {
    return {
      isOpen: '',
      isOpenLL: '',
      isOpenTT: '',
      ttOpenUrl: '',
      flag: 1
     };
  },
  created() {
    this.getMemberInfo();
  },
  methods: {
    // 钱包A
    go_WalletA() {
      this.$toast("易宝暂未开放！")
      // this.$router.push({ path: "/wallet_detaila" });
    },
    // 钱包B开户页面
    to_OpenB() {
      this.getOpenLLPayUrl();
    },
    // 钱包B（已开通）
    go_WalletB() {
      this.$router.push({ path: "/wallet_detailb" });
    },
    // 钱包C开户页面
    to_OpenC() {
      this.getOpenTTPayUrl();
    },
    // 钱包C（已开通）
    go_WalletC() {
      this.getOpenTTPayUrl();
    },
    // 用户信息（判断钱包是否开通）
    getMemberInfo() {
      this.$post({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.isOpenLL = res.data.lianlian_account_id;
            this.isOpenTT = res.data.ttpay_user_id;
            console.log("isOpenLL:", this.isOpenLL, "isOpenTT:", this.isOpenTT,);
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 连连开户页面
    getOpenLLPayUrl() {
      let return_url = returnUrl.baseUrl + "#/wallet_detailb?jump=page";
      this.$post({
        url: "/app/wallet_lian_lian/getOpenAccUrl",
        data: { return_url: return_url },
        success: (res) => {
          if (res.code == 1) {
            window.location.href = res.data.gateway_url;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 统统支付开户页面
    getOpenTTPayUrl() {
      this.$post({
        url: "/app/wallet_tong_tong/getUserRegisterUrl",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            window.location.href = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 支付测试
    toPay() {
      this.$post({
        url: "/app/wallet_tong_tong/placeOrder",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            window.location.href = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; background: #FFFFFF;}
.main {width: 100%; height: auto; margin: 10px auto;}
.divb {width: 92%; height: 1px; margin: 5px 4%; background: #E6C9C9;}
.divc {width: 92%; height: 1px; margin: 5px 4%; background: #C9D1E6;}
.item {width: 94%; height: 32vw; margin: 0 3%;  border-radius: 2vw;}
.bg_a {background: #FFE5E5;}
.bg_b {background: #FFF3E7; margin: 5vw auto;}
.bg_c {background: #E5EFFF; margin: 5vw auto;}
.open {width: 100%; height: 32vw; display: flex;}
.obga {background: url("~@/assets/img/wallet_bg01.png") no-repeat; background-size: 100% 100%;}
.obgb {background: url("~@/assets/img/wallet_bg02.png") no-repeat; background-size: 100% 100%;}
.obgc {background: url("~@/assets/img/wallet_bg03.png") no-repeat; background-size: 100% 100%;}
.open_lt {flex: 1.5;}
.icon {width: 15vw; height: 15vw; line-height: 15vw; margin: 7.8vw auto; text-align: center; background: #FFFFFF; border-radius: 50%;}
.icon img {width: 100%; height: 100%; vertical-align: middle;}
.open_rt {flex: 4; height: 15vw; line-height: 2; margin: 7.8vw auto; text-align: left; color: #000;}
.namea {color: #F75757; font-size: 4.8vw;}
.statea {color: #F86363; font-size: 3.2vw;}
.nameb {color: #FC9C32; font-size: 4.8vw;}
.stateb {color: #FC9C32; font-size: 3.2vw;}
.namec {color: #2775FB; font-size: 4.8vw;}
.statec {color: #2775FB; font-size: 3.2vw;}
.ul {width: 100%; height: 32vw;}
.li {width: 100%; height: auto; margin: 10px auto;}
.wallet {width: 94%; height: auto; margin: 4vw 3%; padding-top: 2.5vw;}
.icon_img {width: 8vw; height: 8vw; vertical-align: middle; margin-left: 1vw;}
.togo {
  width: 94%; height: auto; margin: 3vw 3%; display: flex; flex-wrap: nowrap; flex-direction: row;
  .tg_ltb {width: 70%; height: 8vw; line-height: 8vw; text-align: left; color: #FC9C32; font-size: 3.2vw; font-weight: 600; margin-left: 2vw;}
  .tg_rtb {width: 30%; height: 8vw; line-height: 8vw; color: #FC9C32; font-size: 3.2vw;}
  .tg_ltc {width: 70%; height: 8vw; line-height: 8vw; text-align: left; color: #2775FB; font-size: 3.2vw; font-weight: 600; margin-left: 2vw;}
  .tg_rtc {width: 30%; height: 8vw; line-height: 8vw; color: #2775FB; font-size: 3.2vw;}
  .go_b {width: 80%; height: auto; margin: 1vw auto; text-align: right; --van-button-primary-background-color: #FC9C32; --van-button-normal-padding: 10px 25px;}
  .go_c {width: 80%; height: auto; margin: 1vw auto; text-align: right; --van-button-primary-background-color: #2775FB; --van-button-normal-padding: 10px 25px;}
}
.txt_b {flex: 2; height: 8vw; line-height: 8vw; text-align: left; color: #FC9C32; font-size: 4.2vw; font-weight: 600; margin-left: 2vw;}
.txt_c {flex: 2; height: 8vw; line-height: 8vw; text-align: left; color: #2775FB; font-size: 4.2vw; font-weight: 600; margin-left: 2vw;}
</style>
