<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="交易密码" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <!-- 表单 -->
    <div class="frombox">
      <van-field clearable v-model="phone" type="tell" maxlength="11" placeholder="请输入手机号" readonly>
      </van-field>
      <van-field clearable v-model="code" type="text" placeholder="请输入验证码">
        <template #button>
          <!-- 获取手机验证码按钮 封装 位置 在 vant-extend ===》 添加了防抖防止连点-->
          <vant-code url="/app/auth/sendSms" scene="forget_trade_password" :phone="phone"></vant-code>
          <!-- <van-button size="small" type="warning">发送验证码</van-button> -->
        </template>
      </van-field>

      <van-field clearable v-model="second_password" :type="second_type" :maxlength="6" :readonly="phone == '' ? true : false" placeholder="请输入6位交易密码" :right-icon="second_type == 'password' ? 'closed-eye' : 'eye'" @click-right-icon="
          second_type == 'password'
            ? (second_type = 'text ')
            : (second_type = 'password')
        ">
      </van-field>
      <div>
        <!--留一个div保证最后一个输入框有下划线-->
      </div>
    </div>
    <div style="height: 8vw"></div>

    <div class="btn_box">
      <!-- 登录按钮 -->
      <van-button size="large" type="success" @click="save">确定</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: "",
      second_password: "",
      code: "",
      pass_type: "password", //input类型
      second_type: "password", //确认密码类型
      readonly: true, //只读
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          this.phone = res.data.phone
          console.log(this.info, "个人信息")
        },
        tip: () => {},
      })
    },
    save() {
      if (!this.code) return this.$toast("请输入验证码")
      if (
        !/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(
          this.second_password
        )
      )
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.second_password) return this.$toast("请输入支付密码")
      // 修改密码
      this.$post({
        url: "/app/auth/forgetSecondPassword",
        data: {
          phone: this.phone,
          sms_code: this.code,
          second_password: this.second_password,
          re_second_password: this.second_password,
        },
        success: () => {
          this.$toast.clear()
          this.$toast("修改成功")
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
//表单
.from_box {
  width: 100%;
  padding: 0 2vw;
}
//单元格
:deep(.van-cell) {
  margin-bottom: 4vw;
}

.btn_box {
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}

:deep(.van-button--warning) {
  border: 1px solid #01fdbd !important;
}

:deep(.van-field__button) {
  display: flex;
}
</style>
