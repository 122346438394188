<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="充值" left-arrow @click-left="xz_go('wallet_detaila')" :border="false" z-index="1000" />
    <div style="height: 2vw"></div>
    <!-- //输入充值数量 -->
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="充值金额" value="" /></div>
      <div style="height: 5vw"></div>
      <van-cell-group inset><van-field label="¥" v-model="number" type="number" clearable placeholder="请输入价格" /></van-cell-group>
      <div style="height: 2vw"></div>
      <div class="text">账户金额：{{ parseFloat(money).toFixed(2) }}</div>
    </div>
    <div style="height: 5vw"></div>
    <van-radio-group v-model="radio" class="van_group">
      <!-- 支付宝 -->
      <van-cell-group :border="false">
        <div class="vcell">
          <van-cell title="支付宝A" clickable @click="radio = '2'">
            <template #icon><div class="left"><img :src="img" /></div></template>
            <template #right-icon>
              <van-radio name="2">
                <template #icon><div class="icono" v-if="radio == 2"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
              </van-radio>
            </template>
          </van-cell>
        </div>
        <!-- <div class="vcell">
          <van-cell title="支付宝B" clickable @click="radio = '3'">
            <template #icon><div class="left"><img :src="img" /></div></template>
            <template #right-icon>
              <van-radio name="3">
                <template #icon><div class="icono" v-if="radio == 3"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
              </van-radio>
            </template>
          </van-cell>
        </div> -->
      </van-cell-group>
    </van-radio-group>
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="充值须知" /></div> <div style="height: 2vw"></div> <div class="text">{{ notice }}</div>
    </div>
    <div style="height: 32vw"></div>
    <div class="btn_box"><van-button size="large" :loading="loading" type="success" @click="save">确认充值</van-button> <div class="text">充值成功后立即到账</div></div>
  </div>
</template>

<script>
import returnUrl from '@/utils/return_url.js';
export default {
  components: {},
  data() {
    return {
      number: "",
      loading: false,
      money: "",
      notice: '',
      radio: '',
      img: require("@/assets/img/op03.png")
    }
  },
  created() {
    this.getBalanceInfo();
    this.getRechargeNotice();
  },
  methods: {
    // 余额信息
    getBalanceInfo () {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.money = res.data.balance;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 保存
    save() {
      this.loading = false;
      let return_url = returnUrl.baseUrl + '#/wallet_detaila?jump=page';
      const reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (this.number == '') {
        this.$toast("请输入充值金额!");
        this.loading = false;
        return
      }
      if (!reg.test(this.number)) {
        this.$toast("金额格式错误! 金额为大于0的数字，且最多保留2位小数！");
        this.loading = false;
        return
      }
      if (this.radio == '') {
        this.$toast("请选择支付方式！");
        return
      }
      this.$get({
        url: "/app/recharge/add",
        data: { amount: this.number, pay_type: this.radio },
        success: (res) => {
          console.log("===res:", res);
          if (res.code == 1) {
            this.$get({
              url: "/app/pay/pay",
              data: { order_sn: res.data.order_sn, app: 0, return_url: return_url },
              success: (ress) => {
                if (ress.code == 1) {
                  if (this.radio == "2") {
                    window.location.href = ress.data.url;
                  }
                  if (this.radio == "3") {
                    window.location.href = ress.data;
                  }
                } else {
                  this.$toast(ress.msg);
                  this.loading = false;
                }         
                this.loading = false;
              },
              tip: () => { this.loading = false }
            }).catch((err) => {
              this.loading = false;
              console.log("服务器请求失败", err);
              if (err.response.status == "404") {
                _this.$toast("接口不存在");
              } else if (err.response.status == "500") {
                _this.$toast("服务器内部错误");
              } else { _this.$toast("参数错误"); };
            });
          } else  {
            this.$toast(res.msg);
            this.loading = false;
          }
          this.loading = false;
        },
        tip: () => {}
      })
    },
    // 充值须知
    getRechargeNotice() {
      this.$get({
        url: "/app/recharge/getInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.notice = res.data.info;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.input_box {
  align-items: center; margin: 0 auto; padding: 2vw; width: 90vw; background: #fff; --van-cell-text-color: #000; box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);
  .cell {:deep(.van-cell) {padding: 0; .van-badge__wrapper {display: flex; align-items: center;}}}
  .van-cell-group {
    width: 92%; height: 12vw; margin: 0 4%; background: #F5F5F5;
    :deep(.van-cell__title) {width: 3vw; font-size: 3vw; background-image: linear-gradient(308deg, #FE5A65 0%, #FF907A 100%); background-clip: text; -webkit-background-clip: text;
    color: transparent; font-weight: 800;}
    :deep(.van-field__control) {background: linear-gradient(308deg, #FE5A65 0%, #FF907A 100%); background-clip: text; -webkit-background-clip: text; color: transparent; font-weight: 800;}
  }
  .text {width: 78vw; margin: 0 auto; color: #000; font-size: 3vw;}
}
.van_group {width: 90%; margin: 0 5%;
  :deep(.van-cell) {padding: 30px 30px; background: #fff; --van-cell-text-color: #000; box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);}
  .vcell {margin-bottom: 5vw;}
  .left {width: 5.5vw; height: 5.5vw; margin-right: 20px; img {width: 100%; height: 100%;} }
  .icono {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
  .icont {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
}
//确认按钮
.btn_box {position: fixed; width: 100%; margin: 0 auto; margin-bottom: 3vw; bottom: 0; padding: 3vw;
  .van-button--success {width: 92%; height: 14.2vw; margin: 2vw 4%; background: #2A7AF0 ; border-radius: 7.2vw;}
  .text {color: #ff5c5c; font-size: 3vw; text-align: center;}
}
</style>
