<template>
  <div class="box">
    <van-nav-bar title="邀请好友" left-arrow @click-left="xz_go('mine')" :border="false" class="nav_bg">
      <template #right v-if="isRun == 2"><van-icon :name="require('@/assets/img/c05.png')" size="20" @click="toShare" /></template>
    </van-nav-bar>
    <div class="invite">
      <div class="invite_top">
        <div class="top_bg"><div style="height: 2vw;"></div><div v-if="isExist == 1" class="tb_tit" @click="xz_go('active_rule')">&nbsp;&nbsp;活动规则</div></div>
        <img src="@/assets/img/invite_tit.png" class="img_tit" />
      </div>
      <div class="invite_mid">
        <div style="height: 23vw;"></div>
        <div class="h3">我的专属邀请码</div>
        <div class="code">{{ code }}</div>
        <div id="qr_img" class="qr" style="width: 42vw; height: 42vw; margin: 4.5vw auto; text-align: center;"><vue-qr :logoSrc="logoUrl" :text="url" :size="280"></vue-qr></div>
        <div class="h4">扫描二维码</div><div class="h4">下载注册道拍数藏账号</div>
      </div>
      <div class="title"><div class="r_bg"></div><div class="r_tit">当前活动榜单</div></div> 
      <div class="rank">
        <div style="height: 1.5vw;"></div>
        <div class="r_box">
          <table class="table" align="center">
            <thead><tr height="50"><td width="20%">排名</td><td width="15%">头像</td><td width="30%">昵称</td><td width="35%">已邀请人数</td></tr></thead>
            <tbody>
              <tr height="45" v-for="(item, index) in activityList" :key="index">
                <td><img src="@/assets/img/top01.png" class="img_v" v-if="index == 0" /><img src="@/assets/img/top02.png" class="img_v" v-if="index == 1" />
                <img src="@/assets/img/top03.png" class="img_v" v-if="index == 2" /><span v-if="index > 2">{{ index + 1 }}</span></td>
                <td><img :src="item.mem_head_img" class="img_h" /></td><td>{{ item.mem_nickname }}</td><td class="num">{{ item.account_num }}</td>
              </tr>
            </tbody>
          </table>
          <van-button class="more" type="primary" round @click="onLoad" :loading='loading' :disabled='finished'>{{ !finished ?'查 看 更 多' : '没有更多数据了'}}</van-button>
          <div class="r_bot">&nbsp;&nbsp;&nbsp;&nbsp;我的邀请人数： {{ inviteNum }} 人</div>
        </div>
        <div style="height: 1.5vw;"></div>
      </div>
      <div class="title"><div class="r_bg" style="width: 35%;"></div><div class="r_tit">邀请记录</div></div> 
      <div class="record">
        <div class="tabs">
          <div class="tab_opt" :class="tabIndex == 0 ? 'tab_act' : ''" @click="changeTab(0)">当前活动</div>
          <div class="tab_opt" :class="tabIndex == 1 ? 'tab_act' : ''" @click="changeTab(1)">历史记录</div>
        </div>
        <div v-if="tabIndex == 0">
          <div class="re_box" v-for="(item, index) in recordList" :key="index">
            <div class="reb_lt"><img :src="item.mem_head_img" class="img_head" /></div>
            <div class="reb_mm"><div class="name">{{ item.mem_nickname }}</div> <div class="tel">{{ item.mem_phone }}</div></div>
            <div class="reb_rt">{{ item.is_real == 0 ? '待实名' : '邀请成功' }}</div>
          </div>
          <van-button type="primary" round :loading='loading1' :disabled='finished1' @click="onLoad1" class="more">{{ !finished1 ? '查 看 更 多' : '没有更多数据了'}}</van-button>
        </div>
        <div v-if="tabIndex == 1">
          <div class="re_box"  v-for="(item, index) in historyList" :key="index">
            <div class="reb_lt"><img :src="item.head_img" class="img_head" /></div>
            <div class="reb_mm"><div class="name">{{ item.nickname }}</div> <div class="tel">{{ item.phone }}</div></div>
            <div class="reb_rt">{{ item.is_real == 0 ? '待实名' : '邀请成功' }}</div>
          </div>
          <van-button type="primary" round :loading='loading2' :disabled='finished2' @click="onLoad2" class="more">{{ !finished2 ? '查 看 更 多' : '没有更多数据了'}}</van-button>
        </div>
      </div>
      <div style="height: 4vw"></div>
      <div class="invite_bot"><van-button round type="success" class="rbbot_btn" @click="xz_go('invite_new')">参与邀新活动</van-button></div>
    </div>
    <div class="bot">
      <div class="bot_box">
        <van-button round type="default" class="bot_btn" @click="toCopyUrl">复制链接</van-button><van-button round type="default" class="bot_btn" @click="toImgShow">保存图片</van-button>
      </div>
    </div>
    <van-popup v-model:show="show" position="center" closeable close-icon="close" lock-scroll class="show">
      <div class="pic_box">
        <div id="down" class="pic" ref="downImg"><div style="height: 68vw"></div><div class="qr_down"><vue-qr :logoSrc="logoUrl" :text="url" :size="280"></vue-qr></div></div>
        <van-button round type="success" size="mini" color="#7232dd" class="btn" @click="draw">点我生成图片后长按保存</van-button>
      </div>
    </van-popup>
    <van-popup v-model:show="show_down" position="center" closeable close-icon="close" lock-scroll class="show_down" @touchstart="touchStart()" @touchend="touchEnd()">
      <van-image v-show="imgUrl" :src="imgUrl" class="pic_down" />
    </van-popup>
    <van-popup v-model:show="show_share" position="center" closeable close-icon="close" class="show_share">
      <div class="share">
        <div style="height: 4vw;"></div>
        <div class="share_tit">分享</div>
        <div class="url_box">
          <div class="ub_li"><div class="label">外部链接</div><van-field v-model="share_link" placeholder="请输入外部链接" class="input" /></div>
          <div class="ub_li"><div class="label">标题</div><van-field v-model="share_title" placeholder="请输入分享标题" class="input" /></div>
          <div class="ub_li"><div class="label">内容</div><van-field v-model="share_desc" placeholder="请输入分享内容" class="input" /></div>
          <van-field label="上传图片">
            <template #input>
              <van-uploader :before-read="beforeUpload" :after-read="uploadImg" accept="image/*" :max-count="1" :max-size="4 *1024 * 1024" @oversize="onOversize">
                <van-image width="64" height="64" :src="share_img" v-if="share_img"></van-image><div class="img_up" v-else><van-icon class="camera-icon" name="photograph" /></div>
              </van-uploader>
            </template>
          </van-field>
        </div>
        <div class="share_btn">
          <div class="btn_sb" @click="getShareData(0)"><img src="@/assets/img/c06.png" style="width: 5.6vw; height: 4.8vw;" /> 转发微信</div>
          <div class="btn_sb" @click="getShareData(1)"><img src="@/assets/img/c07.png" class="img_btn" /> 转发朋友圈</div>
        </div>
      </div> 
    </van-popup>
    <div class="pop_guide" v-show="show_guide" @click="toClose"><div class="guide"><img src="~@/assets/img/guide.png" alt="" /></div></div>
  </div>
</template>

<script>
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import html2canvas from "html2canvas";
import returnUrl from '@/utils/return_url.js';
import useClipboard from "vue-clipboard3"; 
import * as imageConversion from 'image-conversion';
import wx from "weixin-js-sdk";
const { toClipboard } = useClipboard();
export default {
  components: { vueQr },
  data() {
    return {
      show: false,
      tabIndex: 0,
      code: "",
      url: "",
      logoUrl: require("@/assets/img/logo.png"), //icon路径
      imgUrl: '',
      isExist: 0, 
      activityList: [],
      inviteNum: 0,
      recordList: [],
      historyList: [],
      loading: false,
      finished: false,
      pageIndex: 1,
      loading1: false,
      finished1: false,
      pageIndex1: 1,
      loading2: false,
      finished2: false,
      pageIndex2: 1,
      show_down: false,
      times: '', // 定时器
      isRun: 1,
      show_share: false,
      choose: 0,
      appId: '',
      timestamp: '',
      nonceStr: '',
      signature: '',
      lineink: '',
      share_title: '',
      share_desc: '',
      share_link: '',
      share_img: '',
      show_guide: false
    }
  },
  created() {
    this.getMemberInfo();
    this.getActiveIsExist();
    this.onLoad();
    this.getInviteNum();
    this.changeTab(this.tabIndex);
  },
  methods: {
    // 用户信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.code = res.data.invite_code
            this.url = returnUrl.baseUrl + '#/register?code=' + this.code;
            this.isRun = res.data.type;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 下拉加载
    onLoad() {
      this.getActivityList();
    },
    // 当前活动榜单
    getActivityList() {
      if (this.finished || this.loading) return;
      this.loading = true;
      this.$get({
        url: '/app/member/newRank',
        data: { page: this.pageIndex },
        success: (res) => {
          if (res.code == 1) {
            if (!res.data || res.data.length == 0) {
              this.finished = true
            } else {
              this.pageIndex++;
              let dataList = res.data;
              this.activityList = [...this.activityList, ...dataList];
            }
            setTimeout(() => { this.loading = false }, 1000)
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 我的邀请人数
    getInviteNum() {
      this.$get({
        url: 'app/member/myInviteNum',
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.inviteNum = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 选项卡切换
    changeTab(index){
      this.tabIndex = index; 
      if (this.tabIndex == 0) {
        this.onLoad1();
      } else {
        this.onLoad2();
      }
    },
    // 下拉加载
    onLoad1() {
      this.getRecordList();
    },
    // 当前活动记录
    getRecordList() {
      if (this.finished1 || this.loading1) return;
      this.loading1 = true;
      let sentData = { page: this.pageIndex1 };
      this.$get({
        url: '/app/member/inviteLog',
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            if (!res.data || res.data.length == 0) {
              this.finished1 = true
            } else {
              this.pageIndex1++;
              let dataList = res.data;
              this.recordList = [...this.recordList, ...dataList];
            }
            setTimeout(() => { this.loading1 = false }, 1000)
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 上拉加载
    onLoad2() {
      this.getHistoryList();
    },
    // 历史记录
    getHistoryList() {
      if (this.finished2 || this.loading2) return;
      this.loading2 = true;
      let sentData = { page: this.pageIndex2 };
      this.$get({
        url: '/app/member/teamList',
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            if (!res.data.data || res.data.data.length == 0) {
              this.finished2 = true
            } else {
              this.pageIndex2++;
              let dataList = res.data.data;
              this.historyList = [...this.historyList, ...dataList];
            }
            setTimeout(() => { this.loading2 = false }, 1000)
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 判断活动是否存在
    getActiveIsExist() {
      this.$get({
        url: '/app/member/inviteInfo',
        data: {},
        success: (res) => {
          if (res.code == 1) {
            if (res.data) {
              this.isExist = 1;
            } else {
              this.isExist = 0;
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 复制链接
    toCopyUrl() {
      let codeText = this.url;
      try {
        toClipboard(codeText);
        this.$toast("复制成功");
      } catch (e) {
        this.$toast("复制失败!", e);
      }
    },
    // 分享海报弹窗
    toImgShow() {
      this.show = true;
      document.documentElement.scrollTop = 0;
    },
    // 合成分享海报（背景图和二维码合二为一）
    draw(){
      this.show = false;
      this.show_down = true;
      let that = this;
      let imgWidth = window.document.querySelector('#down').offsetWidth // 获取 DOM宽度
      let imgHeight = window.document.querySelector('#down').offsetHeight; // 获取 DOM高度
      html2canvas(this.$refs.downImg, {
        backgroundColor: null, // canvas的背景颜色,如果没有设定默认透明
        width: imgWidth,
        height: imgHeight,
        dpi: window.devicePixelRatio * 2 , // 对应屏幕的dpi，适配高清屏，解决canvas模糊问题
        scale: 2, // 缩放
        allowTaint: true, // 是否允许跨域
        useCORS: true, // 是否在恢复代理之前,尝试加载跨域图片（用来解决图片跨域加载问题）
        scrollX: 0,
        scrollY: 0,
        imageTimeout: 15000 // 加载图像的超时时间（以毫秒为单位），设置为 0 以禁用超时。
      }).then(canvas => {
        that.imgUrl = canvas.toDataURL('image/png');
      })
    },
    //手指触摸
    touchStart() {
      clearTimeout(this.times); // 再次清空定时器，防止重复注册定时器
      this.times = setTimeout(() => { this.saveImg(this.imgUrl, "邀请好友"); }, 1000);
    },
    //手指离开
    touchEnd() {
      clearTimeout(this.times);
      this.show_down = false;
    },
    // 图片保存（长按保存）
    saveImg(imgsrc, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); // 解决跨域 Canvas 污染问题
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d"); //得到画布的上下文
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); // 得到图片的 base64 编码数据
        let a = document.createElement("a"); // 生成一个a元素 
        let event = new MouseEvent('click', (true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)); // 创建一个单击事件
        a.download = name || "邀请好友"; // 设置图片名称
        a.href = url; // 将生成的 URL设置为 a.href 属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    // 分享
    toShare() {
      let broser = window.navigator.userAgent.toLowerCase();
      // 判断是否是微信浏览器
      if (broser.match(/MicroMessenger/i) == 'micromessenger') {
        this.show_share = true;
      } else {
        this.$toast("请使用微信浏览器打开或者从'道拍'公众号中进入平台！");
        this.show_share = false;
      }
    },
    // 图片大小限制
    onOversize() {
      return this.$toast("图片大小不能超过4M！");
    },
    // 图片上传前，进行图片校验
    beforeUpload(file) {
      const size = (file.size / 1024 / 1024) * 10 < 20;
      if (!size) {
        this.$toast("图片大小超过2M将进行压缩！");
        let myImg = new Promise((resolve) => {
          imageConversion.compressAccurately(file, 500).then((res) => { resolve(res) });
        });
        return myImg;
      }
      return size;
    },
    // 上传封面图片
    uploadImg(file) {
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/app/tool/uploadImage",
        data: formData,
        upload: true,
        success: (res) => {
          if (res.code == 1) {
            this.share_img = res.data;
          } else {
            this.$toast(res.msg)
          }
        },
        tip: () => {}
      })
    },
    // 获取分享参数
    getShareData(val) {
      let shareUrl = '';
      let reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      if (!this.share_link) { 
        this.$toast("请输入要分享的链接！") 
        return
      }
      if (!reg.test(this.share_link)) { 
        this.$toast("链接格式错误，必须以 http(s):// 开头，并且输入完整的网址！") 
        return
      }
      if (!this.share_title) { 
        this.$toast("请输入要分享的标题！")
        return  
      } 
      this.$post({
        url: '/app/member/shareUrl',
        data: { url:  this.share_link },
        success: (res) => {
          console.log("创建分享链接：", res);
          if (res.code == 1) {
            shareUrl = res.data.share_url;
            this.appId = res.data.sign.appId;
            this.timestamp = res.data.sign.timestamp;
            this.nonceStr = res.data.sign.nonceStr;
            this.signature = res.data.sign.signature;
            this.getWeChatInfo(shareUrl, val);
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 分享到微信好友和朋友圈
    getWeChatInfo(shareUrl, val) {
      let that = this;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。（测试记得关掉）
        appId: that.appId, // 必填，公众号的唯一标识
        timestamp: that.timestamp, // 必填，生成签名的时间戳
        nonceStr: that.nonceStr, // 必填，生成签名的随机串
        signature: that.signature, // 必填，签名，见附录1
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.checkJsApi({
        jsApiList: ['chooseImage', 'updateAppMessageShareData', 'updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          // 以键值对的形式返回，可用的api值true，不可用为false 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          console.log("checkJsApi:", res)
        }
      });
      wx.ready(function () {
        if (val == 0) {
          //“分享给朋友”及“分享到QQ”
          wx.updateAppMessageShareData({
            title: that.share_title, // 分享标题
            desc: that.share_desc, // 分享描述
            link: shareUrl, // 分享链接 该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.share_img, // 分享图标
            success: function (res) {
              console.log("分享朋友成功返回的信息为:", res);
              that.show_share = false;
              that.show_guide = true;
            }
          })
        } else {
          // 分享到朋友圈”及“分享到QQ空间”
          wx.updateTimelineShareData({
            title: that.share_title, // 分享标题
            link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.share_img, // 分享图标
            success: function (res) {
              console.log("分享朋友圈成功返回的信息为:", res);
              that.show_share = false;
              that.show_guide = true;
            }
          })
        }
      });
      wx.error(function (res) {
        console.log('验证失败返回的信息:', JSON.stringify(res));
      });
    },
    // 关闭引导
    toClose() {
      this.show_guide = false;
    }
  }
}
</script>

<style lang="scss" scoped>
*{margin: 0; padding: 0;}
.box {width: 100%; height: auto; margin: 0 auto;}
.nav_bg {background: #161824; --van-nav-bar-title-text-color: #fff; --van-nav-bar-icon-color: #fff;}
.invite {
  width: 100%; height: auto; margin: 0 auto; background: url("~@/assets/img/invite.png") no-repeat; background-size: 100% 100%; padding-top: 1vw; padding-bottom: 20vw;
  background-image: linear-gradient(#01044A, #320656, #01044A);
  .invite_top {
    width: 100%; height: auto; margin: 0 auto; 
    .top_bg {width: 100%; height: 50vw; background: url("~@/assets/img/invite_top.png") no-repeat; background-size: 100% 100%;}
    .tb_tit {width: 20%; height: 7.4vw; line-height: 7.4vw; text-align: center; background: linear-gradient(54deg, #3C46FB 0%, #FC1EBA 100%); color: #fff; font-size: 3.4vw; 
    margin-left: 80%; border-radius: 3.6vw 0px 0px 3.6vw;}
   .img_tit {width: 86%; height: 10.4vw; line-height: 10.4vw; margin: 0 7%; margin-top: -4vw;}}
  .invite_mid {
    width: 92%; height: 108vw; margin: 0 4%; background: url("~@/assets/img/invite_mid.png") no-repeat; background-size: 100% 100%; margin-top: -16vw;
    .h3 {width: 90%; height: 5vw; line-height: 5vw; margin: 2vw 5%; text-align: center; color: #fff; font-size: 3.7vw;}
    .code {width: 42%; height: 12vw; line-height: 12vw; margin: 2vw 29%; text-align: center; background: linear-gradient(to right, #4E8CF9, #E35BEB, #B269F6); color: #fff; 
    font-size: 6.4vw; font-weight: 600; border-radius: 6vw;}
    .h4 {width: 90%; height: 4vw; line-height: 4vw; margin: 0.5vw 5%; text-align: center; color: #fff; font-size: 3.2vw;}
  }
  .title {
    width: 92%; height: 7vw; line-height: 7vw; margin: 10vw 4% 0 4%;
    .r_bg {width: 48%; height: 4vw; background: linear-gradient(45deg, #3C46FB 0%, #FA1EBD 100%);}
    .r_tit {width: 50%; height: 6vw; line-height: 6vw; color: #fff; font-size: 6vw; margin-top: -7vw; margin-left: 4vw;}
  }
  .rank {width: 92%; height: auto; margin: 0 4%; background: linear-gradient(135deg, #68CBF6 0%, #D74CF5 46%, #5E9EF9 75%, #5FE2FD 100%); border-radius: 2vw;}
  .r_box {
    width: 96%; height: auto; margin: 0 2%; background: #090238; padding-bottom: 2vw;
    .table {width: 100%; height: auto; margin: 0 auto; text-align: center;}
    .img_v {width: 6.4vw; height: 6.4vw; vertical-align: middle;}
    .img_h {width: 6.6vw; height: 6.6vw; vertical-align: middle; border-radius: 50%;}
    .r_bot {width: 92%; height: 14vw; line-height: 14vw; margin: 4vw 4%; background: #20194B; font-size: 4vw; border-radius: 2vw;}
  }
  .more {width: 90%; height: 10vw; margin: 4vw 5%; background: linear-gradient(135deg, #68CBF6 0%, #D74CF5 46%, #5E9EF9 75%, #5FE2FD 100%); color: #fff;}
  .record {width: 92%; height: auto; margin: 0 4%; --van-tab-font-size: 4.2vw;}
  .tabs {
    width: 100%; height: 10vw; margin: 0 auto; display: flex; flex-wrap: nowrap; flex-direction: row;
    .tab_opt {flex: 1; width: 25%; height: 10vw; line-height: 10vw; margin: 0 auto; text-align: center; color: #7B7DA1; font-size: 4vw;}
    .tab_act {color: #fff; font-size: 4.2vw; font-weight: 600; border-bottom: 0.5vw solid; border-image: linear-gradient(45deg, #4E69C6 0%, #7C3CC3 51%, #4E69C6 100%) 4;}
  }
  .re_box {
    width: 96%; height: auto; margin: 6vw 2%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .reb_lt {flex: 1.5; height: 10vw; line-height: 10vw; .img_head {width: 10vw; height: 100%; vertical-align: middle;}}
    .reb_mm {flex: 6; height: 10vw; .name {width: 96%; height: 5vw; line-height: 5vw; font-size: 4.2vw;} .tel {width: 96%; height: 5vw; line-height: 5vw; font-size: 3vw;}}
    .reb_rt {flex: 2; height: 10vw; line-height: 10vw; text-align: right;}
  }
  .invite_bot {
    width: 92%; height: 14.5vw; margin: 4vw 4%; 
    .rbbot_btn {width: 60%; height: 14vw; line-height: 14vw; margin: 0.25vw 20%; background: linear-gradient(45deg, #4E8CF9 0%, #E35BEB 51%, #B269F6 100%);}
  }
}
.qr {background: #FFFFFF; border-radius: 3.2vw;}
.bot {
  position: fixed; bottom: 0px; width: 100%; height: auto; margin: 0 auto; text-align: center; background: #fff;
  .bot_box {
    width: 92%; height: 14vw; line-height: 14vw; margin: 3vw 4%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .bot_btn {flex: 1; height: 14vw; line-height: 14vw; margin: 0 2%; text-align: center; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; font-size: 4vw;
    font-weight: 500;}
  }
}
.show {width: 90vw; height: 90%; top: 8%; left: 5%; transform: none;}
.pic_box {
  width: 90vw; height: 150vw; margin: 0 auto;
  .pic {width: 90vw; height: 150vw; margin: 0 auto; background: url("~@/assets/img/invite_bg01.png") no-repeat; background-size: 100% 100%; padding: 2vw 0;}
  .btn {width: 70%; height: 7.5vw; line-height: 7.5vw; margin: 0.5vw 15%; margin-top: -8.4vw;}
}
.qr_down {width: 50vw; height: 50vw; margin: 0 auto; text-align: center;}
.num {color: #FD893D; font-size: 3.4vw; font-weight: 500;}
.show_down {width: 90vw; height: 150vw; margin: 0 auto;}
.pic_down {width: 90vw; height: 150vw; margin: 0 auto; text-align: center; vertical-align: middle;}
.show_share {width: 90vw; height: 80vw; margin: 0 auto; margin-top: -2vw;}
.share {
  width: 86vw; height: auto; margin: 0 auto;
  .share_tit {width: 90%; height: 6vw; line-height: 6vw; margin: 2vw 5%; text-align: center; color: #161824; font-size: 4.6vw; font-weight: 500;}
  .url_box {
    width: 92%; height: auto; margin: 4vw 4%;  
    .ub_li {
      width: 96%; height: auto; margin: 2vw 2%; background: #F3F4F8; --van-field-input-text-color: #333;
      .label {width: 90%; height: 4vw; line-height: 4vw; margin: 5px 5%; color: #39393C; font-size: 3.2vw; padding-top: 1.5vw;}
      .input {width: 92%; margin: 5px 4%;}
    }
    .img_up {width: 20vw; height: 20vw; line-height: 6; text-align: center; background: #F3F4F8; .camera-icon {color: #DCDEE0; font-size: 8vw;}}
  }
  .share_btn {
    width: 90%; height: 12vw; line-height: 12vw; margin: 4vw 5%; text-align: center; display: flex; flex-wrap: nowrap; flex-direction: row; color: #fff; font-size: 4vw; font-weight: 500;
    .btn_sb {
      width: 42%; height: 12vw; line-height: 12vw; margin: 0 4%; text-align: center; background: #12C752; border-radius: 3vw;
      .img_btn {width: 6vw; height: 6vw; vertical-align: middle; z-index: 9999;}}
  }
}
.pop_guide {
  position: fixed; width: 100%; height: 100%; left: 0; top: 0; background-color: rgba(0, 0, 0, 0.5); overflow-y: auto; z-index: 9999;
  .guide {width: 84%; height: 100%; margin: 0 8%; text-align: center; img {width: 100%; height: 70%; vertical-align: middle; text-align: center};}
}
</style>