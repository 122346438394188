<template>
  <div class="box bg">
    <!-- 顶部栏 -->
    <van-nav-bar title="盲盒订单" left-arrow @click-left="toBack" :border="false" z-index="1000" class="while" />
    <div class="tabs">
      <van-tabs v-model:active="active" @click="getOnload">
        <van-tab title="全部"></van-tab> <van-tab title="待付款" name="0"></van-tab> <van-tab title="已取消" name="-1"></van-tab> <van-tab title="已完成" name="1"></van-tab>
      </van-tabs>
      <div style="height: 3vw"></div>
      <van-list v-model="loading" :finished="finished" @load="onload">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="order" v-for="(item, index) in order" :key="index" style="margin-top: 0">
            <div class="order_box" >
              <div class="order_img"><img :src="item.get_box.image" /></div>
              <div class="order_info">
                <div class="order_name"><div class="price">{{ getSubString(item.get_box.name) }}</div><div class="priceo">x 1</div></div>
                <span class="order_brand">{{ item.brand }}</span>
                <div class="pricet">￥{{ item.price }}</div>
              </div>
            </div>
            <div class="order_bot">
              <div class="ob_lt">{{ item.create_time }}</div>
              <div class="ob_rt" v-if="item.status == 0">
                <span class="ob_jg" @click="toCancel(item.order_sn)">取消订单</span><span class="bg_rt" @click="toPay(item.id, item.order_sn)">待付款</span>
              </div>
              <div class="ob_rt" v-if="item.status == 1"><span class="bg_de">已完成</span></div>
              <div class="ob_rt" v-if="item.status == -1"><span class="bg_de">已取消</span></div>
            </div>
          </div>
        </van-pull-refresh>
      </van-list>
      <div class="empty" v-if="order && order.length==''"><van-empty description="盲盒订单里没有东西哟" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blind_list",
  data() {
    return {
      active: "",
      order: [],
      loading: false,
      finished: false,
      isLoading: false,
      page: 1,
    }
  },
  created() {
    this.getOnload();
  },
  methods: {
    // 返回我的
    toBack() {
      this.$router.push({ path: '/mine' });
    },
    // 订单加载
    getOnload() {
      this.loading = false
      this.finished = false
      this.page = 1
      this.order = []
      this.onload()
    },
    // 下拉刷新
    onRefresh() {
      var page = this.page++;
      let data = {};
      if (this.active == "") {
        data = { page: page };
      } else {
        data = { page: page, status: this.active };
      }
      setTimeout(() => {
        this.$toast("刷新成功")
        this.isLoading = false
      }, 1000)
      this.$get({
        url: "/app/blind_box_orders/myOrders",
        data: data,
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.order = list
            } else {
              for (var i in list) {
                this.order.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => { this.finished = true },
      })
    },
    // 上拉刷新
    onload() {
      var page = this.page++
      let data = {}
      if (this.active == "") {
        data = { page: page }
      } else {
        data = { page: page, status: this.active }
      }
      this.$get({
        url: "/app/blind_box_orders/myOrders",
        data: data,
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
            this.total = 0
          } else {
            var list = res.data.data
            var lpage = res.data.current_page
            if (page == 1) {
              list.forEach((val) => {
                val.page = lpage
              })
              this.order = list
            } else {
              list.forEach((val) => {
                val.page = lpage
                this.order.push(val)
              })
            }
          }
          this.loading = false
          console.log("这是orderpage", this.order)
        },
        tip: () => { this.finished = true },
      })
    },
    // 截取字符串
    getSubString(item) {
      if (item.length > 10) {
        return item.substring(0, 10) + "..."
      } else {
        return item
      }
    },
    // 去付款
    toPay(id, order_sn) {
      this.$router.push({ path: "/blind_order", query: { ordersn: order_sn, id: id } })
    },
    // 取消订单
    toCancel(orderNo) {
      let sentData = { order_sn: orderNo };
      this.$get({
        url: "/app/blind_box_orders/cancleOrder",
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            this.$toast("订单取消成功！");
            this.getOnload();
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.van-pull-refresh) { min-height: calc((100vh - 176vw) / 2); }
.bg {width: 100%; height: auto; background: #F7F7F9;}
.while {background: #ffffff;}
.tabs {
  /deep/ .van-tab--active {font-size: 4.5vw !important; font-weight: 500 !important; color: #000 !important;}
  /deep/ .van-tab {font-size: 4vw; font-weight: 400; color: #797979; background: #ffffff;}
  /deep/ .van-tabs__line {height: 0;}
}
.order {
  width: 96%; height: 41vw; margin: 2vw 2%; background: #fff;
  .order_box {
     width: 100%; display: flex; align-items: center; padding: 1vw 1vw 1vw 2vw;
    .order_img {width: 30%; height: 30vw; img {width: 28vw; height: 28vw; object-fit: cover; padding-top: 1.2vw;}}
    .order_info {
      width: 70%; height: 30vw; flex-shrink: 1; padding-left: 1.5vw;
      .order_name {
        display: flex; justify-content: space-between; align-items: center; line-height: 1.2;
        .price {flex: 6; margin: 1.2vw 2%; color: #000; font-size: 4.15vw; font-weight: 600;} .priceo {flex: 2; text-align: right; font-size: 3.2vw; font-weight: 400; color: #999;}
      }
      .order_brand {height: 5vw; line-height: 5vw; background: #FFFAF5; color:#FC9C32; font-size: 3vw; padding: 0.5vw 2vw;}
      .pricet {color: #000; font-size: 4.27vw !important; font-weight: 600; margin-top: 6vw;}
    }
  }
  .order_bot {
    width: 100%; height: 8vw; line-height: 8vw; margin: 0 auto; display: flex; flex-wrap: nowrap; flex-direction: row; padding: 0 2%;
    .ob_lt {flex: 1; height: 8vw; line-height: 8vw; margin: 0 auto; color:#CBCBCC; font-size: 3.2vw;}
    .ob_rt {
      flex: 1; height: 6vw; line-height: 6vw; text-align: right; margin: 0 auto; span {padding: 1vw 2.5vw; margin-left: 10px; color: #000; font-size: 3.4vw;}
      .bg_lt {border: #FC3D3D solid 1px; color: #FF2222;} .bg_rt {text-align: right; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff;} 
      .ob_jg {border: #FC3D3D solid 1px; color: #FF2222;}
      .bg_de {background: #CBCBCC; color: #fff;}
    }
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
