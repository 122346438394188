<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="添加银行卡" left-arrow @click-left="xz_go('bankb_list')" :border="false" z-index="1000" />
    <div style="height: 3vw"></div>
    <!-- 表单 -->
    <div class="from_box">
      <van-field :border="false" clearable label="姓名" v-model="name" placeholder="请输入姓名" readonly />
      <van-field :border="false" clearable label="身份证号" v-model="cardID" placeholder="请输入身份证号" readonly />
      <van-field :border="false" clearable label="银行卡号" v-model="cardNo" placeholder="请输入银行卡号" />
      <van-field :border="false" clearable label="预留手机号" v-model="phone" placeholder="请输入预留手机号" />
      <div><!--留一个div保证最后一个输入框有下划线--></div>
    </div>
    <div style="height: 1vw"></div>
    <div class="cont"><van-checkbox v-model="checked">同意 <span class="txt" @click="toGo">《连连支付服务协议》</span></van-checkbox></div>
    <div class="btn_box"><van-button size="normal" type="success" @click="addBank">提 交</van-button></div>
    <div style="height: 4vw"></div>
    <!-- <div class="tit"><div class="h2">绑卡说明</div><div class="tit_txt">工商银行、农业银行、招商银行、兴业银行、部分地区商业银行等不支持充值，仅支持提现。</div></div> -->
    <van-dialog v-model:show="show" show-cancel-button @confirm="toVerify">
      <div class="pop"><div class="pop_tit">验证码</div><van-field type="text" v-model="verify_code" clearable placeholder="请输入验证码" class="input" /></div>
    </van-dialog>
  </div>
</template>

<script>
import returnUrl from '@/utils/return_url.js';
export default {
  components: {},
  data() {
    return {
      userId: "",
      bank_token: '',
      flag: '',
      openUrl: '',
      name: "",
      cardID: "",
      cardNo: "",
      phone: "",
      checked: true,
      password: '',
      randomKey: '',
      txn_seqno: '',
      bing_token: '',
      verify_code: '',
      show: false
    }
  },
  mounted() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://oss-mpay-static.lianlianpay.com/lib/accp-password/v2.0.0.js';
    document.body.appendChild(s);
  },
  created() { 
    this.getBankInfo();
    this.getBankFlag();
  },
  methods: {
    // 个人信息
    getBankInfo() {
      this.$get({
        url: "/app/member/getRealInfo",
        data: {},
        success: (res) => {
          this.userId = res.data.member_id;
          this.cardID = res.data.id_num;
          this.name = res.data.real_name;
        },
        tip: () => {},
      })
    },
    // 获取开户信息
    getBankFlag() {
      this.$post({
        url: "/app/wallet_lian_lian/userInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.flag = res.data.bank_open_flag
            console.log("flag:", this.flag);
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 获取绑卡token
    addBank() {
      this.$post({
        url: "/app/wallet_lian_lian/getPasswordElement",
        data: { scene: "bind_card_password"},
        success: (res) => {
          if (res.code == 1) {
            this.bank_token = res.data;
            this.toBankAdd();
          } else {
            this.$toast(res.msg);
          } 
        },
        tip: () => {},
      })
    },
    // 添加银行卡
    toBankAdd() { 
      if (!this.checked) return this.$toast("请勾选吉吉数藏协议！");
      if (!this.cardNo) return this.$toast("请输入银行卡号");
      if (!this.phone) return this.$toast("请输入预留手机号");
      if (this.flag == 1) {
        this.toBankBing();
      } else {
        this.$toast("您尚未开户! 即将进入连连官方开户页面！");
        this.getLianlianUrl();
      }
    },
    // 绑卡
    toBankBing() {
      lianlianpay.invokePasswordControl({
        passwordScene: 'bind_card_password', // 使用场景 (设置密码: setting_password, 修改密码: change_password, 换绑卡: bind_card_password, 提现: cashout_password, 支付: pay_password)
        oidPartner: '2022090107638862', // 商户号
        userId: this.userId, // 用户号
        passwordElementToken: this.bank_token, // 向服务端请求生成的 token
        env: 'production', // 默认 production-生产
        styles: { themeColor: '#0E59F0' } // 主题颜色（按钮等）
      }, (res) => {
        console.log("密码数据", res);
        if (res.code == '0000') {
          this.password = res.data.password;
          this.randomKey = res.data.randomKey;
          this.getBankBindApply();
        } else {
          this.$toast(res.message);
        }
      })  
    },
    // 绑卡申请
    getBankBindApply() {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardBindApply",
        data: { acct_no: this.cardNo, acct_phone: this.phone, password: this.password, random_key: this.randomKey },
        success: (res) => {
          if (res.code == 1) {
            this.bing_token = res.data.token;
            this.txn_seqno = res.data.txn_seqno;
            this.show = true;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 去验证
    toVerify() {
      this.getBankBindVerify();
    },
    // 绑卡验证
    getBankBindVerify() {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardBindVerify",
        data: { ll_token: this.bing_token, verify_code: this.verify_code, txn_seqno: this.txn_seqno},
        success: (res) => {
          if (res.code == 1) {
            console.log("成功：", res.data);
            this.xz_go('bankb_list');
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 获取连连开户页面
    getLianlianUrl() {
      let return_url = returnUrl.baseUrl + "#/bankb_list?jump=page";
      this.$get({
        url: "/app/wallet_lian_lian/getOpenAccUrl",
        data: { return_url: return_url },
        success: (res) => {
          if (res.code == 1) {
            window.location.href = res.data.gateway_url;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 协议
    toGo() {
      window.location.href = 'https://static.lianlianpay.com/accpweb/agreement/OpenAccountAgree_ACS.html'
    }
  }
}
</script>

<style lang="scss" scoped>
//单元格
:deep(.van-field) {background: #F5F5F5; margin-bottom: 4vw; }
.from_box {width: 96%; margin: 2vw 2%; --van-field-label-color: #000; --van-field-input-text-color: #000;}
.cont {width: 92%; height: 20vw; margin: 4vw 4%; --van-checkbox-size: 4vw; --van-checkbox-checked-icon-color: #2A7AF0;}
.txt {color: #2A7AF0; font-size: 4vw;}
.btn_box { .van-button--success {width: 92%; height: 14.2vw; margin: 2vw 4%; background: #2A7AF0; border-radius: 20vw;} }
:deep(.van-button--warning) {border: 1px solid #01fdbd !important;}
:deep(.van-field__button) {display: flex;}
.field { :deep(.van-cell) { .van-cell__value {flex: 1; color: #000;} .van-field__label {display: flex; align-items: center;}}}
.tit {width: 92%; height: auto; margin: 2vw 4%;}
.h2 {color: #000; font-size: 3.7vw;}
.tit_txt {color: #959595; font-size: 3.2vw;}
.pop {width: 90%; height: 16vw; margin: 2vw 5%; --van-dialog-background-color: #848484; --van-field-input-text-color: #000;}
.pop_tit {width: 90%; height: 5vw; margin: 2vw 5%; text-align: center; color: #848484; font-size: 3.6vw;}
.input {width: 90%; height: auto; margin: 2vw 5%; border-bottom: #E3E3E3 solid 1px;}
</style>
