<template>
  <van-nav-bar title="中签结果公示" left-arrow  @click-left="xz_back" :border="false" />
  <div class="box">
    <div class="banner"><img :src="goodImg" /></div>
    <div class="top">
      <div class="top_tit">{{ goodName }}</div>
      <div class="top_txt"><div class="top_lt">公示时间：{{ showTime }}</div><div class="top_rt">¥{{ price }}</div></div>
      <div class="top_bot">
        <div class="tb_num" style="text-align: left;">参与人数：{{ joinNum }}</div> 
        <div class="tb_num" style="text-align: center;">抽签码总计：{{ totalNum }}</div> 
        <div class="tb_num" style="text-align: right;">中签码数量： {{ drawNum }}</div>
      </div>
    </div>
    <div class="div"></div>
    <div class="bot">
      <div class="bot_tit">中签结果</div>
      <div class="bot_con" v-for="(item, index) in drawList" :key="index">
        <div class="bot_lt"><img :src="item.head_img" /></div>
        <div class="bot_mm"><div class="name">{{ item.nickname }}</div><div class="tel">{{ item.phone }}</div></div>
        <div class="bot_rt"><div class="no">{{ item.code }}</div><div class="txt">中签码</div></div>
      </div>
    </div>
    <div class="btn">恭喜你中签 {{ luckyNum }} 次</div>
    <div style="height: 2vw"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      goodImg: '',
      goodName: '',
      joinNum: '',
      totalNum: '',
      drawNum: '',
      showTime: '',
      price: 0,
      drawList: [],
      luckyNum: 0
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDrawInfo();
  },
  methods: {
    // 规则列表
    getDrawInfo() {
      this.$get({
        url: "/app/goods/goodsInfo",
        data: { id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.goodImg = res.data.product_image;
            this.goodName = res.data.product_name;
            this.joinNum = res.data.draw.joinNumber;
            this.totalNum = res.data.draw.totalCodeNum;
            this.drawNum = res.data.draw.totalBallotNum;
            this.showTime = res.data.rob_show_time;
            this.price = res.data.price;
            this.drawList = res.data.draw.allBallotCodes;
            this.luckyNum = res.data.draw.ballotNum;
          } else {
            this.$toast(res.msg);
          }
        }
      })
    }
  },
}
</script>

<style lang="scss">
.banner {width: 94%; height: 95vw; margin: 2vw 3%; img {width: 100%; height: 100%; object-fit: cover; border-radius: 2vw;}} 
.top {width: 94%; height: 26vw; margin: 2vw 3%;}
.top_tit {width: 100%; height: 8vw; line-height: 8vw; color: #000; font-size: 3.8vw; font-weight: 600;}
.top_txt {width: 100%; height: auto; margin: 0 auto; display: flex;}
.top_lt {width: 100%; height: 5vw; line-height: 5vw; color: #666666; font-size: 3.2vw;}
.top_rt {width: 100%; height: 5vw; line-height: 5vw; text-align: right; color: #000; font-size: 6.4vw; margin-top: -2vw;}
.top_bot {width: 100%; height: 10vw; margin: 4vw auto; display: flex; flex-wrap: nowrap; flex-direction: row; border-top: #F5F5F5 solid 1px;}
.tb_num {flex: 1; height: 10vw; line-height: 10vw; color: #666666; font-size: 3.2vw;}
.div {width: 100%; height: 3vw; background: #F5F5F5;}
.bot {width: 94%; height: auto; margin: 2vw 3%; border-bottom: #F5F5F5 solid 1px;}
.bot_tit {width: 100%; height: 10vw; line-height: 10vw; color: #000; font-size: 4.2vw; font-weight: 600;}
.bot_con {width: 100%; height: 15vw; margin: 4vw auto; display: flex; flex-wrap: nowrap; flex-direction: row;}
.bot_lt {width: 15%; height: 12vw; line-height: 12vw; text-align: left; img {width: 12vw; height: 12vw; vertical-align: middle; border-radius: 50%;}}
.bot_mm {width: 32%; height: 12vw; line-height: 1.6; text-align: left; .name {color: #26221D; font-size: 4vw; font-weight: 600;} .tel {color: #666666; font-size: 3.2vw;}}
.bot_rt {width: 53%; height: 12vw; line-height: 1.6; text-align: right; .no {color: #F66D2A; font-size: 4vw; font-weight: 600;} .txt {color: #666666; font-size: 3.2vw;}}
.btn {width: 80%; height: 12.8vw; line-height: 12.8vw; margin: 5vw 10%; text-align: center; background: #FFF3F3; color: #FF2222; font-size: 4vw; font-weight: 500; border-radius: 2vw;}
</style>