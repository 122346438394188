<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="充值" left-arrow @click-left="xz_go('wallet_detailb')" :border="false" z-index="1000" />
    <div style="height: 2vw"></div>
    <!-- //输入充值数量 -->
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="充值金额" value="" /></div>
      <div style="height: 5vw"></div>
      <van-cell-group inset><van-field label="¥" v-model="number" type="number" clearable placeholder="请输入充值金额" /></van-cell-group>
      <div style="height: 2vw"></div>
      <div class="text">账户金额：{{ parseFloat(balance).toFixed(2) }}</div>
    </div>
    <div style="height: 5vw"></div>
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="充值须知" /></div> <div style="height: 2vw"></div> <div class="text">{{ info.info }}</div>
    </div>
    <div style="height: 5vw"></div>
    <div class="btn_box"><van-button size="large" :loading="loading" type="success" @click="toRecharge">充 值</van-button> <div class="text">充值成功后立即到账</div></div>
  </div>
</template>

<script>
import returnUrl from '@/utils/return_url.js';
export default {
  data() {
    return {
      number: "",
      loading: false,
      balance: "",
      settle_money: '', // 待结算余额
      drawal_money: '', // 可提现余额
      info: {}
    }
  },
  created() {
    this.getBalanceInfo();
    this.getExplain();
  },
  methods: {
    // 获取用户余额
    getBalanceInfo() {
      this.$post({
        url: "/app/wallet_lian_lian/userInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.settle_money = res.data.acctinfo_list[0].amt_balaval;
            this.drawal_money = res.data.acctinfo_list[1].amt_balaval;
            this.balance = parseFloat(this.settle_money) + parseFloat(this.drawal_money);
          } else {
            this.$toast(res.msg);
          }
        }
      })
    },
    // 充值须知
    getExplain() {
      this.$get({
        url: "/app/recharge/getInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.info = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 去充值
    toRecharge() {
      if (this.number == '') {
        this.$toast("请输入充值金额");
        return;
      }
      let return_url = returnUrl.baseUrl + '#/recharge_b?jump=page';
      this.$post({
        url: "/app/wallet_lian_lian/userCharge",
        data: { amount: this.number, return_url: return_url },
        success: (res => {
          console.log("充值：", res.data);
          if (res.code == 1) {
            window.location.href = res.data.gateway_url;
          } else {
            this.$toast(res.msg);
          }
        }),
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.input_box {
  align-items: center; margin: 0 auto; padding: 2vw; width: 90vw; background: #fff; --van-cell-text-color: #000; box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);
  .cell {:deep(.van-cell) {padding: 0; .van-badge__wrapper {display: flex; align-items: center;}}}
  .van-cell-group {
    width: 92%; height: 12vw; margin: 0 4%; background: #F5F5F5;
    :deep(.van-cell__title) {width: 3vw; font-size: 3vw; background: #ff5c5c; background-clip: text; -webkit-background-clip: text; color: transparent; font-weight: 800;}
    :deep(.van-field__control) {background: #ff5c5c; background-clip: text; -webkit-background-clip: text; color: transparent; font-weight: 800;}
  }
  .text {width: 78vw; margin: 0 auto; color: #000; font-size: 3vw;}
}
//确认按钮
.btn_box {
  position: fixed; width: 92%; margin: 0 4%; margin-bottom: 4vw; bottom: 0;
  .van-button--success {height: 14.1vw; background: #2A7AF0 ; border-radius: 7.2vw;}
  .text {color: #ff5c5c; font-size: 3vw; text-align: center;}
}
</style>
