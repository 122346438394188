<template>
  <div class="yuangong">
    <!-- <van-nav-bar title="付款方式" /> -->

    <!-- <div class="bar_empty"></div> -->
    <van-nav-bar left-arrow fixed :border="false" @click-left="back" />
    <div class="box">
      <div class="eee">
        <img class="pay_zhezhao" v-if="!is" src="@/assets/img/pay_zhezhao.png" />
        <img class="weixin_pay" src="@/assets/img/weixin_pay.png" alt />
        <!-- <p class="tip">您必须在手机浏览器中打开本页面才能继续进行支付操作！</p> -->
      </div>
      <div class="title">支付宝支付</div>

      <div class="btn">
        <button @click="buy" :loading="loading" class="divform">
          立即支付
        </button>
      </div>
    </div>
    <van-dialog v-model="show" title="提示" :before-close="comfirm_show" show-cancel-button confirmButtonColor="#333">
      <p class="ttt">请在右上角菜单中选择在浏览器中打开本！</p>
    </van-dialog>
    <!-- <van-dialog v-model="show2" title="提示" :before-close="comfirm_show2" show-cancel-button>
      <p class="ttt">您是否已经在手机浏览器中打开本页面并完成支付？</p>
    </van-dialog>-->
  </div>
</template>

<script>
  export default {
    props: {},
    data() {
      return {
        way: "wechat", //alipay
        order_sn: "",
        type: "",
        info: "",
        show1: false,
        loading: false,
        no: false,
        is: false,
        show: false,
        show2: false,
        orderid: this.$route.query.orderid,
      }
    },
    created() {
      // this.check();
      this.order_sn = this.$route.query.order_sn
      this.type = this.$route.query.type
      this.radio = this.$route.query.radio

      this.isWeixinBrowser()
    },
    methods: {
      back() {
        this.xz_back()
        // this.$dialog
        //   .confirm({
        //     title: "是否取消支付",
        //   })
        //   .then(() => {
        //     this.$post({
        //       url: "/order/cancel",
        //       data: {
        //         id: this.orderid,
        //       },
        //       success: (res) => {
        //         console.log(res, 445566);
        //         setTimeout(() => {
        //           this.$router.push("/");
        //         }, 1200);
        //       },
        //     });
        //   })
        //   .catch(() => {
        //     // on cancel
        //   });
      },
      choice(index) {
        this.way = index
      },
      comfirm_show(action, done) {
        if (action == "confirm") {
          this.show = false
          //this.show2 = true;
          done()
        } else {
          this.show = false
          //this.show2 = true;
          done()
        }
      },
      //判断是步是支付宝浏览器

      isWeixinBrowser() {
        var ua = navigator.userAgent.toLowerCase()
        var result = /micromessenger/.test(ua) ? true : false
        if (result) {
          console.log("你正在访问支付宝浏览器")
          this.is = false
          this.show = true
        } else {
          console.log("你访问的不是支付宝浏览器")
          this.is = true
          this.show = false
        }
        return result
      },

      buy() {
        this.loading = true
        this.$get({
          url: "/app/pay/pay",
          data: { order_sn: this.order_sn, app: 0 },
          success: (res) => {
            console.log("支付宝支付。。。", res)
            // const div = document.createElement("div");
            // div.innerHTML = res.data.data; //res.data是返回的表单
            // document.body.appendChild(div);
            // document.forms[0].submit(); //渲染支付宝支付页面
            location.href = res.data.url
          },
          tip: () => {
            setTimeout(() => {
              this.loading = false
            }, 1500)
          },
        })
      },
    },
  }
</script>

<style scoped lang='scss'>
  .title {
    font-size: 6vw;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 44vw;
    z-index: 2;
  }

  .pay_zhezhao {
    width: 100%;
    position: absolute;
    z-index: 3;
  }
  .weixin_pay {
    width: 100%;
    position: absolute;
    z-index: 1;
  }

  .yuangong {
    width: 100%;
    min-height: 100vh;
    background: #f7f7f7;
  }
  /deep/ .van-nav-bar {
    position: absolute;
    z-index: 100;
    background: transparent;
    box-shadow: none;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff !important;
  }
  /deep/.van-nav-bar__text {
    color: #000;
  }
  .box {
    padding: 0;
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    // align-items: center;
  }
  .xz {
    width: 100%;
    background: #fff;
    padding: 10px 16px;
    margin-bottom: 100px;
    .tt {
      font-size: 14px;
      color: #333333;
    }
    .choice {
      position: relative;
      .cho {
        display: flex;
        align-items: center;
        padding: 10px 0 10px 10px;
        .tu {
          width: 25px;
          height: 25px;
        }
        p {
          padding-left: 10px;
          font-size: 13px;
        }
        .queren {
          position: absolute;
          right: 15px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  button {
    width: 60vw;
    padding: 4vw;
    background: linear-gradient(to right, #134e5e, #71b280);
    color: #fff;
    font-size: 4vw;
    border-radius: 2vw;
  }
  .ttt {
    padding: 10px;
  }
  .tip {
    font-size: 15px;
    width: 100%;
    overflow: auto;
    text-align: justify;
    text-justify: newspaper;
    word-break: break-all;
  }
  .eee {
    width: 100%;
    img {
      width: 100%;
    }
    p {
      padding-top: 30px;
      color: #fff;
    }
  }
  .btn {
    position: absolute;
    top: 150vw;
    z-index: 2;
  }
  .btn2 {
    position: absolute;
    top: 150vw;
    z-index: 4;
  }
  .ye {
    background: linear-gradient(to right, #134e5e, #71b280);
  }
</style>
