<template>
  <div class="steps">
    <ul>
      <li class="stepItem" v-for="(stepItem, index) in stepInfo.list" :key="index">
        <p :class="stepInfo.step >= index + 1 ? 'name name_act' : 'name'">{{ stepItem.status }}</p>
        <div :class="stepInfo.step >= index + 1 ? 'icon' : 'icon'"><img src="~@/assets/img/rank02.png" /></div>
        <div :class="stepInfo.step >= index + 2 ? 'line line_act' : 'line'" v-show="index !== stepInfo.list.length - 1"></div>
        <p :class="stepInfo.step >= index + 1 ? 'good good_act' : 'good'">{{ stepItem.goodName }}</p>
        <div :class="stepItem.btnClass" @click="toReceive(stepInfo.id, stepItem.lv, stepItem.isRecvice)">{{ stepItem.btnName }}</div>
      </li>
    </ul>
  </div>
</template>
  
<script>
export default {
  name: "step",
  props: {
    stepInfo: {
      type: Object,
      default: function () {
        return {
          list: [],
          step: 0, 
          id: 0
        }
      },
    }
  },
  data() {
    return {}
  },
  methods: {
    // 领取奖励
    toReceive(id, lv, pick) {
      let sentData = { id: id, rechieve_level: lv }
      if (pick == 0) {
        this.$get({
          url: "/app/member/getRechieveGoods",
          data: sentData,
          success: (res) => {
            if (res.code == 1) {
              this.$toast("领取成功！");
              this.$router.go(0);
            } else {
              this.$toast(res.msg);
            }
          },
          tip: () => {},
        })
      } else if (pick == 1) {
        return
      } else if (pick == -1) {
        return
      }
    },
  }
};
</script>
  
<style lang="scss" scoped>
.steps {
  width: 100%; height: 22vw; margin: 0 auto; display: flex; justify-content: center;
  .stepItem {
    position: relative; width: 30vw; height: 14vw; text-align: center; font-size: 3.6vw; float: left;
    .name {width: 100%; height: 5vw; line-height: 5vw; text-align: center; color: #000000;}
    .name_act {color: #F66D2A;}
    .icon {position: relative; width: 5.6vw; height: 5.6vw; margin: 2vw auto; z-index: 9999; img {width: 100%; height: 100%; vertical-align: middle;}}
    .line {position: absolute; width: 30vw; top: 10.8vw; left: 50%; border-bottom: #EDEDED solid 0.6vw; z-index: 111;}
    .line_act {border-bottom: #F66D2A solid 1px;}
    .good {width: 100%; height: auto; line-height: 4vw; margin: 0 auto; text-align: center; color: #9A999E; font-size: 3.2vw;}
    .good_act {color: #F66D2A;}
    .btn {width: 60%; height: 5vw; line-height: 1.2; margin: 2vw 20%; text-align: center; background: #EDEDED; color: #999; font-size: 3vw; padding: 0.5vw 2vw; border-radius: 13vw;}
    .btn_act {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff;}
  }
}
</style>
  