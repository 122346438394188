"use strict";
import axios from "axios";
import router from "./../router";
import qs from "qs";
import JSONBIG from "json-bigint";
import returnUrl from '@/utils/return_url.js';

export default function http(app) {
  const _this = app.config.globalProperties;
  _this.$axios = axios;
  axios.defaults.withCredentials = false; //携带cookie
  axios.defaults.transformResponse = [
    function (data) {
      const json = JSONBIG({ storeAsString: true });
      const res = json.parse(data);
      if (localStorage.version == undefined && res.version != undefined) {
        localStorage.version = res.version;
      }
      if (localStorage.version != undefined && res.version != undefined && res.version != localStorage.version) {
        localStorage.version = res.version;
        if (confirm('当前APP有更新，是否刷新？')) {
          location.reload();
        }
      }
      return res;
    },
  ];

  if (process.env.NODE_ENV == "development") {
    axios.defaults.baseURL = returnUrl.baseUrl; // 解决跨域
    _this.httpPath = returnUrl.baseUrl; //访问地址
    console.log("测试", axios.defaults.baseURL, _this.httpPath);
  } else {
    axios.defaults.baseURL = returnUrl.baseUrl; //接口地址
    _this.httpPath = returnUrl.baseUrl; //访问地址 
    console.log("正式", axios.defaults.baseURL, _this.httpPath);
  }

  axios.defaults.timeout = 60000; //请求超时
  const no_login_code = -1; //未登录返回的状态码
  const success_code = 1; //成功返回的状态码
  const err_code = 0; //失败返回的状态码

  _this.$get = (params) => {
    const token = localStorage.getItem("token") || "";
    axios.get(params.url, {
      params: Object.assign({}, params.data, token ? { token } : ""),
      validateStatus: function (status) { return status > 10; }
    }).then((res) => {
      _this.checkStatus(params, res);
    }).catch((err) => {
      console.log("服务器请求失败", err);
      if ('undefined' != typeof err.response) {
        if (err.response.status == "404") {
          _this.$toast({ messgae: "接口不存在" });
        } else if (err.response.status == "500") {
          _this.$toast("服务器内部错误");
        } else if (params.fail) params.fail(err);
      }
    });
  };

  _this.$post = (params) => {
    const token = localStorage.getItem("token") || "";
    let $axios;
    if (params.upload) {
      params.data.append("token", token ? token : "");
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
        validateStatus: function (status) { return status > 10; }
      };
      $axios = axios.post(params.url, params.data, config);
    } else if (params.noToken) {
      let data = qs.stringify(Object.assign({}, params.data));
      $axios = axios.post(params.url, data);
    } else {
      let data = qs.stringify(Object.assign({}, params.data, token ? { token } : {}));
      $axios = axios.post(params.url, data);
    }
    $axios.then((res) => {
      _this.checkStatus(params, res);
    }).catch((err) => {
      console.log("服务器请求失败", err);
      if (err.response.status == "404") {
        _this.$toast("接口不存在");
      } else if (err.response.status == "500") {
        _this.$toast("服务器内部错误");
      } else if (params.fail) params.fail(err);
    });
  };

  _this.checkStatus = (params, res) => {
    if (res.data.code == no_login_code) {
      _this.$toast("未登录");
      const token = localStorage.getItem("token") || "";
      if (token) localStorage.removeItem("token");
      setTimeout(() => { window.scrollTo(0, 0); router.replace("login") }, 1500);
    } else if (res.data.code == success_code) {
      if (params.success) params.success(res.data);
    } else if (res.data.code == err_code) {
      if (params.tip) {
        params.tip("val");
        _this.$toast(res.data.msg);
        console.group("%c" + params.url, "color: #6600FF; line-height: 30px; padding: 10px 0;", res.data);
      } else if (params.tips) {
        // 无轻提示
        params.tips("vals");
      }
      if ('undefined' != typeof res.data.url) {
        _this.$toast(res.data.msg);
        setTimeout(function () { window.location.href = res.data.url }, 2000);
      }
      if ('undefined' != typeof res.data.essStatus) {
        if (res.data.essStatus == 3 // 合同拒签
          || res.data.essStatus == -1) { // 合同创建失败
          console.log("res_:", res.data.img);
          _this.$toast({
            type: 'html',
            message: '<div style="width: 100%; height: auto;"><div style="wigth: 96%; height: auto; margin: 10px 2%; font-size: 12px; color: #fff;">' + res.data.msg + '</div>' +
              '<div style="wigth: 96%; height: auto; margin: 10px 2%;"><img style="width: 100%;" src="' + res.data.img + '" /></div></div>',
            duration: 0
          })
        }
      }
    }
  };
}
