<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="忘记密码" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div class="hollo"></div>
    <!-- 表单 -->
    <div class="frombox">
      <van-field clearable v-model="phone" type="tell" maxlength="11" placeholder="请输入手机号"><template #left-icon><img src="@/assets/img/icon01.png" alt=""></template></van-field>
      <van-field clearable v-model="code" type="text" placeholder="请输入验证码">
        <template #button><vant-code url="/app/auth/sendSms" scene="forget_password" :phone="phone"></vant-code></template>
        <template #left-icon><img src="@/assets/img/icon02.png" alt=""></template>
      </van-field>
      <van-field clearable v-model="password" :type="pass_type" :readonly="phone == '' ? true : false" placeholder="请输入6～12位数字+字母密码" 
       :right-icon="pass_type == 'password' ? 'closed-eye' : 'eye'" @click-right-icon="pass_type == 'password' ? (pass_type = 'text ') : (pass_type = 'password')">
        <template #left-icon><img src="@/assets/img/icon03.png" alt=""></template>
      </van-field>
      <van-field clearable v-model="re_password" :type="re_password_type" :readonly="phone == '' ? true : false" placeholder="请再次输入6～12位数字+字母密码" 
       :right-icon="re_password_type == 'password' ? 'closed-eye' : 'eye'" @click-right-icon="re_password_type == 'password' ? (re_password_type = 'text ') : (re_password_type = 'password')">
        <template #left-icon><img src="@/assets/img/icon03.png" alt=""></template>
      </van-field>
      <div><!--留一个div保证最后一个输入框有下划线--></div>
    </div>
    <div style="height: 8vw"></div>
    <div class="btn_box"><van-button size="large" type="success" :disabled="disabled" @click="save">确定</van-button></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      disabled: false, //邀请人禁用
      btn_disabled: false, //不禁用按钮
      checked: false, //用户协议状态
      phone: "", //手机号
      password: "", //密码
      re_password: "", //交易密码
      referral_code: "", //推荐码
      code: "", //验证码
      pass_type: "password", //input类型
      re_password_type: "password", //确认密码类型
      readonly: true //只读
    }
  },
  created() {},
  methods: {
    //表单验证
    save() {
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.phone))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.phone) return this.$toast("请输入手机号")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.code))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.code) return this.$toast("请输入验证码")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.password))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.password) return this.$toast("请输入登录密码")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.re_password))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.re_password) return this.$toast("请输入重复密码")
      if (this.disabled) {
        return false
      }
      this.disabled = true
      // 修改密码
      this.$post({
        url: "/app/auth/forgetPassword",
        noToken: true,
        data: { phone: this.phone, sms_code: this.code, password: this.password, re_password: this.re_password },
        success: () => {
          this.$toast.clear()
          this.$toast("修改成功")
          setTimeout(() => { this.$router.replace("login"), this.disabled = false }, 1000)
        },
        tip: () => { setTimeout(() => { this.disabled = false }, 1000) }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.hollo {background: url(~@/assets/img/aa@2x.png); background-size: 100% 100%; background-repeat: no-repeat; width: 75vw; height: 55vw; margin: 0 auto; font-size: 7vw; color: #fff;}
//表单
.frombox {width: 100%; padding: 0 2vw; --van-field-input-text-color:#000; img {width: 5vw; height: 5vw; margin-right: 3vw; display: block;} }
.img_icon {width: 4vw; height: 4vw; display: block; img {width: 100%; height: 100%; display: block;} }
//单元格
:deep(.van-cell) {margin-bottom: 4vw; padding: 4vw 4vw;}
.btn_box {padding: 3vw; .van-button--success {height: 15vw; color: #fff; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);} }
:deep(.van-button--warning) {border: 0px solid #01fdbd !important;}
:deep(.VantCode .van-button--small) {color: #12eba2 !important; border: none !important;}
:deep(.van-field__button) {display: flex;}
</style>
