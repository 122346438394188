<template>
  <van-nav-bar title="公告" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
  <div class="box">
    <van-swipe class="banner" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="toBannerUrl(item.url)"><div class="img"><img :src="item.image_path" /></div></van-swipe-item>
    </van-swipe>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="tab_box">
        <van-tabs v-model:active="active" @click-tab="onClickTab"><van-tab :title="item.title" v-for="(item, index) in classify" :key="index" :name="item.id"></van-tab></van-tabs>
      </div>
      <div class="list">
        <div class="item" @click="xz_go('new_detail', item.id)" v-for="(item, index) in advList" :key="index">
          <div class="item_lt"><img :src="item.image" /></div>
          <div class="item_rt">
            <div class="name one">{{ getSubString(item.title) }}</div>
            <div style="height: 17vw"></div> <div class="abstr">{{ item.brand }} · {{ item.create_time }}</div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
      classify: [],
      active: "0",
      advList: [],
      page: 1,
      isLoading: false,
      minId: 0
    }
  },
  created() {
    this.minId = this.$cookies.get("minId");
    this.getBannerList();
    this.getNoticeClassify();
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      var page = this.page++
      setTimeout(() => {
        this.$toast("刷新成功")
        this.isLoading = false
      }, 1000)
    },
    //轮播
    getBannerList() {
      this.$get({
        url: "/app/slider/sliderList",
        success: (res) => {
          if (res.code == 1) {
            this.bannerList = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // banner转跳
    toBannerUrl(url) {
      if (this.minId == 1) {
        this.$toast("暂不支持此类型链接跳转！");
      } else {
        window.location.href = url;
      }
    },
    // 公告分类
    getNoticeClassify() {
      this.$get({
        url: "/app/homes/getArticleType",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.classify = res.data
            this.getNoticeTitle(this.classify[0].id)
            console.log("分类", this.classify)
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 切换
    onClickTab({ name }) {
      console.log(name)
      this.getNoticeTitle(name)
    },
    // 公告标题
    getNoticeTitle(type) {
      this.$get({
        url: "/app/homes/getArticleList",
        data: { type: type },
        success: (res) => {
          if (res.code == 1) {
            this.advList = res.data
            console.log("内容：", this.advList)
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 字符串截取
    getSubString(text) {
      if (text.length > 12) {
        return text.substring(0, 12) + "...";
      } else {
        return text;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
//广告
.banner {width: 100%; height: 38vw; margin: 0 auto; .img {width: 100%; height: 36.8vw; margin: 0.6vw auto; img {width: 100%; height: 100%; object-fit: cover;}}}
:deep(.van-tab--active) {color: #000000; font-size: 4.2vw; font-weight: 600;}
.tab_box {--van-tabs-bottom-bar-color: transparent;}
.list {
  .item {
    width: 94%; height: 32vw; background: #fff; margin: 4vw 3%; display: flex;
    .item_lt {flex: 4; height: 31vw; margin-right: 3vw; flex-shrink: 0; img {width: 100%; height: 100%; object-fit: cover;}}
    .item_rt {flex: 8; height: auto; .name {width: 88%; font-size: 4vw;} .one {color: #000;} .abstr {font-size: 3vw; color: #000;}}
    .text {display: flex; flex-flow: column; justify-content: space-between; .name {width: 88%; font-size: 4vw;} .one {color: #000;} .abstr {font-size: 3vw; color: #000;}}
  }
}
</style>
