<template>
  <div class="box">
    <van-nav-bar title="活动规则" left-arrow @click-left="xz_go('invite')" :border="false" />
    <div class="txt" v-html="explain"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      explain: '' 
    }
  },
  created() {
    this.getRuleInfo();
  },
  methods: {
    // 活动规则
    getRuleInfo() {
      this.$get({
        url: '/app/member/inviteInfo',
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.explain = res.data.note;
            console.log("explain:", this.explain);
          } else {
            this.$toast(res.msg);
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.txt {width: 92%; height: auto; margin: 4vw 4%; color: #000;}
</style>