<!--
 * @Author: your name
 * @Date: 2022-01-08 09:30:51
 * @LastEditTime: 2022-01-11 13:09:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \yinheshop\src\vant-extend\vant-code\vant-code.vue
-->
<template>
  <div class="VantCode">
    <van-button size="small" v-if="is_time" @click="showPopup()">获取验证码</van-button>
    <van-button size="small" v-else class="no">{{ scend }}S</van-button>
    <van-popup v-model:show="show_botto"><div class="slider_box"><noCaptcha @slideCallback="finishSlide" :reload="reload" /></div></van-popup>
    <!-- <van-overlay :show="show_botto" @click="show = false">
      <div class="wrapper"><div class="block"><noCaptcha @slideCallback="finishSlide" :reload="reload" /></div></div>
    </van-overlay> -->
  </div>
</template>

<script>
import noCaptcha from "@/components/noCaptcha.vue"
export default {
  components: { noCaptcha },
  name: "VantCode",
  props: {
    url: String,
    scene: String,
    phone: String,
  },
  data() {
    return {
      loginForm: { session_id: "", verify_token: "", sig: "" },
      show_botto: false,
      reload: true,
      is_time: true,
      scend: 60
    }
  },
  created() {
    console.log("加载验证码按钮组件，组件地址为vant-extend")
  },
  methods: {
    showPopup() {
      this.show_botto = true
    },
    // 完成滑动
    finishSlide(data) {
      // 按需使用返回值
      console.log("会话ID", data.sessionId)
      console.log("签名串", data.sig)
      console.log("滑块请求的token", data.token)
      console.log("data.scene", data.scene)
      if (data.cls === false) {
        //验证加载失败
        this.loginForm.cls = false
      }
      if (data.sessionId) {
        //非人机操作
        this.loginForm.scene = data.scene
        this.loginForm.ncToken = data.token
        this.loginForm.sig = data.sig
        this.loginForm.sessionId = data.sessionId
        delete this.loginForm.cls
      }
      //1 为抢购藏品
      this.sendsms()
    },
    //////////////////////////////////
    hClick() {
      alert("失败")
    },
    sendsms() {
      this.reload = false // 验证通过后，重置滑块设置为false
      //防抖 监听输入框
      var that = this
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        that.$post({
          url: that.url,
          data: {
            phone: that.phone,
            scene: that.scene,
            session_id: this.loginForm.sessionId,
            verify_token: this.loginForm.ncToken,
            sig: this.loginForm.sig,
          },
          success: (res) => {
            //  this.reload = true // 验证通过后，重置滑块设置为true
            this.show_botto = false
            that.$toast({ message: "发送成功" })
            that.is_time = false
            var timer = setInterval(() => {
              that.scend--
              if (that.scend == 0) {
                clearInterval(timer)
                that.is_time = true
                that.scend = 60
              }
            }, 1000)
          },
          tip: (val) => {
            //   this.reload = true // 验证通过后，重置滑块设置为true
            this.show_botto = false
          },
        })
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped="scoped">
:deep(.van-popup) {border-radius: 4vw; overflow: hidden;
  .slider_box {width: 92vw; height: 40vw; background: #222; display: flex; align-items: center; .sm-pop-inner {display: flex; justify-content: center;} }
}
/deep/#nc_2_n1z {height: 64px !important; line-height: 64px !important;}
/deep/#nc_1_n1z {height: 64px !important; line-height: 64px !important;}
/deep/.nc_bg {background: #078d24 !important;}
.VantCode { .van-button--small {color: #078d24; background: transparent; height: 6vw; border: none; border-radius: 26vw; display: block;} }
</style>