<template>
  <div class="box bg">
    <!-- 顶部栏 -->
    <van-nav-bar title="资金明细" left-arrow @click-left="xz_back" :border="false" z-index="1000" class="while" />
    <div class="tabs">
      <van-tabs v-model:active="active" @click="getonload">
        <van-tab title="全部"></van-tab>
        <van-tab title="支出" name="2"></van-tab>
        <van-tab title="收入" name="1"></van-tab>
        <van-tab title="充值" name="3"></van-tab>
        <van-tab title="提现" name="4"></van-tab>
        <div style="height: 3vw"></div>
        <van-list v-model="loading" :finished="finished" @load="onload">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div class="list">
              <div class="listo" v-for="(item, index) in order" :key="index">
                <div class="listt">
                  <div class="itemo">{{ item.remark }}</div>
                  <div class="itemt" v-if="item.amount < 0">{{ item.amount }}</div>
                  <div class="itemorge" v-if="item.amount > 0">+{{ item.amount }}</div>
                </div>
                <div class="lists"><div class="iteme">{{ item.create_time }}</div><div class="itemn">余额：{{ item.after }}</div></div>
              </div>
            </div>
          </van-pull-refresh>
        </van-list>
      </van-tabs>
      <div class="empty" v-if="order.length == 0"><van-empty description="资金明细里没有东西哟" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "",
      order: [],
      loading: false,
      finished: false,
      page: 1,
      isLoading: false,
    }
  },
  created() {},
  methods: {
    getonload() {
      this.loading = false
      this.finished = false
      this.page = 1
      this.order = []
      this.onload()
    },
    onRefresh() {
      var page = this.page++
      let data = {}
      if (this.active == "") {
        data = { page: page, balance_type: 1 }
      } else {
        data = { page: page, type: this.active, balance_type: 1 }
      }
      setTimeout(() => {
        this.$toast("刷新成功")
        this.isLoading = false
      }, 1000)
      this.$get({
        url: "/app/balance_log/balanceList",
        data: data,
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.order = list
            } else {
              for (var i in list) {
                this.order.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => { this.finished = true }
      })
    },
    onload() {
      var page = this.page++
      let data = {}
      if (this.active == "") {
        data = { page: page, balance_type: 1 }
      } else {
        data = { page: page, type: this.active, balance_type: 1 }
      }
      this.$get({
        url: "/app/balance_log/balanceList",
        data: data,
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
            this.total = 0
          } else {
            var list = res.data.data
            var lpage = res.data.current_page
            if (page == 1) {
              list.forEach((val) => {
                val.page = lpage
              })
              this.order = list
            } else {
              list.forEach((val) => {
                val.page = lpage
                this.order.push(val)
              })
            }
          }
          this.loading = false
          console.log("这是orderpage", this.order)
        },
        tip: () => { this.finished = true }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.bg {width: 100%; height: auto; background: #F5F5F5;}
.while {background: #ffffff;}
:deep(.van-pull-refresh) {min-height: calc((100vh - 176vw) / 2);}
.tabs {
  /deep/ .van-tab--active {font-size: 28px !important; font-weight: 500 !important; color: #000 !important;}
  /deep/ .van-tab {font-size: 24px; font-weight: 400; color: #797979; background: #fff;}
  /deep/ .van-tabs__line {height: 0;}
}
.list {
  padding: 0 30px; margin-bottom: 20px;
  .listo {
    width: 98%; height: auto; margin: 10px auto; background: #fff; margin-bottom: 30px; padding: 10px 20px;
    .listt {
      display: flex; justify-content: space-between; align-items: center; line-height: 1.5;
      .itemo {font-size: 28px; font-weight: 400; color: #000;}
      .itemt {font-size: 48px; font-weight: 500; color: #ff5c5c;}
      .itemorge {font-size: 48px; font-weight: 500; color: #139990;}
    }
    .lists {
      display: flex; justify-content: space-between; align-items: center; padding-top: 10px;
      .iteme {font-size: 24px; font-weight: 400; color: #000;}
      .itemn {font-size: 24px; font-weight: 400; color: #000;}
    }
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
