<template>
  <div class="box content">
    <!-- 顶部栏 -->
    <van-nav-bar :title="title" left-arrow @click-left="xz_back" :border="false" z-index="1000" class="bg"/>
    <div class="head"><img :src="goods_img" /> <div class="head_name">{{ goods_name }}</div></div>
    <div class="tsftop1">
      <div class="panel_box">
        <div class="panel_list">
          <div class="new_box"><div class="img"><img :src="author" alt=""></div><div><div>创作者</div><div class="fsz"> {{ name }}</div></div></div>
          <div style="height: 3vw"></div>
          <div class="div"></div>
          <div class="nrw"><div class="nrwli"><div class="ess">链上标识</div></div><div class="nrwli">{{ hash }}</div></div>
          <div style="height: 3vw"></div>
        </div>
      </div>
    </div>
    <div style="height: 23vw"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.query.id,
      title: '藏品数字证书',
      collect: '',
      goods_img: '',
      goods_name: '',
      author: '',
      name: '',
      hash: '',
      show_loading: true
    }
  },
  created() {
    let minId = this.$cookies.get("minId");
    if (minId == 1) {
      this.title = '商品数字证书'
    }
    this.getCertInfo();
  },
  methods: {
    // 页面加载
    onLoad() {
      this.show_loading = false;
      this.rotate();
    },
    rotate() {
      this.rotation.y += 0.01;
      this.rotateFrame = requestAnimationFrame(this.rotate);
    },
    // 证书信息
    getCertInfo() {
      this.$get({
        url: "/app/member_space/myDetails",
        data: { space_id: this.id },
        success: (res) => {
          this.collect = res.data;
          this.goods_img = res.data.good.product_image;
          this.goods_name = res.data.good.product_name;
          this.author = res.data.good.author_image;
          this.name = res.data.good.author_name;
          this.hash = res.data.hash;
        },
        tip: () => {},
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.box {--van-nav-bar-icon-color:#fff; --van-nav-bar-title-text-color: #fff;}
.content {width: 100%; height: auto; margin: 0 auto; background: #F7F7F9;}
.bg {background: #000;}
:deep(.van-loading) {display: flex; justify-content: center; width: 82vw; height: 50vw; align-items: center;}
.head {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 1vw; img {width: 100%; max-height: 100%; object-fit: cover;}
  .head_name {width: 96%; height: 5vw; line-height: 5vw; margin: 3vw 2%; color: #222222; font-size: 4.2vw; font-weight: 600;}
}
.tsftop1 {position: relative; z-index: 1; margin: 3vw auto;}
.details_title {display: flex; align-items: center; justify-content: center; .turn {width: 6vw; height: 6vw; img {width: 100%; height: 100%; display: block;}} }
.div {width: 100%; height: 3vw; background: #F7F7F9;}
//面板
.panel_box {
  width: 100%; margin: 0 auto; background: #fff; padding-top: 5vw;
  .panel_list {
    .new_box {
      width: 100%; height: 17vw; padding: 0 3vw; display: flex; align-items: center; background: #fff; color: #000; font-size: 4.2vw; font-weight: 600;
      .fsz {line-height: 1.5; color: rgb(138, 138, 138); font-size: 4vw; font-weight: normal;}
      .img {width: 15vw; height: 15vw; margin-right: 3vw; border-radius: 50%; img {width: 100%; height: 100%; display: block;} }
    }
    .nrw {
      width: 100%; min-height: 18vw; margin: 2vw auto; padding: 0 3vw; align-items: center; background: #fff;
      .nrwli {width: 100%; min-height: 9vw; color: #000; align-items: center; justify-content: space-between; .ess {color: #000; font-size: 4.2vw;}}
    }
  }
  --van-cell-vertical-padding: 1vw;
  --van-cell-horizontal-padding: 0;
  --van-cell-text-color: #fff;
  --van-cell-value-color: #fff;
  --van-grid-item-content-padding: 3vw 0;
  --van-grid-item-content-background-color: rgba(0, 238, 166, 0.1);
}
</style>
