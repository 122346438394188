const components_files = require.context("@/components", true, /\.vue$/);
const components = {};
// Be lightweight, powerful and easy to use
components_files.keys().forEach((key) => {
  if (components_files(key).default.name != "tabbar") {
    components[key.replace(/(\.\/|\.vue)/g, "")] =
      components_files(key).default;
  }
  // console.log("打印下", components);
});

export default components;
