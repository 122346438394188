<template>
  <div class="box bg">
    <!-- 顶部栏 -->
    <van-nav-bar title="藏品订单" left-arrow @click-left="xz_go('mine')" :border="false" z-index="1000" class="white"/>
    <div class="tabs white" >
      <van-tabs v-model:active="active" @click="toTabChange">
        <van-tab title="全部"></van-tab> <van-tab title="待付款" name="0"></van-tab><van-tab title="已取消" name="-1"></van-tab><van-tab title="已完成" name="1"></van-tab>
      </van-tabs>
      <div class="div"></div>
      <van-list v-model="loading" :finished="finished" @load="onload" class="bg">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="order" v-for="(item, index) in order" :key="index" style="margin-top: 0">
            <div class="order_box" >
              <div class="order_img"><img :src="item.product_image" /></div>
              <div class="order_info">
                <div class="order_name"><div class="price">{{ item.product_name }}</div><div class="priceo">X{{ item.goods_num }}</div></div>
                <span class="order_brand">{{ item.brand }}</span>
                <div class="order_price"><div class="op_lt">￥{{ item.price }}</div><div class="op_rt">{{ item.create_time }}</div></div>
              </div>
            </div>
            <div class="order_bot">
              <div class="ob_box" v-if="(item.status == 0)">
                <span class="ob_ht" v-if="item.ess_url" @click="toContract(item.ess_url)">查看合同</span>
                <span class="ob_jg" @click="toCancel(item.order_sn)">取消订单</span><span class="ob_xj" @click="toPay(item.id, item.order_sn)">去付款</span>
              </div>
              <div class="ob_box" v-if="(item.status == 1)"><span class="ob_ht" v-if="item.ess_url" @click="toContract(item.ess_url)">查看合同</span><span class="ob_mc">已完成</span></div>
              <div class="ob_box" v-if="(item.status == -1)"><span class="ob_mc">已取消</span></div>
            </div>
          </div>
        </van-pull-refresh>
      </van-list>
      <div class="empty" v-if="order && order.length==''"><van-empty description="藏品订单里没有东西哟" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderlist",
  data() {
    return {
      active: "",
      order: [],
      loading: false,
      finished: false,
      page: 1,
      isLoading: false
    }
  },
  created() {
    this.onload()
  },
  methods: {
    // 选项卡切换
    toTabChange() {
      this.loading = false
      this.finished = false
      this.page = 1
      this.order = []
      this.onload()
    },
    // 上拉加载
    onload() {
      var page = this.page++
      let data = {}
      if (this.active == "") {
        data = { page: page }
      } else {
        data = { page: page, status: this.active }
      }
      this.$get({
        url: "/app/goods_orders/orderList",
        data: data,
        success: (res) => {
          this.$toast.clear();
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
            this.total = 0
          } else {
            var list = res.data.data
            var lpage = res.data.current_page
            if (page == 1) {
              list.forEach((val) => { val.page = lpage })
              this.order = list
            } else {
              list.forEach((val) => {
                val.page = lpage
                this.order.push(val)
              })
            }
          }
          this.loading = false
        },
        tip: () => { this.finished = true },
      })
    },
    // 下拉刷新
    onRefresh() {
      var page = this.page++
      let data = {}
      if (this.active == "") {
        data = { page: page }
      } else {
        data = { page: page, status: this.active }
      }
      setTimeout(() => { this.$toast("刷新成功"), this.isLoading = false }, 1000)
      this.$get({
        url: "/app/goods_orders/orderList",
        data: data,
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.order = list
            } else {
              for (var i in list) {
                this.order.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => { this.finished = true },
      })
    },
    // 待付款
    toPay(id, order_sn) {
      this.$router.push({ path: "/optbox", query: { ordersn: order_sn, form: "数字藏品", type: 1, id: id } })
    },
    // 取消订单
    toCancel(orderNo) {
      let sentData = { order_sn: orderNo };
      this.$get({
        url: "/app/goods_orders/cancleOrder",
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            this.$toast("订单取消成功！");
            this.toTabChange();
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 查看合同
    toContract(url) {
      window.location.href = url;
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.van-pull-refresh) { min-height: calc((100vh - 176vw) / 2); }
.bg {width: 100%; height: auto; background: #F7F7F9;}
.white {background: #fff;}
.div {width: 100%; height: 2vw; background: #F7F7F9;}
.tabs {
  /deep/ .van-tab--active {font-size: 4.53vw !important; font-weight: 500 !important; color: #000 !important;}
  /deep/ .van-tab {font-size: 4vw; font-weight: 400; color: #848484;}
  /deep/ .van-tabs__line {width: 16vw; height: 0; border-bottom: #FC272E solid 1px;}
}
.order {
  width: 96%; height: 41vw; margin: 2vw 2%; background: #fff;
  .order_box {
     width: 100%; display: flex; align-items: center; padding: 1vw 1vw 1vw 2vw;
    .order_img {width: 30%; height: 30vw; img {width: 28vw; height: 28vw; object-fit: cover; padding-top: 1.2vw;}}
    .order_info {
      width: 70%; height: 30vw; flex-shrink: 1; padding-left: 1.5vw;
      .order_name {
        display: flex; justify-content: space-between; align-items: center; line-height: 1.2;
        .price {flex: 6; margin: 1.2vw 2%; color: #000; font-size: 4.15vw; font-weight: 600;} .priceo {flex: 2; text-align: right; font-size: 3.2vw; font-weight: 400; color: #999;}
      }
      .order_brand {height: 5vw; line-height: 5vw; background: #FFFAF5; color:#FC9C32; font-size: 3vw; padding: 0.5vw 2vw;}
      .order_price {
        width: 98%; height: 5vw; margin: 0 1%; display: flex; flex-wrap: nowrap; flex-direction: row; margin-top: 6.2vw;
        .op_lt {width: 40%; height: 5vw; line-height: 5vw; color: #000; font-size: 4.2vw; font-weight: 600;}
        .op_rt {width: 60%; height: 5vw; line-height: 5vw; text-align: right; color: #CBCBCC; font-size: 3.2vw;}
      }
    }
  }
  .order_bot {
    width: 100%; height: 8vw; line-height: 8vw; margin: 0 auto; display: flex; flex-wrap: nowrap; flex-direction: row; padding: 0 2%;
    .ob_box {
      width: 100%; height: 6vw; line-height: 6vw; margin: 0 auto; text-align: right; span {padding: 1vw 2.5vw; margin-left: 2.5vw; font-size: 3.4vw;}
      .ob_ht {border: #999999 solid 1px; color: #999999;} .ob_jg {border: #FC3D3D solid 1px; color: #FF2222;}
      .ob_xj {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff;} 
      .ob_mc {background: #CBCBCC; color: #fff;}
    }
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
