<template>
  <div class="box content">
    <van-nav-bar title="收藏详情" left-arrow @click-left="xz_back" :border="false" z-index="1000" class="bg">
      <template #right><van-icon :name="require('@/assets/img/c01.png')" size="20" @click="toShow" /></template>
    </van-nav-bar>
    <div class="head">
      <img :src="goods_image" class="img_top" /><div class="qr" v-if="spot_id > 0"><vue-qr :text="spot_url" class="qr_img" @click="toShowBig"></vue-qr></div>
      <div class="head_name">{{ goods_name }} # {{ goods_num }}</div>
    </div>
    <div style="height: 3vw"></div>
    <div class="tsftop1">
      <div class="panel_box">
        <div class="panel_list">
          <div class="new_box"><div class="img"><img :src="head_img" alt=""></div><div><div>收藏者</div><div class="fsz"> {{ nickname }}</div></div></div>
          <div style="height:3vw"></div>
          <div class="new_box"><div class="img"><img :src="author_img" alt=""></div><div><div>创作者</div><div class="fsz"> {{ author_name }}</div></div></div>
          <div style="height:3vw"></div>
          <div class="div"></div>
          <div class="nrw">
            <div style="height: 3vw"></div>
            <div class="nrwli"><div class="ess">链上标识</div></div><div class="nrwli">{{ hash }}</div>
            <div class="nrwli"><div class="ed">生成日期</div><div class="es one">{{ create_time  }}</div></div>
          </div>
          <div style="height: 3vw"></div>
          <div class="div"></div>
          <div class="nrw">
            <div class="nrwli"><div class="ess">流转信息</div></div>
            <div class="nrwli" v-for="(item, index) in moveList" :key="index"><div class="ed">持有人 {{ item.nickname }}</div><div class="es one">{{ item.create_time }}</div></div>
          </div>
        </div>
        <div class="div"></div>
        <!-- //发行方 -->
        <div class="issuer">
          <div class="iu_top"><div class="iu_name">{{ brand_name }}</div><div class="iu_more" @click="toBarnd(brand_id)">更多品牌作品 <img src="~@/assets/img/go02.png" class="img" /></div></div> 
          <div class="iu_bot" v-html="brand_info"></div>
          <div style="height: 2vw"></div>
        </div>
      </div>
    </div>
    <div style="height: 20vw"></div>
    <div class="bsn"><img src="~@/assets/img/bsn.png" /></div>
    <div style="height: 18vw"></div>
    <div class="foot_btn">
      <van-button :icon="require('@/assets/img/c02.png')" type="primary" @click="toSend()">转赠</van-button>
      <van-button :icon="require('@/assets/img/c02.png')" type="primary" @click="toSell">转售</van-button>
      <van-button :icon="require('@/assets/img/c03.png')" type="primary" @click="toUse">使用</van-button>
      <van-button :icon="require('@/assets/img/c04.png')" type="primary" @click="toCert">证书</van-button>
    </div>
    <van-popup v-model:show="show_big" position="center" closeable close-icon="close"><vue-qr :text="spot_url" :size="320"></vue-qr></van-popup>
    <van-popup v-model:show="show_use" position="center" closeable close-icon="close" class="show">
      <div class="spot_box" ref="downImg">
        <div class="spot_top"><img :src="getCollectImage()" alt="" class="img_spot" crossOrigin="anonymous"></div>
        <div class="spot_mid">
          <div style="height: 4vw;"></div>
          <div class="sm_name">{{ goods_name }} # {{ goods_num }}</div>
          <div style="height: 1.5vw;"></div>
          <div class="sm_text">
            <div class="smt_lt"><vue-qr :text="spot_url" :size="220"></vue-qr></div>
            <div class="smt_rt"><div class="scq_name">所属景区：{{ spot_name }}</div><div class="scan">扫描二维码 <br /> 查看藏品详情</div></div>
          </div>
        </div>
      </div>
      <div class="spot_btn" @click="toDrawPic">点我生成图片后长按保存</div>
    </van-popup> 
    <van-popup v-model:show="show_down" position="center" closeable close-icon="close" lock-scroll class="show_down" @touchstart="touchStart()" @touchend="touchEnd()">
      <div class="show_down1"><img v-show="img_url" id="downimg" :src="img_url" class="pic_down" crossOrigin="anonymous" /></div>
    </van-popup>
    <van-popup v-model:show="show_send" position="center" closeable close-icon="close" style="width: 90%; height: 100vw;" v-if="remain == 0">
      <div class="send_box">
        <div style="height: 7vw"></div>
        <div class="send_tit">转赠好友</div>
        <div class="send_txt">转赠成功后你的藏品将移交给对应账号</div>
        <div class="send_img"><img :src="goods_image" class="img_send" /></div>
        <div class="sent_inp"><div style="height: 2vw"></div><div class="sb_lab">用户手机号</div><van-field v-model="use_phone" placeholder="请输入用户手机号码" /></div>
        <div style="height: 1vw"></div>
        <van-button round type="success" class="send_btn" @click="sendGoods">确 定</van-button>
      </div>
    </van-popup>
    <van-popup v-model:show="show_send" position="center" closeable close-icon="close" style="width: 90%; height: 82vw;" v-if="remain > 0">
      <div class="send_box">
        <div style="height: 7vw"></div>
        <div class="send_tit">温馨提示</div>
        <div class="send_ts">本数字藏品尚未满足转赠条件</div>
        <div class="send_con">
          <div class="sec"><div class="sc_lt">▪</div><div class="sc_rt">该数字藏品在持有时长满 {{ remain }} 天后，可以转赠给平台实名用户。</div></div>
          <div class="sec"><div class="sc_lt">▪</div><div class="sc_rt">我们不支持任何形式的有偿转赠，请警惕欺诈风险，一旦发现异常行为，我们会对相关账户采取限制措施。</div></div>
        </div>
        <div style="height: 1vw"></div>
        <van-button round type="success" class="send_btn" @click="toClose">知道了</van-button>
      </div>
    </van-popup>
    <div class="popup">
      <van-popup v-model:show="show_off" closeable :close-on-click-overlay="false">
        <div ref="canvasContent" class="canvas_box">
          <div class="canvas_bg"><img src="~@/assets/img/show_bg01.png" /></div>
          <div class="imbg"><img :src="share_img" /></div>
          <div class="canvas_block">
            <div class="left"><div class="title">{{ goods_name }}</div><span>编号：#{{ goods_num }} · {{ nickname }} 持有</span></div>
            <div class="right"><vue-qr :text="text" :size="200"></vue-qr><div class="down">下载官方APP<br /><div class="qr_txt">扫描二维码 <br /> 欣赏更多藏品</div></div></div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import html2canvas from "html2canvas";
import axios from "axios";
import returnUrl from '@/utils/return_url.js';
export default {
  components: { vueQr },
  data() {
    return {
      id: '',
      goods_image: '',
      goods_name: '',
      goods_num: '',
      spot_id: 0,
      spot_name: '',
      hash: '',
      create_time: '',
      head_img: '',
      nickname: '',
      author_name: '',
      author_img: '',
      brand_id: '',
      brand_name: '',
      brand_info: '',
      moveList: [],
      spot_url: '', // 景区二维码
      share_img: '',
      show_big: false,
      show_use: false,
      show_down: false,
      show_off: false,
      show_reg: false,
      show_send: false,
      img_url: '',
      text: '', // 邀请二维码
      use_phone: '',
      remain: 0,
      merId: '',
      daopai: 0
    }
  },
  created() {
    this.token = localStorage.getItem("token");
    this.merId = this.$cookies.get("merchantId");
    this.id = this.$route.query.id;
    this.getCollectInfo();
    this.getMemberInfo();
    window.scrollTo(0, 0);
  },
  methods: {
    //藏品详情
    getCollectInfo() {
      this.$get({
        url: "/app/member_space/myDetails",
        data: { space_id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.goods_image = res.data.good.product_image;
            this.goods_name = res.data.good.product_name;
            this.goods_num = res.data.goods_number;       
            this.author_name = res.data.good.author_name;
            this.author_img = res.data.good.author_image;
            this.hash = res.data.hash;
            this.create_time = res.data.create_time;
            this.moveList = res.data.logs;
            this.brand_id = res.data.good.mer_id;
            this.brand_name = res.data.good.mer_name;
            this.brand_info = res.data.good.meta_info;
            this.share_img = res.data.good.share_img;
            this.spot_url = returnUrl.baseUrl + "#/scenic_spot?hash=" + this.hash; // 景区二维码地址
            if (res.data.good.spot_id > 0) {
              this.spot_id = res.data.good.spot_id;
            }
            if (res.data.good.spot_name) {
              this.spot_name = res.data.good.spot_name;
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    //个人信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.text = returnUrl.baseUrl + "#/register?code=" + res.data.invite_code; // 邀请二维码
            this.head_img = res.data.head_img;
            this.nickname = res.data.nickname;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 放大二维码
    toShowBig() {
      this.show_big = true;
    },
    // 更多品牌
    toBarnd(id) {
      this.$router.push({ path: "brand", query: { id: id } })
    },
    // 炫耀
    toShow() {
      this.show_off = true;
    },
    // 转赠弹窗
    toSend() {
      axios({
        url: returnUrl.baseUrl + "app/member_space/is_product_transfer",
        method: "post",
        headers: { 'Content-Type': 'application/json', },
        data: { id: this.id, token: this.token },
        timeout: 6000
      }).then(res => {
        if (res.data.code == -1) {
          this.remain = res.data.data.transfer_hold_days;
          this.show_send = true;
        } else if (res.data.code == 0) {
          this.$toast("本数字藏品不可转赠!");
        } else if (res.data.code == 1) {
          this.remain = 0;
          this.show_send = true;
        } else { 
          this.$toast(res.data.msg);
        }
      })
    },
    // 关闭弹窗
    toClose() {
      this.show_send = false;
    },
    // 转赠
    sendGoods() {
      this.$get({
        url: "/app/member_space/transfer",
        data: { id: this.id, phone: this.use_phone },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("转赠成功!");
            this.$router.push({ path: "/house" });
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    //转售
    toSell() {
      if (this.merId && this.merId !== 'null') {
        this.$toast("转售暂未开放！");
      } else {
        this.$router.push({ path: "/transfer", query: { id: this.id } })
      }
    },
    // 使用
    toUse() {
      if (this.spot_id > 0) {
        this.show_use = true;
        let ua = navigator.userAgent.toLowerCase();
        let isIOS = ua.match(/(iPhone|iPod|iPad);?/i); // ios终端
        if (isIOS) {
          setTimeout(() => {
            let imgWidth = window.document.querySelector('.spot_top').offsetWidth; // 获取 DOM宽度
            let imgHeight = window.document.querySelector('.spot_top').offsetHeight; // 获取 DOM高度
            if (imgHeight < imgWidth) {
              this.show_use = false;
              setTimeout(() => { this.show_use = true }, 200);
            } 
          }, 200);
        } 
      } else {
        this.show_use = false;
      } 
    },
    // 图片跨域处理
    getCollectImage() {
      if (this.goods_image) {
        return this.goods_image + '&t=' + new Date().getTime();
      }
    },
    // 生成分享海报
    toDrawPic(){
      this.show_use = false;
      let that = this;
      // 使用$nextTick，解决数据还没有渲染到html就先转为图片，此时的图片会是空内容的问题
      that.$nextTick(() => { 
        window.scroll(0, 0); // 回顶部
        let shareContent = window.document.querySelector('.spot_box'); // 需要截图的包裹的（原生的）DOM 对象
        let width = shareContent.offsetWidth; // 获取dom 宽度
        let height = shareContent.offsetHeight; // 获取dom 高度
        let canvas = document.createElement('canvas'); // 创建一个canvas节点
        let scale = window.devicePixelRatio; // 获取设备像素比
        canvas.width = width * scale;
        canvas.height = height * scale;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        let context = canvas.getContext('2d');
        context.scale(scale, scale);
        context.translate(0, 0);
        let opts = {
          dpi: window.devicePixelRatio * 2, // 对应屏幕的dpi，适配高清屏，解决canvas模糊问题
          backgroundColor: null, // canvas的背景颜色,如果没有设定默认透明
          scale: scale,
          canvas: canvas,
          logging: true,
          width: width, // dom 原始宽度
          height: height,
          useCORS: true, // 是否在恢复代理之前,尝试加载跨域图片（用来解决图片跨域加载问题）
          scrollX: 0,
          scrollY: -document.documentElement.scrollTop,
          imageTimeout: 15000
        }
        html2canvas(shareContent, opts).then(canvas => {
          let context = canvas.getContext('2d');
          // 【重要】关闭抗锯齿
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let url = canvas.toDataURL('image/png'); // base64数据
          that.img_url = url;
        });
      })
      this.show_down = true;
    }, 
    //手指触摸
    touchStart() {
      clearTimeout(this.times); // 再次清空定时器，防止重复注册定时器
      this.times = setTimeout(() => { this.saveImg(this.img_url, this.goods_name) }, 1000);
    },
    //手指离开
    touchEnd() {
      clearTimeout(this.times);
      this.show_down = false;
    },
    // 图片保存（长按保存）
    saveImg(imgsrc, name) {
      this.$nextTick(() => {
        let image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); // 解决跨域 Canvas 污染问题
        image.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          let context = canvas.getContext("2d"); //得到画布的上下文
          context.drawImage(image, 0, 0, image.width, image.height);
          let url = canvas.toDataURL("image/png"); // 得到图片的 base64 编码数据
          let a = document.createElement("a"); // 生成一个a元素
          let event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "景区名片"; // 设置图片名称
          a.href = url; // 将生成的 URL设置为 a.href 属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgsrc;
      })
    },
    //证书
    toCert() {
      this.$router.push({ path: "/certifi", query: { id: this.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {--van-nav-bar-icon-color:#fff; --van-nav-bar-title-text-color: #fff;}
.content {width: 100%; height: auto; margin: 0 auto; background: #F7F7F9;}
.bg {background: #000;}
.head {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 1vw; .img_top {width: 100%; height: auto; object-fit: cover;}
  .qr {width: 92%; height: 20vw; line-height: 20vw; margin: 0 4%; text-align: right; margin-top: -24vw; margin-bottom: 6vw; .qr_img {width: 20vw; height: 100%; border-radius: 3vw;}}
  .head_name {width: 96%; height: 5vw; line-height: 5vw; margin: 2vw 2%; color: #222222; font-size: 4.2vw; font-weight: 600;}
}
.tsftop1 {position: relative; z-index: 1;}
:deep(.van-loading) {display: flex; justify-content: center; width: 82vw; height: 50vw; align-items: center;}
.div {width: 100%; height: 3vw; background: #F7F7F9;}
//面板
.panel_box {
  width: 100%; margin: 0 auto; background: #fff; padding-top: 5vw;
  .panel_list {
    .new_box {
      width: 100%; height: 17vw; padding: 0 3vw; display: flex; align-items: center; background: #fff; color: #000; font-size: 4.2vw; font-weight: 600;
      .fsz {line-height: 1.5; color: rgb(138, 138, 138); font-size: 4vw; font-weight: normal;}
      .img {width: 15vw; height: 15vw; margin-right: 3vw; img {width: 100%; height: 100%; border-radius: 50%;} }
    }
    .nrw {width: 100%; min-height: 18vw; padding: 0 3vw; align-items: center; background: #fff; 
      .nrwli { color: #000;
        display: flex; width: 100%; min-height: 9vw; align-items: center; justify-content: space-between; 
        .ess {color: #000; font-size: 4.2vw;} .ed {width: 30vw; color: #999;} .es {word-break: break-all; width: 50vw; text-align: right;}
      }
    }
  }
  .issuer {
      width: 100%; height: auto; margin: 2vw auto; background: #fff;
      .iu_top {
        width: 96%; height: 8vw; margin: 0 2%; color: #000; display: flex; flex-wrap: nowrap;
        .iu_name {flex: 1; line-height: 8vw; text-align: left; font-size: 4.2vw; font-weight: 600;} 
        .iu_more {flex: 1; line-height: 8vw; font-size: 4vw; text-align: right; .img {width: 3.7vw; height: 3.7vw; vertical-align: middle; margin-top: -1vw;}} }
      .iu_bot {width: 96%; height: 6vw; margin: 0 2%; color: #000; font-size: 3.4vw;}
    }
  --van-cell-vertical-padding: 1vw; --van-cell-horizontal-padding: 0; --van-cell-text-color: #fff; --van-cell-value-color: #fff; --van-grid-item-content-padding: 3vw 0;
  --van-grid-item-content-background-color: rgba(0, 238, 166, 0.1);
}
.show {width: 90%; height: 90%; top: 8%; left: 5%; transform: none; overflow: visible}
.spot_box {
  width: 90vw; height: 100%; 
  .spot_top {width: 90vw; height: 100%; .img_spot {width: 100%; height: 100%; vertical-align: middle;}}
  .spot_mid {
    width: 100%; height: 48vw; margin: 0 auto; background: url("~@/assets/img/show_bg.png") no-repeat; background-size: 100% 100%;;
    .sm_name {width: 90%; height: 5vw; line-height: 5vw; margin: 0 5%; font-size: 6vw; font-weight: 500;}
    .sm_text {
      width: 92%; height: 28vw; margin: 4vw 4%; display: flex; flex-wrap: nowrap; flex-direction: row;
      .smt_lt {flex: 4; height: 28vw; line-height: 28vw; margin: 0 2%;}
      .smt_rt {flex: 6; height: 28vw; .scq_name {font-size: 4vw; font-weight: 500; margin-top: 1vw;} .scan {line-height: 1.5; font-size: 3.2vw; font-weight: 500; margin-top: 7vw;}}
    }
  }
}
.spot_btn {width: 50%; height: 7vw; line-height: 7vw; margin-left: 50%; text-align: center; background: #6B46F3; color: #fff; font-size: 3.2vw; margin-top: -7vw;}
.show_down {width: 90vw; height: auto; margin: 0 auto; text-align: center; transform: translateX(-50%);}
.show_down1 {width: 90vw; height: auto; margin: 0 auto; text-align: center;}
.pic_down {width: 100%; height: 100%;  vertical-align: middle;}
// 转增
.send_box {width: 100%; height: auto;}
.send_tit {width: 90%; height: 7vw; line-height: 7vw; margin: 2vw 5% 0 5%; text-align: center; color: #FF2222; font-size: 4.8vw; font-weight: 500;}
.send_txt {width: 96%; height: 6vw; line-height: 6vw; margin: 1vw 2%; text-align: center; color: #9A999E; font-size: 3.2vw;}
.send_img {width: 60%; height: 32vw; margin: 4vw 20%; text-align: center; .img_send {width: 32vw; height: 100%; vertical-align: middle;}}
.sent_inp {
  width: 86%; height: 15vw; margin: 4vw 7%; background: #F3F4F8; --van-field-input-text-color: #000;
  .sb_lab {width: 92%; height: 3vw; line-height: 3vw; margin: 0 4%; color: #39393C; font-size: 3.2vw;}
}
.send_btn {width: 86%; height: 12vw; line-height: 12vw; margin: 1vw 7%; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); font-size: 4vw;}
.send_ts {width: 96%; height: 7vw; line-height: 7vw; margin: 2vw 2%; text-align: center; color: #333; font-size: 4vw;}
.send_con {
  width: 86%; height: auto; margin: 2vw 7%;
  .sec {
    width: 90%; height: auto; margin: 0 5%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .sc_lt{width: 4%; line-height: 2; color: #000;} .sc_rt{width: 96%; height: auto; line-height: 1.5; margin: 2vw 2%; color: #9A999E; font-size: 3.2vw;}}
}
// 底部图标
.bsn {width: 92%; height: 14vw; margin: 4vw 4%; text-align: center; img {width: 50vw; height: 14vw; vertical-align: middle;}}
.foot_btn {
  width: 100%; padding: 3vw 5vw; position: fixed; z-index: 99; display: flex; justify-content: space-around; bottom: 0; --van-button-round-border-radius: 0;
  --van-button-primary-background-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); clip-path: polygon(85% 0%, 124% 100%, 0 100%, 0 100%, 0 0);
  --van-submit-bar-button-height: 8vw; --van-button-danger-color: #ffffff; 
  :deep(.van-button--primary):nth-child(1) {clip-path: polygon(85% 0%, 124% 100%, 0 100%, 0 100%, 0 0);}
  :deep(.van-button--primary):nth-child(2) {clip-path: polygon(85% 0%, 124% 100%, 0 100%, 0 100%, 0 0);}
  :deep(.van-button--primary):nth-child(3) {clip-path: polygon(85% 0%, 124% 100%, 0 100%, 0 100%, 0 0);}
}
.popup {
  :deep(.van-popup) {background: transparent;}
  .canvas_box {
    position: relative; width: 84vw; height: 121vw;
    .canvas_bg {z-index: 1; position: absolute; width: 84vw; height: 121vw; img {width: 100%; height: 100%;} }
    .imbg {width: 75vw; height: 75vw; margin: 4vw; z-index: 1; position: absolute; img {width: 100%; height: 100%; object-fit: cover;} }
  }
  .canvas_block {
    position: absolute; margin: 0 auto; left: 0; right: 0; bottom: 23vw; z-index: 2; width: 78vw; height: 18vw; justify-content: space-between; align-items: center; padding: 2vw;
    .left {display: flex; flex-flow: column; justify-content: space-between; .title {font-size: 4vw;} }
    .right {
      flex-shrink: 0; height: 17vw; display: flex; margin-top: 3vw; 
      img {width: 17vw; height: 17vw; margin-right: 2vw;} .down {display: block; font-size: 3.2vw;} .qr_txt {display: block; line-height: 1.5; font-size: 10px;}
    }
  }
}
</style>
