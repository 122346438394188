<template>
  <div class="box">
    <van-nav-bar title="充值方式" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div style="height: 30px"></div>
    <van-radio-group v-model="radio" class="van_group">
      <!-- 微信 -->
      <van-cell-group :border="false">
        <div class="vcell">
          <van-cell title="支付宝-1" clickable @click="radio = '1'">
            <template #icon><div class="left"><img :src="img" /></div></template>
            <template #right-icon>
              <van-radio name="1">
                <template #icon><div class="icono" v-if="radio == 1"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
              </van-radio>
            </template>
          </van-cell>
        </div>
        <div class="vcell">
          <van-cell title="支付宝-2" clickable @click="radio = '2'">
            <template #icon><div class="left"><img :src="img" /></div></template>
            <template #right-icon>
              <van-radio name="2">
                <template #icon><div class="icono" v-if="radio == 2"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
              </van-radio>
            </template>
          </van-cell>
        </div>
        <div class="vcell">
          <van-cell title="连连支付" clickable @click="radio = '5'">
            <template #icon><div class="left"><img :src="img1" /></div></template>
            <template #right-icon>
              <van-radio name="5">
                <template #icon><div class="icono" v-if="radio == 5"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
              </van-radio>
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </van-radio-group>
    <div class="btn_boxs"> <van-button size="large" type="success" :disabled="disabled" @click="save(radio, img, img1)">确 定</van-button> </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: "",
      img: require("@/assets/img/op03.png"),
      img1: require("@/assets/img/op06.png")
    }
  },
  mounted() {},
  methods: {
    save(radio, img, img1) {
      localStorage.setItem("rech", JSON.stringify({ radio, img, img1 }))
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.btn_boxs {position: fixed; width: 100%; margin: 0 auto; margin-bottom: 3vw; bottom: 50px; padding: 3vw;
  .van-button--success {background: url(~@/assets/img/btn.png); background-size: 100% 100%; overflow: hidden; background-position: 0px 0px;}
  .text {color: #ff5c5c; font-size: 3vw; text-align: center;}
}
.van_group {width: 92%; margin: 0 auto;
  :deep(.van-cell) {padding: 30px 35px; background: linear-gradient(to right, #454545, #242424) !important;}
  .vcell {margin-bottom: 50px;}
  .left {width: 5.5vw; height: 5.5vw; margin-right: 20px; img {width: 100%; height: 100%;} }
  .icono {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
  .icont {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
}
</style>
