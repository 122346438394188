<template>
  <div class="box bg">
    <!-- 顶部栏 -->
    <van-nav-bar title="设 置" left-arrow @click-left="xz_back('mine')" :border="false" z-index="1000" class="while" />
    <div style="height: 4vw"></div>
    <div class="nav">
      <van-cell-group :border="false">
        <div class="cell">
          <van-cell :border="false" title="个人信息" is-link to="member_infor"><template #value><div class="value"><img :src="info.head_img" /></div></template></van-cell>
        </div>
        <div class="div"></div>
        <van-cell :border="false" title="实名认证" is-link @click="real()" />
        <div class="div"></div>
        <van-cell :border="false" title="支付密码" is-link to="payment_password" />
        <div class="div"></div>
        <van-cell :border="false" title="登录密码" is-link to="updatepwd" />
        <div class="div"></div>
        <van-cell :border="false" title="隐私协议" is-link to="hide" />
        <div class="div"></div>
        <van-cell :border="false" title="使用条款" is-link to="use" />
        <div class="div"></div>
        <van-cell :border="false" title="退出登录" is-link @click="show=true" />
      </van-cell-group>
    </div>
    <van-dialog v-model:show="show" message="确定要退出？" show-cancel-button confirmButtonColor="#333" @confirm="confirm"> </van-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info: {},
      shows: false,
      cancel: "",
      show: false
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    real() {
      if (this.info.is_real == 0) {
        this.$router.push("/real")
      } else {
        this.$toast("您已实名!")
      }
    },
    //退出
    confirm() {
      this.$get({
        url: "/app/member/logout",
        success: () => {
          this.$toast("退出成功")
          this.$router.replace("login")
          localStorage.removeItem("token")
        },
        tip: () => {}
      })
    },
    getinfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          this.info = res.data
          console.log("个人信息:", this.info)
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
body {color: #333 !important;}
.bg {width: 100%; height: auto; background: #F7F7F9;}
.while {background: #fff;}
:deep(.van-dialog__message) {color: #333 !important;}
.nav {
  width: 96%; margin: 0 auto; background: #fff; border-radius: 1vw; --van-cell-text-color: #000; padding-left: 2vw;
  .cell {
    /deep/ .van-cell {.van-cell__value {flex: none;} .van-cell__title {padding-top: 10px;} .van-icon {margin-top: 10px;}}
    .value {width: 64px; height: 64px; img {width: 100%; height: 100%; object-fit: cover; border-radius: 50%; display: block;}}
  }
  :deep(.van-icon) {display: flex !important; align-items: center !important;}
}
.div {width: 96%; height: 1px; margin: 0 2%; background: #F7F7F9;}
:deep(.van-icon__image) {width: 7vw !important; height: 7vw !important;}
:deep(.van-cell) {padding-left: 2vw !important;}
</style>
