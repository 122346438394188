<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="银行卡管理" left-arrow @click-left="xz_back" right-text="删除" @click-right="$router.push('/del_bank')" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onload">
      <div class="list">
        <div class="card" v-for="(item, index) in list" :key="index" @click="go(item,radio)">
          <van-card :desc="item.bank" :title="item.bank_name">
            <template #price>
              <div>{{ getTel(item.bank_account) }}</div>
              <div class="user" v-if="item.is_default == 1">
                <div class="usero">默认</div>
              </div>
            </template>
          </van-card>
        </div>
        <!-- <div class="cue">左滑删除银行卡</div> -->
      </div>
    </van-list>
    <div style="height: 3vw"></div>
    <div class="btn_box">
      <!-- 登录按钮 -->
      <van-button size="large" type="success" @click="$router.push('/add_bank')">添加银行卡</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      list: [],
      radio: this.$route.query.radio,
    }
  },
  created() {},
  methods: {
    go(item, radio) {
      console.log(radio, 4566)
      localStorage.setItem("optbank", JSON.stringify({ radio, item }))
      this.$router.go(-1)
    },
    getTel(bank_account) {
      return bank_account.replace(/^(\d{0})\d+(\d{4})$/, "$1 **** **** $2")
    },
    onload() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      var page = this.page++
      this.$get({
        url: "/app/member_bank/getBank",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.list {
  position: relative;
  padding: 0 30px;
  .card {
    margin-bottom: 30px;
    .user {
      width: 108px;
      height: 52px;
      background-image: url("~@/assets/img/xz.png");
      background-size: 108px 52px;
      background-repeat: no-repeat;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      padding-left: 55px;
      position: absolute;
      right: -6%;
      top: -10%;
      .usero {
        transform: rotate(30deg);
      }
    }
    :deep(.van-card) {
      background: #414141;
      padding-left: 100px;
      .van-card__title {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
      }
      .van-card__desc {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        padding-top: 10px;
      }
      .van-card__price {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
      }
      .van-ellipsis {
        overflow: visible;
      }
    }
  }
}

.btn_box {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3vw;
  bottom: 0;
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}
</style>
