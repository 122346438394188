<template>
  <van-nav-bar title="合同签名" left-arrow @click-left="xz_back" :border="false" />
  <div class="box">
    <div class="tit">合同签名</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.box {width: 100%; min-height: 100vh; margin: 0 auto;}
</style>
