<template>
  <div class="box">
    <van-nav-bar title="抽取记录" left-arrow @click-left="toBack" :border="false" z-index="1000" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad" :offset="200">
        <div class="blind_box" v-for="(item, index) in recordList" :key="index">
          <div class="bb_lt"><div class="bb_name">{{ item.member_nick_name }}</div> <div class="bb_price">{{ item.create_time }}</div></div>
          <div class="bb_rt">抽中{{ getSubString(item.product_name) }}</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      pageIndex: 0, // 当前页
      limit: 10, // 分页数
      total: 0, // 总页数
      finishedText: '没有更多了',
      recordList: [],
      timer: null
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.backTop();
  },
  methods: {
    // 返回顶部
    backTop () {
      document.documentElement.scrollTop = 0;
    },
    // 返回
    toBack() {
      this.$router.push({ path: '/blind_detail', query: { id: this.id } });
    },
    // 上拉加载
    onLoad() {
      this.loading = false;
      if(!this.timer) { 
        this.timer = setTimeout(() => {
          this.pageIndex += 1;
          this.getBlindRecordList(this.pageIndex, 10);
          this.timer = null;
        }, 1000);
      }
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.recordList = [];
      this.loading = true;
      this.refreshing = true;
      this.finished = false;
      this.finishedText = "";
      setTimeout(this.onLoad(), 1000)
    },
    // 抽取记录
    getBlindRecordList(pageIndex, limit) {
      this.$get({
        url: '/app/blind_box/blindBoxOrder',
        data: { id: this.id, page: pageIndex, limit: limit },
        success: (res) => {
          if (res.code == 1) { 
            this.loading = false;
            this.refreshing = false;
            if (res.data.data.length == 0) {
              this.finished = true; // 没有数据的话，加载完成
              this.finishedText = '没有更多数据了';
            } else {
              this.recordList = this.recordList.concat(res.data.data);
              this.total = res.data.total;
              if (this.recordList.length >= this.total) {
                this.finished = true;
                this.finishedText = '没有更多数据了';
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 截取字符串
    getSubString(item) {
      if (item.length > 6) {
        return item.substring(0, 5) + '...';
      } else {
        return item;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; margin: 0 auto;}
.blind_box {
  width: 96%; height: auto; margin: 2vw 2%; background: #F7F7F9; display: flex;
  .bb_lt {
    flex: 1; height: 16vw; margin: 0 auto; padding: 2vw;
    .bb_name {height: 5vw; line-height: 5vw; margin: 1vw auto; color: #000; font-size: 4.2vw; font-weight: 500;}
    .bb_price {height: 5vw; line-height: 5vw; margin: 1.5vw auto; color: #666; font-size: 3.6vw;}
  }
  .bb_rt {flex: 1; height: 6vw; line-height: 6vw; margin: 4vw auto; text-align: right; color: #999; padding: 2vw;}
}
</style>