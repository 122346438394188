<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar :title="title" left-arrow @click-left="xz_go('synth_list')" :border="false" z-index="1000" class="black" />
    <div class="head"><img :src="product_image" /><div class="head_name">{{ product_name }}</div></div>
    <div style="height: 3vw"></div>
    <div class="tsftop1">
      <div class="odd_box">
        <div class="odd_title">合成材料</div>
        <div style="height: 2vw"></div>
        <div class="odd_body">
          <div class="middle_odd">
            <van-grid :column-num="2" :gutter="10" :border="false">
              <van-grid-item v-for="(item, index) in list" :class="item.is_satisfy == 1 ? 'grid' : 'grids'" :key="index" :icon="item.product_image" :badge="item.num" position="top-left">
                <template #text><div class="text">{{ item.product_name }}</div></template>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
      </div>
    </div>
    <div class="div"></div>
    <div class="brand">
      <div class="iu_top"><div class="iu_name">{{ barnd }}</div><div class="iu_more" @click="toBarnd(mer_id)">更多品牌作品 <img src="~@/assets/img/go02.png" class="img" /></div></div> 
    </div>
    <div style="height: 5vw"></div>
    <div class="input_box">
      <div class="cell"><van-cell title="合成攻略" /></div><div style="height: 2vw"></div><div class="text" v-html="compound"></div>
    </div>
    <div style="height: 30vw"></div>
    <div class="btn_box"><van-button size="large" type="success" :disabled="disabled" @click="save">{{ btn_name }}</van-button></div>
    <div class="popup">
      <van-popup v-model:show="show" :close-on-click-overlay="false">
        <div class="popupo">
          <div class="buy">发布成功</div><div class="serice">《xx系列》系列1</div>
          <div class="stuff">
            <div class="stuffo"><div class="num">合成所需</div><div class="numo">x1</div></div>
            <div class="stufft"></div>
            <div class="stufff"><div class="numt">已经拥有</div><div class="numf">x1</div></div>
          </div>
          <div class="buyo"><img src="~@/assets/img/popup01.png" /></div>
          <div class="no">暂无合成材料</div>
          <div class="buys"><van-button size="large" type="success" :disabled="disabled" @click="show = false">放入合成</van-button></div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import modelGltf from "vue-3d-model/src/model-gltf.vue"
export default {
  components: { modelGltf },
  data() {
    return {
      show: false,
      id: '',
      product_name: '',
      product_image: '',
      three_d: '',
      compound: '',
      mer_id: '',
      barnd: '',
      show_loading: true,
      list: [],
      info: {},
      infos: "",
      rotateFrame: "", //存储旋转动画 方便删除
      disabled: false, //防止连点
      title: '藏品合成',
      btn_name: '合成数字藏品'
    }
  },
  created() {
    this.id = this.$route.query.id;
    let minId = this.$cookies.get("minId");
    if (minId == 1) {
      this.title = '商品合成';
      this.btn_name = '合成商品'
    }
    this.getDetailInfo();
  },
  beforeDestroy() {
    console.log("离开页面了")
    cancelAnimationFrame(this.rotateFrame)
  },
  methods: {
    // 合成藏品详情
    getDetailInfo() {
      this.$get({
        url: "/app/synthetic_product/productDetail",
        data: { id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.product_name = res.data.product_name;
            this.product_image = res.data.share_img;
            this.barnd = res.data.mer_name;
            this.mer_id = res.data.mer_id;
            this.list = res.data.detail;
            this.compound = res.data.compound;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    onLoad() {
      this.show_loading = false
      this.rotate()
    },
    rotate() {
      this.rotation.y += 0.01
      this.rotateFrame = requestAnimationFrame(this.rotate)
    },
    // 更多品牌
    toBarnd(id) {
      this.$router.push({ path: "brand", query: { id: id } })
    },
    save() {
      if (this.disabled) {
        return false
      }
      this.disabled = true
      this.$get({
        url: "/app/member_space/synthetic",
        data: { id: this.id },
        success: () => {
          this.$toast("合成成功")
          setTimeout(() => { this.$router.go(-1) }, 2000)
        },
        tip: () => { setTimeout(() => { this.disabled = false }, 1500) },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.box {--van-nav-bar-icon-color: #fff; --van-nav-bar-title-text-color: #fff;}
.black {width: 100%; height: auto; background: #000000;}
.head {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 2vw;
  img {width: 100%; max-height: 100%; object-fit: cover;}
  .head_name {width: 96%; height: 5vw; line-height: 5vw; margin: 2vw 2%; color: #222222; font-size: 4.2vw; font-weight: 600;}
}
.tsftop1 {position: relative; z-index: 99;}
.box {position: relative;}
//奇怪的盒子
.odd_box {
  width: 96%; margin:0 2%; margin-top: 5vw;
  .odd_title {width: 98%; margin: 0 1%; color: #000; font-size: 4.2vw; font-weight: 600;}
  .odd_body {
    width: 98%; margin: 0 1%;
    .middle_odd {width: 100%; margin: 0 auto; --van-grid-item-text-color: #666; --van-badge-background-color: #FFA81D; --van-badge-color: #fff; 
      --van-background-color-light: #FFA81D; --van-grid-item-icon-size: 42.6vw; 
      .grids {
        .text {width: 63%; color: #000; font-size: 3.73vw; font-weight: 500; text-align: center; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;}
        :deep(.van-grid) {width: 100% !important;}
        :deep(.van-grid-item__content) { .van-icon__image {object-fit: cover;}  .van-icon {filter: grayscale(100%);}  .van-grid-item__text {color: gray;} }
      }
      .grid {
        .text {width: 63%; color: #000; font-size: 3.73vw; font-weight: 500; text-align: center; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;}
        :deep(.van-icon__image) {object-fit: cover;}
      }
    }
  }
}
.brand {
  width: 100%; height: auto; margin: 2vw auto; background: #fff;
  .iu_top {
    width: 96%; height: 5vw; margin: 0 2%; color: #000; display: flex; flex-wrap: nowrap;
    .iu_name {flex: 1; line-height: 2.5; text-align: left; font-size: 4.2vw; font-weight: 600;} 
    .iu_more {flex: 1; line-height: 2.5; font-size: 4vw; text-align: right; .img {width: 3.7vw; height: 3.7vw; vertical-align: middle; margin-top: -1vw;}} }
}
.div {width: 100%; height: 3vw; background: #F7F7F9;}
.input_box {
  width: 96%; margin: 0 2%; align-items: center; padding: 2vw; background: #F7F7F9;
  .cell { :deep(.van-cell) {padding: 0; --van-cell-text-color: #000; .van-badge__wrapper {display: flex; align-items: center;} } }
  .text {width: 100%; margin: 0 auto; color: #000; font-size: 3vw;}
}

//确认按钮
.btn_box {position: absolute; width: 100%; margin: 0 auto; bottom: 0vw; padding: 3vw;
  .van-button--success {height: 15vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); overflow: hidden; background-position: 0px 0px; border-radius: 8px;}
  .text {text-align: center; color: #ff5c5c; font-size: 4vw;}
}
.popup {
  :deep(.van-popup) {background: transparent; border: none;}
  .popupo {
    width: 618px; height: 688px; background-image: url("~@/assets/img/s08.png"); background-size: 100% 100%; background-repeat: no-repeat; text-align: center; overflow: auto;
    .middle_odd {width: 100%; border-left: 1px solid #00ff9b; border-right: 1px solid #00ff9b; --van-grid-item-text-color: #00ff9b; --van-badge-background-color: transparent;
      --van-badge-color: #00ff9b; --van-background-color-light: #00ff9b; --van-grid-item-icon-size: 162px; --van-grid-item-content-padding: 0; :deep(.van-icon__image) {object-fit: cover;}}
    .serice {text-align: center; color: #333; font-size: 28px; font-weight: 600; padding-bottom: 30px;}
    .stuff {
      display: flex; justify-content: space-between; align-items: center; width: 90%; margin: 0 auto; background: #369868; padding: 18px 20px 26px; margin-bottom: 30px;
      .stuffo {display: flex; align-items: center; .num {color: #333; font-size: 24px; font-weight: 400;} .numo {color: #333; font-size: 24px; font-weight: 600;}}
      .stufft {width: 2px; height: 28px; border: 2px solid #ffffff;}
      .stufff {display: flex; align-items: center; .numt {color: #333; font-size: 24px; font-weight: 400;} .numf {color: #333; font-size: 24px; font-weight: 600;}}
    }
    .buy {color: #333; font-size: 32px; font-weight: 400; padding-bottom: 10px;}
    .buyo {width: 298px; height: 142px; margin: 0 auto; img {width: 100%; height: 100%; object-fit: cover;} }
    .no {padding-top: 30px; font-size: 32px; font-weight: 400; color: #000000;}
    .buys {
      padding: 20px 60px 0;
      .van-button--success {background: url(~@/assets/img/set02.png); background-size: 100% 100%; overflow: hidden; background-position: 0px 0px; color: #fff;}
    }
  }
}
.bg_img {background: url("~@/assets/img/gg_bg.png") no-repeat; background-size: 100% 100%; padding-top: 10vw;}
</style>
