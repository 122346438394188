<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="删除银行卡" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onload">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">

        <div class="list">
          <van-swipe-cell v-for="(item, index) in list" :key="index">
            <van-card currency="" :title="item.bank_name" desc="储蓄卡" class="item">
              <template #price>
                <div>{{ getTel(item.bank_account) }}</div>
                <div class="user" v-if="item.is_default == 1">
                  <div class="usero">默认</div>
                </div>
              </template>
            </van-card>
            <template #right>
              <van-button square text="删除" type="danger" @click="del(item.id)" class="delete-button" />
            </template>
          </van-swipe-cell>
          <div class="cue">左滑删除银行卡</div>
        </div>
      </van-pull-refresh>

    </van-list>
    <div style="height: 3vw"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      list: [],
      isLoading: false,
    }
  },
  created() {},
  methods: {
    del(id) {
      this.$get({
        url: "/app/member_bank/delBank",
        data: {
          id: id,
        },
        success: () => {
          this.$toast("删除成功")
          this.getinfo()
        },
        tip: () => {},
      })
    },
    getTel(bank_account) {
      return bank_account.replace(/^(\d{0})\d+(\d{4})$/, "$1 **** **** $2")
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功")
        this.isLoading = false
      }, 1000)
      this.$get({
        url: "/app/member_bank/getBank",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
    onload() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      var page = this.page++
      this.$get({
        url: "/app/member_bank/getBank",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
:deep(.van-pull-refresh) {
  min-height: 0;
}
.list {
  --van-padding-xs: 3vw;
  --van-card-thumb-border-radius: 50%;
  --van-card-thumb-size: 14vw;
  --van-card-text-color: #fff;
  --van-card-desc-color: #fff;
  --van-card-price-color: #fff;
  --van-button-default-height: 30vw;
  position: relative;
  .item {
    height: 30vw;
    width: 90vw;
    margin: 0 auto;
    padding: 3vw 5vw;
    box-sizing: border-box;
    background: #414141;

    background-size: 100% 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-bottom: 3vw;
    .user {
      width: 108px;
      height: 52px;
      background-image: url("~@/assets/img/xz.png");
      background-size: 108px 52px;
      background-repeat: no-repeat;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      padding-left: 55px;
      position: absolute;
      right: -6.5%;
      top: -12%;
      .usero {
        transform: rotate(30deg);
      }
    }
    :deep(.van-card__thumb) {
      --van-card-thumb-size: 5vw;
      --van-card-thumb-size: 5vw;
    }
    :deep(.van-card__header) {
      height: 100%;
    }
  }

  // .van-button {
  // }

  .cue {
    height: 12vw;
    line-height: 12vw;
    font-size: 3vw;
    padding: 0 5vw;
    text-align: right;
  }
}

.btn_box {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3vw;
  bottom: 0;
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}
</style>
