<template>
  <div class="box">
    <van-nav-bar title="余额明细" left-arrow @click-left="xz_go('wallet_detailb')" :border="false" z-index="1000" />
    <div class="item">
      <van-list :finished="finished" finished-text="没有更多了">
        <!-- <van-cell title="选择交易日期" :value="date" @click="show = true" />
        <van-calendar v-model:show="show" type="range" @confirm="onConfirm" /> -->
        <van-tabs v-model:active="active">
          <van-tab title="待结算">
            <div v-for="(item, index) in balanceList1" :key="index">
              <div class="banc">
                <div class="banc_lt">
                  <div class="key">{{ item.txn_type }}</div> 
                  <div class="val">
                    {{ (item.txn_time).substring(0, 4) }}-{{ (item.txn_time).substring(4, 6) }}-{{ (item.txn_time).substring(6, 8) }} 
                    {{ (item.txn_time).substring(8, 10) }}:{{ (item.txn_time).substring(10, 12) }}:{{ (item.txn_time).substring(12, 14) }}
                  </div>
                </div>
                <div class="banc_rt"><span v-if="item.flag_dc == 'DEBIT'" class="red">- {{ item.amt }}</span> <span v-if="item.flag_dc == 'CREDIT'" class="gen">+ {{ item.amt }}</span></div>
              </div>
            </div>
          </van-tab>
          <van-tab title="可提现">
            <div v-for="(item, index) in balanceList2" :key="index">
              <div class="banc">
                <div class="banc_lt">
                  <div class="key">{{ item.txn_type }}</div> 
                  <div class="val">
                    {{ (item.txn_time).substring(0, 4) }}-{{ (item.txn_time).substring(4, 6) }}-{{ (item.txn_time).substring(6, 8) }} 
                    {{ (item.txn_time).substring(8, 10) }}:{{ (item.txn_time).substring(10, 12) }}:{{ (item.txn_time).substring(12, 14) }}
                  </div>
                </div>
                <div class="banc_rt"><span v-if="item.flag_dc == 'DEBIT'" class="red">- {{ item.amt }}</span> <span v-if="item.flag_dc == 'CREDIT'" class="gen">+ {{ item.amt }}</span></div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      date: '',
      text: '',
      show: false,
      onConfirm: '',
      balanceList1: [],
      balanceList2: []
    }
  },
  created() {
    this.getBalanceList1();
    this.getBalanceList2();
  },
  filters: {
    formatTimer: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m;
    }
  },
  methods: {
    onConfirm(val) {
      this.text = val;
      this.show = false;
      console.log("val:", val);
    },
    // 待结算
    getBalanceList1() {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardTradeList",
        data: { acct_type: 'USEROWN_PSETTLE', page_no: 1, page_size: 100 },
        success: (res) => {
          if (res.code == 1) {
            this.balanceList1 = res.data.acctbal_list;
          } else {
            this.$toast(res.msg);
          }        
        }
      })
    },
    // 可提现
    getBalanceList2() {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardTradeList",
        data: { acct_type: 'USEROWN_AVAILABLE', page_no: 1, page_size: 100 },
        success: (res) => {
          if (res.code == 1) {
            this.balanceList2 = res.data.acctbal_list;
          } else {
            this.$toast(res.msg);
          }        
        }
      })
    },
    // 时间格式化
    formatDate (date) {
      var date = new Date(date);
      var timeStr = date.getFullYear() + '-';
      if(date.getMonth() < 9) {
        timeStr += '0';
      }
      timeStr += date.getMonth() + 1 + '-';
      timeStr += date.getDate() < 10 ? ('0' + date.getHours()) : date.getHours();
      timeStr += ' ';
      timeStr += date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours();
      timeStr += ':';
      timeStr += date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes();
      timeStr += ':';
      timeStr += date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds();
      return timeStr;
    },
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto;}
.item {--van-tab-active-text-color: #000; --van-cell-text-color: #000; --van-cell-label-colo: #9A999E; --van-cell-value-color: #2A7AF0}
.banc {width: 90%; height: 20vw; margin: 4vw 5%; display: flex;}
.banc_lt {flex: 6;}
.key {color: #000; font-size: 4vw; font-weight: 500;}
.val {color: #CBCBCC; font-size: 3.5vw;}
.banc_rt {flex: 6; height: 20vw; line-height: 4; text-align: right; color: #2A7AF0;}
.red {color: red;}
.gen {color: rgb(10, 189, 10);}
</style>