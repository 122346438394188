<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="提现" left-arrow @click-left="$router.go(-1)" right-text="管理银行卡" @click-right="$router.push('/banka_list')" :border="false" z-index="1000" class="nav_color" />
    <div style="height: 2vw"></div>
    <!-- //输入充值数量 -->
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="提现金额"><template #value><div>提现服务费{{ info.fee }}%</div></template></van-cell></div>
      <div style="height: 5vw"></div>
      <van-cell-group inset><van-field label="¥" v-model="number" type="number" clearable placeholder="请输入金额" /></van-cell-group>
      <div style="height: 2vw"></div>
      <div class="text"> <div>账户可提现：{{ parseFloat(info.balance).toFixed(2) }}</div> <div class="green" @click="all()">全部提现</div> </div>
    </div>
    <div style="height: 8vw"></div>
    <van-radio-group v-model="radio" class="van_group">
      <van-cell-group :border="false">
        <!-- 支付宝 -->
        <div class="vcells">
          <van-cell title="支付宝" clickable @click="radio = '2'">
            <template #icon><div class="left"><div class="img"><img :src="img" /></div></div></template>
            <template #right-icon>
              <van-radio name="2">
                <template #icon><div class="icono" v-if="radio == 2"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
              </van-radio>
            </template>
          </van-cell>
          <div v-if="radio == 2">
            <div class="setf"><van-field v-model="apiname" label="支付宝姓名" type="text" placeholder="请输入支付宝姓名" /></div>
            <div class="setf"><van-field v-model="card" label="支付宝账号" type="text" placeholder="请输入支付宝账号" /></div>
          </div>
        </div>
      </van-cell-group>
    </van-radio-group>
    <div style="height: 8vw"></div>
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="提现须知" /></div>
      <div style="height: 2vw"></div> <div class="text">{{ info.info }}</div>
    </div>
    <div style="height: 8vw"></div>
    <div class="btn_box"><van-button size="large" type="success" :disabled="disabled" @click="save">确认提现</van-button><div class="text">工作日T+1到账</div></div>
    <div class="popup">
      <van-popup v-model:show="show" closeable :close-on-click-overlay="false" close-icon-position="top-left" position="bottom" :style="{ height: '50%' }">
        <div style="height: 70px"></div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      number: "",
      show: false,
      simg: "",
      money: "",
      info: {},
      radio: "",
      card: "",
      bank: "",
      banks: "",
      apiname: "",
      img: require("@/assets/img/op03.png"),
      img1: require("@/assets/img/op06.png"),
      sbank: {},
      disabled: false
    }
  },
  created() {
    this.getBankInfo();
    this.getmoney();
    this.getinfo();
    //获取支付宝信息
    this.getapi()
    this.getdata = localStorage.getItem("optbank");
    if (this.getdata) {
      let banks = JSON.parse(this.getdata);
      var sbank = banks.item;
      this.card = sbank.bank_account;
      this.bank = sbank.bank_name;
      this.banks = sbank.bank;
      this.radio = banks.radio;
      localStorage.removeItem("optbank");
    } else {
      this.getBankInfo();
    }
  },
  methods: {
    getBankInfo() {
      this.$get({
        url: "/app/member_bank/getBank",
        data: {},
        success: (res) => {
          if (res.data.data.length > 1) {
            res.data.data.forEach((item) => {
              if (item.is_default == 1) {
                this.sbank = item;
              }
            })
          } else {
            this.address = res.data.data[0];
          }
        },
        tip: () => {}
      })
    },
    getapi() {
      this.$get({
        url: "/app/member/getRealInfo",
        data: {},
        success: (res) => {
          this.name = res.data.real_name
          this.apiname = res.data.real_name
          console.log("支付宝信息:", res)
        },
        tip: () => {}
      })
    },
    back() {
      this.$router.go(-1)
    },
    save() {
      if (!this.number) return this.$toast("请输入金额")
      if (this.radio == 2) {
        if (!this.card) return this.$toast("请输入支付宝账号")
      } else if (this.radio == 3) {
        if (!this.apiname) return this.$toast("请输入银行卡号")
        if (!this.card) return this.$toast("请输入银行卡号")
        if (!this.bank) return this.$toast("请输入开户行")
        if (!this.banks) return this.$toast("请输入银行名称")
      }
      this.disabled = true
      this.$post({
        url: "/app/withdraw/withdrawDo",
        data: { amount: this.number, name: this.apiname, account: this.card, bank: this.bank, pay_type: this.radio == "2" ? 2 : "" },
        success: () => {
          this.$toast("提现申请成功！");
          setTimeout(() => { this.$router.go(-1) }, 2000);
          this.disabled = false;
        },
        tip: () => { this.disabled = false }
      })
    },
    getmoney() {
      this.$get({
        url: "/app/withdraw/getWithdrawInfo",
        data: {},
        success: (res) => {
          this.info = res.data
          console.log("个人信息:", this.info)
        },
        tip: () => {}
      })
    },
    all() {
      this.number = this.money
    },
    getinfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => { this.money = res.data.balance },
        tip: () => {}
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.box {--van-nav-bar-text-color: #999;}
:deep(.van-cell:after) { border-bottom: none; }
.input_box {align-items: center; margin: 0 auto; padding: 2vw; width: 90vw; background: #fff; --van-cell-text-color: #000; box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);
  .cell { :deep(.van-cell) {padding: 0;  .van-badge__wrapper {display: flex; align-items: center;} } }
  .van-cell-group {width: 80vw; height: 12vw; background: #F5F5F5;
    :deep(.van-cell__title) {width: 3vw; font-size: 3vw; color: #FF2222; font-weight: 800;}
    :deep(.van-field__control) {background: linear-gradient(308deg, #FE5A65 0%, #FF907A 100%); background-clip: text; -webkit-background-clip: text; color: transparent; font-weight: 800;}
  }
  .text {width: 83vw; margin: 0 auto; color: #000; font-size: 3vw; display: flex; justify-content: space-between; padding-left: 3vw;  .green {color: #2A7AF0 ;} }
}
.van_group {width: 90%; margin: 0 auto; --van-field-input-text-color: #000;
  :deep(.van-radio) {align-items: start;}
  :deep(.van-cell) {width: 100%; padding: 30px 35px; background: #fff; --van-cell-text-color: #000; box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);}
  .vcell {margin-bottom: 50px;}
  .left {width: 5.5vw; height: 5.5vw; margin-right: 20px; img {width: 100%; height: 100%;} }
  .icono {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
  .icont {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
}
.mode {
  :deep(.van-cell) {align-items: center; margin: 0 auto; padding: 2vw; width: 90vw; height: 15vw; background: #212121;  .van-badge__wrapper {display: flex; align-items: center;} }
  .value {display: flex; justify-content: flex-end; align-items: center;  .text {line-height: 4vw;}
    .img_icon {margin-left: 5vw; width: 4vw; height: 4vw; img {width: 100%; height: 100%; display: block;} }
  }
}
.setf {margin-right: 60px; width: 100%; padding-top: 30px;}
//确认按钮
.btn_box {width: 100%; margin: 0 auto; margin-bottom: 3vw; bottom: 0; padding: 3vw;
  .van-button--success {width: 96%; height: 14.12vw; margin: 2vw 2%; background: #2A7AF0 ; border-radius: 7.2vw;}
  .text {color: #ff5c5c; font-size: 3vw; text-align: center;}
}
.popup {
  .btn_boxs {position: fixed; width: 100%; margin: 0 auto; margin-bottom: 3vw; bottom: 50px; padding: 3vw;
    .van-button--success {background: url(~@/assets/img/btn.png); background-size: 100% 100%; overflow: hidden; background-position: 0px 0px;}
    .text {color: #ff5c5c; font-size: 3vw; text-align: center;}
  }
  :deep(.van-popup) {background: #000;}
}
</style>
