<template>
  <div class="box" v-if="isOpen == 0">
    <van-search @click="toSearch" :left-icon="require('@/assets/img/search.png')" v-model="text" :disabled="true" placeholder="请输入您感兴趣的NFT" />
    <div class="good_tabs">
      <van-dropdown-menu>
        <van-dropdown-item v-model="value1" :options="brandList" @click="toBrand(value1)"></van-dropdown-item>
        <van-dropdown-item v-model="value4" :options="seriesList" @click="toSeries(value4)"/>
        <van-dropdown-item v-model="value2" :options="levelList" @click="toLevel(value2)"/> 
        <van-dropdown-item v-model="value3" :options="priceList" @click="toPrice(value3)"/>
      </van-dropdown-menu>
    </div>
    <vant-up-down @list="(val) => (list = val)" ref="child" res="res.data.data" url="/app/goods_rent/marketList" :init="true" 
    :data="{ list_rows, mer_id, series_id, level, field, sort, keywords }">
      <div class="good_box">
        <div class="good_list">
          <div class="item" v-for="(item, index) in list" :key="index" @click="toGoodList(item)">
            <div class="img"><img :src="item.good.product_image + '?x-oss-process=image/resize,w_250/quality,q_90'" /></div>
            <div class="name one">{{ item.good.product_name }}</div>
            <div class="tag_box">
              <div class="tago"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'N'">{{ item.level_desc }}</van-tag></div>
              <div class="tagt"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'R'">{{ item.level_desc }}</van-tag></div>
              <div class="tagf"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'SR'">{{ item.level_desc }}</van-tag></div>
              <div class="tags"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'SSR'">{{ item.level_desc }}</van-tag></div>
            </div>
            <div class="role">
              <div class="conrole">
                <div class="avatar"><img :src="item.head_img + '?x-oss-process=image/resize,w_50/quality,q_90'" /></div><div class="nick">{{ item.nickname }}</div>
              </div>
              <div class="price one"><span>¥{{ item.price }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </vant-up-down>
    <div style="height: 15vw"></div>
  </div>
  <div class="box bg" v-else>
    <div class="open"><div style="height: 40vw;"></div><img src="~@/assets/img/empty.png" class="img_open" /><div class="txt_open">市场暂未开放</div></div>
  </div>
</template>

<script>
import { DropdownMenu, DropdownItem } from 'vant';
export default {
  components: { DropdownMenu, DropdownItem },
  data() {
    return {
      field: 'create_time',
      sort: 'asc',
      brandList: [],
      seriesList: [ { text: '选择系列', value: 0 }],
      levelList: [ { text: '等级', value: 0 }, { text: 'N', value: 1 }, { text: 'R', value: 2 }, { text: 'SR', value: 3 }, { text: 'SSR', value: 4 } ],
      priceList: [ { text: '最新上架', value: 0 }, { text: '价格升序', value: 1 }, { text: '价格降序', value: 2 } ],
      list: [],
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      markList: [],
      list_rows: 10,
      mer_id: '',
      series_id: '',
      level: '',
      keywords: '',
      text: '',
      merId: '',
      isOpen: 0,
      show: false
    }
  },
  created() {
    this.merId = this.$cookies.get("merchantId");
    console.log("good_merId:", this.merId);
    if (this.merId && this.merId !== 'null') {
      this.isOpen = 1
    } 
    console.log("isOpen:", this.isOpen);
    if (this.$route.query.mer_id) {
      if (this.$route.query.value1 == 0) {
        this.mer_id = '',
        this.value1 = 0;
      } else {
        this.value1 = parseInt(this.$route.query.value1);
        this.mer_id = this.$route.query.mer_id;
      }
      console.log("mer_id:", this.mer_id);
    }
    if (this.$route.query.series_id) {
      if (this.$route.query.value4 == 0) {
        this.series_id = '',
        this.value4 = 0;
      } else {
        this.value4 = parseInt(this.$route.query.value4);
        this.series_id = this.$route.query.series_id;
        this.getSeriesList(this.mer_id);
      }
      console.log("value4:", this.value4);
    }
    if (this.$route.query.level) {
      this.level = this.$route.query.level;
      this.value2 = parseInt(this.$route.query.level);
    }
    if (this.$route.query.value3) {
      this.field = this.$route.query.field;
      this.sort = this.$route.query.sort;
      this.value3 = parseInt(this.$route.query.value3);
    }
    if (this.$route.query.keywords) {
      this.keywords = this.$route.query.keywords;
    }
    setTimeout(() => { this.$refs.child.list_init() }, 0)
    this.getBrandList();
  },
  methods: {
    // 品牌列表
    getBrandList() {
      this.$get({
        url: "/app/merchants/allInfo",
        data: { type: 1 },
        success: (res) => {
          if (res.code == 1) {
            res.data.map(item => {
              let obj = {};
              obj.text = item.name;
              obj.value = item.id;
              this.brandList.push(obj);
            })
            this.brandList.unshift({ text: '全部品牌', value: 0 });
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 品牌查询
    toBrand(val) {
      this.keywords = '';
      this.value1 = val;
      this.mer_id = val;
      this.value4 = 0;
      this.series_id = '';
      this.seriesList = [{ text: '选择系列', value: 0 }];
      this.getSeriesList(this.mer_id);
      setTimeout(() => { this.$refs.child.list_init() }, 100)
    },
    // 全部系列
    getSeriesList(mer_id) {
      let sentData = sentData = { mer_id: mer_id }; 
      console.log("系列查询：", sentData);
      this.$get({
        url: "/app/product_series/lists",
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            res.data.map(item => {
              let obj = {};
              obj.text = item.series_name;
              obj.value = item.id;
              this.seriesList.push(obj);
            })
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 系列查询
    toSeries(val) {
      this.value4 = val;
      this.series_id = val;
      setTimeout(() => { this.$refs.child.list_init() }, 100)
    },
    // 等级查询
    toLevel(val) {
      this.level = val;
      setTimeout(() => { this.$refs.child.list_init() }, 100)
    },
    // 价格排序
    toPrice(val) {
      if (val == 0) {
        this.field = 'create_time',
        this.sort = 'desc' 
      } else if (val == 1) {
        this.field = 'price ',
        this.sort = 'asc' 
      } else if (val == 2) {
        this.field = 'price ',
        this.sort = 'desc' 
      }
      this.val = val;
      setTimeout(() => { this.$refs.child.list_init() }, 100)
    },
    // 市场列表
    getMarklist() {
      let sentData = { mer_id: this.mer_id, series_id: this.series_id, level: this.level, field: this.field, sort: this.sort, keywords: this.keywords, page: 1, list_rows: this.list_rows }
      console.log("sentData:", sentData);
      this.$get({
        url: "/app/goods_rent/marketList",
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            this.markList = res.data.data;
            console.log("市场：", this.markList);
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 藏品列表
    toGoodList(item) {
      console.log("item:", item);
      this.$router.push({ 
        path: "/good_list", 
        query: { id: item.good.id, mer_id: item.good.mer_id, series_id: item.good.series_id, value1: this.value1, level: this.level, field: this.field, sort: this.sort, value3: this.value3, 
        value4: this.value4, keywords: this.keywords }
      })
    },
    // 搜索
    toSearch() {
      this.$router.push({ path: '/searcho', query: { text: this.text } })
    }
  }
}
</script>

<style lang="scss" scoped>
.good_tabs {--van-popup-background-color: #fff;	--van-cell-text-color: #333;}
.good_box {width: 100%; height: auto; padding: 3vw 0; background: #F5F5F5;}
.good_list {
  width: 94%; margin: 0 3%; display: flex; justify-content: space-between; flex-wrap: wrap; 
  .item {
    padding: 1vw; width: 45vw; margin-bottom: 20px; background: #fff;
    .img {width: 43vw; height: 33vw; img {width: 100%; height: 100%; object-fit: cover;}}
    .name {height: 8vw; line-height: 2; color: #000; font-size: 4vw; padding: 5px 10px;}
    .tag_box {
      display: flex; padding-left: 10px;
      .tago {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tagt {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tagf {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tags {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .van-tag--warning {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-warning-color: #064e3c; margin-right: 3vw; margin-top: 1.9vw;}
    }
    .role {
      width: 100%; position: relative; display: flex; align-items: center; justify-content: space-between; padding-top: 5px;
      .conrole {
        display: flex; align-items: center; padding-left: 10px;
        .avatar {width: 4vw; height: 4vw; border-radius: 50%; overflow: hidden; margin-right: 10px; flex-shrink: 0; img {width: 100%; height: 100%; display: block;}}
      }
      .nick {color: #9A999E; font-size: 3.2vw;}
      .price {font-size: 4vw; color: #000; font-weight: 800; padding-top: 5px;}
    }
  }
}
.bg {background: #F5F5F5;}
.open {
  width: 100%; height: 100%; margin: 0 auto; text-align: center; color: #000;
  .img_open {width: 33vw; height: 33vw; margin: 0 auto; text-align: center;} 
  .txt_open {width: 80%; height: 12vw; line-height: 12vw; margin: 4vw 10%; text-align: center; color: #B9B9B9; font-size: 4vw;}
}
</style>
