<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="银行卡管理" left-arrow @click-left="xz_go('wallet_detaila')" right-text="删除" @click-right="$router.push('/del_bank')" :border="false" z-index="1000" />
    <div style="height: 2vw"></div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onload">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="list">
          <div class="card" v-for="(item, index) in list" :key="index" @click="toEdit(item.id)">
            <van-card :desc="item.bank" :title="item.bank_name">
              <template #price><div>{{ getTel(item.bank_account) }}</div><div class="user" v-if="item.is_default == 1"><div class="usero">默认</div></div></template>
            </van-card>
          </div>
        </div>
      </van-pull-refresh>
    </van-list>
    <div style="height: 3vw"></div>
    <div class="btn_box"><van-button size="large" type="success" @click="$router.push('/add_banka')">添加银行卡</van-button></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      list: [],
      isLoading: false
    }
  },
  created() {},
  methods: {
    // 修改
    toEdit(id) {
      this.$router.push({ path: "/edit_banka", query: { id: id } })
    },
    // 截取银行卡号
    getTel(bank_account) {
      if (bank_account != '') {
        return bank_account.replace(/^(\d{0})\d+(\d{4})$/, "$1 **** **** $2")
      } else {
        return ''
      }
    },
    // 刷新
    onRefresh() {
      setTimeout(() => { this.$toast("刷新成功"); this.isLoading = false }, 1000)
      this.$get({
        url: "/app/member_bank/getBank",
        data: { page: page },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true;
          } else {
            var list = res.data.data;
            if (page == 1) {
              this.list = list;
            } else {
              for (var i in list) {
                this.list.push(list[i]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => { this.finished = true }
      })
    },
    // 加载
    onload() {
      this.$toast.loading({ message: "加载中...", forbidClick: true });
      var page = this.page++;
      this.$get({
        url: "/app/member_bank/getBank",
        data: { page: page },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true;
          } else {
            var list = res.data.data;
            if (page == 1) {
              this.list = list;
            } else {
              for (var i in list) {
                this.list.push(list[i]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => { this.finished = true }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.van-pull-refresh) { min-height: 0; }
.list {
  .card {width: 92%; margin: 0 4%; margin-bottom: 30px;
    .user {width: 108px; height: 52px; background-image: url("~@/assets/img/xz.png"); background-size: 108px 52px; background-repeat: no-repeat; font-size: 12px; font-weight: 500; color: #fff;
     text-align: center; padding-left: 50px; position: absolute; right: -5.8%; top: -9.8%;  .usero {transform: rotate(30deg);} }
    :deep(.van-card) {background: linear-gradient(308deg, #4E69FF 0%, #4490FF 100%); padding-left: 50px;
      .van-card__title {font-size: 4.27vw; font-weight: 500; color: #fff;}
      .van-card__desc {font-size: 3.2vw; font-weight: 400; color: #fff; padding-top: 10px;}
      .van-card__price {font-size: 5.3vw; font-weight: 500; color: #fff;}
      .van-ellipsis {overflow: visible;}
    }
  }
}
.btn_box {position: fixed; width: 100%; bottom: 3vw; .van-button--success {width: 90%; height: 14.1vw; margin: 0 5%; background: #2A7AF0; border-radius: 7.2vw;}}
</style>
