<template>
  <div class="box">
    <div class="top"> 
      <img :src="goods_image" class="img_pro" />
      <div class="top_tit">{{ goods_name }} # {{ goods_num }}</div>
      <div class="top_com"><div class="company">{{ company }}</div></div>
    </div>
    <div class="spot"><van-cell title="所属景区" :value="spot_name" /></div>
    <div class="mid">
      <div class="h2">链上标识</div>
      <div style="height: 2vw;"></div>
      <div class="chain">{{ hash }}</div>
      <div class="mid_ul"><div class="mu_lt">哈希值</div><div class="mu_rt">{{ getSubString(hash) }}</div></div>
      <div class="mid_ul"><div class="mu_lt">生成日期</div><div class="mu_rt">{{ create_time }}</div></div>
    </div>
    <div class="bot">
      <div class="h2">所属信息</div>
      <div style="height: 2vw;"></div>
      <div v-if="status == 0">
        <div class="bbox"><div class="bb_lt">姓名</div> <div class="bb_rt">{{ name }}</div></div>
        <div class="bbox"><div class="bb_lt">身份证号</div> <div class="bb_rt">{{ card_no }}</div></div>
        <div class="bbox"><div class="bb_lt">手机号</div> <div class="bb_rt">{{ phone }}</div></div>
      </div>
      <div class="liuz" v-else>该藏品已流转</div>
    </div>
    <div style="height: 1vw;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: '',
      hash: '',
      goods_image: '',
      goods_name: '',
      goods_num: '',
      company: '',
      spot_name: '',
      chain: '',
      hash: '',
      create_time: '',
      name: '',
      card_no: '',
      phone: '',
      status: 0
    }
  },
  created() {
    this.hash = this.$route.query.hash;
    this.getCollectInfo(this.hash);
  },
  methods: {
    // 藏品详情
    getCollectInfo(hash) {
      this.$get({
        url: "/api/market/getProductSpotInfo",
        data: { hash: hash },
        success: (res) => {
          if (res.code == 1) {
            this.goods_image = res.data.product_image;
            this.goods_name = res.data.product_name;
            this.goods_num = res.data.goods_number;
            this.company = res.data.mer_name;
            this.spot_name = res.data.spot_name;
            this.hash = res.data.hash;
            this.create_time = res.data.create_time;
            this.name = res.data.real_name;
            this.phone = res.data.phone;
            this.card_no = res.data.id_num;
            this.status = res.data.status;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 截取字符串
    getSubString(txt) {
      if (txt) {
        return ( txt.substring(0, 8) + "******" + txt.substr(txt.length - 8) );
      } else {
        return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; margin: 0 auto; background: #F7F7F9;}
.top {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 1vw;
  .top_tit {width: 92%; height: 7.5vw; line-height: 7.5vw; margin: 0 4%; color: #222; font-size: 5vw; font-weight: 600;}
  .top_com {
    width: 92%; height: 6vw; margin: 2vw 4%;
    .company {width: 20%; height: 5.6vw; line-height: 5.6vw; text-align: center; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; font-size: 3.2vw;}
  }
}
.spot {width: 100%; height: 12vw; line-height: 12vw; margin: 3vw auto; background: #fff; --van-cell-text-color: #222;	--van-cell-value-color: #222;	--van-cell-font-size: 4.2vw;}
.h2 {width: 92%; height: 6vw; line-height: 6vw; margin: 2vw 4%; color: #000; font-size: 4.2vw; font-weight: 600; padding-top: 3vw;}
.mid {
  width: 100%; height: 38vw; margin: 0 auto; background: #fff; padding-bottom: 5vw;
  .chain {width: 92%; height: 9vw; line-height: 1.5; margin: 2vw 4%; color: #000; font-size: 3.4vw;}
  .mid_ul {
    width: 92%; height: 5vw; margin: 2vw 4%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .mu_lt {flex: 1; height: 5vw; line-height: 5vw; color: #848484; font-size: 3.4vw;}
    .mu_rt {flex: 1; height: 5vw; line-height: 5vw; text-align: right; color: #000; font-size: 3.4vw;}
  }
}
.liuz {width: 92%; height: 5vw; line-height: 5vw; margin: 5vw auto; text-align: center; color: #000; font-size: 4vw; font-weight: 600;}
.bot {
  width: 100%; height: 35vw; margin: 3vw auto; background: #fff; padding-bottom: 5vw;
  .bbox {
    width: 92%; height: 5vw; margin: 2vw 4%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .bb_lt {flex: 1; height: 5vw; line-height: 5vw; color: #000; font-size: 3.4vw;}
    .bb_rt {flex: 1; height: 5vw; line-height: 5vw; text-align: right; color: #999; font-size: 3.4vw;}
  }
}
</style>