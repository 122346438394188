<template>
  <van-nav-bar title="公告详情" left-arrow :fixed="true" @click-left="xz_back" :border="false" z-index="1000" />
  <div style="height: 12vw"></div>
  <div class="box">
    <div class="top">
      <div class="top_tit">{{ info.title }}</div>
      <div class="top_dec">
        <div class="td_lt"><div>{{ info.brand }}</div>  <div>{{ info.create_time }}</div> </div> 
        <div class="td_rt">
          <van-icon name="good-job" @click="toThumbs" color="#FFA81D" v-if="info.is_thumbs" /><van-icon name="good-job-o" @click="toThumbs" color="#9A999E" v-else />
        </div>
      </div>
    </div>
    <div style="height: 5vw"></div>
    <div class="article" v-html="info.content"></div>
    <div class="comment_title" v-if="isDiscuss == 1">评论({{ info.comment_number }})</div>
    <div class="comment_list" v-if="isDiscuss == 1">
      <vant-up-down ref="child" @list="(val) => (list = val)" res="res.data.data" url="/app/homes/getArticleComment" :data="{ article_id: id }">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="role">
            <div class="avatar"><img :src="item.get_member.head_img" /></div>
            <div class="text"><div class="name">{{ item.get_member.nickname }}</div><div class="time">{{ item.create_time }}</div></div>
          </div>
          <div class="topic">{{ item.content }}</div>
        </div>
      </vant-up-down>
      <div class="send_box">
        <van-field v-model="sms" center placeholder="输入评论内容……">
          <template #button><van-button round size="mini" @click="toSend" type="primary">发送</van-button></template>
        </van-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      id: "",
      info: {},
      list: [],
      thumb: false,
      sms: "",
      islike: false,
      isDiscuss: ''
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    this.getNoticeInfo();
  },
  methods: {
    // 公告详情
    getNoticeInfo() {
      this.$get({
        url: "/app/homes/getArticleList",
        data: { id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.info = res.data;
            this.isDiscuss = res.data.is_discuss;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 点赞
    toThumbs() {
      this.info.is_thumbs = !this.info.is_thumbs
      this.$get({
        url: "/app/homes/articleThumbs",
        data: { id: this.id },
        success: (res) => {
          console.log(res)
          if (this.info.is_thumbs) {
            this.$toast("已点赞")
          } else {
            this.$toast("已取消点赞")
          }
        },
        tip: () => {},
      })
    },
    // 发送评论
    toSend() {
      this.$post({
        url: "/app/homes/articleComment",
        data: { article_id: this.id, content: this.sms },
        success: (res) => {
          console.log("藏品", res)
          this.$refs.child.list_init()
          this.sms = ""
        },
        tip: () => {},
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.top {
  width: 92%; height: 20vw; margin: 0 4%;
  .top_tit {width: 100%; height: auto; line-height: 1.2; margin: 2vw auto; color: #000; font-size: 4.2vw; font-weight: 500;}
  .top_dec {
    width: 100%; height: 8vw; line-height: 8vw; margin: 2vw auto; display: flex; flex-wrap: nowrap; flex-direction: row;
    .td_lt {flex: 1; height: 4vw; line-height: 4vw; color: #9A999E; font-size: 3.2vw;} .td_rt {flex: 1; height: 4vw; line-height: 4vw; text-align: right;}
  }
}
:deep(.van-empty) {padding-top: 0 !important; padding-bottom: 0 !important;}
:deep(.van-pull-refresh) {min-height: 80vh !important;}
//文章
.article {width: 90vw; margin: 0 auto; padding: 3vw; color: #333333; :deep(a) {color: #333;}}
.thumb_box {padding-right: 40px; color: #333; .thumb_btn {text-align: right;}}
.comment_title {padding: 5vw 2vw; color: #333;}
.comment_list {
  .item {
    width: 90vw; margin: 0 auto; margin-bottom: 3vw; color: #333;
    .role {
      position: relative; display: flex; align-items: center; margin-bottom: 3vw;
      .avatar {width: 7vw; height: 7vw; border-radius: 50%; overflow: hidden; margin-right: 3vw; img {width: 100%; height: 100%; display: block;}}
      .text {font-size: 3vw; .name {color: #bbb9b9; line-height: 1.5;} .time {color: #bbb9b9; line-height: 1.5;}}
    }
    .topic {padding-left: 10vw; font-size: 3vw; line-height: 5vw;}
  }
}
.send_box {
  position: fixed; width: 90vw; margin: 0 auto; background: #fff; padding: 1vw; border: #e3e9e8 1px solid; left: 0; right: 0; bottom: 3vw;
  --van-cell-background-color: #fff; --van-cell-vertical-padding: 0; --van-cell-horizontal-padding: 0; --van-button-small-height: 8vw;
  --van-button-primary-background-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); --van-button-small-padding: 5vw; --van-field-input-text-color: #FFFFFF;
  :deep(.van-cell) {padding-left: 2vw !important; color: #333;}
  :deep(.van-field__control) {color: #333;}
  --van-button-mini-height: 7vw; --van-button-mini-padding: 0 4vw;
}
</style>
