<template>
  <view class="box">
    
  </view>
</template>

<script>
export default {
  data() {
    return {
      token: '',
      xz_tell: '',
      xz_pass: '',
      userId: ''
    }
  },
  created() {
    this.token = this.$route.query.token;
    this.xz_tell = this.$route.query.xz_tell;
    this.xz_pass = this.$route.query.xz_pass;
    this.userId = this.$route.query.userId;
    this.getDown();
  },
  methods: {
    getDown() {
      localStorage.setItem("token",this.token)
      localStorage.setItem("xz_tell", this.xz_tell)
      localStorage.setItem("xz_pass", this.xz_pass)
      localStorage.setItem("userId", this.userId)
      console.log("localStorage:", localStorage);
      this.xz_go('wallet')
    }
  },
}
</script>

<style>

</style>