<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="添加银行卡" left-arrow @click-left="xz_go('banka_list')" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <!-- 表单 -->
    <div class="from_box">
      <van-field :border="false" clearable label="姓名" readonly v-model="name" type="text" placeholder="请输入姓名"></van-field>
      <van-field clearable :border="false" label="银行卡号" v-model="bank" type="text" placeholder="请输入银行卡号"></van-field>
      <van-field clearable :border="false" label="开户行" v-model="banks" type="text" placeholder="请输入开户行"></van-field>
      <van-field label="银行名称" :border="false" clearable v-model="bankname" type="tell" placeholder="请输入银行名称"></van-field>
      <van-field clearable :border="false" label="支付宝姓名" readonly v-model="apiname" type="text" placeholder="请输入支付宝姓名"></van-field>
      <van-field clearable :border="false" label="支付宝账号" v-model="apicard" type="text" placeholder="请输入支付宝账号"></van-field>
      <div><!--留一个div保证最后一个输入框有下划线--></div>
    </div>
    <div class="switch"><div class="switcho"><div class="switcht">是否使用</div><div><van-switch v-model="checked" /></div></div></div>
    <div style="height: 8vw"></div>
    <div class="btn_box"><van-button size="large" type="success" @click="fix">确定</van-button></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: "",
      bank: "",
      banks: "",
      bankname: "",
      apicard: "",
      apiname: "",
      checked: false,
    }
  },
  created() {
    //获取支付宝信息
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$get({
        url: "/app/member/getRealInfo",
        data: {},
        success: (res) => {
          this.name = res.data.real_name
          this.apiname = res.data.real_name
          console.log(res, "这是支付宝信息")
        },
        tip: () => {},
      })
    },
    fix() {
      if (!this.name) return this.$toast("请输入姓名")
      if (!this.bank) return this.$toast("请输入银行卡号")
      if (!this.banks) return this.$toast("请输入开户行")
      if (!this.bankname) return this.$toast("请输入银行名称")
      if (!this.apicard) return this.$toast("请输入支付宝账号")
      this.$get({
        url: "/app/member_bank/addBank",
        data: {
          member_name: this.name,
          bank: this.bankname,
          bank_account: this.bank,
          bank_name: this.banks,
          ali_name: this.apiname,
          ali_account: this.apicard,
          is_default: this.checked ? "1" : "0",
        },
        success: () => {
          this.$toast("添加成功")
          this.$router.go(-1)
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style lang="scss" scoped>
//单元格
:deep(.van-cell) {
  margin-bottom: 4vw;

  //  border: 1px solid #01fdbd;
  background: #F5F5F5;
  .van-cell__value {
    flex: none;
  }
}

.value {
  width: 34px;
  height: 34px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.password_btn {
  padding-left: 8vw;
  color: #000;
  font-size: 3vw;
  box-sizing: border-box;
}

//表单
.from_box {
  width: 100%;
  padding: 0 2vw;

  --van-field-label-color: #000;
  --van-field-input-text-color: #000;
}
.switch {
  padding: 0 16px;
  .switcho {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #F5F5F5;
    align-items: center;
    padding: 0 30px;
    color: #000;
  }
  :deep(.van-switch) {
    margin-top: 15px;
  }
}
.btn_box { width: 96%; margin: 0 auto;
  padding: 3vw;
  .van-button--success {
    background: #2A7AF0 ;
    overflow: hidden;
    border-radius: 20vw;
  }
}

:deep(.van-button--warning) {
  border: 1px solid #01fdbd !important;
}

:deep(.van-field__button) {
  display: flex;
}
.field {
  :deep(.van-cell) {
    .van-cell__value {
      flex: 1; color: #000;
    }
    .van-field__label {
      display: flex;
      align-items: center;
    }
  }
}
</style>
