<template>
  <div class="box">
    <van-nav-bar title="立即购买" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div style="height: 2vw"></div>
    <div class="time">
      <div class="timeo">
        <div class="times">付款时间倒计时：</div>
        <div class="down">
          <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒">
            <template #default="timeData">
              <span class="block">{{ timeData.hours >= 10 ? timeData.hours : "0" + timeData.hours }}</span><span class="colon">:</span>
              <span class="block">{{ timeData.minutes >= 10 ? timeData.minutes : "0" + timeData.minutes }}</span><span class="colon">:</span>
              <span class="block">{{ timeData.seconds >= 10 ? timeData.seconds : "0" + timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
      </div>
    </div>
    <div class="list"><van-cell-group><van-cell title="存储地址" :value="getSubString(info)" /><van-cell title="藏品类型" value="数字盲盒" /></van-cell-group></div>
    <div class="list" v-if="blind"><van-card :num="1" :price="blind.price" :title="getNameSubString(blind.name)" :thumb="blind.image" /></div>
    <div class="list"><div class="money"><div class="moneyo">应付金额</div><div class="moneyt">¥{{ blind.price }}</div></div></div>
    <div class="pay">
      <div class="pay_tit">支付方式</div>
      <div class="pay_con">
        <van-radio-group v-model="radio" class="van_group">
          <van-cell-group :border="false">
            <van-cell title="" clickable @click="toRadio(3)" v-if="minId == 1">
              <template #icon><div class="left"><div class="img"><img src="~@/assets/img/wallet01.png" /></div></div><div>余额支付</div></template>
              <div class="pay_h">由平台方提供服务&nbsp;</div>
              <template #right-icon>
                <van-radio name="2">
                  <template #icon>
                    <div class="icono" v-if="radio == 3"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div>
                  </template>
                </van-radio>
              </template>
            </van-cell>
            <van-cell title="" clickable @click="toRadio(1)" v-if="minId == 1">
              <template #icon><div class="left"><div class="img"><img src="~@/assets/img/op02.png" /></div></div><div>微信支付</div></template>
              <div class="pay_h">微信支付提供服务&nbsp;</div>
              <template #right-icon>
                <van-radio name="2">
                  <template #icon>
                    <div class="icono" v-if="radio == 1"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div>
                  </template>
                </van-radio>
              </template>
            </van-cell>
            <van-cell title="" clickable @click="toRadio(2)" v-if="minId == null">
              <template #icon><div class="left"><div class="img"><img src="~@/assets/img/op03.png" /></div></div><div>支付宝</div></template>
              <div class="pay_h">由支付宝提供服务&nbsp;</div>
              <template #right-icon>
                <van-radio name="2">
                  <template #icon>
                    <div class="icono" v-if="radio == 2"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div>
                  </template>
                </van-radio>
              </template>
            </van-cell>
            <van-cell title="" clickable @click="toRadio(5)" v-if="minId == null">
              <template #icon><div class="left"><div class="img"><img src="~@/assets/img/op06.png" /></div></div><div>连连钱包</div></template>
              <div class="pay_h">连连支付提供服务&nbsp;</div>
              <template #right-icon>
                <van-radio name="5">
                  <template #icon>
                    <div class="icono" v-if="radio == 5"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div>
                  </template>
                </van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div style="height: 5px"></div>
      </div>
    </div>
    <div class="set" v-if="pwd_show"><van-field v-model="password" type="password" placeholder="请输入支付密码" /></div>
    <div class="list">
      <div class="select">
        <div class="selecto">服务须知</div>
        <div class="selectt" v-if="blind">
          <van-checkbox v-model="checked" shape="square">
            <div v-html="txt"></div>
            <template #icon><div v-if="checked" class="icon"><img src="~@/assets/img/xuanz.png" /></div><div v-else class="icons"></div></template>
          </van-checkbox>
        </div>
      </div>
    </div>
    <div style="height: 20vw"></div>
    <div class="bot">
      <div class="btn_box">
        <div class="btb_lt">￥ {{ blind.price }}</div>
        <div class="btb_rt"><van-button type="success" :disabled="disabled" :loading="load" @click="toPay">立即支付</van-button></div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import returnUrl from '@/utils/return_url.js';
export default {
  data() {
    return {
      checked: true,
      getTimes: "", // 倒计时
      time: "",
      radio: '',
      show: false,
      password: "",
      load: false,
      blind: {},
      ordersn: '',
      disabled: false,
      info: "",
      txt: '',
      return_url: '',
      pwd_show: false,
      minId: '',
      boxId: ''
    }
  },
  created() {
    this.minId = this.$cookies.get("minId");
    this.ordersn = this.$route.query.ordersn;
    this.getBlindOrderInfo();
    this.getMemberInfo();
  },
  methods: {
    // 盲盒订单详情
    getBlindOrderInfo() {
      this.$get({
        url: "/app/blind_box_orders/orderInfo",
        data: { order_sn: this.ordersn },
        success: (res) => {
          if (res.code == 1) {
            this.blind = res.data.blind_box;
            let date = Date.now();
            this.time = res.data.end_time * 1000 - date;
            this.txt = res.data.digital_blind_box.replace(/\n/g,'<br>');
            this.boxId = res.data.blind_box.id;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 个人信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.info = res.data.eth_address;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 选择支付方式
    toRadio(val) {
      this.radio = val;
      if (this.radio == 3) {
        this.pwd_show = true
      } else {
        this.pwd_show = false
      }
    },
    // 去支付
    toPay() {
      if (this.radio.toString() === '') {
        return this.$toast("请选择支付方式!")
      } 
      if (this.radio == 3) {
        if (!this.password) return this.$toast("请输入支付密码");
        this.load = true;
        this.getBalancePay();
      } else if (this.radio == 1) {
        this.getWeChatPay();
      } else if (this.radio == 2) {
        this.getAlipayPay();
      } else if (this.radio == 5) {
        this.getLianlianPay();
      } 
    },
    // 余额支付
    getBalancePay() {
      let return_url = returnUrl.baseUrl + "#/blind_list?jump=page";
      this.$get({
        url: "/app/blind_box_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 3, return_url: return_url },
        success: (res) => {
          if (res.code == 1) {
            setTimeout(() => { 
              this.load = false; 
              this.$router.push({ path: "/pay_result", query: { ordersn: this.ordersn, boxId: this.boxId } }); 
            }, 1000)
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => { this.load = false },
      })
    },
    // 微信支付
    getWeChatPay() {
      let return_url = returnUrl.baseUrl + "#/blind_list?jump=page";
      this.$get({
        url: "/app/blind_box_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 1, return_url: return_url },
        success: (res) => {
          console.log("微信:", res);
          if (res.code == 1) {
            let url = res.data.gateway_url;
            wx.navigateTo({ url: url });
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 支付宝支付
    getAlipayPay() {
      let return_url = returnUrl.baseUrl + "#/blind_list?jump=page";
      this.$get({
        url: "/app/blind_box_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 2, return_url: return_url },
        success: (res) => {
          console.log("支付宝:", res);
          if (res.code == 1) {
            window.location.href = res.data.gateway_url;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 连连支付
    getLianlianPay() {
      let return_url = returnUrl.baseUrl + "#/blind_list?jump=page";
      this.$get({
        url: "/app/blind_box_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 5, return_url: return_url },
        success: (res) => {
          console.log("连连:", res);
          if (res.code == 1) {
            window.location.href = res.data.gateway_url;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 截取字符串
    getSubString(info) {
      return ( info.substring(0, 8) + "******" + info.substr(info.length - 8) )
    },
    // 截取字符串
    getNameSubString(item) {
      if (item.length > 8) {
        return item.substring(0, 8) + '...';
      } else {
        return item;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.box {position: relative; width: 100%; height: auto; background: #F7F7F9; --van-nav-bar-background-color: #FFFFFF;}
.time {
  width: 94%; height: auto; margin: 0 3%;
  .timeo {
    display: flex; justify-content: space-between; background: #FFF; padding: 22px; border-radius: 6px;
    .times {line-height: 1.8; font-size: 4vw; font-weight: 400; color: #000; padding-left: 5px;}
    .down {.block {background: #FF2222; color: #FFFFFF;  margin-right: 5px; padding: 6px 10px; border-radius: 6px;} .colon {color: #000; margin-right: 5px;}}
  }
}
.list {
   width: 94%; height: auto; margin: 3vw 3%; background: #fff; --van-cell-text-color: #000; --van-card-font-size: 4vw; --van-card-title-line-height: 1.5;	
   --van-card-price-integer-font-size: 4.2vw; --van-card-padding: 2.5vw 2vw;	
   :deep(.van-card) {background-color: #fff;} 
   :deep(.van-card__price) {font-size: 4.2vw; font-weight: 600;}
   :deep(.van-card__num) {font-size: 4vw;}
   .money {
     display: flex; justify-content: space-between; padding: 20px 20px; align-items: center; background: #fff;
     .moneyo {font-size: 4vw; font-weight: 500; color: #000;}
     .moneyt {font-size: 48px; font-weight: 500; background: #fff; color: #000; font-size: 4.2vw; font-weight: 600;}
   }
  .select {
    padding: 24px 20px;
    .selecto {font-size: 4vw; font-weight: 500; color: #000;}
    .selectt {
      padding-top: 10px; font-size: 3.5vw; :deep(.van-checkbox) {overflow: visible; align-items: flex-start;  .van-checkbox__label {line-height: 1.5;}}
      .icon {width: 28px; height: 28px; margin-top: 5px; img {width: 100%; height: 100%; object-fit: cover;}}
      .icons {width: 28px; height: 28px; border: 1px solid #fff; margin-top: 5px;}
    }
  }
}
.pay {width: 94%; height: auto; margin: 4vw 3%; background: #fff;}
.pay_tit {width: 94%; height: 10vw; line-height: 10vw; margin: 10px 3%; color: #000; font-size: 4vw; font-weight: 600;}
.pay_h {width: 100%; color: #FC9C32; font-size: 2.4vw;}
.set {width: 94%; background: #fff; color: #000; margin: 0 auto; --van-field-label-color: #000; --van-field-input-text-color:#000;	}
.van_group {
  width: 100%; background: #FFF; --van-cell-text-color: #000; margin: 0 auto; :deep(.van-cell) {padding: 30px 35px; align-items: center;}
  .left {width: 56px; height: 56px; margin-right: 1vw; img {width: 100%; height: 100%;} }
  .icono {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;}}
  .icont {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;}}
}
.bot {
   width: 100%; height: 18vw; margin: 0 auto; background: #fff; position: fixed; bottom: 0; z-index: 9999;
  .btn_box {
    width: 96%; height: 12vw; margin: 3vw 2%; display: flex; flex-wrap: nowrap; flex-direction: row; 
    .btb_lt {width: 60%; height: 12vw; line-height: 12vw; margin: 0 auto; color: #000; font-size: 5vw; font-weight: 600;}
    .btb_rt {
      width: 40%; height: 12vw; line-height: 12vw; margin: 0 auto; text-align: right; 
      .van-button--success {width: 100%; height: 12vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); border-radius: 2.2vw;}
    }
  }
}
</style>
