<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="使用条款" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div class="list"><div class="item" v-html="info"></div></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info: "",
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$get({
        url: "/app/auth/privacy",
        success: (res) => {
          if (res.code == 1) {
            this.info = res.data.agreement;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.list {padding: 5vw; color: #000;}
.item {min-height: 100vh; padding: 2vw; font-size: 3vw;}
</style>
