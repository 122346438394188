<template>
  <div class="box content">
    <!-- 顶部栏 -->
    <van-nav-bar title="转让详情" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div class="head">
      <img v-if="collect && collect.good" :src="collect.good.product_image" class="img_head" /> 
      <div class="head_name">{{ goods_name }} # {{ goods_number }}</div>
    </div>
    <div style="height: 3vw"></div>
    <div class="tsftop1">
      <div class="field">
        <van-cell-group inset>
          <van-field type="number" v-model="text" input-align="right" @input="input" placeholder="请输入转售价格"><template #label><div class="label"><span></span>价格</div></template></van-field>
        </van-cell-group>
        <div class="price">最低不少于 {{ min_price }} 元</div>
      </div>
      <div class="service">
        <div class="sero">手续费</div>
        <div class="cell" @click="isOpenServiceFee">
          <van-cell center title="是否开启积分抵扣手续费">
            <template #right-icon><van-switch @click="check" v-model="checked" active-color="#00f998" inactive-color="#dcdee0" size="24" /></template>
          </van-cell>
        </div>
        <div class="cell">
          <van-cell>
            <template #title><div>支付通道费（{{ pay_fee }}%）</div></template>
            <template #value>
              <div class="cells" v-if="checked">{{ tongdao ? (tongdao / points).toFixed(2) : "0.00" }}积分</div><div class="cells" v-if="!checked">¥{{ tongdao ? tongdao : "0.00" }}</div>
            </template>
          </van-cell>
        </div>
        <div class="cell">
          <van-cell>
            <template #title><div>平台综合服务费（{{ service_fee }}%）</div></template>
            <template #value>
              <div class="cells" v-if="checked">{{ plat ? (plat / points).toFixed(2) : "0.00" }}积分</div><div class="cells" v-if="!checked">¥{{ plat ? plat : "0.00" }}</div>
            </template>
          </van-cell>
        </div>
        <div class="cell">
          <van-cell>
            <template #title><div>创作者版权分成（{{ copyright_fee }}%）</div></template>
            <template #value>
              <div class="cells" v-if="checked">{{ auto ? (auto / points).toFixed(2) : "0.00" }}积分</div><div class="cells" v-if="!checked">¥{{ auto ? auto : "0.00" }}</div>
            </template>
          </van-cell>
        </div>
        <div class="cells">
          <van-cell title="转售所得">
            <template #value><div class="cells" v-if="checked">¥ {{ text ? text : "0.00" }}</div><div class="cells" v-if="!checked">¥{{ gets ? gets : "0.00" }}</div></template>
          </van-cell>
        </div>
      </div>
    </div>
    <div style="height: 10vw"></div>
    <div class="bot">
      <div class="btn_boxs" v-if="text != ''"><van-button size="large" type="success" :disabled="disabled" @click="toSell">转售数字藏品</van-button></div>
      <div class="btn_box" v-else><van-button size="large" type="success">转售数字藏品</van-button></div>
    </div>
    <div style="height: 10vw"></div>
    <div class="popup">
      <van-popup v-model:show="show" :close-on-click-overlay="false">
        <div class="popupo">
          <img src="~@/assets/img/success.png" class="top_img" />
          <div class="pop_box"><div class="buy">转售成功</div><div class="buys"><van-button size="large" type="success" @click="toLook">前往查看订单</van-button></div></div>
        </div>
      </van-popup>
    </div>
    <div class="popup">
      <van-popup v-model:show="show_open" closeable close-icon="close">
        <div class="popupo">
          <img src="~@/assets/img/success.png" class="top_img" />
          <div class="pop_box"><div class="buy">{{ txt }}</div><div class="buys"><van-button size="large" type="success" @click="toWallet">前往开通</van-button></div></div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import base from '@/utils/return_url.js';
export default {
  data() {
    return {
      token: '',
      text: "",
      show: false,
      goods_name: '',
      goods_number: '',
      pay_fee: '',
      service_fee: '',
      copyright_fee: '',
      tongdao: "", //支付通道费用
      plat: "", //平台费用
      auto: "", //创作者
      gets: "", //最终获得
      collect: {},
      anys: [],
      id: "",
      checked: false,
      points: "",
      disabled: false,
      show_loading: true,
      min_price: '',
      show_open: false,
      txt: ''
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.token = localStorage.getItem("token");
    console.log("this.token:", this.token);
    this.getResellInfo();
    this.getFee();
    this.getPoints();
  },
  methods: {
    // 藏品详情
    getResellInfo() {
      this.$get({
        url: "/app/member_space/myDetails",
        data: { space_id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.collect = res.data;
            this.goods_name = res.data.product_name;
            this.goods_number = res.data.goods_number;
            this.anys = res.data.logs
            this.min_price =  parseFloat(res.data.good.min_resale_price);
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 是否开启积分抵扣手续费
    isOpenServiceFee() {
      this.checked = false;
      this.$toast("暂未开放");
    },
    check() {},
    onLoadz() {
      this.show_loading = false
      this.rotate()
    },
    rotate() {
      this.rotation.y += 0.01
      this.rotateFrame = requestAnimationFrame(this.rotate)
    },
    // 手续费比例
    input() {
      //支付通道百分比
      this.tongdao = ((this.text * this.pay_fee) / 100).toFixed(2)
      this.plat = ((this.text * this.service_fee) / 100).toFixed(2)
      this.auto = ((this.text * this.copyright_fee) / 100).toFixed(2)
      this.gets = (this.text - this.plat - this.auto - this.tongdao).toFixed(2)
    },
    // 积分比例
    getPoints() {
      this.$get({
        url: "/app/member/getDeduction",
        data: {},
        success: (res) => {
          this.points = res.data;
        },
        tip: () => {},
      })
    },
    // 手续费
    getFee() {
      this.$get({
        url: "/app/member_space/getFee",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.pay_fee = res.data.pay_fee;
            this.service_fee = res.data.service_fee;
            this.copyright_fee = res.data.copyright_fee;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 转售
    toSell() {
      console.log("最低价:", this.min_price, "输入价：", this.text);
      if (!this.text) return this.$toast("请输入价格")
      if (parseFloat(this.text) < parseFloat(this.min_price)) { return this.$toast("转售价格不能低于" + this.min_price + "最低转让价格") }
      axios({
        url: base.baseUrl + "app/member_space/rent",
        method: "post",
        headers: { 'Content-Type': 'application/json', },
        data: { id: this.id, price: this.text, is_deduction: this.checked ? 1 : 0, token: this.token },
        timeout: 6000
      }).then(res => {
        console.log("res:", res)
        if (res.data.code == 1) {
          this.show = true;
        } else if (res.data.code == -2) {
          this.txt = res.data.msg;
          this.show_open = true;
        } else {
          this.$toast(res.data.msg);
        }
      })
    },
    // 查看订单
    toLook() {
      this.$router.push({ path: "/resale" });
    },
    // 开通钱包
    toWallet() {
      this.$router.push({ path: "/wallet" });
    }
  },
}
</script>

<style lang="scss" scoped>
.content {width: 100%; background: #F7F7F9;}
:deep(.van-loading) {display: flex; justify-content: center; width: 82vw; height: 50vw; align-items: center;}
.head {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 1vw;
  .img_head {width: 100%; max-height: 100%; }
  .head_name {width: 96%; height: 5vw; line-height: 5vw; margin: 2vw 2%; color: #222222; font-size: 4.2vw; font-weight: 600; }
}
.tsftop1 {position: relative; z-index: 99;}
.field {
  width: 100%; height: 20vw; margin: 0 auto; background: #fff; --van-field-input-text-color: #000;
  .label {font-size: 3.74vw; font-weight: 500; color: #000; margin-left: -3.6vw; span {font-size: 3.6vw; color: #000;}}
  .price {width: 86%; height: auto; line-height: 1; margin: 0 7%; text-align: right; font-size: 2.6vw; font-weight: 500; color: #FF2222;}
}
.service {
  width: 100%; margin: 4vw auto; background: #fff; margin-bottom: 30px;
  .sero {font-size: 28px; font-weight: 500; color: #000; padding-top: 20px; padding-left: 30px;}
  .cell {
    .cells {display: inline-block; font-size: 32px; font-weight: 500; color: #FF2222;} --van-cell-text-color: #000;
    :deep(.van-cell) {line-height: 1; .van-cell__title {font-size: 24px !important; font-weight: 500; color: #000;}
    }
  }
  .cells {
    .cells {display: inline-block; width: 100%; margin: 0 auto; font-size: 32px; font-weight: 500; color: #FF2222; padding-right: 4.2vw;}
    :deep(.van-cell) {
      margin-bottom: 20px; padding-right: 0px !important; padding-top: 26px; padding-bottom: 26px;
      .van-cell__title {font-size: 28px; font-weight: 500; color: #000;}
    }
  }
}
.bot {
   width: 100%; height: 18vw; margin: 0 auto; background: #fff; position: fixed; bottom: 0; z-index: 9999;
  .btn_box {
    width: 96%; height: 14vw; margin: 2vw 2%; 
    .van-button--success {width: 100%; height: 13vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); border-radius: 8px;} 
  }
}
.popup {
  :deep(.van-popup) {background: transparent; border: none;}
  .popupo {width: 618px; height: 658px; text-align: center;}
  .top_img {width: 45.6vw; height: 30vw; margin-bottom: -10vw;}
  .pop_box {
    width: 100%; height: 42vw; margin: 0 auto; background: #fff; padding-top: 10vw;
    .buy {width: 92%; height: 5vw; line-height: 2; margin: 4vw 4%; text-align: center; color: #000; font-size: 4.2vw; font-weight: 600;}
    .van-button--success {width: 60%; height: 10vw; margin: 6vw 20%; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; border-radius: 1vw;}
  }
}
.btn_boxs {
  position: absolute; width: 100%; margin: 0 auto; margin-bottom: 0vw; bottom: 0; padding: 3vw;
  .van-button--success {height: 15vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); overflow: hidden;}
}
.open {width: 90%; height: auto; margin: 4vw 5%; text-align: center; color: #000;}
</style>
