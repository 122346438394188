<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="我的钱包" left-arrow @click-left="toBack" :border="false" z-index="1000" />
    <div class="item">
      <div class="top"><div class="top_img"><img :src="avatar" class="avatar"/></div><div class="top_con"><div class="top_name">{{ name }}</div> <div class="phone">{{ phone }}</div></div></div>
      <div class="tit">账户余额</div>
      <div class="price">¥ {{ parseFloat(balance).toFixed(2) }}</div>
      <div class="balance">
        <div class="bac_lt">可提现：<span>￥ {{ parseFloat(settle_money).toFixed(2) }}</span></div> <div class="bac_rt">待结算：<span>￥ {{ parseFloat(drawal_money).toFixed(2) }}</span></div>
      </div>
      <div class="check">
        <div class="ck_rec"><van-button square type="primary" @click="xz_go('recharge_b')">充 值</van-button></div> 
        <div class="ck_rew"><van-button square type="primary" @click="xz_go('cash_b')">提 现</van-button></div>
      </div>
    </div>
    <!-- <div class="notice"><img src="~@/assets/img/notice.png" class="img_notice" /><span>尊敬的用户，提现到账时间为2个自然日后到账</span></div> -->
    <div style="height: 15vw"></div>
    <div class="list">
      <div class="bank">
        <div class="bank_li" @click="$router.push('/bankb_list')">
          <img src="~@/assets/img/wa_list01.png" class="img_bank" /><span class="toc">我的银行卡</span><span class="go"><img src="~@/assets/img/go.png" class="img_go" /></span>
        </div>
        <div class="bank_li" style="border-bottom: none;" @click="xz_go('balance')">
          <img src="~@/assets/img/wa_list02.png" class="img_bank" /><span class="toc">余额明细</span><span class="go"><img src="~@/assets/img/go.png" class="img_go" /></span>
        </div>
      </div>
      <div style="height: 1vw"></div>
    </div>
    <div style="height: 3vw"></div>
    <div class="list">
      <div class="bank">
        <div class="bank_li" @click="toPwdUpdate">
          <img src="~@/assets/img/icon05.png" style="width: 5.5vw; height: 6vw;" /><span class="toc">修改密码</span><span class="go"><img src="~@/assets/img/go.png" class="img_go" /></span>
        </div>
        <div class="bank_li" @click="toForgetPwd">
          <img src="~@/assets/img/icon05.png" style="width: 5.5vw; height: 6vw;" /><span class="toc">忘记密码</span><span class="go"><img src="~@/assets/img/go.png" class="img_go" /></span>
        </div>
        <div class="bank_li" style="border-bottom: none;" @click="toCancel" v-if="isCancel == 1">
          <img src="~@/assets/img/icon06.png" style="width: 5.5vw; height: 6vw;" /><span class="toc">账户注销</span><span class="go"><img src="~@/assets/img/go.png" class="img_go" /></span>
        </div>
      </div>
      <div style="height: 1vw"></div>
    </div>
    <div class="lianlian">由连连支付提供服务</div>
    <div style="height: 9vw"></div>
    <van-dialog v-model:show="show" show-cancel-button @confirm="toBank">
      <div class="pop"><div class="pop_tit">银行卡</div><van-field type="text" v-model="cardNo" clearable placeholder="请输入银行卡号" class="input" /></div>
    </van-dialog>
    <van-dialog v-model:show="code_show" show-cancel-button @confirm="toSet">
      <div class="pop"><div class="pop_tit">验证码</div><van-field type="text" v-model="verify_code" clearable placeholder="请输入验证码" class="input" /></div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: '',
      avatar: '',
      name: '',
      phone: '',
      balance: "",
      settle_money: '', // 待结算余额
      drawal_money: '', // 可提现余额
      bank_token: "",
      oldPwd: '',
      newPwd: '',
      show: false,
      cardNo: '',
      set_token: '',
      password: '',
      code_show: false,
      verify_code: '',
      pwd_token: '',
      randomKey: '',
      cancel_token: '',
      cancel_pwd: '',
      cancel_key: '',
      isCancel: 0
    }
  },
  mounted() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://oss-mpay-static.lianlianpay.com/lib/accp-password/v2.0.0.js';
    document.body.appendChild(s);
  },
  created() {
    this.getMemberInfo();
    this.getBalanceInfo();
  },
  methods: {
    // 返回上一页
    toBack() {
      this.xz_go('mine');
    },
    // 个人信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        success: (res) => {
          if (res.code == 1) {
            this.avatar = res.data.head_img;
            this.userId = res.data.id;
            this.name = res.data.real_name;
            this.phone = res.data.phone;
            this.isCancel = res.data.lian_lian_user_destroy;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 获取用户余额
    getBalanceInfo() {
      this.$post({
        url: "/app/wallet_lian_lian/userInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            if (res.data.acctinfo_list[0].acct_type == 'USEROWN_AVAILABLE') {
              this.settle_money = res.data.acctinfo_list[0].amt_balaval;
            } else if (res.data.acctinfo_list[0].acct_type == 'USEROWN_PSETTLE') {
              this.drawal_money = res.data.acctinfo_list[0].amt_balaval;
            }
            if (res.data.acctinfo_list[1].acct_type == 'USEROWN_AVAILABLE') {
              this.settle_money = res.data.acctinfo_list[1].amt_balaval;
            } else if (res.data.acctinfo_list[1].acct_type == 'USEROWN_PSETTLE') {
              this.drawal_money = res.data.acctinfo_list[1].amt_balaval;
            }
            this.balance = parseFloat(this.settle_money) + parseFloat(this.drawal_money);
            console.log("balance:", this.balance, "settle_money:", this.settle_money, "，drawal_money:", this.drawal_money);
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 修改密码弹窗
    toPwdUpdate() {
      this.$post({
        url: "/app/wallet_lian_lian/getPasswordElement",
        data: { scene: "change_password" },
        success: (res) => {
          if (res.code == 1) {
            this.bank_token = res.data;
            this.getLianLianInfo();
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 连连控件（修改密码）
    getLianLianInfo() {
      lianlianpay.invokePasswordControl({
        passwordScene: 'change_password', // 修改密码
        oidPartner: '2022090107638862', // 商户号
        userId: this.userId, // 用户号
        passwordElementToken: this.bank_token, // 向服务端请求生成的 token
        env: 'production', // 默认 production-生产
        styles: { themeColor: '#0E59F0' } // 主题颜色（按钮等）
      }, (res) => {
        console.log("修改密码：", res);
        if (res.code == '0000') {
          this.oldPwd = res.data.oldPassword;
          this.newPwd = res.data.password;
          this.randomKey = res.data.randomKey;
          // this.getPwdUpdate();
        } else {
          this.$toast(res.msg);
        }
      })  
    },
    // 修改密码
    getPwdUpdate() {
      this.$post({
        url: '/app/wallet_lian_lian/bankCardChangePassword',
        data: { pwd_old: this.oldPwd, pwd_new: this.newPwd, random_key: this.randomKey },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("密码修改成功！");
            this.$router.push({ path: '/wallet_detailb' })
          } else {
            this.$toast(res.msg);
          }
          this.$toast(res.msg);
        },
        tip: () => {}
      })
    },
    // 忘记密码
    toForgetPwd() {
      this.show = true;
    },
    // 提交
    toBank() {
      this.$post({
        url: "app/wallet_lian_lian/bankCardSetPasswordApply",
        data: { acct_no: this.cardNo },
        success: (res) => {
          if (res.code == 1) {
            console.log("申请：", res.data);
            this.set_token = res.data.token;
            this.getToken();
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 获取token
    getToken() {
      this.$post({
        url: "/app/wallet_lian_lian/getPasswordElement",
        data: { scene: "setting_password" }, 
        success: (res) => {
          if (res.code == 1) {
            this.pwd_token = res.data;
            this.getLianLianList();
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 连连控件
    getLianLianList() {
      lianlianpay.invokePasswordControl({
        passwordScene: 'setting_password', // 设置密码
        oidPartner: '2022090107638862', // 商户号
        userId: this.userId, // 用户号
        passwordElementToken: this.pwd_token, // 向服务端请求生成的 token
        env: 'production', // 默认 production-生产
        styles: { themeColor: '#0E59F0' } // 主题颜色（按钮等）
      }, (res) => {
        if (res.code == '0000') {
          console.log("设置密码：", res);
          this.password = res.data.password;
          this.randomKey = res.data.randomKey;
          this.code_show = true;
        } else {
          this.$toast(res.msg);
        }
      })  
    },
    // 提交验证码
    toSet() {
      this.verify_code = this.verify_code;
      this.getPwdForget();
      this.code_show = false;
    },
    // 忘记密码
    getPwdForget() {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardBindSetPasswordVerify",
        data: { verify_code: this.verify_code, password: this.password, ll_token: this.set_token, random_key: this.randomKey },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("密码设置成功！");
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 获取注销token
    toCancel() {
      this.$post({
        url: "/app/wallet_lian_lian/getPasswordElement",
        data: { scene: "bind_card_password"},
        success: (res) => {
          if (res.code == 1) {
            this.cancel_token = res.data;
            this.getLianLianCancel();
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 连连控件(注销)
    getLianLianCancel() {
      lianlianpay.invokePasswordControl({
        passwordScene: 'bind_card_password', // 解绑卡
        oidPartner: '2022090107638862', // 商户号
        userId: this.userId, // 用户号
        passwordElementToken: this.cancel_token, // 向服务端请求生成的 token
        env: 'production', // 默认 production-生产
        styles: { themeColor: '#0E59F0' } // 主题颜色（按钮等）
      }, (res) => {
        if (res.code == '0000') {
          console.log("注销：" , res);
          this.cancel_pwd = res.data.password;
          this.cancel_key = res.data.randomKey;
          this.CancelAccount();
        } else {
          this.$toast(res.msg);
        }
      })  
    },
    // 账户注销
    CancelAccount() {
      let sentData = { password: this.cancel_pwd, random_key: this.cancel_key };
      this.$post({
        url: '/app/wallet_lian_lian/userDestroy',
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            this.$toast("注销成功！");
            this.$router.go(0); 
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item {width: 100%; height: 68vw; margin: 0 auto; background: url("~@/assets/img/wallet_bg.png") no-repeat; background-size: 100% 100%; padding-top: 30px;}
.top {width: 92%; height: 16vw; margin: 0 4%; display: flex;}
.top_img {flex: 1.6;}
.avatar {width: 16vw; height: 16vw; vertical-align: middle; border-radius: 50%;}
.top_con {flex: 6; height: 11vw; line-height: 2.4; text-align: left;}
.top_name {width: 100%; height: 5vw; font-size: 4.26vw; font-weight: 500;}
.phone {width: 100%; height: 5vw; padding-top: 10px;}
.tit {width: 86%; height: 24px; line-height: 24px; margin: 24px 7%; font-size: 4.2vw; font-weight: 500; margin-top: 6.4vw;}
.price {width: 86%; height: 8vw; line-height: 8vw; margin: 4vw 7%; font-size: 7vw; font-weight: normal;}
.balance {width: 86%; height: 8vw; line-height: 8vw; margin: 1.5vw 7%; font-size: 3.6vw; display: flex;}
.bac_lt {flex: 6; font-size: 3.46vw;}
.bac_lt span {font-weight: 500;}
.bac_rt {flex: 6; font-size: 3.46vw;}
.bac_rt span {font-weight: 500;}
.mid {height: 2vw;}
.check {width: 92%; height: 20.8vw; margin: 3.6vw 4%; background: #fff; box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.08); border-radius: 2vw; display: flex; 
--van-button-normal-padding: 0 10vw;}
.ck_rec {flex: 6; margin-left: 10%; margin-top: 5vw; --van-button-primary-background-color: linear-gradient(96deg, #67A6FC 0%, #115FFB 100%); border-radius: 2.1vw;}
.ck_rew {flex: 6; width: 100%; margin-top: 5vw; --van-button-primary-background-color: linear-gradient(96deg, #67A6FC 0%, #115FFB 100%);}
.notice {width: 92%; height: 12.27vw; line-height: 12.27vw; margin: 4vw 4%; color: #2E7BFB; font-size: 3vw; box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.08); margin-top: 14vw;
border-radius: 1vw;}
.img_notice {width: 3.2vw; height: 4vw; vertical-align: middle; margin-right: 2vw; margin-left: 4vw; margin-top: -5px;}
.list {
  width: 92%; height: auto; margin: 0 4%; box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.08); border-radius: 1vw;
  .bank {
    width: 92%; height: auto; margin: 0 4%; 
    .bank_li {width: 100%; height: 14vw; line-height: 1; margin: 0 auto; color: #000; border-bottom: #F5F5F5 solid 1px; .img_bank {width: 5.51vw; height: 5vw;}}
  }
}
.toc {line-height: 120px; margin-left: 25px; font-size: 4vw;}
.go {width: 60px; line-height: 100px; text-align: right; float: right;}
.img_go {width: 32px; height: 24px; vertical-align: middle; text-align: right; margin-top: 20px;}
.lianlian {width: 100%; height: 5vw; line-height: 4; margin: 2vw auto; text-align: center; color: red; font-size: 3.2vw;}
.pop {width: 90%; height: 16vw; margin: 2vw 5%; --van-dialog-background-color: #848484; --van-field-input-text-color: #000;}
.pop_tit {width: 90%; height: 5vw; margin: 2vw 5%; text-align: center; color: #848484; font-size: 3.6vw;}
.input {width: 90%; height: auto; margin: 2vw 5%; border-bottom: #E3E3E3 solid 1px;}
</style>
