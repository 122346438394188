<template>
  <div class="box bg">
    <!-- 顶部栏 -->
    <van-nav-bar :title="title" left-arrow @click-left="xz_go('mine')" :border="false" right-text="合成记录" @click-right="xz_go('synth_record')" z-index="1000" class="while"/>
    <van-list v-model="loading" :finished="finished" @load="onload">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="list">
          <div class="item" @click="this.$router.push({ path: 'synth_details', query: { id: item.id, page: 1 } })" v-for="(item, index) in list" :key="index">
            <van-card :desc="item.brand" :price="item.price" :title="item.product_name" :thumb="item.product_image"></van-card>
          </div>
        </div>
      </van-pull-refresh>
    </van-list>
    <div class="empty" v-if="list && list.length==''"><van-empty description="合成里没有东西哟" /></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      isLoading: false,
      title: '藏品合成'
    }
  },
  created() {
    let minId = this.$cookies.get("minId");
    if (minId == 1) {
      this.title = '商品合成'
    }
    this.onload();
   },
  methods: {
    onRefresh() {
      var page = this.page++
      setTimeout(() => { this.$toast("刷新成功"), this.isLoading = false }, 1000)
      this.$get({
        url: "/app/synthetic_product/productList",
        data: { page: page },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => { this.finished = true }
      })
    },
    onload() {
      var page = this.page++
      this.$get({
        url: "/app/synthetic_product/productList",
        data: { page: page },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
            this.total = 0
          } else {
            var list = res.data.data
            var lpage = res.data.current_page
            if (page == 1) {
              list.forEach((val) => {
                val.page = lpage
              })
              this.list = list
            } else {
              list.forEach((val) => {
                val.page = lpage
                this.list.push(val)
              })
            }
          }
          this.loading = false
          console.log("这是orderpage", this.order)
        },
        tip: () => { this.finished = true }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(.van-pull-refresh) {min-height: calc((100vh - 176vw) / 2);}
.box {--van-nav-bar-text-color: #000;}
.bg {width: 100%; height: auto; background: #F7F7F9;}
.while {background: #fff;}
.list {width: 96%; margin: 0 2%;}
.item {width: 98%; margin: 3vw 1%; --van-card-background-color: #fff; --van-card-text-color: #000; --van-card-font-size: 4vw; --van-card-thumb-border-radius: 6px; 
--van-card-desc-color: #FC9C32;}
:deep(van-image) {img {width: 100%; height: 100%; object-fit: fill !important;} }
:deep(.van-card__thumb) {position: static;}
:deep(.van-card__tag) {right: -10vw; top: 1vw; left: auto;}
:deep(.van-card__desc) {width: 50%; background: #FFF8F0; font-size: 3.2vw; padding: 0.1vw 1vw; margin-top: 2vw;}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
