<template>
  <div class="box">
    <van-nav-bar title="立即购买" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div style="height: 20px"></div>
    <div class="time">
      <div class="timeo">
        <div class="timet"><div class="timef"></div><div class="times">付款倒计时：</div></div>
        <div class="down">
          <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒">
            <template #default="timeData">
              <span class="block">{{ timeData.hours >= 10 ? timeData.hours : "0" + timeData.hours }}</span><span class="colon">:</span>
              <span class="block">{{ timeData.minutes >= 10 ? timeData.minutes : "0" + timeData.minutes }}</span><span class="colon">:</span>
              <span class="block">{{ timeData.seconds >= 10 ? timeData.seconds : "0" + timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="listo">
        <div class="cells"><van-cell title="存储地址"><template #value><div>{{ geTel(info)}}</div></template></van-cell></div>
        <div class="line"></div>
        <div class="cell"><van-cell title="藏品类型" :value="form" /></div>
        <div class="good">
          <div class="good_tabs"><div class="title">• <span>藏品名称</span></div></div>
          <div class="card">
            <van-card :thumb="blind.image">
              <template #title><div class="title">{{ blind.name }}</div></template>
            </van-card>
          </div>
        </div>
        <div class="money"><div class="moneyo">应付金额</div><div class="moneyt">¥{{ blind.price }}</div></div>
        <div class="select">
          <div class="selecto">服务须知</div>
          <div class="selectt">
            <van-checkbox v-model="checked" shape="square">{{ blind.info }}
              <template #icon><div v-if="checked" class="icon"><img src="~@/assets/img/xuanz.png" /></div><div v-else class="icons"></div></template>
            </van-checkbox>
          </div>
        </div>
      </div>
    </div>
    <van-radio-group v-model="radio" class="van_group">
      <!-- 微信 -->
      <van-cell-group :border="false">
        <van-cell title="" clickable @click="radio = 3">
          <template #icon>
            <div class="left"><div class="img"><img src="~@/assets/img/op01.png" /></div></div>
            <div> 余额支付</div>
          </template>
          <template #right-icon>
            <van-radio name="3">
              <template #icon>
                <div class="icono" v-if="radio == 3"><img src="~@/assets/img/xuanz.png" /></div> <div class="icont" v-else><img src="~@/assets/img/op04.png" /></div>
              </template>
            </van-radio>
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="set"><van-field v-model="password" type="password" placeholder="请输入支付密码" /></div>
    <div style="height: 100px"></div>
    <div class="btn_box"><van-button size="large" type="success" :disabled="disabled" :loading="load" @click="border()">立即支付</van-button></div>
    <div class="popup">
      <van-popup v-model:show="show" :close-on-click-overlay="false">
        <div class="popupo">
          <div class="buy">购买成功</div>
          <div class="buyo"><img src="~@/assets/img/set01.png" /></div>
          <div class="buys"><van-button size="large" type="success" :disabled="disabled" @click="open">前往查看订单</van-button></div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: true,
      getTimes: "", //daojishi
      time: "",
      radio: 3,
      show: false,
      password: "",
      load: false,
      blind: {},
      ordersn: this.$route.query.ordersn,
      form: this.$route.query.form,
      id: this.$route.query.id,
      info: "",
      is_app: false,
    }
  },

  mounted() {},
  created() {
    this.getblind()
    this.getinfo()
  },
  methods: {
    //盲盒
    border() {
      if (!this.password) return this.$toast("请输入支付密码")
      this.load = true
      this.$post({
        url: "/app/blind_box_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: 3 },
        success: (res) => {
          console.log(res)
          setTimeout(() => {
            this.load = false
            this.$router.push({ path: "/optorder", query: { ordersn: this.ordersn, type: 2, radio: this.radio } })
          }, 2000)
        },
        tip: () => { this.load = false  },
      })
    },
    // 是不是app
    platformCallback(text) {
      if (text == "qttx") {
        this.is_app = true
      }
    },
    geTel(info) {
      return (
        info.substring(0, 10) + "***********" + info.substr(info.length - 10)
      )
    },
    getinfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          this.info = res.data.eth_address
          console.log(this.info, "个人信息")
        },
        tip: () => {},
      })
    },
    //微信支付宝支付
    getpay(data) {
      if (navigator.userAgent.match(/(Android)/i)) {
        if (this.radio == 1) {
          let payData = JSON.stringify(data)
          console.log("Android appwx")
          window.android.call_wx_andriod(payData)
        } else if (this.radio == 2) {
          console.log("Android appali")
          window.android.call_ali_andriod(data)
        }
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        if (this.radio == 1) {
          let payData = JSON.stringify(data)
          console.log("iPhone appwx")
          window.webkit.messageHandlers.call_wx_ios.postMessage(payData)
        } else if (this.radio == 2) {
          console.log("iPhone appali")
          window.webkit.messageHandlers.call_ali_ios.postMessage(data)
        }
      }
    },
    //支付回调
    payCallback(status) {
      if (status == "SUCCESS") {
        this.$toast({
          message: "支付成功",
          duration: 1000,
        })
      } else if (status == "FAIL") {
        this.$toast({
          message: "支付失败",
          duration: 1000,
        })
      } else if (status == "CANCEL") {
        this.$toast({
          message: "已取消支付！",
          duration: 1000,
        })
      }
    },
    //数字藏品订单详情
    getblind() {
      this.$get({
        url: "/app/blind_box_orders/orderInfo",
        data: { order_sn: this.ordersn },
        success: (res) => {
          console.log(res)
          this.blind = res.data.blind_box
          let date = Date.now()
          this.time = res.data.end_time * 1000 - date
          console.log(this.time, 89898)
        },
        tip: () => {},
      })
    },
    //支付
    open() {
      if (!this.radio) return this.$toast("请选择支付方式")
      this.$post({
        url: "/app/blind_box_orders/pay",
        data: {
          order_sn: this.ordersn,
          pay_type: this.radio,
        },
        success: (res) => {
          console.log(res)
          if (this.is_app) {
            this.aliapp()
          } else {
            this.xz_go("apli", { order_sn: this.ordersn, type: 1, radio: this.radio })
          }
        },
        tip: () => {},
      })
    },
    //app 支付
    aliapp() {
      this.$get({
        url: "/app/pay/pay",
        data: { order_sn: this.ordersn, app: 1 },
        success: (res) => {
          this.getpay(res.data)
          console.log(res)
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style scoped lang="scss">
.set {
  width: 92vw;
  background: rgb(58, 58, 58);
  margin: 0 auto;
}
.box {
  position: relative;
}
.list {
  padding: 0 30px;
  margin-bottom: 30px;
  .listo {
    //  border: 2px solid #fff;
    background: #212121;
    padding: 10px 0px 30px;
    .cells {
      /deep/ .van-cell__value {
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        flex: none;
      }
    }
    /deep/ .van-cell__value {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
    }
    .line {
      width: 618px;
      // border: 1px solid #fff;
      margin: 0 auto;
      // margin-top: 20px;
      // margin-bottom: 20px;
    }
    .good {
      // margin-top: 60px;
      margin-bottom: 20px;
      //   border: 1px solid #fff;
      .good_tabs {
        // padding: 0 50px;
        padding-left: 14px;
        margin-bottom: 15px;
        .title {
          span {
            //   border-bottom: 1px solid #fff;
            display: inline-block;
          }
        }
      }
      .card {
        padding-bottom: 30px;
        .title {
          font-size: 32px;
          font-weight: 500;
          color: #ffffff;
          padding-left: 20px;
        }
        :deep(.van-card) {
          background: transparent;
        }
        .price {
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          // border: 1px solid #fff;
          padding: 5px 14px;
          margin-left: 20px;
        }
      }
    }
    .money {
      display: flex;
      justify-content: space-between;
      //   border: 1px solid #fff;
      padding: 0 20px;
      align-items: center;
      background: #343636;
      .moneyo {
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
      }
      .moneyt {
        font-size: 48px;
        font-weight: 500;
        background-image: -webkit-linear-gradient(
          180deg,
          #0dafda 0%,
          #58ffa1 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .select {
      padding: 24px 30px 20px;
      .selecto {
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
      }
      .selectt {
        padding-top: 10px;
        :deep(.van-checkbox) {
          overflow: visible;
          align-items: flex-start;
          .van-checkbox__label {
            line-height: 1.2;
          }
        }
        .icon {
          width: 28px;
          height: 28px;
          pointer-events: none; /* 禁止长按图片保存 */
          margin-top: 5px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none; /* 禁止长按图片保存 */
          }
        }
        .icons {
          width: 28px;
          height: 28px;
          border: 1px solid #fff;
          margin-top: 5px;
        }
      }
    }
  }
}
.time {
  padding: 0 30px;
  margin-bottom: 50px;
  .timeo {
    display: flex;
    justify-content: space-between;
    background: #020202;
    padding: 22px 76px 22px 30px;
    .timet {
      display: flex;
      .timef {
        width: 24px;
        height: 26px;
        line-height: 1.3;
        pointer-events: none; /* 禁止长按图片保存 */
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          pointer-events: none; /* 禁止长按图片保存 */
        }
      }
      .times {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        padding-left: 5px;
      }
    }
    .down {
      .block {
        color: #fff;
        background: #0e3938;
        margin-right: 5px;
        padding: 6px;
      }
      .colon {
        color: #fff;
        margin-right: 5px;
      }
    }
  }
}
.btn_box {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0vw;
  bottom: 3vw;
  padding: 3vw;
  .van-button--success {
    background: url(~@/assets/img/btn.png);
    background-size: 100% 100%;
    overflow: hidden;
    background-position: 0px 0px;
  }
}
.van_group {
  width: 92%;
  // border: 1px solid #fff;
  margin: 0 auto;
  :deep(.van-cell) {
    padding: 30px 35px;
    align-items: center;
  }
  // .left {
  //   width: 56px;
  //   height: 56px;
  //   pointer-events: none; /* 禁止长按图片保存 */
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     pointer-events: none; /* 禁止长按图片保存 */
  //   }
  // }
  .left {
    width: 56px;
    height: 56px;
    margin-right: 5vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icono {
    width: 28px;
    height: 28px;
    pointer-events: none; /* 禁止长按图片保存 */
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none; /* 禁止长按图片保存 */
    }
  }
  .icont {
    width: 28px;
    height: 28px;
    pointer-events: none; /* 禁止长按图片保存 */
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none; /* 禁止长按图片保存 */
    }
  }
}
.popup {
  :deep(.van-popup) {
    background: transparent;
    border: none;
  }
  .popupo {
    width: 618px;
    height: 658px;
    background-image: url("~@/assets/img/s08.png");
    background-size: 618px 658px;
    background-repeat: no-repeat;
    text-align: center;
    .buy {
      font-size: 32px;
      font-weight: 400;
      color: #333;
      padding-top: 58px;
      padding-bottom: 46px;
    }
    .buyo {
      width: 298px;
      height: 286px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .buys {
      padding: 30px 60px 0;
      .van-button--success {
        background: url(~@/assets/img/set02.png);
        background-size: 100% 100%;
        overflow: hidden;
        background-position: 0px 0px;
        color: #fff;
      }
    }
  }
}
</style>
