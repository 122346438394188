<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="我的钱包" left-arrow @click-left="xz_go('mine')" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <div class="item">
      <div class="top"></div>
      <div class="tit">钱包余额</div>
      <div class="price">¥ {{ parseFloat(balance).toFixed(2) }}</div>
      <div class="mid"></div>
      <div class="opt"><span class="opt_btn" @click="toRecharge">充 值</span> <span class="opt_btn" @click="toCash">提 现</span></div>
    </div>
    <div class="list">
      <div class="box">
        <div class="li" @click="$router.push('/fund')">
          <img src="~@/assets/img/wa_list01.png" class="img" /><span class="toc">查看资金明细</span><span class="go" style="float: right;"><img src="~@/assets/img/go.png" class="img_go" /></span>
        </div>
        <div class="li" @click="xz_go('banka_list')">
          <img src="~@/assets/img/wa_list02.png" class="img" /><span class="toc">绑定收款账户</span><span class="go" style="float: right;"><img src="~@/assets/img/go.png" class="img_go"  /></span>
        </div>
      </div>
    </div>
    <div style="height: 10vw"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      balance: "",
      integral: ""
    }
  },
  created() {
    this.getBalanceInfo();
  },
  methods: {
    // 获取余额信息
    getBalanceInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.balance = res.data.balance;
          this.integral = res.data.integral;
        },
        tip: () => {}
      })
    },
    // 去充值
    toRecharge() {
      this.xz_go('recharge_a')
    },
    // 去提现
    toCash() {
      this.xz_go('cash_a')
    }
  }
}
</script>

<style lang="scss" scoped>
.item {background: url("~@/assets/img/wallet.png") no-repeat; background-size: 100% 100%; width: 90vw; height: 48vw; margin: 0 auto;}
.top {height: 5vw;}
.tit {width: 86%; height: 24px; line-height: 24px; margin: 15px 7%; font-size: 5vw; font-weight: 500;}
.price {width: 86%; height: 30px; line-height: 30px; margin: 60px 7%; font-size: 8vw; font-weight: 600;}
.mid {height: 2vw;}
.opt {width: 86%; height: 60px; margin: 10px 7%;}
.opt_btn {width: 120px; height: 50px; text-align: center; background: url("~@/assets/img/wallet_btn.png") no-repeat; background-size: 100% 100%; padding: 12px 25px; margin-right: 20px;}
.list {width: 96%; height: auto; margin: 20px 2%;}
.box {width: 96%; height: auto; margin: 10px 2%;}
.li {width: 100%; height: 90px; line-height: 90px; margin: 20px auto; color: #000;}
.img {width: 64px; height: 48px;}
.toc {line-height: 120px; margin-left: 25px; font-size: 4vw;}
.go {width: 60px; line-height: 100px; text-align: right;}
.img_go {width: 32px; height: 32px; vertical-align: middle; text-align: right; margin-top: 20px;}
</style>
