<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar :title="name" :border="false" z-index="1000" left-arrow @click-left="xz_back" />
    <div style="height: 2vw"></div>
    <div class="tab_box">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad" :offset="10">
          <div class="good_box" v-if="collectList.length > 0">
            <div class="good_list">
              <div class="item" v-for="(item, index) in collectList" :key="index" @click="toDetail(item.id)">
                <div class="img"><img :src="item.goods.product_image + '?x-oss-process=image/resize,w_300/quality,q_90'" /></div>
                <div class="icon" v-if="spot_id > 0"><img src="~@/assets/img/qr.png" class="img_icon" /></div>
                <div class="name one">{{ item.goods.product_name }}</div>
                <div class="id"> # {{ item.goods_number }}</div>
                <div class="time" v-if="item.hold_reward_time == '0'">奖励已发完</div>
                <div class="time" v-if="item.hold_reward_time == '1'">奖励已发放</div>
                <div class="time" v-if="item.hold_reward_time && item.hold_reward_time > 1">
                  <div class="time_lt">奖励倒计时:</div><div class="time_rt"><van-count-down :time="new Date(item.hold_reward_time).getTime() - new Date().getTime()" /></div>
                </div>
                <div class="time1" v-if="item.hold_reward_time == ''"></div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="empty" v-if="collectList.length == 0"><van-empty description="收藏室里没有东西哟" /></div>
    </div>
    <div style="height: 3vw"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detailId: '',
      name: '',
      from: '',
      collectList: [],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 0,
      limit: 10,
      total: 0, // 总页数
      time: '',
      spot_id: 0
    }
  },
  created() {
    this.detailId = this.$route.query.id;
    this.name = this.$route.query.name;
    this.from = this.$route.query.from; 
    this.backTop();
  },
  methods: {
    // 返回顶部
    backTop () {
      document.documentElement.scrollTop = 0;
    },
    // 上拉加载
    onLoad() {
      this.pageIndex += 1;
      this.getCollectList(this.pageIndex, 10);
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.collectList = [];
      this.loading = true;
      this.isLoading = true;
      this.finished = false;
      this.onLoad();
    },
    // 藏品列表
    getCollectList(page, limit) {
      this.$get({
        url: "/app/member_space/albumInfo",
        data: { product_id: this.detailId, page: page, limit: limit, from: this.from },
        success: (res) => {
          if (res.code == 1) {
            this.isLoading = false;
            this.loading = false;
            if (res.data.data.length == 0) {
              this.finished = true;
            } else {
              this.collectList = this.collectList.concat(res.data.data);
              this.total = res.data.total;
              console.log("spot_id:", res.data.data[0]);
              if (res.data.data[0].goods.spot_id > 0) {
                this.spot_id = res.data.data[0].goods.spot_id;
              }
              if (this.collectList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => { }
      })
    },
    // 藏品详情
    toDetail(detailId) {
      this.$router.push({ path: '/collection_details', query: { id: detailId } });
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.van-pull-refresh) {min-height: 0;}
.tab_box {
  width: 100%; height: 12vw; margin: 0 auto; --van-tabs-bottom-bar-width: 180vw; --van-tabs-bottom-bar-height: 12vw;
  :deep(.van-tab) {z-index: 10; width: 100%; margin-bottom: 30vw;}
  :deep(.van-tabs__line) {top: 0;}
}
.good_box {width: 100%;  min-height: 100vh; padding: 2vw 0; background: #F5F5F5 ;}
.good_list {width: 94%; height: auto; margin: 0 3%; display: flex; justify-content: space-between; flex-wrap: wrap; position: relative; margin-bottom: 30vw;
  .item {padding: 1vw; width: 46vw; margin-bottom: 3vw; position: relative; background: #fff;
    .img {width: 44vw; height: 40vw; img {width: 100%; height: 100%; object-fit: cover;}}
    .icon {width: 94%; height: 6.4vw; margin: 0 3%; text-align: right; margin-top: -9vw; .img_icon {width: 6.4vw; height: 100%; vertical-align: middle;}}
    .name {line-height: 9vw; color: #000; font-size: 4vw; padding: 1vw 2vw;}
    .id {width: 60%; height: 5vw; line-height: 5vw; background: #FFF7EA; color: #FF9E19; font-size: 3vw; margin-left: 5%;}
    .time {width: 92%; height: 7.5vw; margin: 2vw 4%; background: #F0F0F0; color: #999;  border-radius: 1.2vw; display: flex;}
    .time_lt {flex: 6; line-height: 2; font-size: 3vw; padding-left: 2vw;}
    .time_rt {flex: 6; line-height: 2; margin-top: 5px; --van-count-down-text-color: #FF2222;}
    .time1 {width: 92%; height: 1vw; margin: 5px 4%;}
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -20vw;}
</style>
