<template>
  <van-nav-bar title="品牌详情" left-arrow @click-left="toBack" :border="false"></van-nav-bar>
  <div class="box">
    <div class="top">
      <div style="height: 8vw"></div>
      <div class="top_img"><img :src="brand.image" /></div> <div class="top_tit">{{ brand.name }}</div>
      <div class="top_txt">{{ brand.introduce }}</div>
    </div>
    <div class="tabs">
      <div class="tab_txt" :class="tabIndex == 0 ? 'tab_act' : ''" @click="changeTab(0)">首发</div> 
      <div class="tab_txt" :class="tabIndex == 1 ? 'tab_act' : ''" @click="changeTab(1)" v-if="minId == null">市场</div>
    </div>
    <div class="good">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" v-if="tabIndex == 0">
        <van-list v-model="loading" :finished="finished" @load="onLoad" :offset="10">
          <div class="good_index">
            <div class="list">
              <div class="item" v-for="(item, index) in indexList" :key="index" @click="toIndexDetail(item.id)">
                <div :class="item.status == 3 ? 'squares' : 'square'">
                  <div class="img_box">
                    <!-- 非抽签 -->
                    <div class="mark" v-if="item.rob_type == 1 && new Date(item.start_time).getTime() >= new Date().getTime()">
                      <van-icon :name="require('@/assets/img/time01.png')" size="4vw" />
                      <div class="start_time">
                        即将开启: &nbsp;<van-count-down :time="new Date(item.start_time).getTime() - new Date().getTime()" format="DD 天 HH 时 mm 分 ss 秒" @finish="finish" />
                      </div>
                    </div>
                    <!-- //进行中 -->
                    <div class="mark" v-if="item.rob_type == 1 && new Date().getTime() > new Date(item.start_time).getTime()"><span>{{ item.status_desc }}</span></div>
                    <!-- 抽签购 -->
                    <div class="mark" v-if="item.rob_type == 2 && new Date(item.rob_start_time).getTime() >= new Date().getTime()">
                      <van-icon :name="require('@/assets/img/time01.png')" size="4vw" />
                      <div class="start_time">
                        抽签即将开启: &nbsp;<van-count-down :time="new Date(item.rob_start_time).getTime() - new Date().getTime()" format="DD 天 HH 时 mm 分 ss 秒" @finish="finish" />
                      </div>
                    </div>
                    <div class="mark" v-if="item.rob_type == 2"><span >{{ this.getShowStatus(item.rob_start_time, item.rob_end_time, item.rob_show_time, item.end_time) }}</span></div>
                    <div class="img"><img :src="item.product_image + '?x-oss-process=image/resize,w_400/quality,q_90'" /></div>
                  </div>
                  <div class="title">{{ item.product_name }}&nbsp;<van-tag type="danger">{{ item.brand }}</van-tag></div>
                  <div class="tag_box"><van-tag type="success">发售总量</van-tag><van-tag type="danger">{{ item.total_num }}份</van-tag></div>
                  <div class="cell_box">
                    <div class="role"><div class="avatar"><img :src="item.author_image + '?x-oss-process=image/resize,w_50/quality,q_90'" /></div><div class="name">{{ item.author_name }}</div></div>
                    <div class="price"><span>¥{{ item.price }}</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 2vw"></div>
          </div>
          <!-- index == 2 -->
        </van-list>
      </van-pull-refresh>
      <van-pull-refresh v-model="isLoading1" @refresh="onRefresh1" v-if="tabIndex == 1">
        <van-list v-model="loading1" :finished="finished1" @load="onLoad1" :offset="10">
          <div class="good_box">
            <div class="good_list">
              <div class="item" v-for="(item, index) in goodList" :key="index" @click="toGoodDetail(item)">
                <div class="img"><img :src="item.good.product_image + '?x-oss-process=image/resize,w_250/quality,q_90'" /></div>
                <div class="name one">{{ item.good.product_name }}</div>
                <div class="tag_box">
                  <div class="tago"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'N'">{{ item.level_desc }}</van-tag></div>
                  <div class="tagt"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'R'">{{ item.level_desc }}</van-tag></div>
                  <div class="tagf"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'SR'">{{ item.level_desc }}</van-tag></div>
                  <div class="tags"><van-tag type="success" v-if="item.level_desc != '' && item.level_desc == 'SSR'">{{ item.level_desc }}</van-tag></div>
                </div>
                <div class="role">
                  <div class="conrole"><div class="avatar"><img :src="item.head_img + '?x-oss-process=image/resize,w_50/quality,q_90'" /></div><div class="nick">{{ item.nickname }}</div></div>
                  <div class="price one"><span>¥{{ item.price }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div style="height: 5vw"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      brand: [],
      indexList: [],
      goodList: [],
      isLoading: false,
      loading: false,
      finished: false,
      loading: false,
      pageIndex: 0,
      limit: 10,
      total: 0, // 总页数
      isLoading1: false,
      loading1: false,
      finished1: false,
      loading1: false,
      pageIndex1: 0,
      limit1: 10,
      total1: 0,
      minId: 0 
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.minId = this.$cookies.get("minId");
    this.getBrandInfo();
    this.changeTab(0);
    this.backTop();
  },
  methods: {
    // 返回顶部
    backTop () {
      document.documentElement.scrollTop = 0;
    },
    // 返回市场
    toBack() {
      this.$router.push({ 
        path: "/", 
        query: { id: this.goods_id, series_id: this.seriesId, value1: this.value1, level: this.level, field: this.field1, sort: this.sort, value3: this.value3, keywords: this.keywords }
      })
    },
    // 品牌信息
    getBrandInfo() {
      this.$get({
        url: "/app/merchants/info",
        data: { id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.brand = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 选项卡切换
		changeTab(index){
			this.tabIndex = index; 
      console.log("index:", index);
      if (index == 0) {
        this.onLoad();
      } else if (index == 1) {
        this.onLoad1();
      }
		},
    // 上拉加载
    onLoad() {
      this.pageIndex += 1;
      setTimeout(this.getIndexList(this.pageIndex, 10), 100)
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.indexList = [];
      this.loading = true;
      this.isLoading = true;
      this.finished = false;
      setTimeout(this.onLoad(), 10)
    },
    // 首发藏品 
    getIndexList(page, limit) {
      this.$get({
        url: "/app/merchants/merchantGoodsList",
        data: { id: this.id, page: page, limit: limit },
        success: (res) => {
          if (res.code == 1) {
            this.isLoading = false;
            this.loading = false;
            if (res.data.data.length == 0) {
              this.finished = true; // 没有数据的话，加载完成
            } else {
              this.indexList = this.indexList.concat(res.data.data);
              this.total = res.data.total;
              if (this.indexList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    /**
     * 抽签购状态
     * 抽签即将开始  < rob_start_time  
     * 抽签中   rob_start_time — rob_end_time
     * 待公示   rob_end_time - rob_show_time
     * 已公示   rob_show_time - end_time
     * 已结束   > end_time
     */
     getShowStatus(rob_start_time, rob_end_time, rob_show_time, end_time){
      let t = new Date().getTime();
      let robS = new Date(rob_start_time).getTime();
      let robE = new Date(rob_end_time).getTime();
      let show = new Date(rob_show_time).getTime();
      let end = new Date(end_time).getTime();

      if (t >= robS && t < robE) return '抽签中';
      if (t >= robE && t < show) return '待公示';
      if (t >= show && t < end) return '已公示';
      if (t >= end) return '已结束';
    },
    onLoad1() {
      this.pageIndex1 += 1;
      setTimeout(this.getGoodsList(this.pageIndex1, 10), 100)
    },
    onRefresh1 () {
      this.pageIndex1 = 0;
      this.goodList = [];
      this.loading1 = true;
      this.isLoading1 = true;
      this.finished1 = false;
      setTimeout(this.onLoad1(), 10)
    },
    // 市场藏品
    getGoodsList(page, limit) {
      this.$get({
        url: "/app/merchants/merchantGoodsRentList",
        data: { id: this.id, page: page, limit: limit },
        success: (res) => {
          if (res.code == 1) {
            this.isLoading1 = false;
            this.loading1 = false;
            if (res.data.data.length == 0) {
              this.finished1 = true; // 没有数据的话，加载完成
            } else {
              this.goodList = this.goodList.concat(res.data.data);
              this.total1 = res.data.total;
              if (this.goodList.length >= this.total1) {
                this.finished1 = true;
              } else {
                this.finished1 = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 首发详情
    toIndexDetail(id) {
      this.$router.push({ path: "/details", query: { id: id, type: 1, bid: this.id, brand: 1 } })
    },
    // 市场详情
    toGoodDetail(item) {
      if (item.status == 1) {
        this.$toast("该藏品正在支付中，请稍后再试")
      } else {
        this.$router.push({ 
          path: "/details", 
          query: { id: item.id, type: 3, series_id: item.good.series_id, bid: this.id, brand: 2 }
        })
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.box {width: 100%; min-height: 100vh; margin: 0 auto; background: #F5F5F5;}
.top {
  width: 100%; min-height: 50vw; margin: 0 auto; background: url(~@/assets/img/cp_bg.png) no-repeat; background-size: 100% 100%;
  .top_img {width: 15.2vw; height: 15vw; line-height: 15.2vw; margin: 0 auto; text-align: center; background: #fff; img {width: 95%; height: 95%; vertical-align: middle; margin-top: -1vw;}} 
  .top_tit {width: 100%; height: 5vw; line-height: 5vw; margin: 3.2vw auto; text-align: center; color: #fff; font-size: 4.8vw;}
  .top_txt {width: 92%; height: 60vw; line-height: 5.3vw; margin: 4vw 4%; color: #F3F3F3; font-size: 3.2vw; font-weight: 500;}
}
.tabs {
  width: 100%; height: 10vw; background: #fff; display: flex; flex-wrap: nowrap; flex-direction: row;
  .tab_txt {width: 20%; height: 10vw; line-height: 10vw; text-align: center; color: #B9B9B9; font-size: 3.7vw; font-weight: 500;}
  .tab_act {color: #222222; font-size: 4.2vw; font-weight: 500; border-bottom: #FF2222 solid 5px;}
}
.good {width: 100%; height: auto; margin: 0 auto;}
.good_index {
  width: 100%; min-height: 100vh; 
  .list {
    width: 95%; height: auto; margin: 0 2.5%;
    .item {width: 100%; height: 110vw; margin: 5vw auto; background: linear-gradient(154deg, #FFFFFF 0%, #FFFFFF 100%); box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.12); 
      border-radius: 8px; opacity: 1; filter: blur(undefinedpx);
      .squares {
        .img_box {width: 100%; height: 77.6vw; margin: 3vw auto;
          .mark {
            position: absolute; background: rgba(0, 0, 0, 0.5); font-size: 3vw; color: #FFFFFF; display: flex; align-items: center; margin-left: 1vw; margin-top: 20px;; padding: 0 2vw;
            .start_time {display: flex; color: #fff; .van-count-down {width: 35vw; color: #fff; font-size: 3vw; line-height: 2;}}
            .van-icon {margin-right: 2vw; margin-left: -2vw;} .over {color: #fff;}}
          .img {width: 100%; height: 77.6vw; img {width: 100%; height: 77.6vw; object-fit: cover; border-radius: 8px 8px 0 0;} } }
        .title {
          width: 92%; height: 50px; line-height: 50px; margin: 0 4%; color: #000; font-size: 4vw; font-weight: 600;
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 1.5vw 2vw; height: 5vw; --van-tag-danger-color: #FFF8F0 !important; margin-right: 3vw; color: #FC9C32;}
        }
        .tag_box {
          width: 92%; height: auto; margin: 1vw 4%;
          .van-tag--success {--van-tag-font-size: 3vw; -van-tag-padding: 3vw 5vw; height: 6vw; --van-tag-border-radius: 0; opacity: 1; filter: blur(undefinedpx);
          --van-tag-success-color: linear-gradient(180deg, #FFCF4E 0%, #FF1F1F 100%);}
          .van-tag--warning {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw;}
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw; --van-tag-danger-color: #FFF3EF !important; margin-right: 3vw; color: #FC201F;}
        }
        .cell_box {width: 92%; height: auto; margin: 2vw 4%; display: flex; justify-content: space-between;
          .role {
            display: flex; align-items: center;
            .avatar {margin-right: 3vw; border-radius: 50%; flex-shrink: 0; overflow: hidden; width: 6vw; height: 6vw; img {width: 100%; height: 100%; display: block;}} 
            .name {color: #000; font-size: 4vw;}
          }
          .price {width: 14vw; color: #000; font-size: 4vw; font-weight: 800;}
        }
      }
      .square {
        .img_box {width: 100%; height: 77.6vw; margin: 3vw auto;
          .mark {
            position: absolute; background: #000; opacity: 0.5; font-size: 3vw; color: #FFFFFF; display: flex; align-items: center; margin-left: 3vw; margin-top: 20px; padding: 0 5vw;
            .start_time {display: flex; color: #fff; .van-count-down {width: 35vw; line-height: 2; color: #fff; font-size: 3vw; }}
            .van-icon {margin-right: 2vw; margin-left: -2vw;} .over {color: #fff;}}
          .img {width: 100%; height: 77.6vw; img {width: 100%; height: 77.6vw; object-fit: cover; border-radius: 8px 8px 0 0;} } }
        .title {
          width: 92%; height: 40px; line-height: 40px; margin: 0 4%; color: #000; font-size: 4vw; font-weight: 600;
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 1.5vw 2vw; height: 5vw; --van-tag-danger-color: #FFF8F0 !important; margin-right: 3vw; color: #FC9C32;}
        }
        .tag_box {width: 92%; height: auto; margin: 2vw 4%;
          .van-tag--success {--van-tag-font-size: 3vw; -van-tag-padding: 3vw 5vw; height: 6vw; --van-tag-border-radius: 0; opacity: 1; filter: blur(undefinedpx);
            --van-tag-success-color: linear-gradient(180deg, #FFCF4E 0%, #FF1F1F 100%);}
          .van-tag--warning {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw;}
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw; --van-tag-danger-color: #FFF3EF !important; margin-right: 3vw; color: #FC201F;}
        }
        .cell_box {width: 92%; height: auto; margin: 2vw 4%; display: flex; justify-content: space-between;
          .role {display: flex; align-items: center;
            .avatar {margin-right: 3vw; border-radius: 50%; flex-shrink: 0; overflow: hidden; width: 6vw; height: 6vw; img {width: 100%; height: 100%; display: block;}} 
            .name {color: #000; font-size: 4vw;}
          }
          .price {width: 14vw; color: #000; font-size: 4vw; font-weight: 800;}
        }
      }
    }
  }
}
.good_box {width: 100%; height: auto; padding: 3vw 0; background: #F5F5F5;}
.good_list {
  width: 94%; margin: 0 3%; display: flex; justify-content: space-between; flex-wrap: wrap; 
  .item {
    padding: 1vw; width: 45vw; margin-bottom: 20px; background: #fff;
    .img {width: 43vw; height: 33vw; img {width: 100%; height: 100%; object-fit: cover;}}
    .name {height: 8vw; line-height: 2; color: #000; font-size: 4vw; padding: 5px 10px;}
    .tag_box {
      display: flex; padding-left: 10px;
      .tago {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tagt {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tagf {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .tags {.van-tag--success {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-success-color: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); margin-right: 3vw;}}
      .van-tag--warning {--van-tag-font-size: 3vw; padding: 0 10px; height: 5vw; --van-tag-warning-color: #064e3c; margin-right: 3vw; margin-top: 1.9vw;}
    }
    .role {
      width: 100%; position: relative; display: flex; align-items: center; justify-content: space-between; padding-top: 5px;
      .conrole {
        display: flex; align-items: center; padding-left: 10px;
        .avatar {width: 4vw; height: 4vw; border-radius: 50%; overflow: hidden; margin-right: 10px; flex-shrink: 0; img {width: 100%; height: 100%; display: block;}}
      }
      .nick {color: #9A999E; font-size: 3.2vw;}
      .price {font-size: 4vw; color: #000; font-weight: 800; padding-top: 5px;}
    }
  }
}
</style>
