<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="合成记录" left-arrow @click-left="xz_back" :border="false" z-index="1000" class="while"/>
    <div style="height: 3vw"></div>
    <div class="list">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div class="item" v-for="(item, index) in recordList" :key="index">
            <van-card v-if="item" :title="item.product_name" :thumb="item.product_image">
              <template #tag><div class="deck"><van-tag color="#FF2222" v-if="true">合成成功</van-tag><van-tag color="#545555" v-if="false">已结束</van-tag></div></template>
              <template #price>
                <div class="cell"><div class="lable">灵动空间</div></div>
                <div class="cell"><div class="lable">合成数量</div><div class="value">x1</div></div>
                <div class="cell"><div class="lable">合成时间</div><div class="value">{{ item.create_time }}</div></div>
              </template>
              <template #footer>
                <div class="footer_cell">
                  <div style="height: 5vw"></div>
                  <van-collapse v-model="activeNames" :border="false" @change="change">
                    <van-collapse-item title="查看赋能" :name="item.id" :border="false">
                      <div class="enable">
                        <div style="height: 3vw"></div>
                        <div class="enable_title">藏品赋能</div>
                        <div style="height: 3vw"></div>
                        <div class="enable_body">
                          <div class="enable_item" v-if="item"><div class="lable">藏品名称：</div><div class="text">{{ item.product_name }}</div></div>
                          <div class="enable_item"><div class="lable">赋能效果：</div><div class="text">{{item.enabling_effect}}</div></div>
                        </div>
                      </div>
                    </van-collapse-item>
                  </van-collapse>
                </div>
              </template>
            </van-card>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="empty" v-if="recordList.length == 0"><van-empty description="合成记录里没有东西哟" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["0"],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 0,
      limit: 10,
      total: 0, // 总页数
      recordList: []
    }
  },
  created() {},
  methods: {
    // 上拉加载
    onLoad() {
      this.pageIndex += 1;
      setTimeout(this.getRecordList(this.pageIndex, 10), 1000)
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.recordList = [];
      this.loading = true;
      this.isLoading = true;
      this.finished = false;
      this.onLoad();
    },
    // 合成记录
    getRecordList(pageIndex, limit) {
      this.$get({
        url: "/app/member_space/syntheticLog",
        data: { page: pageIndex, limit: limit },
        success: (res) => {
          if (res.code == 1) {
            this.isLoading = false;
            this.loading = false;
            if (res.data.data.length == 0) {
              this.finished = true;
            } else {
              this.recordList = this.recordList.concat(res.data.data);
              this.total = res.data.total;
              if (this.recordList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 藏品赋能
    change(index) {
      this.activeNames = index
    },
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; background: #F7F7F9;}
.while {background: #fff;}
:deep(.van-pull-refresh) {min-height: calc((100vh - 176vw) / 2);}
.list {width: 92%; margin: 0 4%;}
.item {width: 100%; height: auto; margin-bottom: 3vw; background: #fff; --van-card-font-size: 8.5vw;}
.van-card {
  overflow: hidden; position: relative; background: #fff; padding: 2vw; --van-card-text-color: #000; --van-card-font-size: 4vw;
  .van-card__title {font-size: 4vw; color: #000;}
  .green {color: #FF2222;}
}
:deep(.van-card__thumb) {position: static;}
:deep(.van-card__tag) {right: -18vw; top: 0vw; left: auto;}
.deck {transform: rotate(25deg); --van-tag-padding: 2vw 20vw; .van-tag {--van-tag-font-size: 2.8vw;}}
.cell {width: 60vw; display: flex; justify-content: space-between; color: #000;}
:deep(.van-card__footer) {text-align: left;}
.footer_cell {
  :deep(.van-cell) {font-size: 3vw; --van-cell-background-color: #FFF9F2; --van-cell-vertical-padding: 1vw; --van-cell-horizontal-padding: 2vw; --van-cell-text-color: #333333;
   --van-cell-right-icon-color: #999;}
  --van-collapse-item-content-background-color: transparent; --van-collapse-item-content-padding: 0;
}
.enable {
  .enable_title {color: #FC9C32; font-size: 4vw; text-align: center; font-weight: normal;}
  .enable_body {
    width: 87vw; margin: 0 auto; background: #FFF9F2;
    .enable_item {padding: 3vw; display: flex; .lable {width: 20vw; flex-shrink: 0;} .text {color: #000;}}
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
