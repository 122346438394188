<template>
  <div class="box">
    <div style="height: 2vw"></div>
    <van-search @click="toSearch" v-model="text" :left-icon="require('@/assets/img/search.png')" :disabled="true" placeholder="请输入您感兴趣的NFT" />
    <van-swipe class="banner" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="toBannerUrl(item)"><div class="item"><img :src="item.image_path" /></div></van-swipe-item>
    </van-swipe>
    <div style="height: 3vw"></div>
    <!-- 通知 -->
    <div class="notice_box" @click="xz_go('new_list')">
      <van-notice-bar :left-icon="require('@/assets/img/horn.png')" :scrollable="false">
        <van-swipe vertical class="notice-swipe" :autoplay="2000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in noticeList" :key="index"><div style="font-size: 3.8vw;">{{ item.title }}</div></van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <div class="tabs">
      <div :class="tabIndex == 0 ? 'tab_act' : ''" @click="changeTab(0)" class="tab_opt">数字藏品</div>
      <div :class="tabIndex == 1 ? 'tab_act' : ''" @click="changeTab(1)" class="tab_opt">数字盲盒</div>
    </div>
    <!-- 数字藏品 -->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" v-if="tabIndex == 0">
      <van-list v-model="loading" :finished="finished" @load="onLoad" :offset="200">
        <div class="good_box">
          <div class="list">
            <div class="item" v-for="(item, index) in goodList" :key="index" @click="toGoodDetail(item.rob_type, item.id)">
              <div :class="item.status == 3 ? 'squares' : 'square'">
                <div class="img_box">
                  <!-- 非抽签  -->
                  <van-tag round class="tags" color="rgba(0, 0, 0, 0.5)" v-if="item.rob_type == 1 && getFormatTime(item.start_time) >= new Date().getTime()">
                    <van-icon :name="require('@/assets/img/time01.png')" size="4vw" style="line-height: 6vw;" />
                    即将开启: &nbsp;<van-count-down :time="getCountdown(item.start_time)" format="DD 天 HH 时 mm 分 ss 秒" @finish="finish" />
                  </van-tag>
                  <!-- 进行中 -->
                  <van-tag round class="tags" color="rgba(0, 0, 0, 0.5)" v-if="item.rob_type == 1 && new Date().getTime() > getFormatTime(item.start_time)">{{ item.status_desc }}</van-tag>
                  <van-tag round class="tags" color="rgba(0, 0, 0, 0.5)" v-if="item.rob_type == 1 && item.status == 1 && new Date().getTime() > getFormatTime(item.start_time)">进行中</van-tag>
                  <!-- 抽签购  -->
                  <van-tag round class="tags" v-if="item.rob_type == 2 && new Date(item.rob_start_time).getTime() >= new Date().getTime()">
                    <van-icon :name="require('@/assets/img/time01.png')" size="4vw" style="line-height: 6vw;" />
                    抽签即将开启: &nbsp;<van-count-down :time="getCountdown(item.rob_start_time)" format="DD 天 HH 时 mm 分 ss 秒" @finish="finish" />
                  </van-tag>
                  <van-tag class="tags" color="rgba(0, 0, 0, 0.5)" v-if="item.rob_type == 2">
                   {{ this.getShowStatus(item.rob_start_time, item.rob_end_time, item.rob_show_time, item.end_time) }}
                  </van-tag>
                  <div class="img"><img :src="item.product_image + '?x-oss-process=image/resize,w_400/quality,q_90'" /></div>
                </div>
                <div class="title">{{ item.product_name }}&nbsp;<van-tag type="danger">{{ item.brand }}</van-tag></div>
                <div class="tag_box"><van-tag type="success">发售总量</van-tag><van-tag type="danger">{{ item.total_num }}份</van-tag></div>
                <div class="cell_box">
                  <div class="role"><div class="avatar"><img :src="item.author_image + '?x-oss-process=image/resize,w_50/quality,q_90'" /></div><div class="name">{{ item.author_name }}</div></div>
                  <div class="price"><span>¥{{ item.price }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-pull-refresh v-model="isLoading1" @refresh="onRefresh1" v-if="tabIndex == 1">
      <van-list v-model="loading1" :finished="finished1" @load="onLoad1" :finished-text="finishedText" :offset="200">
        <div class="good_box">
          <div class="list" style="height: 40vw;" v-if="blindList.length == 0"><van-empty image-size="100" description="暂无盲盒数据" /></div>
          <div class="list" v-else>
            <div class="item" v-for="(item, index) in blindList" :key="index" @click="toBlindDetail(item.id)">
              <div class="img_box">
                <van-tag round class="tags" color="rgba(0, 0, 0, 0.5)" v-if="item.status == 5">已售罄</van-tag>
                <div class="img"><img :src="item.image + '?x-oss-process=image/resize,w_400/quality,q_90'" /></div>
              </div>
              <div class="squares">
                <div class="title">{{ getSubString(item.name) }}&nbsp;<van-tag type="danger">{{ item.mer_name }}</van-tag></div>
                <div class="tag_box"><van-tag type="success">发售总量</van-tag><van-tag type="danger">{{ item.num }}份</van-tag></div>
                <div class="cell_box">
                  <div class="role">
                    <div class="avatar"><img :src="item.author_image + '?x-oss-process=image/resize,w_50/quality,q_90'" /></div><div class="name">{{ getSubString(item.author_name) }}</div>
                  </div>
                  <div class="price"><span>¥{{ item.price }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div style="height: 15vw"></div>
    <van-dialog v-model:show="show" message="欢迎来到道拍，根据我国相关法律法规，您必须进行实名认证后才可体验本APP完整内容" show-cancel-button @confirm="toReal"></van-dialog>
  </div>
</template>

<script>
let bullist = [];
if (JSON.parse(localStorage.getItem("notice"))) {
  bullist = JSON.parse(localStorage.getItem("notice"));
}
export default {
  data() {
    return {
      text: "",
      bannerList: [],
      noticeList: bullist || [],
      tabIndex: 0,
      goodList: [], // 首发列表
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 0,
      limit: 10,
      total: 0, // 总页数
      blindList: [], //盲盒列表
      isLoading1: false,
      loading1: false,
      finished1: false,
      pageIndex1: 0,
      finishedText: '没有更多了',
      show: false,
      isReal: "", //实名信息
      time: "",
      merId: "",
      isReload: 0,
      minId: 0,
      timer: null,
      timer1: null
    };
  },
  mounted() {
    this.merId = this.$cookies.get("merchantId");
    this.isReload = this.$route.query.isReload;
    console.log("index_merId:", this.merId, "isReload:", this.isReload);
    if (!this.merId || this.merId === "null") {
      if (this.isReload > 0) {
        if (location.href.indexOf("#reloaded") == -1) {
          location.href = location.href + "#reloaded";
          location.reload();
        }
      }
    }
  },
  created() {
    this.minId = this.$cookies.get("minId");
    this.text = this.$route.query.text;
    this.tabIndex = this.$route.query.tabIndex;
    if (this.tabIndex == undefined) {
      this.tabIndex = 0;
    } 
    this.is_reload = this.$route.query.is_reload;
    this.getBannerList();
    this.getNoticeList();
    this.getRealInfo();
    this.changeTab(this.tabIndex);
  },
  methods: {
    // 获取banner图
    getBannerList() {
      this.$get({
        url: "/app/slider/sliderList",
        data: { type: 1 },
        success: (res) => {
          if (res.code == 1) {
            this.bannerList = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      });
    },
    // banner链接
    toBannerUrl(item) {
      if (item.jump_type == 1) {
        if (this.minId == 1) {
          this.$toast("暂不支持此类型链接跳转！");
        } else {
          window.location.href = item.url;
        }
      } else if (item.jump_type == 2) {
        this.$router.push({ path: "/new_detail", query: { id: item.art_id } });
      } else {
        this.xz_go("/");
      }
    },
    // 公告
    getNoticeList() {
      this.$get({
        url: "/app/homes/getArticleList",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.noticeList = res.data;
            localStorage.setItem("notice", JSON.stringify(this.noticeList));
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      });
    },
    // 选项卡切换
		changeTab(index){
			this.tabIndex = index; 
      console.log("tabIndex:", this.tabIndex);
      if (this.tabIndex == 0) {
        this.pageIndex = 0;
        this.onLoad();
      } else {
        this.pageIndex1 = 0;
        this.onLoad1();
      }
		},
    // 上拉加载
    onLoad() {
      this.loading = false;
      if(!this.timer) { 
        this.timer = setTimeout(() => {
          this.pageIndex += 1;
          this.getGoodList(this.pageIndex, 10);
          this.timer = null;
        }, 100);
      }
    },
    // 下拉刷新
    onRefresh() {
      this.pageIndex = 0;
      this.goodList = [];
      this.loading = true;
      this.isLoading = true;
      this.finished = false;
      setTimeout(() => { this.onLoad() }, 1000);
    },
    // 数字藏品
    getGoodList(page, limit) {
      this.$get({
        url: "/app/goods/goodsList",
        data: { name: this.text, page: page, limit: limit },
        success: (res) => {
          if (res.code == 1) {
            this.isLoading = false;
            this.loading = false;
            if (res.data.data.length == 0) {
              this.finished = true; // 没有数据的话，加载完成
            } else {
              this.goodList = this.goodList.concat(res.data.data);
              this.total = res.data.total;
              if (this.goodList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      });
    },
    // 倒计时时间转换（解决IOS失效的问题）
    getCountdown(start_time) {
      let time =  new Date(start_time.replaceAll(".", "/")).getTime() - new Date().getTime();
      return time;
    },
    // 时间格式化
    getFormatTime(time) {
      return new Date(time.replaceAll(".", "/")).getTime();
    },
    /**
     * 抽签购状态
     * 抽签即将开始  < rob_start_time
     * 抽签中   rob_start_time — rob_end_time
     * 待公示   rob_end_time - rob_show_time
     * 已公示   rob_show_time - end_time
     * 已结束   > end_time
     */
    getShowStatus(rob_start_time, rob_end_time, rob_show_time, end_time) {
      let t = new Date().getTime();
      let robS = new Date(rob_start_time).getTime();
      let robE = new Date(rob_end_time).getTime();
      let show = new Date(rob_show_time).getTime();
      let end = new Date(end_time).getTime();

      if (t >= robS && t < robE) return "抽签中";
      if (t >= robE && t < show) return "待公示";
      if (t >= show && t < end) return "已公示";
      if (t >= end) return "已结束";
    },
    // 数字藏品跳转
    toGoodDetail(rob_type, id) {
      if (this.isReal === 0) {
        this.show = true;
      } else if (rob_type == 1) {
        this.$router.push({ path: "/details", query: { id: id, type: 1 } });
      } else {
        this.$router.push({ path: "/details", query: { id: id, type: 6 } });
      }
    },
    finish() {
      this.getGoodList();
    },
    // 上拉加载
    onLoad1() {
      this.loading1 = false;
      if(!this.timer1) { 
        this.timer1 = setTimeout(() => {
          this.pageIndex1 += 1;
          this.getBlindList(this.pageIndex1, 10);
          this.timer1 = null;
        }, 100);
      }
    },
    // 下拉刷新
    onRefresh1() {
      this.pageIndex1 = 0;
      this.blindList = [];
      this.loading1 = true;
      this.isLoading1 = true;
      this.finished1 = false;
      setTimeout(() => { this.onLoad1() }, 1000);
    },
    // 数字盲盒列表
    getBlindList(page, limit) {
      this.$get({
        url: "/app/blind_box/blindBoxList",
        data: { page: page, limit: limit },
        success: (res) => {
          console.log("盲盒列表：", res);
          if (res.code == 1) {
            this.isLoading1 = false;
            this.loading1 = false;
            if (res.data.data.length == 0) {
              this.finished1 = true; // 没有数据的话，加载完成
            } else {
              this.blindList = this.blindList.concat(res.data.data);
              let totalPages = res.data.total;
              if (this.blindList.length >= totalPages) {
                this.finished1 = true;
              } else {
                this.finished1 = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      });
    },
    // 盲盒详情
    toBlindDetail(id) {
      this.$router.push({ path: "/blind_detail", query: { id: id } });
    },
    // 实名信息
    getRealInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.isReal = res.data.is_real;
            if (this.isReal === 0) {
              this.show = true;
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      });
    },
    // 去实名认证
    toReal() {
      this.$router.push("/real");
    },
    // 搜索
    toSearch() {
      this.$router.push({ path: "/search", query: { text: this.text } });
    },
    // 截取字符串
    getSubString(item) {
      if (item.length > 8) {
        return item.substring(0, 8) + '...';
      } else {
        return item;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
// banner
.banner {width: 96%; height: auto; margin: 0 2%; .item {width: 96%; height: 40vw; margin: 0 2%; text-align: center;} img {width: 100%; height: 100%; object-fit: cover; border-radius: 2.1vw;}}
// 公告
.notice_box {width: 92%; margin: 0 4%; .notice-swipe {width: 100%; height: 40px; line-height: 40px; font-size: 16px; border-radius: 8px;}}
:deep(.van-pull-refresh) {min-height: calc(100vh - 105vw);}
// 选项卡
.tabs {
  width: 96%; height: 10vw; margin: 2vw 2% 0 2%;  display: flex; flex-wrap: nowrap; flex-direction: row;
  .tab_opt {width: 46%; height: 10vw; line-height: 10vw; margin: 0 2%; text-align: center; color: #B9B9B9; font-size: 3.6vw;}
  .tab_act {width: 46%; margin: 0 2%; text-align: center; color: #222222; font-size: 4vw; border-bottom: #FF2222 solid 0.8vw;}
}
// 数字藏品
.good_box {
  .list {
    width: 94%; height: auto; margin: 0 3%; 
    .item {
      width: 100%; height: 110vw; margin: 6vw auto; background: linear-gradient(154deg, #FFFFFF 0%, #FFFFFF 100%); box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.12); border-radius: 8px;
      opacity: 1; filter: blur(undefinedpx);
      .img_box {
        width: 100%; height: 78vw; margin: 3vw auto; position: relative; overflow: auto;
        .tags {
          position: absolute; height: 6vw; margin: 2vw 2.5%; --van-tag-padding: 0 4vw; --van-count-down-text-color: #fff; --van-count-down-font-size: 3vw; --van-count-down-line-height: 2;
          z-index: 999;
          .van-icon {margin-right: 2vw; margin-left: -1vw;}
        }
        .img {width: 100%; height: 78vw; img {width: 100%; height: 78vw; object-fit: cover; border-radius: 8px 8px 0 0;}}
      }
      .squares {
        .title {
          width: 92%; height: 50px; line-height: 50px; margin: 0 4%; color: #000; font-size: 4vw; font-weight: 600;
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 1.5vw 2vw; height: 5vw; --van-tag-danger-color: #FFF8F0 !important; margin-right: 3vw; color: #FC9C32;}
        }
        .tag_box {
          width: 92%; height: auto; margin: 1vw 4%;
          .van-tag--success {--van-tag-font-size: 3vw; -van-tag-padding: 3vw 5vw; height: 6vw; --van-tag-border-radius: 0; opacity: 1; filter: blur(undefinedpx);
          --van-tag-success-color: linear-gradient(180deg, #FFCF4E 0%, #FF1F1F 100%);}
          .van-tag--warning {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw;}
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw; --van-tag-danger-color: #FFF3EF !important; margin-right: 3vw; color: #FC201F;}
        }
        .cell_box {
          width: 92%; height: auto; margin: 2vw 4%; display: flex; justify-content: space-between;
          .role {
            flex: 8; display: flex; align-items: center;
            .avatar {width: 6vw; height: 6vw; margin-right: 2vw; border-radius: 50%; flex-shrink: 0; overflow: hidden; img {width: 100%; height: 100%; display: block;}}
            .name {color: #000; font-size: 3.6vw;}
          }
          .price {flex: 4; text-align: right; color: #000; font-size: 4vw; font-weight: 800;}
        }
      }
      .square {
        .title {
          width: 92%; height: 40px; line-height: 40px; margin: 0 4%; color: #000; font-size: 4vw; font-weight: 600;
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 1.5vw 2vw; height: 5vw; --van-tag-danger-color: #FFF8F0 !important; margin-right: 3vw; color: #FC9C32;}
        }
        .tag_box {
          width: 92%; height: auto; margin: 2vw 4%;
          .van-tag--success {--van-tag-font-size: 3vw; -van-tag-padding: 3vw 5vw; height: 6vw; --van-tag-border-radius: 0; opacity: 1; filter: blur(undefinedpx);
          --van-tag-success-color: linear-gradient(180deg, #FFCF4E 0%, #FF1F1F 100%);}
          .van-tag--warning {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw;}
          .van-tag--danger {--van-tag-font-size: 3vw; --van-tag-padding: 3vw 2vw; height: 5vw; --van-tag-danger-color: #FFF3EF !important; margin-right: 3vw; color: #FC201F;}
        }
        .cell_box {
          width: 92%; height: auto; margin: 2vw 4%; display: flex; justify-content: space-between;
          .role {
            flex: 8; display: flex; align-items: center;
            .avatar {width: 6vw; height: 6vw; margin-right: 2vw; border-radius: 50%; flex-shrink: 0; overflow: hidden; img {width: 100%; height: 100%; display: block;}}
            .name {color: #000; font-size: 3.6vw;}
          }
          .price {flex: 4; text-align: right; color: #000; font-size: 4vw; font-weight: 800;}
        }
      }
    }
    :deep(.van-empty) {padding: 20vw 0;}
  }
}
:deep(.van-dialog__message) {color: #000; font-size: 3.2vw;}
</style>
