import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import components from "./router/modules/components";
import http from "./utils/http.js";
import VueCookies from 'vue-cookies';
import wx from 'weixin-js-sdk';
import "@/assets/css/reset.css";
import xz_fun from "./utils/function.js";

const app = createApp(App);
app.use(store).use(router).use(Vant).use(xz_fun).use(http);
// 注册cookies
app.config.globalProperties.$cookies = VueCookies;
//重新定义vue3中的this
const _this = app.config.globalProperties;
//判断是左滑进入还是右滑出去
_this.isBack = false;
//设置域名地址
_this.http = "12312312";

//全局注册components中的组件组件
Object.keys(components).forEach((item) => {
  app.component(item, components[item]);
});

// 设置cookies(品牌外链使用)
let geturl = window.location.href;
let getqyinfo = geturl.split('?')[1];
let getqys = new URLSearchParams('?' + getqyinfo)  //将参数放在URLSearchParams函数中
let merId = getqys.get("merchantId")  // 品牌ID  
let wxcode = getqys.get("wxcode")  // 品牌ID  
if (merId) {
  $cookies.set("merchantId", merId);
}
if (wxcode) {
  $cookies.set("wxcode", wxcode);
}
// 小程序专用
let ua = navigator.userAgent.toLowerCase();
if (ua.match(/MicroMessenger/i) == "micromessenger") {
  wx.miniProgram.getEnv(res => {
    if (res.miniprogram) {
      $cookies.set("minId", 1);
    } else {
      $cookies.remove("minId");
    }
  })
} else {
  $cookies.remove("minId");
}

app.mount("#app");
