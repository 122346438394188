<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="提现" left-arrow @click-left="xz_go('wallet_detailb')" right-text="管理银行卡" @click-right="$router.push('/bankb_list')" :border="false" z-index="1000" class="nav_color" />
    <div style="height: 2vw"></div>
    <!-- //输入充值数量 -->
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="提现金额"><template #value><div class="tx">提现服务费{{ info.fee }}%</div></template></van-cell></div>
      <div style="height: 5vw"></div>
      <van-cell-group inset><van-field label="¥" v-model="number" type="number" clearable placeholder="请输入金额" /></van-cell-group>
      <div style="height: 2vw"></div>
      <div class="text"><div>账户可提现：{{ parseFloat(balance).toFixed(2) }}</div> <div class="green" @click="toAll()">全部提现</div></div>
    </div>
    <div style="height: 8vw"></div>
    <van-radio-group v-model="radio" class="van_group">
      <van-cell-group :border="false">
        <div class="vcell" v-for="(item, index) in banklist" :key="index">
          <van-cell :title="item.linked_brbankname" clickable @click="toCheck(index, item)">
            <template #right-icon>
              <van-radio name="index">
                <template #icon>
                  <div class="icono" v-if="radio == index"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div>
                </template>
              </van-radio>
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </van-radio-group>
    <div class="input_box">
      <div class="cell"><van-cell :icon="require('@/assets/img/qb_icon.png')" title="提现须知" /></div><div style="height: 2vw"></div><div class="text">{{ info.info }}</div>
    </div>
    <div style="height: 8vw"></div>
    <div class="btn_box"><van-button size="large" type="success" :disabled="disabled" @click="toCase">确认提现</van-button><!--<div class="text">工作日T+1到账</div>--></div>
    <van-dialog v-model:show="show" show-cancel-button class="code" @confirm="toSubmit">
      <div class="code_tit">验证码</div>
      <van-cell-group inset class="input"><van-field v-model="captcha" placeholder="请输入验证码" /></van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      userId: '',
      bank_name: '',
      cardNo: '',
      balance: '',
      info: {},
      number: '',
      radio: null,
      banklist: [],
      case_token: '',
      disabled: false,
      password: '',
      randomKey: '',
      show: false,
      captcha: '',
      sms_token: '',
      txn_seqno: ''
    }
  },
  mounted() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://oss-mpay-static.lianlianpay.com/lib/accp-password/v2.0.0.js';
    document.body.appendChild(s);
  },
  created() {
    this.getMemberInfo();
    this.getBanlanceInfo();
    this.getCashNotice();
    this.getBankList();
  },
  methods: {
    // 用户信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => { 
          if (res.code == 1) {
            this.userId = res.data.id;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 可提现余额信息
    getBanlanceInfo() {
      this.$post({
        url: "/app/wallet_lian_lian/userInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            if (res.data.acctinfo_list[0].acct_type == 'USEROWN_AVAILABLE') {
              this.balance = res.data.acctinfo_list[0].amt_balaval;
            } else if (res.data.acctinfo_list[1].acct_type == 'USEROWN_AVAILABLE') {
              this.balance = res.data.acctinfo_list[1].amt_balaval;
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 全部提现
    toAll() {
      this.number = this.balance;
    },
    // 提现须知
    getCashNotice() {
      this.$get({
        url: "/app/withdraw/getWithdrawInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.info = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 银行卡列表
    getBankList() {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardList",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.banklist = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 选择银行卡
    toCheck(val, item) {
      this.radio = val;
      this.cardNo = item.linked_acctno;
      this.bank_name = item.linked_brbankname;
      console.log("val:", val, "卡号：", this.cardNo, "户名：", this.bank_name);
    },
    // 提现
    toCase() {
      if (this.radio == null) {
        this.$toast("请选择提现银行卡！");
        return;
      } if (this.number == '') {
        this.$toast("请输入提现金额！");
        return;
      } else {
        this.$post({
          url: "/app/wallet_lian_lian/getPasswordElement",
          data: { scene: "cashout_password", amount: this.number, pyee_name: this.bank_name }, // 提现
          success: (res) => {
            if (res.code == 1) {
              this.case_token = res.data;
              this.getLianLianInfo();
            } else {
              this.$toast(res.msg);
            }
          },
          tip: () => {}
        })
      }
    },
    // 连连控件
    getLianLianInfo() {
      lianlianpay.invokePasswordControl({
        passwordScene: 'cashout_password', // 提现
        oidPartner: '2022090107638862', // 商户号
        userId: this.userId, // 用户号
        passwordElementToken: this.case_token, // 向服务端请求生成的 token
        env: 'production', // 默认 production-生产
        styles: { themeColor: '#0E59F0' } // 主题颜色（按钮等）
      }, (res) => {
        if (res.code == '0000') {
          this.password = res.data.password;
          this.randomKey = res.data.randomKey;
          console.log("miam:", this.case_token, res.data);
          this.getCase();
        } else {
          this.$toast(res.msg);
        }
      })  
    },
    // 提现结果
    getCase() {
      this.$post({   
        url: "/app/wallet_lian_lian/withdrawApply",
        data: { acct_no: this.cardNo, amount: this.number, password: this.password, random_key: this.randomKey },
        success: (res) => {
          console.log("提现信息：", res);
          if (res.code == 1) {
            this.sms_token = res.data.token;
            this.txn_seqno = res.data.txn_seqno;
            this.show = true;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 提交
    toSubmit() {
      this.$post({
        url: "/app/wallet_lian_lian/withdrawVerify",
        data: { acct_no: this.cardNo, amount: this.number, verify_token: this.sms_token, verify_sms: this.captcha, txn_seqno: this.txn_seqno },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("提现申请成功！");
            this.$router.push({ path: '/wallet_detailb' })
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.box {--van-nav-bar-text-color: #999;}
:deep(.van-cell:after) { border-bottom: none; }
.input_box {
  align-items: center; margin: 0 auto; padding: 2vw; width: 90vw; background: #fff; --van-cell-text-color: #000; box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);
  .cell { :deep(.van-cell) {padding: 0;  .van-badge__wrapper {display: flex; align-items: center;} } }
  .van-cell-group {
    width: 92%; height: 12vw; margin: 0 4%; background: #F5F5F5;
    :deep(.van-cell__title) {width: 3vw; font-size: 3vw; background: #FF2222; background-clip: text; -webkit-background-clip: text; color: transparent; font-weight: 800;}
    :deep(.van-field__control) {background: #FF2222; background-clip: text; -webkit-background-clip: text; color: transparent; font-weight: 800;}
  }
  .tx {color: #FF2222; font-size: 15px;}
  .text {width: 83vw; margin: 0 auto; color: #000; font-size: 3vw; display: flex; justify-content: space-between; padding-left: 3vw;  .green {color: #2A7AF0; font-size: 3.73vw;} }
}
.van_group {width: 90%; margin: 0 auto; --van-field-input-text-color: #000;
  :deep(.van-radio) {align-items: start;}
  :deep(.van-cell) {width: 100%; padding: 30px 35px; background: #fff; --van-cell-text-color: #000; box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);}
  .vcell {margin-bottom: 50px;}
  .icono {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
  .icont {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;} }
}
//确认按钮
.btn_box {width: 92%; margin: 0 4%; margin-bottom: 3vw; bottom: 0;
  .van-button--success {height: 14.1vw; background: #2A7AF0 ; border-radius: 7.2vw;}
  .text {color: #ff5c5c; font-size: 3vw; text-align: center;}
}
.code {width: 100%; height: auto;}
.code_tit {width: 100%; height: 8vw; line-height: 8vw; margin: 2vw auto; text-align: center; color: #000; font-size: 3.6vw;}
.input {width: 90%; height: 10vw; margin: 0 5%; background: #F5F5F5 ; --van-field-input-text-color: #000;}
</style>
