<template>
  <van-nav-bar title="APP下载" />
  <div class="box">
    <div style="height: 76vh"></div>
    <div class="dwon"><van-button round type="success" class="btn" @click="toDown">立即下载</van-button><a v-show="showTip" href="javascript:;">?</a></div>
    <div class="popPic" v-show="isShow"><img src="~@/assets/img/wx_down.png" alt=""></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false
    }
  },
  created() {},
  methods: {
    // 判断是否为微信内置浏览器打开
    toDown() {
      let ua = window.navigator.userAgent.toLowerCase();
			let isWeixin = ua.match(/MicroMessenger/i); // 微信
			let isIOS = ua.match(/(iPhone|iPod|iPad);?/i); // ios终端
			if (isWeixin == 'micromessenger') {
				this.isShow = true;
			} else if(isIOS) {
				window.location.href = "https://oss.jijispace.com/download/daopai_1226.mobileconfig";
			} else {
				window.location.href = "https://oss.jijispace.com/download/daopai_0221.apk";
			}
    },
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; min-height: 100vh; margin: 0 auto; background: url("~@/assets/img/down_bg.png") no-repeat; background-size: 100% 100%;}
.dwon {
  width: 90%; height: 24vw; margin: 0 5%; 
  .btn {width: 60%; height: 12vw; line-height: 12vw; margin: 0 20%; background: linear-gradient(116deg, #6BF2F6 0%, #B8CBF5 53%, #FDA8F5 100%); color: #fff; font-size: 5vw;}
}
.popPic {width: 100%; min-height: 100vh; margin-top: -100vh; z-index: 2;}
</style>