<template>
  <div class="box">
    <van-nav-bar title="修改昵称" left-arrow right-text="保 存" @click-left="xz_back('member_infor')" @click-right="editNick" :border="false" z-index="1000" />
    <div class="nick"><van-cell-group inset><van-field v-model="nickName" type="text" label="昵 称：" placeholder="请输入昵称" :maxlength="8" clearable /></van-cell-group></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nickName: ''
    };
  },
  created() {
    this.getMemberInfo();
  },
  methods: {
    // 个人信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.nickName = res.data.nickname;
          } else {
            this.$toast(res.msg)
          }
        },
        tip: () => {}
      })
    },
    // 修改昵称
    editNick() {
      if (!this.nickName) {
        return this.$toast("请输入要修改的昵称");
      } 
      if (this.nickName.length > 8) {
        return  this.$toast("昵称长度不能超过8个字符！");
      }
      this.$post({
        url: "/app/member/editNick",
        data: { nickname: this.nickName },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("修改成功");
            this.xz_back("member_infor");
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.box {--van-nav-bar-title-font-size: 4.2vw;	--van-nav-bar-text-color: #6F6F6F;}
.nick {width: 96%; height: 10vw; line-height: 10vw; margin: 5vw 2%; --van-field-input-text-color: #000;}
</style>