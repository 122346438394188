<template>
  <div class="box">
    <van-nav-bar title="转赠记录" left-arrow @click-left="xz_back" :border="false" class="white" />
    <div class="tabs">
      <div :class="tabIndex == 0 ? 'tab_act' : ''" @click="changeTab(0)" class="tab_opt">接收记录</div>
      <div :class="tabIndex == 1 ? 'tab_act' : ''" @click="changeTab(1)" class="tab_opt">赠送记录</div>
    </div>
    <div v-if="tabIndex == 0">
      <div class="record" v-for="(item, index) in acceptList" :key="index">
        <div style="height: 2vw"></div>
        <div class="rec_lt"><img :src="item.product_image" class="img" /></div>
        <div class="rec_rt">
          <div class="name"><div class="nl">{{ item.product_name }}</div><div class="nr">x1</div></div>
          <span class="brand">{{ item.mer_name }}</span>
          <div class="send">赠送人：{{ item.nickname }}（{{ item.phone }}）</div>
          <div class="times">赠送时间：{{ item.create_time }}</div>
        </div>
      </div>
      <div class="empty" v-if="acceptList.length == 0"><van-empty description="暂无接受记录" /></div>
    </div>
    <div v-if="tabIndex == 1">
      <div class="record" v-for="(item, index) in sendList" :key="index">
        <div style="height: 2vw"></div>
        <div class="rec_lt"><img :src="item.product_image" class="img" /></div>
        <div class="rec_rt">
          <div class="name"><div class="nl">{{ item.product_name }}</div><div class="nr">x1</div></div>
          <span class="brand">{{ item.mer_name }}</span>
          <div class="send">接受人：{{ item.nickname }}（{{ item.phone }}）</div>
          <div class="times">赠送时间：{{ item.create_time }}</div>
        </div>
      </div>
      <div class="empty" v-if="sendList.length == 0"><van-empty description="暂无赠送记录" /></div>
    </div>
    <div style="height: 5vw;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      acceptList: [],
      sendList: []
    }
  },
  created() {
    this.changeTab(0);
  },
  methods: {
    // 选项卡切换
		changeTab(index){
			this.tabIndex = index; 
      if (this.tabIndex == 0) {
        this.getAcceptList();
      } else {
        this.getSendList();
      }
		},
    // 接受记录
    getAcceptList() {
      this.$get({
        url: "/app/transfer_log/to",
        data: { page: 1 },
        success: (res) => {
          if (res.code == 1) {
            this.acceptList = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 赠送记录
    getSendList() {
      this.$get({
        url: "/app/transfer_log/from",
        data: { page: 1 },
        success: (res) => {
          if (res.code == 1) {
            this.sendList = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; margin: 0 auto; background: #F7F7F9;}
.white {background: #fff;}
// 选项卡
.tabs {width: 100%; height: 12vw; margin: 0 auto; background: #fff; display: flex; flex-wrap: nowrap; flex-direction: row; align-items: center;}
.tab_opt {width: 46%; height: 12vw; line-height: 12vw; margin: 0 2%; text-align: center; color: #B9B9B9; font-size: 4vw;}
.tab_act {color: #000; font-size: 4.2vw; font-weight: 500; border-bottom: #F59A23 solid 1px;}
// 记录
.record {
  width: 94%; height: auto; margin: 4vw 3%; background: #fff; display: flex;
  .rec_lt {flex: 2.4; height: 26vw; text-align: center; margin: 2vw 1vw 1.2vw 1vw; .img {width: 25vw; height: 25vw; vertical-align: middle;}}
  .rec_rt {
    flex: 5.6; height: 26vw; color: #000; margin: 2vw 1vw 1.2vw 1vw;
    .name {
      width: 96%; height: 5vw; line-height: 5vw; margin: 0 2%; display: flex; justify-content: space-between; align-items: center;
      .nl {flex: 6; margin: 1.2vw 2%; color: #000; font-size: 4.15vw; font-weight: 600;} .nr {flex: 2; text-align: right; font-size: 4vw; color: #999;}
    } 
    .brand {height: 5vw; line-height: 5vw; margin: 0 2%; background: #FFFAF5; color:#FC9C32; font-size: 3vw; padding: 0.5vw 2vw;}
    .send {width: 96%; height: 5vw; line-height: 5vw; margin: 1.2vw 2%; color: #333; font-size: 3.6vw;}
    .times {width: 96%; height: 5vw; line-height: 5vw; margin: 1.2vw 2%; color: #666; font-size: 3.2vw;}
  }
}
.empty {width: 94%; height: auto; margin: 4vw 3%; text-align: center; background: #fff;}
</style>