<template>
  <div class="box">
    <van-nav-bar :title="title" left-arrow @click-left="toBack" :border="false" z-index="1000" />
    <div style="height: 15vw;"></div>
    <div class="top"><van-image width="120" height="120" :src="require('@/assets/img/succes_pay.png')" rel="external nofollow" /></div>
    <div class="foot"><div class="bot_lt" @click="toOpen">开启盲盒</div><div class="bot_rt" @click="toBlind">返 回</div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '支付成功',
      ordersn: '',
      boxId: ''
    }
  },
  created() {
    this.ordersn = this.$route.query.ordersn;
    this.boxId = this.$route.query.boxId;
    this.backTop();
  },
  methods: {
    // 返回顶部
    backTop () {
      document.documentElement.scrollTop = 0;
    },
    // 返回列表
    toBack() {
      this.$router.push({ path: "/blind_list" })
    },
    // 开启盲盒
    toOpen() {
      this.$router.push({ path: '/details', query: { id: this.boxId, type: 5, ordersn: this.ordersn, is_open: 0 } })
    },
    // 返回我的盲盒
    toBlind() {
      this.$router.push({ path: "/mybox" })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; margin: 0 auto;}
.top {width: 90%; height: 40vw; line-height: 40vw; margin: 2vw 5%; text-align: center;}
.bot {
  width: 96%; height: 15vw; margin: 5vw 2%;
  :deep(.van-button--default) {width: 92%; height: 12vw; margin: 0.5vw 4%; text-align: center; background: linear-gradient(180deg, #FFCF4E 0%, #FF1F1F 100%); color: #fff; border-radius: 3vw;}
}
.foot {
  width: 90%; height: 15vw; line-height: 15vw; margin: 10vw 5%; display: flex;
  .bot_lt {width: 40%; height: 12vw; line-height: 12vw; margin: 0 auto; text-align: center; background: linear-gradient(180deg, #FFCF4E 0%, #FF1F1F 100%); color: #fff; border-radius: 2vw;}
  .bot_rt {width: 40%;; height: 12vw; line-height: 12vw; margin: 0 auto; text-align: center; background: #1989FA; color: #fff; border-radius: 2vw;}
  .btn {flex: 1; height: 12vw; border: #000 solid 1px;}}
</style>