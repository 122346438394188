<template>
  <div class="box">
    <van-nav-bar title="能源加工厂" left-arrow @click-left="xz_go('mine')" @click-right="toRecrod" :border="false" >
      <template #right><van-icon name="description" size="20" /></template>
    </van-nav-bar>
    <div class="banner" @click="toRule"><img src="~@/assets/img/mall_banner.png" class="img"></div>
    <div class="mid">
      <div class="mid_box" v-for="(item, index) in mallList" :key="index">
        <div class="mid_img">
          <img :src="img_url1" class="img_pro" v-if="index == 0" /><img :src="img_url2" class="img_pro" v-if="index == 1" />
          <img :src="img_url3" class="img_pro" v-if="index == 2" /><img :src="img_url4" class="img_pro" v-if="index == 3" />
        </div>
        <div class="mid_name">{{ item.name }}</div>
        <div class="mid_num"><div class="mn_lt">{{ item.people }} 人参与</div><div class="mn_rt">{{ item.num }} 件</div></div>
        <div class="mid_opt"><van-button round class="btn" @click="toConver">兑 换</van-button></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img_url1: require("@/assets/img/mall_pro01.png"),
      img_url2: require("@/assets/img/mall_pro02.png"),
      img_url3: require("@/assets/img/mall_pro03.png"),
      img_url4: require("@/assets/img/mall_pro04.png"),
      mallList: [{ id: 0, name: 'iphone14 pro', people: 0, num: 15 }, { id: 1, name: 'Macbook 15.4英…', people: 0, num: 30 }, 
      { id: 2, name: 'SWTCH', people: 0, num: 200 }, { id: 3, name: 'Watch Series8', people: 0, num: 100 }]
    };
  },
  created() {
    
  },
  methods: {
    // 兑换记录
    toRecrod() {
      this.$toast("兑换记录暂未开放！")
    },
    // 兑换规则
    toRule() {
      this.$toast("积分商城暂未开放！")
    },
    // 兑换
    toConver() {
      this.$toast("兑换暂未开放！")
    }
  },
};
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; margin: 0 auto; background: url("~@/assets/img/mall_bg.png") no-repeat; background-size: 100% 100%; padding-bottom: 5vw;}
.banner {width: 94%; height: 36vw; margin: 0 3%; text-align: center; .img {width: 100%; height: 100%; vertical-align: middle;}}
.mid {
  width: 94%; height: auto; margin: 2vw 3%; display: flex; flex-wrap: wrap; justify-content: space-between; align-content: space-between;
  .mid_box {
    width: 48%; height: 68vw; margin: 2vw 1%; background: #fff; border-radius: 3.2vw;
    .mid_img {width: 40vw; height: 40vw; margin: 1vw auto; text-align: center; .img_pro {width: 100%; height: 100%; vertical-align: middle;}}
    .mid_name {width: 90%; height: 6vw; line-height: 6vw; margin: 1vw 5%; color: #000; font-size: 4.2vw; font-weight: 500;}
    .mid_num {
      width: 90%; height: 5vw; margin: 2vw 5%; display: flex; color: #000;
      .mn_lt {flex: 1; height: 5vw; line-height: 5vw; font-size: 3.2vw;}
      .mn_rt {flex: 1; height: 5vw; line-height: 5vw; text-align: right; font-size: 3.2vw;}
    }
    .mid_opt {
      width: 90%; height: 5.6vw; line-height: 5.6vw; margin: 4vw 5%; 
      .btn {width: 50%; height: 5vw; line-height: 5vw; text-align: center; color: #F33C1C; font-size: 3.2vw; border: #F33C1C solid 1px; margin-left: 50%;}
    }
  }
}
</style>
