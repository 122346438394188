<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="隐私协议" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div class="list"><div class="item" v-html="info"></div></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info: "",
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$get({
        url: "/app/auth/privacy",
        success: (res) => {
          this.info = res.data.privacy_explain
          console.log(this.info, 88488)
        },
        tip: () => {},
      })
    },
    //保存
    save() {
      this.xz_set("checked", true)
      this.xz_back()
    },
  },
}
</script>
<style lang="scss" scoped>
.list {padding: 2vw; color: #000;}
.item {min-height: 100vh; padding: 2vw; font-size: 3vw; overflow: auto;}
</style>
