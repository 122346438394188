<template>
  <div class="box">
    <van-nav-bar title="立即购买" left-arrow @click-left="xz_back" :border="false" z-index="1000" class="white" />
    <div class="top" v-if="mark.ess_temp_name"><div class="top_tit">此藏品购买支付完成后须签署《{{ mark.ess_temp_name }}合同》，若拒签，将无法进行其余任何操作！</div></div>
    <div style="height: 2vw"></div>
    <div class="time" v-if="mark.status == 0">
      <div class="timeo">
        <div class="times">付款时间倒计时：</div>
        <div class="down">
          <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒">
            <template #default="timeData">
              <span class="block">{{ timeData.hours >= 10 ? timeData.hours : "0" + timeData.hours }}</span><span class="colon">:</span>
              <span class="block">{{ timeData.minutes >= 10 ? timeData.minutes : "0" + timeData.minutes }}</span><span class="colon">:</span>
              <span class="block">{{ timeData.seconds >= 10 ? timeData.seconds : "0" + timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="listo">
        <div class="cells"><van-cell title="存储地址"><template #value><div>{{ geTel(info) }}</div></template></van-cell></div>
        <div class="cell"><van-cell title="藏品类型" :value="form" /></div>
        <div class="div"></div>
        <div class="good">
          <div class="good_tabs"><div class="title">• <span>藏品名称</span></div></div>
          <div class="card" v-if="mark && mark.product">
            <van-card :thumb="mark.product.product_image"><template #title><div class="title">{{ mark.product.product_name }}</div></template></van-card>
          </div>
        </div>
        <div class="div"></div>
        <div class="money"><div class="moneyo">应付金额</div><div class="moneyt">¥{{ mark.price }}</div></div>
        <div class="div"></div>
        <div class="select">
          <div class="selecto">服务须知</div>
          <div class="selectt">
            <van-checkbox v-if="mark && mark.product" v-model="checked" shape="square">
              <div v-html="txt"></div>
              <template #icon><div v-if="checked" class="icon"><img src="~@/assets/img/xuanz.png" /></div><div v-else class="icons"></div></template>
            </van-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="div"></div>
    <div class="pay">
      <div class="pay_tit">支付方式</div>
      <div class="pay_con">
        <van-radio-group v-model="radio" class="van_group">
          <van-cell-group :border="false">
            <van-cell title="" clickable @click="toRadio(3)" v-if="mark.wallet_a == 0">
              <template #icon><div class="left"><div class="img"><img src="~@/assets/img/wallet01.png" /></div></div><div>余额钱包</div></template>
              <div class="pay_h">由平台方提供服务&nbsp;</div>
              <template #right-icon>
                <van-radio name="2">
                  <template #icon>
                    <div class="icono" v-if="radio == 3"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div>
                  </template>
                </van-radio>
              </template>
            </van-cell>
            <van-cell title="" clickable @click="toRadio(5)" v-if="mark.wallet_b == 1">
              <template #icon><div class="left"><div class="img"><img src="~@/assets/img/wallet02.png" /></div></div><div>连连钱包</div></template>
              <div class="pay_h">连连支付提供服务&nbsp;</div>
              <template #right-icon>
                <van-radio name="5">
                  <template #icon><div class="icono" v-if="radio == 5"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
                </van-radio>
              </template>
            </van-cell>
            <van-cell title="" clickable @click="toRadio(6)" v-if="mark.wallet_c == 1">
              <template #icon><div class="left"><div class="img"><img src="~@/assets/img/wallet01.png" /></div></div><div>统统支付</div></template>
              <div class="pay_h">统统支付提供服务&nbsp;</div>
              <template #right-icon>
                <van-radio name="6">
                  <template #icon><div class="icono" v-if="(radio == 6)"><img src="~@/assets/img/xuanz.png" /></div><div class="icont" v-else><img src="~@/assets/img/op04.png" /></div></template>
                </van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div class="tips" v-if="mark.wallet_c == 1">温馨提示： 统统支付将于3月22日起终止服务</div>
        <div style="height: 1vw" v-if="mark.wallet_c == 1"></div>
      </div>
    </div>
    <div class="div"></div>
    <div class="set" v-if="pwd_show"><van-field v-model="password" type="password" placeholder="请输入支付密码" /></div>
    <div style="height: 35vw"></div>
    <div class="bot">
      <div class="btn_box">
        <div class="btb_lt">￥ {{ mark.price }}</div>
        <div class="btb_rt"><van-button type="success" :disabled="disabled" :loading="load" @click="selectPay()">立即支付</van-button></div>
      </div>
    </div>
    <div class="popup">
      <van-popup v-model:show="show" :close-on-click-overlay="false">
        <div class="popupo">
          <div class="buy">购买成功</div><div class="buyo"><img src="~@/assets/img/set01.png" /></div>
          <div class="buys"><van-button size="large" type="success" :disabled="disabled" @click="open">前往查看订单</van-button></div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import returnUrl from '@/utils/return_url.js';
export default {
  data() {
    return {
      userId: '',
      checked: true,
      getTimes: "", // 倒计时
      time: "",
      radio: 0,
      password: "",
      load: false,
      show: false,
      mark: {},
      txt: '',
      ordersn: this.$route.query.ordersn,
      form: this.$route.query.form,
      id: this.$route.query.id,
      disabled: false,
      info: "",
      pwd_show: true,
      is_app: false,
      // pwd_show: false
    }
  },
  created() {
    this.getMarkInfo()
    this.getUserInfo()
  },
  methods: {
    //市场藏品订单详情
    getMarkInfo() {
      this.$get({
        url: "/app/rent_orders/orderInfo",
        data: { order_sn: this.ordersn },
        success: (res) => {
          this.userId = res.data.id;
          this.mark = res.data;
          this.txt = res.data.purchase_info.replace(/\n/g,'<br>');
          let date = Date.now();
          this.time = res.data.pay_end * 1000 - date;
        },
        tip: () => {}
      })
    },
    // 个人信息
    getUserInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          this.info = res.data.eth_address;
        },
        tip: () => {},
      })
    },
    // 选择支付方式
    toRadio(val) {
      this.radio = val;
      if (this.radio == 3) {
        this.pwd_show = true
      } else {
        this.pwd_show = false
      }
    },
    // 支付转跳
    selectPay() {
      if (!this.radio || this.radio == 0) {
        return this.$toast("请选择支付方式!")
      } 
      if (this.radio == 3) {
        if (!this.password) return this.$toast("请输入支付密码")
        this.load = true
        this.getAiiPay();
      } else if (this.radio == 5) {
        this.getLianlianPay();
      } else if (this.radio == 6) {
        this.getTongtongPay();
      }
    },
    // 支付宝支付
    getAiiPay() {
      let return_url = returnUrl.baseUrl + "#/markets?jump=page";
      this.$get({
        url: "/app/rent_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: this.radio, return_url: return_url },
        success: (res) => {
          setTimeout(() => {
            this.$router.push({ path: "/optorder", query: { ordersn: this.ordersn, type: 3, radio: this.radio } })
            this.load = false
          }, 2000)
        },
        tip: () => { this.load = false },
      })
    },
    // 连连支付
    getLianlianPay() {
      let return_url = returnUrl.baseUrl + "#/markets?jump=page";
      this.$get({
        url: "/app/rent_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: this.radio, return_url: return_url },
        success: (res) => {
          console.log("连连：", res);
          window.location.href = res.data.gateway_url;
        },
        tip: () => {},
      })
    },
    // 统统支付
    getTongtongPay() {
      let return_url = returnUrl.baseUrl + "#/markets?jump=page";
      this.$post({
        url: "/app/rent_orders/pay",
        data: { order_sn: this.ordersn, password: this.password, pay_type: this.radio, return_url: return_url },
        success: (res) => {
          console.log("统统：", res);
          window.location.href = res.data.redirect_url;
        },
        tip: () => {},
      })
    },
    // 截取字符串
    geTel(info) {
      if (info) {
        return ( info.substring(0, 10) + "***********" + info.substr(info.length - 10) );
      } else {
        return ''
      }
    },
    //支付
    open() {
      if (!this.radio) return this.$toast("请选择支付方式")
      this.$post({
        url: "/app/rent_orders/pay",
        data: { order_sn: this.ordersn, pay_type: this.radio },
        success: (res) => {
          if (res.code == 1) {
            this.xz_go("apli", { order_sn: this.ordersn, type: 1, radio: this.radio })
          } 
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style scoped lang="scss">
.box {position: relative; width: 100%; height: auto; background: #F7F7F9;}
.white {background: #FFFFFF;}
.top {
  width: 100%; height: 12vw; margin: 0 auto; background: #FFE8E8; .top_tit {width: 94%; height: 12vw; line-height: 1.5; margin: 0 3%; color: #FF2222; font-size: 3.2vw; padding-top: 1.5vw;}
}
.time {
  width: 94%; margin: 0 auto; margin-bottom: 10px; 
  .timeo {
    display: flex; justify-content: space-between; background: #FFF; padding: 22px; border-radius: 6px;
    .times {line-height: 1.8; font-size: 4vw; font-weight: 400; color: #000; padding-left: 5px;}
    .down { .block {background: #FF2222; color: #FFFFFF;  margin-right: 5px; padding: 6px 10px; border-radius: 6px;} .colon {color: #000; margin-right: 5px;} }
  }
}
.list {
  padding: 0 20px;
  .listo {
    background: #fff; padding: 10px 0; --van-cell-text-color: #000;
    .cells { /deep/ .van-cell__value {font-size: 24px; font-weight: 400; color: #000; flex: none;} }
    /deep/ .van-cell__value {font-size: 24px; font-weight: 400; color: #000;}
    .line {width: 618px; margin: 0 auto;}
    .good {
      margin-bottom: 20px;
      .good_tabs {padding-left: 14px; margin-bottom: 15px;  .title { span {border-bottom: 1px solid #fff; display: inline-block;} } }
      .card {
        padding-bottom: 30px; .title {font-size: 32px; font-weight: 500; color: #000; padding-left: 20px;}  :deep(.van-card) {background: transparent;}
        .price {font-size: 20px; font-weight: 400; color: #000; border: 1px solid #fff; padding: 5px 14px; margin-left: 20px;}
      }
    }
    .money {
      display: flex; justify-content: space-between; padding: 20px 20px; align-items: center; background: #fff;
      .moneyo {font-size: 4vw; font-weight: 500; color: #000;}
      .moneyt {font-size: 48px; font-weight: 500; background: #fff; color: #000; font-size: 4.2vw; font-weight: 600;}
    }
    .select {
      padding: 24px 20px;
      .selecto {font-size: 4vw; font-weight: 500; color: #000;}
      .selectt {
        padding-top: 10px; font-size: 3.5vw; :deep(.van-checkbox) {overflow: visible; align-items: flex-start;  .van-checkbox__label {line-height: 1.2;}}
        .icon {width: 28px; height: 28px; margin-top: 5px; img {width: 100%; height: 100%; object-fit: cover;}}
        .icons {width: 28px; height: 28px; border: 1px solid #fff; margin-top: 5px;}
      }
    }
  }
}
.pay {width: 94%; height: auto; margin: 0 3%; background: #fff;}
.pay_tit {width: 94%; height: 10vw; line-height: 10vw; margin: 10px 3%; color: #000; font-size: 4vw; font-weight: 600;}
.pay_h {width: 100%; color: #FC9C32; font-size: 2.6vw;}
.set {width: 94%; background: #fff; color: #000; margin: 0 auto; --van-field-label-color: #000; --van-field-input-text-color:#000;	}
.div {width: 100%; height: 1vw; background: #F7F7F9;}
.van_group {
  width: 100%; background: #FFF; --van-cell-text-color: #000; margin: 0 auto; :deep(.van-cell) {padding: 30px 35px; align-items: center;}
  .left {width: 56px; height: 56px; margin-right: 3vw; img {width: 100%; height: 100%;} }
  .icono {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;}}
  .icont {width: 28px; height: 28px; img {width: 100%; height: 100%; object-fit: cover;}}
}
.bot {
   width: 100%; height: 18vw; margin: 0 auto; background: #fff; position: fixed; bottom: 0; z-index: 9999;
  .btn_box {
    width: 96%; height: 12vw; margin: 3vw 2% 0 2%; display: flex; flex-wrap: nowrap; flex-direction: row; 
    .btb_lt {width: 60%; height: 12vw; line-height: 12vw; margin: 0 auto; color: #000; font-size: 5vw; font-weight: 600;}
    .btb_rt {
      width: 40%; height: 12vw; line-height: 12vw; margin: 0 auto; text-align: right; 
      .van-button--success {width: 100%; height: 12vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); border-radius: 2.2vw;}
    }
  }
}
.popup {
  :deep(.van-popup) {background: transparent; border: none;}
  .popupo {width: 618px; height: 658px; background-image: url("~@/assets/img/s08.png"); background-size: 618px 658px; background-repeat: no-repeat; text-align: center;
    .buy {font-size: 32px; font-weight: 400; color: #333; padding-top: 58px; padding-bottom: 46px;}
    .buyo {width: 298px; height: 286px; margin: 0 auto; img {width: 100%; height: 100%; object-fit: cover;} }
    .buys {padding: 30px 60px 0; .van-button--success {background: url(~@/assets/img/set02.png); background-size: 100% 100%; overflow: hidden; background-position: 0px 0px; color: #fff;}}
  }
}
.tips {width: 90%; height: 5vw; line-height: 5vw; margin: 2vw 5%; color: #EF1111; font-size: 3.2vw; font-weight: 600;}
</style>
