<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="我的藏品" :border="false" z-index="1000" />
    <div style="height: 2vw"></div>
    <div class="tab_box">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad" :offset="10">
          <div class="good_box">
            <div class="good_list">
              <div class="item" v-for="(item, index) in collectList" :key="index" @click="toCollectList(item)">
                <div class="img"><img :src="item.goods.product_image + '?x-oss-process=image/resize,w_300/quality,q_90'" /></div>
                <div class="name one">{{ item.goods.product_name }}</div>
                <div class="id">持有个数： {{ item.holdQuantity }}</div>
              </div>
            </div>
          </div> 
        </van-list>
      </van-pull-refresh>
      <div class="empty" v-if="collectList.length == 0"><van-empty description="暂无藏品" /></div>
    </div>
    <div style="height: 5vw"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      collectList: [],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 0,
      limit: 10,
      total: 0, // 总页数
      img: '../../assets/img/empty.png'
    }
  },
  created() {},
  methods: {
    // 上拉加载
    onLoad() {
      this.pageIndex += 1;
      setTimeout(this.getCollectList(this.pageIndex, 10), 1000)
    },
    // 下拉刷新
    onRefresh () {
      this.pageIndex = 0;
      this.collectList = [];
      this.loading = true;
      this.isLoading = true;
      this.finished = false;
      setTimeout(this.onLoad(), 1000)
    },
    // 藏品列表
    getCollectList(page, limit) {
      this.$get({
        url: "/app/member_space/lists",
        data: { page: page, limit: limit },
        success: (res) => {
          if (res.code == 1) {
            this.isLoading = false;
            this.loading = false;
            if (res.data.data.length == 0) {
              this.finished = true; // 没有数据的话，加载完成
            } else {
              this.collectList = this.collectList.concat(res.data.data);
              this.total = res.data.total;
              if (this.collectList.length >= this.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            }
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => { }
      })
    },
    // 藏品列表
    toCollectList(item) {
      this.$router.push({ path: '/house_list', query: { id: item.product_id, name: item.product_name, from: item.from } })
    },
  }
}
</script>

<style lang="scss" scoped>
.box {min-height: 100vh;}
:deep(.van-pull-refresh) {min-height: 0;}
.tab_box {width: 100%; min-height: 100vh; margin: 0 auto; background: #F5F5F5; --van-tabs-bottom-bar-width: 180vw; --van-tabs-bottom-bar-height: 12vw; 
:deep(.van-tab) {z-index: 10; width: 100%; margin-bottom: 30vw;} :deep(.van-tabs__line) {top: 0;}}
.good_box {width: 100%; height: auto; padding: 2vw 0; }
.good_list {width: 94%; margin: 0 3%; display: flex; justify-content: space-between; flex-wrap: wrap; position: relative; margin-bottom: 30vw;
  .item {padding: 1vw; width: 45vw; margin-bottom: 3vw; position: relative; background: #fff;
    .buy {position: absolute; transform: translate(0, 0); background: rgba(255, 255, 255, 0.5); font-size: 28px; font-weight: 400; color: #db0601; padding: 0 20px;}
    .img {width: 43vw; height: 40vw; img {width: 100%; height: 100%; object-fit: cover;}}
    .name {line-height: 9vw; color: #000; font-size: 4vw; padding: 1vw 2vw;}
    .id {width: 60%; height: 5vw; line-height: 5vw; background: #FFF7EA; color: #FF9E19; font-size: 3vw; margin-left: 5%;}
  }
}
.empty {width: 100%; height: auto; margin: 0 auto; margin-top: -30vw;}
</style>
