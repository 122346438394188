<template>
  <div class="box">
    <van-nav-bar title="个人信息" left-arrow @click-left="xz_back('set')" z-index="1000" />
    <div style="height: 2vw"></div>
    <div class="nav">
      <van-cell-group :border="false">
        <div class="cell">
          <van-cell :border="false" title="头像" is-link>
            <template #value>
              <van-uploader :after-read="up_img_fun" >
                <div class="value"><van-image round :width="32" :height="32" :src="headimgurl" v-if="headimgurl"></van-image><van-image round :width="32" :height="32" :src="info.head_img" v-else /></div>            
              </van-uploader>
            </template>
          </van-cell>
        </div>
        <div class="div"></div>
        <van-cell :border="false" title="昵称" is-link to="nickEdit"><template #value><div class="value">{{ info.nickname }}</div></template></van-cell>
        <div class="div"></div>
        <van-cell :border="false" title="手机号" is-link><template #value><div class="value">{{ info.phone }}</div></template></van-cell>
        <div class="div"></div>
        <van-cell :border="false" title="区块链地址" is-link><template #value><div class="value one">{{ info.eth_address }}</div></template></van-cell>
        <div class="div"></div>
        <!-- <div class="div"></div><van-cell :border="false" title="我的收款账户" is-link @click="paygo()"></van-cell>
        <div class="div"></div><van-cell :border="false" title="注销账号" is-link @click="ashow = true"></van-cell> -->
      </van-cell-group>
    </div>
    <div class="dia"><van-dialog v-model:show="show" message="欢迎来到道拍，根据我国相关法律法规，您必须进行实名认证后才可体验本APP完整内容" show-cancel-button></van-dialog></div>
    <div class="popups">
      <van-popup v-model:show="ashow">
        <div class="popbg">
          <div class="popinduct">账号注销注意事项</div>
          <div class="wenzi" v-html="cancel"></div>
          <div class="btn">
            <div class="btno"><van-button type="primary" size="small" @click="logout">申请注销</van-button></div>
            <div class="btnt"><van-button @click="ashow = false" type="primary" size="small">取消</van-button></div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: '',
      name: "",
      headimgurl: "",
      shows: false,
      show: false,
      ashow: false
    }
  },
  created() {
    this.getMemberInfo();
    this.getCancel();
  },
  methods: {
    // 个人信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          this.info = res.data
          console.log("个人信息:", this.info)
        },
        tip: () => {}
      })
    },
    // 上传图片
    up_img_fun(file) {
      var formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/app/tool/uploadImage",
        data: formData,
        upload: true,
        success: (res) => {
          console.log(res.data)
          this.$post({
            url: "/app/member/editHead",
            data: { path: res.data },
            success: (res) => {
              this.headimgurl = res.data.path
            },
          })
        },
      })
    },
    // 注销信息
    getCancel() {
      this.$get({
        url: "/app/member/getCancellation",
        data: {},
        success: (res) => {
          this.cancel = res.data
        },
        tip: () => {}
      })
    },
    //注销
    logout() {
      this.$get({
        url: "/app/member/logout",
        success: () => {
          this.$toast("注销成功")
          this.$router.replace("login")
          localStorage.removeItem("token")
        },
        tip: () => {},
      })
    },
    // 取消
    cancel() {
      this.shows = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; margin: 0 auto;}
.nav {
  width: 96%; margin: 0 2%; --van-cell-text-color: #000;
  .cell {/deep/ .van-cell { .van-cell__title {padding-top: 10px;} .van-icon {margin-top: 10px;}}  .value {width: 10vw; height: 10vw; img {width: 100%; height: 100%;}}}
}
.div {width: 94%; height: 1px; margin: 0 3%; background: #F7F7F9;}
.dia { :deep(.van-dialog__message) {color: #333;}  :deep(.van-popup) {background: #fff !important;} }
/deep/ .van-popup {background: #121212 !important;}
.popups {
  position: relative;
  /deep/ .van-popup {background: transparent;}
  .popbg {width: 612px; height: 812px; background: #fff; color: #000;}
  .popinduct {font-size: 32px; font-weight: 600; color: #333; padding: 46px 0 50px; text-align: center;}
  .wenzi {height: 462px; font-size: 24px; font-weight: 600; color: #333; padding: 0 60px; overflow: auto; span{text-align: center;}}
  .btn {
    width: 100%; display: flex; justify-content: space-between; position: absolute; bottom: 70px;
    .btno {
      width: 152px; height: 68px; background: #F53F3F; position: absolute; left: 70px; bottom: 0px; border-radius: 1.2vw;
      /deep/ .van-button {background: transparent; padding-left: 34px;}
    }
    .btnt {
      width: 152px; height: 68px; background: #9A999E; position: absolute; right: 70px; bottom: 0px; display: flex; justify-content: center; border-radius: 1.2vw;
      /deep/ .van-button {background: transparent;}
    }
  }
}
</style>