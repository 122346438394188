<template>
  <van-nav-bar title="邀新活动" left-arrow @click-left="xz_go('invite')" :border="false" />
  <div class="box">
    <div class="tabs">
      <div class="tab_opt" :class="tabIndex == 0 ? 'tab_act' : ''" @click="changeTab(0)">全部</div><div class="tab_opt" :class="tabIndex == 1 ? 'tab_act' : ''" @click="changeTab(1)">未开始</div>
      <div class="tab_opt" :class="tabIndex == 2 ? 'tab_act' : ''" @click="changeTab(2)">进行中</div><div class="tab_opt" :class="tabIndex == 3 ? 'tab_act' : ''" @click="changeTab(3)">已结束</div>
    </div>
    <div v-if="tabIndex == 0">
      <div class="brand" v-for="(item, index) in inviteList" :key="index">
        <div class="brand_box" >
          <div class="brand_top"><div class="bt_icon"><img :src="item.mer_image" /><text>{{ item.mer_name }}</text></div><div class="bt_state">{{ item.act_status }}</div></div>
          <div class="brand_mid"><Steps :stepInfo="getStepInfo(item)"></Steps></div>
          <div class="brand_bot">
            <div class="btit">我已邀请 <span>{{ item.account_num }}</span> 人</div><div class="bdate">{{ item.start_time }} 至 {{ item.end_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tabIndex == 1">
      <div class="brand" v-for="(item, index) in inviteList" :key="index">
        <div class="brand_box" >
          <div class="brand_top">
            <div class="bt_icon"><img :src="item.mer_image" /><text>{{ item.mer_name }}</text></div>
            <div class="bt_state">{{ item.act_status }}</div>
          </div>
          <div class="brand_mid"><Steps :stepInfo="getStepInfo(item)"></Steps></div>
          <div class="brand_bot">
            <div class="btit">我已邀请 <span>{{ item.account_num }}</span> 人</div><div class="bdate">{{ item.start_time }} 至 {{ item.end_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tabIndex == 2">
      <div class="brand" v-for="(item, index) in inviteList" :key="index">
        <div class="brand_box" >
          <div class="brand_top">
            <div class="bt_icon"><img :src="item.mer_image" /><text>{{ item.mer_name }}</text></div>
            <div class="bt_state">{{ item.act_status }}</div>
          </div>
          <div class="brand_mid"><Steps :stepInfo="getStepInfo(item)"></Steps></div>
          <div class="brand_bot">
            <div class="btit">我已邀请 <span>{{ item.account_num }}</span> 人</div><div class="bdate">{{ item.start_time }} 至 {{ item.end_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tabIndex == 3">
      <div class="brand" v-for="(item, index) in inviteList" :key="index">
        <div class="brand_box" >
          <div class="brand_top">
            <div class="bt_icon"><img :src="item.mer_image" /><text>{{ item.mer_name }}</text></div>
            <div class="bt_state">{{ item.act_status }}</div>
          </div>
          <div class="brand_mid"><Steps :stepInfo="getStepInfo(item)"></Steps></div>
          <div class="brand_bot">
            <div class="btit">我已邀请 <span>{{ item.account_num }}</span> 人</div><div class="bdate">{{ item.start_time }} 至 {{ item.end_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 20vw"></div>
    <!-- <div class="bot"> <div style="height: 4vw"></div><van-button type="primary" class="bot_btn" @click="xz_go('rankings')">邀新总榜</van-button></div> -->
  </div>
</template>

<script>
import Steps from '@/components/step_invite.vue'
export default {
  components: { Steps },
  data() {
    return {
      tabIndex: 0,
      inviteList: [],
      stepInfo: {}
    };
  },
  created() {
    this.changeTab(0);
  },
  methods: {
    // 选项卡切换
    changeTab(index){
      this.tabIndex = index; 
      this.getBrandList(index);
    },
    // 品牌商活动列表
    getBrandList(id) {
      this.$get({
        url: "/app/member/getPullActivity",
        data: { status: id },
        success: (res) => {
          if (res.code == 1) {
            this.inviteList = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 步骤条
    getStepInfo(item) {
      let real_step = 0;
      if (item.account_num > 0) {
        if (item.first_recommend <= item.account_num && item.account_num < item.second_recommend){
          real_step = 1;       
        } else if (item.second_recommend <= item.account_num && item.account_num < item.third_recommend){
          real_step = 2;
        } else if (item.account_num >= item.third_recommend){
          real_step = 3;
        } 
      }
      return { 
        list: [{ status: '邀请'+ item.first_recommend + '名好友', goodName:  item.first_product_name + '*' + item.first_reward_num, btnClass: this.getReceiveStatus(item, 1)[0], 
          btnName: this.getReceiveStatus(item, 1)[1], lv: this.getReceiveStatus(item, 1)[2],  isRecvice: this.getReceiveStatus(item, 1)[3] }, 
          { status: '邀请'+ item.second_recommend + '名好友', goodName: item.second_product_name + '*' + item.second_reward_num, btnClass: this.getReceiveStatus(item, 2)[0], 
          btnName: this.getReceiveStatus(item, 2)[1], lv: this.getReceiveStatus(item, 2)[2], isRecvice: this.getReceiveStatus(item, 2)[3] }, 
          { status: '邀请'+ item.third_recommend + '名好友', goodName: item.third_product_name + '*' + item.third_reward_num, btnClass: this.getReceiveStatus(item, 3)[0], 
          btnName: this.getReceiveStatus(item, 3)[1], lv: this.getReceiveStatus(item, 3)[2], isRecvice: this.getReceiveStatus(item, 3)[3] }], 
        step: real_step,
        id: item.id
      }
    },
    // 领取状态
    getReceiveStatus(item, lv) {
      let btnClass = ['btn', '立即领取', 0, 0];
      if (item.act_status == '未开始') {
        return ['btn', '未开始', 0, 0];
      } 
      if (item.act_status == '已结束')  {
        return ['btn', '已结束', 0, 0];
      }
      if (item.act_status == '进行中')  {
        if (lv == 1){
          if (item.receive.indexOf('1') >= 0){
            return ['btn', '已领取', 1, 1];
          } else {
            if (item.account_num > 0 && (item.account_num >= item.first_recommend)){
              return ['btn btn_act', '立即领取', 1, 0];
            }
            return ['btn', '立即领取', 1, -1];
          }
        } else if (lv == 2){
          if (item.receive.indexOf('2') >= 0){
            return ['btn', '已领取', 2, 1];
          } else {
            if (item.account_num > 0 && (item.account_num >= item.second_recommend)){
              return ['btn btn_act', '立即领取', 2, 0];
            }
            return ['btn', '立即领取', 2, -1];
          }
        } else if (lv == 3){
          if (item.receive.indexOf('3') >= 0){
            return ['btn', '已领取', 3, 1];
          } else {
            if (item.account_num > 0 && (item.account_num >= item.third_recommend)){
              return ['btn btn_act', '立即领取', 3, 0];
            }
            return ['btn', '立即领取', 3, -1];
          }
        }
      }
      return btnClass;
    },
  }
}
</script>

<style lang="scss" scoped>
.box {width: 100%; min-height: 100vh; margin: 0 auto; background: #F7F7F9;}
.tabs {
  width: 100%; height: 10vw; margin: 0 auto; background: #fff; display: flex; flex-wrap: nowrap; flex-direction: row;
  .tab_opt {flex: 1; width: 25%; height: 10vw; line-height: 10vw; margin: 0 auto; text-align: center; color: #848484; font-size: 4vw;}
  .tab_act {color: #000000; font-size: 4.2vw; font-weight: 600; border-bottom: #FC272E solid 0.5vw;}
}
.brand {width: 100%; height: auto; margin: 4vw auto;}
.brand_box {
  width: 94%; height: 65vw; margin: 0 auto; background: #fff;
  .brand_top {
    width: 96%; height: 10vw; line-height: 10vw; margin: 0 2%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .bt_icon {
      width: 88%; height: 7.4vw; margin: 1.3vw auto; img {width: 7.4vw; height: 100%; vertical-align: middle; border-radius: 50%;}
      text {height: 10vw; line-height: 10vw; margin: 0 auto; text-align: left; color: #000000; font-size: 4vw; font-weight: 500; padding-left: 2.5vw;}
    }
    .bt_state {width: 12%; height: 10vw; line-height: 4; text-align: right; color: #F66D2A; font-size: 3.4vw;}
  }
  .brand_mid {width: 96%; height: 34vw; margin: 3.5vw 2%; background: #FAFAFA; padding: 4vw 0;}
  .brand_bot {
    width: 94%; height: 12VW; line-height: 10vw; margin: 0 3%; 
    .btit {height: 7vw; line-height: 7vw; color: #000000; font-size: 3.5vw; span {color: #F6702E;}}
    .bdate {height: 5vw; line-height: 5vw; color: #9A999E; font-size: 3.2vw;}
  }
}
.bot {
  width: 100%; height: 20vw; line-height: 20vw; margin: 0 auto; background: #fff; position: fixed; bottom: 0; z-index: 9999;
  .bot_btn { width: 90%; height: 12vw; line-height: 12vw; margin: 0 5%; text-align: center; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; font-size: 4vw;
  border-radius: 3vw;}
}
</style>