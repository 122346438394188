<template>
  <router-view v-slot="{ Component }"><transition :name="transitionName"><component :is="Component" /></transition></router-view>
</template>

<script>
import { ref, watch, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "app",
  setup() {
    const route = useRoute();
    const show = ref(true);
    const transitionName = ref("slide-right");
    const instance = getCurrentInstance();
    const _this = instance.appContext.config.globalProperties;
    watch(
      () => route.path,
      () => {
        //监听到变化 to, from
        // console.log(to, "监听路由到变化", from);
        let isBack = _this.isBack;
        //监听页面路由变化
        if (isBack) {
          transitionName.value = "slide-right";
        } else {
          transitionName.value = "slide-left";
        }
        _this.isBack = false;
      }
    );

    return {
      show,
      transitionName,
    };
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {transition: opacity 0.15s;}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {opacity: 0;}
.router-view {width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; margin: 0 auto; overflow-y: auto; overflow-x: hidden; -webkit-overflow-scrolling: touch;}
.slide-right-enter-active, .slide-right-leave-active, .slide-left-enter-active, .slide-left-leave-active {height: 100%; will-change: transform; transition: all 500ms; position: absolute;
backface-visibility: hidden; perspective: 1000;}
.slide-right-enter, .slide-right-enter-active {animation: bounce-left-in 500ms;}
.slide-right-leave-active {animation: bounce-left-out 500ms;}
.slide-left-enter, .slide-left-enter-active {animation: bounce-right-in 500ms;}
.slide-left-leave-active {animation: bounce-right-out 500ms;}

@keyframes bounce-right-in {
  0% {transform: translate3d(100%, 0, 0); opacity: 0;}
  100% {transform: translate3d(0px, 0, 0); opacity: 1;}
}
@keyframes bounce-right-out {
  0% {transform: translate3d(0, 0, 0); opacity: 1;}
  100% {transform: translate3d(-100%, 0, 0); opacity: 0;}
}
@keyframes bounce-left-in {
  0% {transform: translate3d(-100%, 0, 0); opacity: 0;}
  100% {transform: translate3d(0px, 0, 0); opacity: 1;}
}
@keyframes bounce-left-out {
  0% {transform: translate3d(0, 0, 0); opacity: 1;}
  100% {transform: translate3d(100%, 0, 0); opacity: 0;}
}

[v-cloak] {display: none;}

/* 
<!-- Crosslinker Version:  -->
<!-- Video Ingestion Version: -->
<!-- Journey Version: 4.10.3 --> */

/* // 点击遮罩层弹出框动画 */
.van-overlay:active + .van-dialog {animation: dialog 0.1s ease-in;}
@keyframes dialog {
  0%, 100% {transform: translate3d(-50%, -50%, 0) scale(1);}
  50% {transform: translate3d(-50%, -50%, 0) scale(1.1);}
}
</style>
