<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="我的银行卡" left-arrow @click-left="xz_go('wallet_detailb')" right-text="删除" @click-right="$router.push('/del_bank')" :border="false" z-index="1000" class="white" />
    <div style="height: 1vw"></div>
    <van-list finished-text="没有更多了">
      <div class="list">
        <div class="card" v-for="(item, index) in bankList" :key="index" @click="getToken(item)">
          <div class="card_box" :style="getBankBg(item.linked_brbankname)">
            <div class="box_lt"><img :src="getBankIcon(item.linked_brbankname)" class="bank_img" /></div>
            <div class="box_mm"><ul><li class="bank_name">{{ item.linked_brbankname }}</li><li class="bank_type">储蓄卡</li> <li class="bank_no">{{ item.linked_acctno }}</li></ul></div>
            <div class="box_rt">解绑</div>
          </div>
        </div>
      </div>
    </van-list>
    <div style="height: 10vw"></div>
    <div class="btn_box"><van-button size="large" type="success" @click="toAdd">+ 添加银行卡</van-button></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: '',
      page: 1,
      list: [],
      cardNo: '',
      bank_token: '',
      bankList: [],
      password: '',
      randomKey: '',
      card_bg: {"background": "linear-gradient(308deg, #4E69FF 0%, #4490FF 100%)", "opacity": 1}
    }
  },
  mounted() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://oss-mpay-static.lianlianpay.com/lib/accp-password/v2.0.0.js';
    document.body.appendChild(s);
  },
  created() {
    this.getMemberInfo();
    this.getBankList();
  },
  methods: {
    // 获取个人信息
    getMemberInfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          if (res.code == 1) {
            this.userId = res.data.id;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 银行卡列表
    getBankList() {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardList",
        success: (res) => {
          if (res.code == 1) {
            this.bankList = res.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 银行卡图片
    getBankIcon(name) {
      if (name == '中国工商银行') { return require('../../../assets/img/bank01.png') } 
      if (name == '中国农业银行') { return require('../../../assets/img/bank02.png') } 
      if (name == '中国建设银行') { return require('../../../assets/img/bank03.png') } 
      if (name == '邮政银行') { return require('../../../assets/img/bank04.png') } 
      if (name == '招商银行') { return require('../../../assets/img/bank05.png') } 
      return require('../../../assets/img/bank00.png');
    },
    // 银行卡背景色
    getBankBg(name) {
      if (name == '中国工商银行') { return {"background": "linear-gradient(308deg, #FF954E 0%, #FFC44E 100%)", "opacity": 1} } 
      if (name == '中国农业银行') { return {"background": "linear-gradient(128deg, #58DCCF 0%, #39C4D4 100%)", "opacity": 1} } 
      if (name == '中国建设银行') { return {"background": "linear-gradient(308deg, #4E69FF 0%, #4490FF 100%)", "opacity": 1} } 
      if (name == '邮政银行') { return {"background": "linear-gradient(308deg, #4E69FF 0%, #4490FF 100%)", "opacity": 1} } 
      if (name == '招商银行') { return {"background": "linear-gradient(308deg, #FE5A65 0%, #FF907A 100%)", "opacity": 1} } 
      return {"background": "linear-gradient(308deg, #4E69FF 0%, #4490FF 100%)", "opacity": 1};
    },
    // 添加银行卡
    toAdd() {
      this.$router.push({ path:'/add_bankb', query: { userId: this.userId } })
    },
    // 获取绑卡token
    getToken(item) {
      this.$post({
        url: "/app/wallet_lian_lian/getPasswordElement",
        data: { scene: "bind_card_password"},
        success: (res) => {
          if (res.code == 1) {
            this.bank_token = res.data;
            this.getLianLianInfo(item);
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 获取连连支付基础信息
    getLianLianInfo(item) {
      lianlianpay.invokePasswordControl({
        passwordScene: 'bind_card_password', // 换绑卡
        oidPartner: '2022090107638862', // 商户号
        userId: this.userId, // 用户号
        passwordElementToken: this.bank_token, // 向服务端请求生成的 token
        env: 'production', // 默认 production-生产
        styles: { themeColor: '#0E59F0' } // 主题颜色（按钮等）
      }, (res) => {
        console.log('密码数据', res)
        if (res.code == '0000') {
          this.password = res.data.password;
          this.randomKey = res.data.randomKey;
          this.toDelete(item);
        } else {
          this.$toast(res.msg);
        }
      })  
    },
    // 解绑银行卡
    toDelete(item) {
      this.$post({
        url: "/app/wallet_lian_lian/bankCardUnbind",
        data: { acct_no: item.linked_acctno, password: this.password, random_key: this.randomKey },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("解绑成功");
            this.$router.go(0);
          } else {
            this.$toast("解绑失败：", res.msg);
          }
        },
        tip: () => {}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.box {width: 100%; height: auto; background: #F7F7F9;}
.white {background: #fff;}
.list {width: 92%; height: auto; margin: 10px 4%;}
.card {width: 100%; height: auto; margin: 2vw auto; }
.card_box {width: 100%; height: 26vw; margin: 0 auto; padding: 2vw 0; display: flex; border-radius: 2.13vw; }
.box_lt{flex: 2.2; text-align: center;}
.bank_img {width: 14vw; height: 14vw; margin-top: 10px; border-radius: 50%;}
.box_mm {flex: 6.4; width: 100%;}
.bank_name {width: 100%; height: 6vw; line-height: 2; font-size: 3.7vw;}
.bank_type {width: 100%; height: 5vw; line-height: 2; font-size: 3vw;}
.bank_no {width: 90%; height: 10vw; line-height: 2; font-size: 4.4vw;}
.box_rt {flex: 1; width: 15vw; height: 6vw; line-height: 6vw; margin: 0 auto; text-align: center; border: #fff solid 1px; font-size: 3.6vw; margin-right: 3vw; padding: 0 10px;}
.btn_box {position: fixed; width: 92%; margin: 0 4%; bottom: 3vw; .van-button--success {height: 14.2vw; background: #fff; color: #000; border-radius: 2.1vw;}}
</style>
