<template>
  <div class="box">
    <!-- 顶部栏 -->
    <van-nav-bar title="交易密码" left-arrow @click-left="xz_back" :border="false" z-index="1000" />
    <div style="height: 5vw"></div>
    <!-- 表单 -->
    <div class="from_box">
      <van-field clearable v-model="phones" type="tell" maxlength="11" placeholder="请输入手机号" readonly></van-field>
      <van-field clearable v-model="code" type="text" placeholder="请输入验证码">
        <template #button><vant-code url="/app/auth/sendSms" scene="forget_trade_password" :phone="phone"></vant-code></template>
      </van-field>
      <van-field clearable v-model="second_password" :type="second_type" :maxlength="6" :readonly="phone == '' ? true : false" placeholder="请输入6位交易密码" 
       :right-icon="second_type == 'password' ? 'closed-eye' : 'eye'" @click-right-icon="second_type == 'password' ? (second_type = 'text ') : (second_type = 'password')">
      </van-field>
      <div><!--留一个div保证最后一个输入框有下划线--></div>
    </div>
    <div style="height: 8vw"></div>
    <div class="btn_box"><van-button size="normal" type="success" @click="save">确定</van-button></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      phones: "",
      second_password: "",
      code: "",
      pass_type: "password", //input类型
      second_type: "password", //确认密码类型
      readonly: true //只读
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      this.$get({
        url: "/app/member/memberInfo",
        data: {},
        success: (res) => {
          this.phone = res.data.phone
          this.phones = res.data.phone.substring(0, 3) + "****" + res.data.phone.substr(res.data.phone.length - 4)
          console.log(this.info, "个人信息")
        },
        tip: () => {}
      })
    },
    save() {
      if (!this.code) return this.$toast("请输入验证码")
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9_(_（_)_）_,_，_:_：_—_\-_/_\n]+$/.test(this.second_password))
        return this.$toast("不可输入+-和空格和特殊符号")
      if (!this.second_password) return this.$toast("请输入支付密码")
      // 修改密码
      this.$post({
        url: "/app/auth/forgetSecondPassword",
        data: { phone: this.phone, sms_code: this.code, second_password: this.second_password, re_second_password: this.second_password },
        success: () => {
          this.$toast.clear()
          this.$toast("修改成功")
          this.code = ""
          this.second_password = ""
        },
        tip: () => {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.imgg {width: 62vw; height: 47vw; margin: 0 auto;}
//表单
.from_box {width: 100%; padding: 0 2vw; --van-field-input-text-color: #000;}
//单元格
:deep(.van-cell) {margin-bottom: 4vw;}
.btn_box {padding: 3vw; .van-button--success {width: 60%; height: 13vw; margin: 2vw 20%; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); border-radius: 2vw;}}
:deep(.van-field__button) {display: flex;}
</style>
