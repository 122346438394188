<template>
  <div class="box content">
    <!-- 顶部栏 -->
    <van-nav-bar v-if="classifty == 1 || classifty == 6" :title="title" left-arrow @click-left="xz_back" :border="false" z-index="1000" class="bg" />
    <van-nav-bar v-if="classifty == 2 || classifty == 5" title="盲盒开启详情" left-arrow @click-left="xz_back" :border="false" z-index="1000" class="bg" />
    <van-nav-bar v-if="classifty == 3" title="市场详情" left-arrow @click-left="toBackGood" :border="false" z-index="1000" class="bg" />
    <!-- 首发藏品（首页） -->
    <div v-if="classifty == 1">
      <div class="head">
        <img :src="list.product_image" /><div class="head_name">{{ list.product_name }}</div>
        <div class="head_num">
          <div class="hn_box"><div class="hn_lab">发售总量 </div> <div class="hn_num">{{ list.total_num }}份</div></div>
          <div class="hn_box" style="margin-left: 4vw;"><div class="hn_lab1">已销毁</div><div class="hn_num1">{{ list.burned }}份</div></div>
        </div>
      </div>
      <div style="height: 3vw"></div>
      <div class="tsftop2">
        <div class="cell_box">
          <div class="role">
            <div class="avatar"><img :src="list.author_image" /></div><div class="text"><div class="name">{{ list.author_name }}</div><div class="time">创作者</div></div>
          </div>
        </div>
        <div style="height: 2vw"></div>
        <div class="main">
          <div class="h2">作品故事</div>
          <div class="main_con" v-html="list.info"></div>
          <div class="panel_box">
            <div class="panel_list">
              <div class="panel_title">区块信息</div><van-cell title="认证标准" :border="false" value="ERC-721" /><van-cell title="认证网络" :border="false" value="文昌生态链" />
            </div>
            <van-grid direction="horizontal" :column-num="4" icon-size="5vw" :border="false">
              <van-grid-item :icon="require('@/assets/img/shield.png')" text="唯一编号" /><van-grid-item :icon="require('@/assets/img/shield.png')" text="可信记录" />
              <van-grid-item :icon="require('@/assets/img/shield.png')" text="永久存证" /><van-grid-item :icon="require('@/assets/img/shield.png')" text="不可篡改" />
            </van-grid>
          </div>
        </div>
        <div style="height: 3vw"></div>
        <div class="creator"><div class="h1">创作者介绍</div><div class="cre_con" v-html="list.author_info"></div></div>
        <div style="height: 3vw"></div>
        <!-- //发行方 -->
        <div class="issuer">
          <div style="height: 2vw"></div>
          <div class="iu_top">
            <div class="iu_name">{{ list.brand }}</div><div class="iu_more" @click="toBarnd(list.brand_id)">更多品牌作品 <img src="~@/assets/img/go02.png" class="img" /></div>
          </div> 
          <div class="iu_bot" v-html="list.issuer_info"></div>
          <div style="height: 2vw"></div>
        </div>
        <div style="height: 3vw"></div>
        <div class="universe" v-if="list.meta_info"><div class="h1">元宇宙{{ good_tit }}介绍</div><div class="universe_con" v-html="list.meta_info"></div></div>
        <div style="height: 3vw" v-if="list.meta_info"></div>
        <div class="invest" v-if="list.invest_info"><div class="h1">权益赋能</div><div class="invest_con" v-html="list.invest_info"></div></div>
        <div style="height: 3vw" v-if="list.invest_info"></div>
        <div class="about"><div class="h1">关于{{ good_tit }}</div><div class="about_con">{{ list.cangpin }}</div></div>
        <div style="height: 3vw"></div>
        <!-- •购买须知 -->
        <div class="notice"><div class="title">购买须知</div><div class="notice_text" v-html="buy_notes"></div></div>
      </div>
      <div style="height: 5vw"></div>
      <div class="submit" v-if="list.status == 2">
        <van-submit-bar :disabled="disabled" button-text="我想要" @click="showPopup"><template #default><div class="default">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="submit" v-if="list.status == 1 && new Date().getTime() > getFormatTime(list.start_time)">
        <van-submit-bar :disabled="disabled" button-text="我想要" @click="showPopup"><template #default><div class="default">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="submit_in" v-if="list.status == 1 && getFormatTime(list.start_time) >= new Date().getTime()">
        <van-submit-bar :button-text="getTimes(getFormatTime(list.start_time))"><template #default><div class="default">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="submits" v-if="list.status == 3">
        <van-submit-bar button-text="已售罄"><template #default><div class="default">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="submits" v-if="list.status == 4">
        <van-submit-bar button-text="已结束"><template #default><div class="default">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
    </div>
    <!-- 盲盒详情 -->
    <div v-if="classifty == 2">
      <div class="head">
        <img :src="blind.product_image" /><div class="head_name">{{ blind.product_name }}</div>
        <div class="head_num"><div class="hn_box"><div class="hn_lab">发售总量 </div> <div class="hn_num">{{ blind.num }}份</div></div></div>
      </div>
      <div style="height: 3vw"></div>
      <div class="tsftop2">
        <div class="cell_box">
          <div class="role">
            <div class="avatar"><img :src="blind.author_image" /></div><div class="text"><div class="time">创作者</div></div><br/>
            <div class="cell_name">{{ blind.author_name }}</div>
          </div>
        </div>
        <div style="height: 2vw"></div>
        <div class="main"><div class="h2">作品故事</div><div class="panel_boxs" v-html="blind.content"></div></div>
        <div style="height: 2vw"></div>
        <!-- //发行方 -->
        <div class="issuer">
          <div style="height: 2vw"></div>
          <div class="iu_top">
            <div class="iu_name">{{ blind.issuer }}</div><div class="iu_more" @click="toBarnd(blind.brand_id)">更多品牌作品 <img src="~@/assets/img/go02.png" class="img" /></div>
          </div> 
          <div class="iu_bot">{{ bling.brand_info }}</div>
          <div style="height: 2vw"></div>
        </div>
        <div style="height: 3vw"></div>
        <!-- 关于数字盲盒 -->
        <div class="notices"><div class="good_tab"><div class="title"><span>关于数字盲盒</span></div></div> <div class="notice_text">{{ blind.info }}</div></div>
        <!-- •购买须知 -->
        <div class="notices"><div class="good_tab"><div class="title"><span>购买须知</span></div></div> <div class="notice_text">{{ blind.purchase_info }}</div></div>
        <div style="height: 3vw"></div>
        <div class="celljl"><van-cell center><template #title><div class="title">抽取记录</div></template> <template #value><div class="value">更多抽取记录</div></template></van-cell></div>
        <div class="cellxq" v-for="(item, index) in luck" :key="index">
          <van-cell center>
            <template #title><div class="title">{{ item.name }}</div><div class="titles">{{ item.time }}</div></template>
            <template #value>
              <div class="value">{{ item.good_desc }}</div>
              <div class="valueo" v-if="item.level == 'N'">{{ item.level }}</div><div class="valuet" v-if="item.level == 'R'">{{ item.level }}</div>
              <div class="valuef" v-if="item.level == 'SR'">{{ item.level }}</div><div class="values" v-if="item.level == 'SSR'">{{ item.level }}</div>
            </template>
          </van-cell>
        </div>
      </div>
      <div style="height: 20vw"></div>
      <div class="submit" v-if="blind.status == 2">
        <van-submit-bar label=" " :disabled="disabled" button-text="我想要" @click="showPopup"><template #default><div class="default">￥{{ blind.price }}</div></template></van-submit-bar>
      </div>
      <div class="submits" v-if="blind.status == 1">
        <van-submit-bar label=" " button-text="即将开售"><template #default><div class="default">￥{{ blind.price }}</div></template></van-submit-bar>
      </div>
      <div class="submits" v-if="blind.status == 3">
        <van-submit-bar label=" " button-text="已售罄" @click="showPopup"><template #default><div class="default">￥{{ blind.price }}</div></template></van-submit-bar>
      </div>
    </div>
    <!-- 市场详情 -->
    <div v-if="classifty == 3">
      <div class="head" v-if="mark && mark.good">
        <img :src="mark.good.product_image" /><div class="head_name">{{ mark.good.product_name }}</div>
        <div class="head_num">
          <div class="hn_box"><div class="hn_lab">发售总量 </div><div class="hn_num">{{ mark.good.total_num }}份</div></div>
          <div class="hn_box" style="margin-left: 4vw;"><div class="hn_lab1">已销毁</div><div class="hn_num1">{{ mark.burned }}份</div></div>
        </div>
      </div>
      <div style="height: 3vw"></div>
      <div class="tsftop2" v-if="mark && mark.good">
        <div class="cell_box">
          <div class="role"><div class="avatar"><img :src="mark.head_img" /></div><div class="text"><div class="name">{{ mark.nickname }}</div> <div class="time">持有者</div></div></div>
          <div class="cell_btn" @click="toNumAll(mark.product_id, mark.good.total_num || mark.good.num)">全部编号 <img src="~@/assets/img/go.png" class="img_go" /></div> 
        </div>
        <div style="height: 3vw"></div>
        <div class="notice"><div class="good_tab"><div class="title"><span>作品故事</span></div></div><div class="notice_text" v-if="mark && mark.good" v-html="mark.good.info"></div></div>
        <div style="height: 3vw"></div>
        <div class="panel_box">
          <div class="panel_list">
            <div class="panel_title">区块信息</div>
            <div class="ctitle"><van-cell title="链上标识" :border="false"><template #value><div>{{ getChain(mark.hash) }}</div></template></van-cell></div>
            <van-cell title="认证标准" :border="false" value="ERC-721" />
            <van-cell title="认证网络" :border="false" value="文昌生态链" />
          </div>
          <van-grid direction="horizontal" :column-num="4" icon-size="5vw" :border="false">
            <van-grid-item :icon="require('@/assets/img/shield.png')" text="唯一编号" /><van-grid-item :icon="require('@/assets/img/shield.png')" text="可信记录" />
            <van-grid-item :icon="require('@/assets/img/shield.png')" text="永久存证" /><van-grid-item :icon="require('@/assets/img/shield.png')" text="不可篡改" />
          </van-grid>
        </div>
        <div style="height: 3vw"></div>
        <!-- //发行方 -->
        <div class="issuer">
          <div style="height: 2vw"></div>
          <div class="iu_top">
            <div class="iu_name" v-if="mark && mark.good">{{ mark.good.mer_name }}</div>
            <div class="iu_more" @click="toBarnd(mark.good.mer_id)">更多品牌作品 <img src="~@/assets/img/go02.png" class="img" /></div>
          </div> 
          <div class="iu_bot" v-html="mark.good.issuer_info"></div>
          <div style="height: 2vw"></div>
        </div>
        <div class="universe" v-if="mark.good.meta_info"><div class="h1">元宇宙{{ good_tit }}介绍</div><div class="universe_con" v-html="mark.good.meta_info"></div></div>
        <div style="height: 3vw" v-if="mark.good.meta_info"></div>
        <div class="invest" v-if="mark.good.invest_info"><div class="h1">权益赋能</div><div class="invest_con" v-html="mark.good.invest_info"></div></div>
        <div style="height: 3vw" v-if="mark.good.invest_info"></div>
        <div style="height: 3vw"></div>
        <!-- •购买须知 -->
        <div class="notice"><div class="good_tab"><div class="title"><span>购买须知</span></div></div><div class="notice_text" v-if="mark && mark.good" v-html="buy_notes"></div></div>
      </div>
      <div style="height: 20vw"></div>
      <div class="submit">
        <van-submit-bar :disabled="disabled" button-text="我想要" @click="showPopup"><template #default><div class="default">￥{{ mark.price }}</div></template></van-submit-bar>
      </div>
    </div>
    <!-- 开盲盒 -->
    <div v-if="classifty == 5">
      <div class="head" v-if="turn && turn.blind_box">
        <img :src="turn.blind_box.image" /><div class="head_name">{{ turn.blind_box.name }}</div>
        <div class="head_num"><div class="hn_box"><div class="hn_lab">发售总量 </div> <div class="hn_num" v-if="turn && turn.blind_box">{{ turn.blind_box.num }}份</div></div></div>
      </div>
      <div style="height: 3vw"></div>
      <div class="tsftop2">
        <div class="cell_box">
          <div class="role">
            <div class="avatar" v-if="turn && turn.blind_box"><img :src="turn.mer_image" /></div>
            <div class="text"><div class="time">创作者</div><div class="cell_name" v-if="turn && turn.blind_box">{{ turn.blind_box.mer_name }}</div></div>
          </div>
        </div>
        <div style="height: 3vw"></div>
        <div class="bbox">
          <div class="bbox_tit">• <span>作品故事</span></div>
          <div class="bbox_txt" v-if="turn && turn.blind_box" v-html="turn.blind_box.content"></div>
        </div>
        <div style="height: 3vw"></div> 
        <!-- 关于数字盲盒 -->
        <div class="notices">
          <div class="good_tab"><div class="title">• <span>关于数字盲盒</span></div></div><div class="notice_text bgc" v-if="turn && turn.blind_box">{{ turn.digital_blind_box }}</div>
        </div>
        <div class="cellxq" v-for="(item, index) in luck" :key="index">
          <van-cell center>
            <template #title><div class="title">{{ item.name }}</div><div class="titles">{{ item.time }}</div></template>
            <template #value>
              <div class="value">{{ item.good_desc }}</div>
              <div class="valueo" v-if="item.level == 'N'">{{ item.level }}</div><div class="valuet" v-if="item.level == 'R'">{{ item.level }}</div>
              <div class="valuef" v-if="item.level == 'SR'">{{ item.level }}</div><div class="values" v-if="item.level == 'SSR'">{{ item.level }}</div>
            </template>
          </van-cell>
        </div>
      </div>
      <div class="btn_boxs">
        <div class="box_lt"><van-button type="warning" v-if="turn.is_open == 0" @click="toOpenTen">开启十个</van-button></div>
        <div class="box_mm"><van-button type="warning" v-if="turn.is_open == 0" @click="toOpenFive">开启五个</van-button></div>
        <div class="box_rt"><van-button type="success" v-if="turn.is_open == 0" @click="throttle(toOpen, gapTimes)">开启一个</van-button></div>
        <van-button size="large" v-if="turn.is_open == 1" type="success">已开启</van-button>
      </div>
      <div style="height: 20vw"></div>
    </div>
    <!-- 抽签购 -->
    <div v-if="classifty == 6">
      <div class="head">
        <img :src="list.product_image" /><div class="head_name">{{ list.product_name }}</div>
        <div class="head_num"><div class="hn_box"><div class="hn_lab">发售总量 </div><div class="hn_num">{{ list.total_num }}份</div></div></div>
      </div>
      <div style="height: 3vw"></div>
      <div class="tsftop2">
        <div class="draw" v-if="list.rob_type == 2">
          <div class="top"><div class="top_lt">抢购流程</div><div class="top_rt" @click="toRlue(list.id)">查看规则 ＞ </div></div>
          <div class="draw_top"><steps :stepInfo="stepInfo"></steps></div>
          <div class="draw_mid" v-if="list.draw.status > 1">
            <div style="height: 2vw"></div>
            <div class="mid_tit">已有 <span class="red">{{ list.draw.joinNumber }}</span> 人参与</div>
            <div class="mid_icon"><div class="icon" v-for="(item, index) in list.draw.joinMembers" :key="index"><img :src="item.head_img" /></div>
            </div>
            <div class="mid_bot" v-if="list.draw.status < 5"><span>将产生 <span class="red">{{ list.rob_num }}</span> 个中签码</span></div>
            <div class="mid_bot" v-if="list.draw.status >= 5">
              <span>已产生 <span class="red">{{ list.draw.totalBallotNum }}</span> 个中签码，</span> <span class="not" @click="toShow">查看公示 ＞</span>
            </div>
          </div>
          <div style="height: 2vw"></div>
        </div>
        <div class="draw" v-if="list.rob_type == 2"></div>
        <div style="height: 3vw"></div>
        <div class="cell_box">
          <div class="role">
            <div class="avatar"><img :src="list.author_image" /></div><div class="text"><div class="name">{{ list.author_name }}</div><div class="time">创作者</div></div>
          </div>
        </div>
        <div style="height: 3vw"></div>
        <div class="notice"><div class="good_tab"><div class="title">• <span>作品故事</span></div></div>  <div class="notice_text" v-html="list.info"></div></div>
        <div style="height: 3vw"></div>
        <div class="panel_box">
          <div class="panel_list">
            <div class="panel_title">区块信息</div>
            <van-cell title="认证标准" :border="false" value="ERC-721" />
            <van-cell title="认证网络" :border="false" value="文昌生态链" />
          </div>
          <van-grid direction="horizontal" :column-num="4" icon-size="5vw" :border="false">
            <van-grid-item :icon="require('@/assets/img/shield.png')" text="唯一编号" /><van-grid-item :icon="require('@/assets/img/shield.png')" text="可信记录" />
            <van-grid-item :icon="require('@/assets/img/shield.png')" text="永久存证" /><van-grid-item :icon="require('@/assets/img/shield.png')" text="不可篡改" />
          </van-grid>
        </div>
        <div style="height: 3vw"></div>
        <!-- //发行方 -->
        <div class="issuer">
          <div style="height: 2vw"></div>
          <div class="iu_top">
            <div class="iu_name" v-html="list.issuer_info"></div>
            <div class="iu_more" @click="toBarnd(list.brand_id)">更多品牌作品 <img src="~@/assets/img/go02.png" class="img" /></div>
          </div> 
          <div class="iu_bot">全球领先的数字藏品电商平台</div>
          <div style="height: 2vw"></div>
        </div>
        <div style="height: 3vw"></div>
        <!-- •购买须知 -->
        <div class="notice"><div class="good_tab"><div class="title">• <span>购买须知</span></div></div><div class="notice_text">{{ list.purchase_info }}</div></div>
      </div>
      <div style="height: 20vw"></div>
      <div class="abtns one" v-if="list && list.draw.status == 1">
        <van-submit-bar :disabled="disabled" button-text="未开始"><template #default><div class="default one">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="abtn" v-if="list && list.draw.status == 2">
        <van-submit-bar button-text="参与抽签" @submit="toDraw(list.id)"><template #default><div class="default one">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 3">
        <van-submit-bar :disabled="disabled" button-text="等待公示">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">您当前可获得：<span class="red">{{ list.draw.codeNum }}</span> 个抽签码</div></template>
        </van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 4">
        <van-submit-bar :disabled="disabled" button-text="等待公示">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">共有：<span class="red">{{ list.draw.codeNum }}</span> 个抽签码</div></template>
        </van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 5">
        <van-submit-bar :disabled="disabled" button-text="抽签结束"><template #default><div class="default one">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 6">
        <van-submit-bar :disabled="disabled" button-text="您未中签">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">共有：<span class="red">{{ list.draw.codeNum }}</span> 个抽签码</div></template>
        </van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 7">
        <van-submit-bar :disabled="disabled" button-text="待抢购">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">中签 ：<span class="red">{{ list.draw.ballotNum }}</span> 次</div></template>
        </van-submit-bar>
      </div>
      <div class="abtn" v-if="list && list.draw.status == 8">
        <van-submit-bar :disabled="disabled" button-text="我要购买" @submit="showPopup">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">中签 ：<span class="red">{{ list.draw.ballotNum }}</span> 次</div></template>
        </van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 9">
        <van-submit-bar :disabled="disabled" button-text="已购买">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">中签 ：<span class="red">{{ list.draw.ballotNum }}</span> 次</div></template>
        </van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 10">
        <van-submit-bar :disabled="disabled" button-text="已结束"><template #default><div class="default one">￥{{ list.price }}</div></template></van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 11">
        <van-submit-bar :disabled="disabled" button-text="已结束">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">共有：<span class="red">{{ list.draw.codeNum }}</span> 个抽签码</div></template>
        </van-submit-bar>
      </div>
      <div class="abtns one" v-if="list && list.draw.status == 12">
        <van-submit-bar :disabled="disabled" button-text="已结束">
          <template #default><div class="default one">￥{{ list.price }}</div><div class="peo one">共有：<span class="red">{{ list.draw.codeNum }}</span> 个抽签码</div></template>
        </van-submit-bar>
      </div>
    </div>
    <van-dialog v-model:show="show" message="确定要抽签吗？" show-cancel-button @confirm="confirm"></van-dialog>
    <van-popup v-model:show="show_botto" position="bottom" :style="{ height: '20%' }"><div class="slider_box"><noCaptcha @slideCallback="finishSlide" :reload="reload" /></div></van-popup>
    <van-popup v-model:show="show_detail" position="bottom" :style="{ height: '88%' }" style="background: #fff">
      <div class="det_top"><div class="top_lt">藏品列表</div><div class="top_rt" @click="toClose">X</div></div>
      <div class="det_mid">
        <div class="mid_lt">寄售列表</div>
        <div class="mid_rt">
          <ul class="nav">
            <li :class="curId == 0 ? 'cur' : ''" @click="changeTab(0)" class="tab_opt">按价格升序</li><li :class="curId == 1 ? 'cur' : ''" @click="changeTab(1)" class="tab_opt">按价格降序</li>
          </ul>
        </div>
      </div>
      <div class="nav_txt" v-show="curId === 0" v-for="(item, index) in numList" :key="index" @click="toBackDet(item.id)">
        <div class="nav_lt">
          <ul>
            <li><span class="nav_icon"><img :src="item.head_img" class="nav_img" /> {{item.nickname}}</span> <span class="nav_tit">寄售</span></li> 
            <li><span class="nav_num"># {{ item.goods_number }}/{{ total_num }}</span></li> 
            <li class="small">链上标识： {{ item.hash_s }}</li>
          </ul>
        </div>
        <div class="nav_mm"><ul><li class="nav_price">￥ {{ item.price }}</li> <li class="small" style="text-align: right;">售价</li></ul></div>
        <div class="nav_rt"><img src="~@/assets/img/go.png" class="go_img" /></div>
      </div>
      <div class="nav_txt" v-show="curId === 1" v-for="(item, index) in numList" :key="index" @click="toBackDet(item.id)">
        <div class="nav_lt" >
          <ul>
            <li><span class="nav_icon"><img :src="item.head_img" class="nav_img" /> {{ item.nickname }}</span> <span class="nav_tit">寄售</span></li> 
            <li><span class="nav_num"># {{ item.goods_number }}/{{ total_num }}</span></li> 
            <li class="small">链上标识： {{ item.hash_s }}</li>
          </ul>
        </div>
        <div class="nav_mm"><ul><li class="nav_price">￥ {{ item.price }}</li> <li class="small" style="text-align: right;">售价</li></ul></div>
        <div class="nav_rt"><img src="~@/assets/img/go.png" class="go_img" /></div>
      </div>
    </van-popup>
    <van-popup v-model:show="show_buy" round closeable  close-icon="close" position="bottom">
      <div class="buy_box">
        <div style="height: 6vw"></div>
        <div class="buy_top">
          <div class="buy_top_lt"><img :src="productImg" class="img_pay" /></div>
          <div class="buy_top_rt"><div calsss="buy_tit">{{ productName }}</div><div style="height: 10vw"></div><div calsss="buy_price">￥{{ prodcutPrice }}</div></div>
        </div>
        <div class="buy_mm">
          <div class="buy_mm_lt"><label>购买数量</label><span v-if="buyType==1">每人限购 {{ limit_num }} 件</span><span v-if="buyType==6">每个中签码限购 {{ limit_num }} 件</span></div>
          <div class="buy_mm_rt">
            <div class="mm_box"><span class="min" @click="min(index)">-</span><input class="input" v-model="number" /><span class="add" @click="add(index)">+</span></div>
          </div>
        </div>
        <div class="buy_bot"><van-button type="primary" @click="toBuy">立即购买</van-button></div>
      </div>
    </van-popup>
    <van-dialog :show="shows" :show-confirm-button="false">
      <div class="box_open">
        <div style="height: 15%"></div>
        <div class="open_tit">盲盒开启成功</div>
        <div class="open">
          <div class="open_img"><div class="open_no">x1</div><img :src="bbox.product_image" class="img_open" /><div class="open_txt">{{ bbox.product_name }}</div></div>
        </div>
        <van-button type="primary" @click="openFive" class="box_btn">确认收取</van-button>
      </div>  
    </van-dialog>
    <van-dialog :show="show_open" :show-confirm-button="false">
      <div class="box_open">
        <div style="height: 15%"></div>
        <div class="box_tit">抽中了五件奖励</div>
        <div class="box_res">
          <div class="box_li">
            <div class="box_img" v-for="(item, index) in boxItem" :key="index">
              <div class="box_no">x{{ item.num }}</div><img :src="item.product_image" class="img_box" /><div class="box_txt">{{ item.product_name }}</div>
            </div>
          </div>
        </div>
        <van-button type="primary" @click="openFive" class="box_btn">确认收取</van-button>
      </div>  
    </van-dialog>
    <van-dialog :show="show_open10" :show-confirm-button="false">
      <div class="box_open">
        <div style="height: 15%"></div>
        <div class="box_tit">抽中了十件奖励</div>
        <div class="box_res">
          <div class="box_li">
            <div class="box_img" v-for="(item, index) in boxItem" :key="index">
              <div class="box_no">x{{ item.num }}</div><img :src="item.product_image" class="img_box" /><div class="box_txt">{{ item.product_name }}</div>
            </div>
          </div>
        </div>
        <van-button type="primary" @click="openTen()" class="box_btn">确认收取</van-button>
      </div>  
    </van-dialog>
    <div class="open_box">
      <van-popup v-model:show="show_index_wallet" closeable close-icon="close">
        <div class="open_item">
          <img src="~@/assets/img/success.png" class="top_img" />
          <div class="open_con"><div class="open_tit">{{ index_wallet }}</div><van-button round size="large" type="success" @click="toWallet">前往开通</van-button></div>
        </div>
      </van-popup>
    </div>
    <div class="open_box">
      <van-popup v-model:show="show_mark_wallet" closeable close-icon="close">
        <div class="open_item">
          <img src="~@/assets/img/success.png" class="top_img" />
          <div class="open_con"><div class="open_tit">{{ mark_wallet }}</div><van-button round size="large" type="success" @click="toWallet">前往开通</van-button></div>
        </div>
      </van-popup>
    </div>
    <van-dialog v-model:show="show_limit" @confirm="toConfirm">
      <div style="width: 90%; height: auto; margin: 2vw 5%; color: #000;">{{ limit }}</div>
    </van-dialog>
    <div class="bsn"><img src="~@/assets/img/bsn.png" /></div>
    <div style="height: 20vw"></div>
  </div>
</template>

<script>
import axios from "axios";
import base from '@/utils/return_url.js';
import noCaptcha from "@/components/noCaptcha.vue";
import steps from '@/components/step.vue';
let timer, flag;
export default {
  components: { noCaptcha,  steps },
  props: { gapTimes: { type: Number,	default: 1000 }	},
  data() {
    return {
      token: '',
      title: '藏品详情',
      good_tit: '数字藏品',
      loginForm: { session_id: "", verify_token: "", sig: "" },
      reload: true,
      show_botto: false,
      classifty: this.$route.query.type,
      ordersn: this.$route.query.ordersn,
      id: this.$route.query.id,
      series_id: '',
      classify: "",
      show_loading: true,
      text: "0xDD92F6922d59581c87E42Ecb06245F8ef393bb4C", //合约地址
      rs_time: '', // 抽签开启
      re_time: '', // 抽签结束
      rn_time: '', // 结果公示
      ss_time: '', // 开始抢购
      se_tiem: '', // 抢购结束 
      list: {},
      time: "",
      times: 30 * 60 * 60 * 1000,
      btime: "",
      blind: {},
      bbox: {}, //盲盒内的信息
      turn: {}, //开盲盒详情
      mark: {}, //市场详情
      shows: false,
      isLoading: false,
      luck: [], //抽签记录
      disabled: false,
      show: false,
      show_detail: false,
      checked: true,
      curId: 1,
      goods_id: '',
      numList: [],
      sort_val: '',
      total_num: '',
      value1: '',
      value3: '',
      value4: '',
      level: '',
      lv: '',
      field: '',
      sort: '',
      keywords: '',
      mer_id: '',
      seriesId: '',
      show_buy: false,
      productId: '',
      productImg: '',
      productName: '',
      prodcutPrice: '',
      number: 1,
      limit_num: 0,
      show_open: false,
      show_open10: false,
      boxList: [],
      boxItem: [],
      boxImg: '',
      boxName: '',
      active: 0,
      stepInfo: {},
      buyType: 0,
      bid: '',
      brand: 0,
      buy_notes: '',
      isBuyAll: '',
      index_wallet: '',
      show_index_wallet: false,
      mark_wallet: '',
      show_mark_wallet: false,
      show_limit: false,
      limit: ''
    }
  },
  created() {
    this.token = localStorage.getItem("token");
    let minId = this.$cookies.get("minId");
    if (minId == 1) {
      this.title = '商品详情',
      this.good_tit = '商品'
    }
    this.mer_id = this.$route.query.mer_id;
    this.seriesId = this.$route.query.series_id;
    this.value1 = this.$route.query.value1;
    this.value4 = this.$route.query.value4;
    this.level = this.$route.query.level;
    this.field = this.$route.query.field;
    this.sort = this.$route.query.sort;
    this.value3 = this.$route.query.value3;
    this.keywords = this.$route.query.keywords;
    this.bid = this.$route.query.bid;
    this.brand = this.$route.query.brand;
    this.getOrderInfo();
    window.scrollTo(0, 0);
    console.log("seriesId:", this.seriesId, ", keywords:", this.keywords);
  },
  methods: {
    // 返回市场
    toBackGood() {
      if (!this.brand) {
        this.$router.push({ 
          path: "/good_list", 
          query: { id: this.goods_id, mer_id: this.mer_id, series_id: this.seriesId, value1: this.value1, level: this.level, field: this.field, sort: this.sort, value3: this.value3, 
          value4: this.value4, keywords: this.keywords } 
        })
      } else {
        if (this.brand == 1) {
          this.$router.push({ path: "/brand", query: { id: this.bid } })
        } else if (this.brand == 2) {
          this.$router.push({ path: "/brand", query: { id: this.bid } })
        }
      }
    },
    // 订单信息
    getOrderInfo() {
      if (this.classifty == 1) {
        this.getNumCollectInfo();
      }
      if (this.classifty == 2) {
        this.getBlindInfo()
      }
      if (this.classifty == 5) {
        this.getBoxInfo()
      }
      if (this.classifty == 3) {
        this.getMarkInfo()
      }
      if (this.classifty == 6) {
        this.getDrawInfo()
      }
    },
    // 首发数字藏品详情
    getNumCollectInfo() {
      this.$get({
        url: "/app/goods/goodsInfo",
        data: { id: this.id },
        success: (res) => {
          this.list = res.data;
          this.productId = res.data.id;
          this.productImg = res.data.product_image;
          this.productName = res.data.product_name;
          this.prodcutPrice = res.data.price;
          this.limit_num = res.data.limit_num;
          let txt = res.data.purchase_info;
          this.buy_notes = txt.replace(/\n/g,'<br>');
          this.isBuyAll = res.data.goods_ess_temp_id;
          var d = new Date(res.data.start_time);
          let month = d.getMonth() + 1 >= 10 ? Number(d.getMonth()) + 1 : "0" + (Number(d.getMonth()) + 1),
            sun = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate(),
            hours = d.getHours() >= 10 ? d.getHours() : "0" + d.getHours(),
            minutes = d.getMinutes() >= 10 ? d.getMinutes() : "0" + d.getMinutes();
          this.time = month + "-" + sun + " " + hours + ":" + minutes;
        },
        tip: () => {},
      })
    },
    //市场数字藏品详情
    getMarkInfo() {
      this.$get({
        url: "/app/goods_rent/info",
        data: { id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.mark = res.data;
            this.buy_notes = res.data.purchase_info.replace(/\n/g,'<br>');
            this.series_id = res.data.series_id;
            this.goods_id = res.data.product_id;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 盲盒详情
    getBoxInfo() {
      this.$get({
        url: "/app/blind_box_orders/orderInfo",
        data: { order_sn: this.ordersn },
        success: (res) => {
          this.turn = res.data;
          this.boxList = res.data.order_sn_arr;
          console.log("boxList:", this.boxList);
        },
        tip: () => {},
      })
    },
    // 购买
    showPopup() {
      // 首发订单
      this.buyType = 1;
      if (this.classifty == 1) {
        if (this.isBuyAll) {
          this.number = 1;
          this.toBuy();
        } else {
          this.show_buy = true; // 批量购买弹窗
        }
      }
      // 为盲盒订单
      if (this.classifty == 2) {
        this.toBlindOrder()
      }
      // 开盲盒
      if (this.classifty == 5) {
        this.getBoxInfo()
      }
      // 市场订单支付
      if (this.classifty == 3) {
        this.toMarketPay()
      }
      // 抽签购订单支付
      if (this.classifty == 6) {
        this.show_buy = true; // 批量购买弹窗
        this.buyType = 6
      }
    },
    // 时间格式化
    getFormatTime(time) {
      return new Date(time.replaceAll('.', "/")).getTime()
    },
    // 首发/抽签购买
    toBuy() {
      if (this.buyType == 1) {
        this.toIndexPay();
      } else if (this.buyType == 6) {
        this.toDrawPay()
      }
    },
    // 刷新
    onRefresh() {
      var page = this.page++;
      setTimeout(() => { this.$toast("刷新成功"); this.isLoading = false }, 1000);
    },
    geTel(text) {
      return ( text.substring(0, 10) + "***********" + text.substr(text.length - 10) );
    },
    // 截取合约
    getChain(text) {
      if (text.substring(0, 5) === "avata") {
        return text;
      } else {
        return (text.substring(0, 10) + "***********" + text.substr(text.length - 10));
      }
    },
    onLoad() {
      this.show_loading = false
      this.rotate()
    },
    rotate() {
      this.rotation.y += 0.01
      requestAnimationFrame(this.rotate)
    },
    //开盲盒
    toOpen() {
      this.$get({
        url: "/app/blind_box_orders/openBlindBox",
        data: { order_sn: this.ordersn },
        success: (res) => {
          if (res.code == 1) {
            this.bbox = res.data;
            this.shows = true
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => { this.$router.push({ path: "/mybox" }) }
      })
      console.log("开启盲盒")
    },
    // 节流
    throttle(func, wait = 500, immediate = true) {
			if (immediate) {
				if (!flag) {
					flag = true;
					typeof func === 'function' && func();
					timer = setTimeout(() => { flag = false }, wait);
				}
			} else {
				if (!flag) {
					flag = true
					timer = setTimeout(() => { flag = false, typeof func === 'function' && func() }, wait);
				}
			}
		},
    // 一键开5个
    toOpenFive() {
      if (this.boxList.length < 5) {
        this.$toast("盲盒数量不足5个！");
        return
      } else {
        let list = this.boxList.slice(0, 5);
        let ids = [];
        let arr = [];
        for (let i = 0; i < list.length; i++) {
          arr = list[i]
          ids.push(arr)
        }
        let sentData = { order_sn: ids.join(",") }
        console.log("sentData:", sentData);
        this.$get({
          url: '/app/blind_box_orders/batchOpenBindBox',
          data: sentData,
          success: (res) => {
            if (res.code == 1) {
              this.boxItem = res.data.product_data;
              this.show_open = true;
            } else {
              this.$toast(res.msg);
            }
          }
        })
      }
    },
    // 一键开10个
    toOpenTen() {
      if (this.boxList.length < 10) {
        this.$toast("盲盒数量不足10个！");
        return
      } else {
        let list = this.boxList.slice(0, 10);
        let ids = [];
        let arr = [];
        for (let i = 0; i < list.length; i++) {
          arr = list[i]
          ids.push(arr)
        }
        let sentData = { order_sn: ids.join(",") }
        console.log("sentData:", sentData);
        this.$get({
          url: '/app/blind_box_orders/batchOpenBindBox',
          data: sentData,
          success: (res) => {
            console.log("开启成功：", res);
            if (res.code == 1) {
              this.boxItem = res.data.product_data;
              this.show_open10 = true;
            } else {
              this.$toast(res.msg);
            }
          }
        })
      }
    },
    // 关闭
    toColse() {
      this.shows = false;
    },
    // 一键开5个
    openFive() {
      this.$router.push({ path: "/mybox" })
    },
    // 一键开10个
    openTen() {
      this.$router.push({ path: "/mybox" })
    },
    // 关闭批量开盲盒
    toCloseBox() {
      this.show_open = false;
    },
    toCloseBox10() {
      this.show_open10 = false;
    },
    // 完成滑动
    finishSlide(data) {
      if (data.cls === false) {
        //验证加载失败
        this.loginForm.cls = false
      }
      if (data.sessionId) {
        //非人机操作
        this.loginForm.scene = data.scene
        this.loginForm.ncToken = data.token
        this.loginForm.sig = data.sig
        this.loginForm.sessionId = data.sessionId
        delete this.loginForm.cls
      }
      //1 为抢购藏品
      if (this.classifty == 1) {
        this.toIndexPay()
      }
      //2 为盲盒
      if (this.classifty == 2) {
        this.toBlindOrder()
      }
      if (this.classifty == 5) {
        this.getBoxInfo()
      }
      if (this.classifty == 3) {
        this.toMarketPay()
      }
      if (this.classifty == 6) {
        this.toDrawPay()
      }
    },
    // 更多品牌
    toBarnd(id) {
      this.$router.push({ path: "brand", query: { id: id } })
    },
    //数字藏品支付(首发订单)
    toIndexPay() {
      this.disabled = true;
      axios({
        url: base.baseUrl + "app/goods_orders/createOrder",
        method: "post",
        headers: { 'Content-Type': 'application/json', },
        data: { goods_id: this.productId, session_id: this.loginForm.sessionId, verify_token: this.loginForm.ncToken, sig: this.loginForm.sig, goods_num: this.number, token: this.token },
        timeout: 6000
      }).then(res => {
        if (res.data.code == 1) {
          setTimeout(() => {
            this.disabled = false
            this.$router.push({ path: "optbox", query: { ordersn: res.data.data.order_sn, form: "数字藏品", type: 1, goods_num: this.number } })
            this.show_botto = false
          }, 1000)
        } else if (res.data.code == -2) {
          this.index_wallet = res.data.msg;
          this.show_index_wallet = true;
        } else {
          this.disabled = false; 
          this.show_botto = false, 
          this.limit = res.data.msg;
          let m = res.data.msg.match(/限购(\d+)/);
          if (m) {
            this.show_limit = true;
            this.limit_num = m[1];
          } else if (this.limit.indexOf("未到开始时间") >= 0) {
            this.show_limit = true;
          } else {
            this.$toast({ message: res.data.msg, duration: 3000 });
          }
        }
      })
    },
    // 提示确认弹窗
    toConfirm() {
      this.show_limit = false;
    },
    //市场支付
    toMarketPay() {
      this.disabled = true;
      axios({
        url: base.baseUrl + "app/rent_orders/createOrder",
        method: "post",
        headers: { 'Content-Type': 'application/json', },
        data: { rent_id: this.mark.id, session_id: this.loginForm.sessionId, verify_token: this.loginForm.ncToken, sig: this.loginForm.sig, token: this.token },
        timeout: 6000
      }).then(res => {
        if (res.data.code == 1) {
          setTimeout(() => {
            this.disabled = false
            this.$router.push({ path: "markbox", query: { ordersn: res.data.data.order_sn, form: "市场藏品", type: 3, id: this.mark.id } })
            this.show_botto = false
          }, 1000)
        } else if (res.data.code == -2) {
          this.mark_wallet = res.data.msg;
          this.show_mark_wallet = true;
        } else {
          this.disabled = false; 
          this.show_botto = false, 
          this.$toast(res.data.msg);
        }
      })
    },
    // 开通钱包
    toWallet() {
      this.$router.push({ path: "/wallet" });
    },
    //数字盲盒支付
    toBlindOrder() {
      this.disabled = true
      this.$get({
        url: "/app/blind_box_orders/createOrder",
        data: { box_id: this.blind.id, session_id: this.loginForm.sessionId, verify_token: this.loginForm.ncToken, sig: this.loginForm.sig },
        success: (res) => {
          setTimeout(() => {
            this.disabled = false
            this.$router.push({ path: "blindbox", query: { ordersn: res.data.order_sn, form: "数字盲盒", type: 2 } })
            this.show_botto = false
          }, 500)
        },
        tip: () => {
          this.$toast("活动太火爆了，请稍后再试")
          setTimeout(() => {
            this.disabled = false
            this.show_botto = false
            //this.reload = true // 验证通过后，重置滑块设置为false.
          }, 1000)
        },
      })
    },   
    //数字盲盒
    getBlindInfo() {
      this.$get({
        url: "/app/blind_box/info",
        data: { id: this.id },
        success: (res) => {
          this.blind = res.data;
          this.luck = res.data.order_list_arr;
          var d = new Date(res.data.start_at * 1000);
          let month = d.getMonth() + 1 >= 10 ? Number(d.getMonth()) + 1 : "0" + (Number(d.getMonth()) + 1),
            sun = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate(),
            hours = d.getHours() >= 10 ? d.getHours() : "0" + d.getHours(),
            minutes = d.getMinutes() >= 10 ? d.getMinutes() : "0" + d.getMinutes();
          this.btime = month + "-" + sun + " " + hours + ":" + minutes;
        },
        tip: () => {}
      })
    },
    // 倒计时
    getTimes(times) {
      let nowTime = new Date();//获取当前时间
      let setTime = new Date(times);//设置结束时间
      let totalSeconds = Math.floor((setTime.getTime() - nowTime.getTime()) / 1000);//剩余总秒数
      let days = Math.floor(totalSeconds / (60 * 60 * 24));//剩余天数 
      let hours = Math.floor(totalSeconds / (60 * 60) % 24);//剩余小时数
      let minutes = Math.floor(totalSeconds / 60 % 60);//剩余分钟数
      let seconds = Math.floor(totalSeconds % 60);//剩余秒数
      let str=`即将开售 ${days}天${hours}小时${minutes}分`
      return str; 
    },
    // 全部编号弹窗
    toNumAll(id, num) {
      this.goods_id = id;
      this.total_num = num;
      this.show_detail = true;
      this.changeTab(0);
    },
    // 关闭全部编号弹窗
    toClose() {
      this.show_detail = false;
    },
    // 选项卡切换
    changeTab(index){
      this.curId = index;
      console.log("index:", index);
      if (this.curId == 0) {
        this.sort_val = 'asc';
      } else {
        this.sort_val = 'desc';
      }
      this.getNumberList();
    },
    // 获取全部编号
    getNumberList() {
      let sentData = { series_id: this.seriesId, product_id: this.goods_id, field: 'price', sort: this.sort_val, page: 1, limit: this.total_num }
      this.$get({
        url: "/app/goods_rent/allNumber",
        data: sentData,
        success: (res) => {
          if (res.code == 1) {
            this.numList = res.data.data;
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {}
      })
    },
    // 返回
    toBackDet(id) {
      if (!this.brand) {
        this.$router.push({ 
          path: "/details_det", 
          query: { id: id, type: 3, series_id: this.seriesId, value1: this.value1, level: this.level, field: this.field, sort: this.sort, value3: this.value3, keywords: this.keywords }
        })
      } else {
        this.$router.push({ path: "/details_det", query: { id: id, type: 3, series_id: this.seriesId, bid: this.bid, brand: this.brand } })
      }
      this.show_detail = false;
    },
    // 数字加
    add() {
      if (this.number < 30) {
        this.number++;
      } else {
        alert('别点啦，最大啦')
      }
    },
    // 数字减
    min() {
      if (this.number > 0) {
        this.number--;
      } else {
        alert('别点啦，最小啦')
      }
    },
    //抽签藏品信息
    getDrawInfo() {
      this.$get({
        url: "/app/goods/goodsInfo",
        data: { id: this.id },
        success: (res) => {
          this.list = res.data;
          this.productId = res.data.id;
          this.productImg = res.data.product_image;
          this.productName = res.data.product_name;
          this.prodcutPrice = res.data.price;
          this.limit_num = res.data.limit_num;
          var steps = 0;
          var time1 = new Date(Date.parse((res.data.rob_start_time).replace(/-/g,"/")));
          var time2 = new Date(Date.parse((res.data.rob_end_time).replace(/-/g,"/")));
          var time3 = new Date(Date.parse((res.data.rob_show_time).replace(/-/g,"/")));
          var time4 = new Date(Date.parse((res.data.start_time).replace(/-/g,"/")));
          var time5 = new Date(Date.parse((res.data.end_time).replace(/-/g,"/")));
          var curDate = new Date();
          console.log("curDate:", curDate, "time1:", time1);
          if (time1 < curDate) {
            steps = 1
          }
          if (time2 < curDate) {
            steps = 2
          }
          if (time3 < curDate) {
            steps = 3
          }
          if (time4 < curDate) {
            steps = 4
          }
          if (time5 < curDate) {
            steps = 5
          }
          console.log("steps:", steps);
          // 抽奖开始
          var month = time1.getMonth() + 1 >= 10 ? Number(time1.getMonth()) + 1 : "0" + (Number(time1.getMonth()) + 1),
            sun = time1.getDate() >= 10 ? time1.getDate() : "0" + time1.getDate(),
            hours = time1.getHours() >= 10 ? time1.getHours() : "0" + time1.getHours(),
            minutes = time1.getMinutes() >= 10 ? time1.getMinutes() : "0" + time1.getMinutes()
          this.rs_time = month + "." + sun + " " + hours + ":" + minutes
          console.log("抽签开启：", this.rs_time);
          // 抽奖关闭
          var month = time2.getMonth() + 1 >= 10 ? Number(time2.getMonth()) + 1 : "0" + (Number(time2.getMonth()) + 1),
            sun = time2.getDate() >= 10 ? time2.getDate() : "0" + time2.getDate(),
            hours = time2.getHours() >= 10 ? time2.getHours() : "0" + time2.getHours(),
            minutes = time2.getMinutes() >= 10 ? time2.getMinutes() : "0" + time2.getMinutes()
          this.re_time = month + "." + sun + " " + hours + ":" + minutes
          console.log("抽签结束：", this.re_time);
          // 结果公示
          var month = time3.getMonth() + 1 >= 10 ? Number(time3.getMonth()) + 1 : "0" + (Number(time3.getMonth()) + 1),
            sun = time3.getDate() >= 10 ? time3.getDate() : "0" + time3.getDate(),
            hours = time3.getHours() >= 10 ? time3.getHours() : "0" + time3.getHours(),
            minutes = time3.getMinutes() >= 10 ? time3.getMinutes() : "0" + time3.getMinutes()
          this.rn_time = month + "." + sun + " " + hours + ":" + minutes
          console.log("结果公示：", this.rn_time);
          //开始抢购
          var month = time4.getMonth() + 1 >= 10 ? Number(time4.getMonth()) + 1 : "0" + (Number(time4.getMonth()) + 1),
            sun = time4.getDate() >= 10 ? time4.getDate() : "0" + time4.getDate(),
            hours = time4.getHours() >= 10 ? time4.getHours() : "0" + time4.getHours(),
            minutes = time4.getMinutes() >= 10 ? time4.getMinutes() : "0" + time4.getMinutes()
          this.ss_time = month + "." + sun + " " + hours + ":" + minutes
          console.log("开始抢购：", this.ss_time);
          // 抢购结束
          var month = time5.getMonth() + 1 >= 10 ? Number(time5.getMonth()) + 1 : "0" + (Number(time5.getMonth()) + 1),
            sun = time5.getDate() >= 10 ? time5.getDate() : "0" + time5.getDate(),
            hours = time5.getHours() >= 10 ? time5.getHours() : "0" + time5.getHours(),
            minutes = time5.getMinutes() >= 10 ? time5.getMinutes() : "0" + time5.getMinutes()
          this.se_time = month + "." + sun + " " + hours + ":" + minutes
          console.log("抢购结束：", this.se_time);
          // 步骤条
          this.stepInfo = { list: [ { status: '抽签开启', statusTime: this.rs_time }, { status: '抽签结束', statusTime: this.re_time }, 
           { status: '结果公示', statusTime: this.rn_time }, { status: '开始抢购', statusTime: this.ss_time }, { status: '抢购结束', statusTime: this.se_time } ], 
           step: steps        
          }
        },
        tip: () => {},
      })
    },
    // 参与抽签
    toDraw() {
      this.show = true
    },
    // 确认抽签
    confirm() {
      this.$get({
        url: "/app/goods_draw/join",
        data: { id: this.id },
        success: (res) => {
          if (res.code == 1) {
            this.$toast("参与成功");
            this.getDrawInfo();
          } else {
            this.$toast(res.msg);
          }
        },
        tip: () => {},
      })
    },
    // 查看规则
    toRlue() {
      this.$router.push({ path: "/draw_rule", query: { id: this.id } })
    },
    // 查看公示
    toShow() {
      this.$router.push({ path: "/draw_show", query: { id: this.id } })
    },
    //抽签购支付
    toDrawPay() {
      this.disabled = true
      this.$get({
        url: "/app/goods_orders/createOrder",
        data: { goods_id: this.productId, session_id: this.loginForm.sessionId, verify_token: this.loginForm.ncToken, sig: this.loginForm.sig, goods_num: this.number },
        success: (res) => {
          console.log(res)
          if (res.code == 0) {
            this.$toast("活动太火爆了，请稍后再试")
          }
          setTimeout(() => {
            this.disabled = false
            this.$router.push({ path: "optbox", query: { ordersn: res.data.order_sn, form: "数字藏品", type: 6 } })
            this.show_botto = false
          }, 1000)
        },
        tip: () => {
          setTimeout(() => {
            this.disabled = false
            this.show_botto = false
          }, 1000)
          this.$toast("活动太火爆了，请稍后再试")
        },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.box {--van-nav-bar-icon-color:#fff; --van-nav-bar-title-text-color: #fff; --van-toast-max-width: 100%;}
.content {width: 100%; background: #F7F7F9;}
.head {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding-bottom: 2vw;
  img {width: 100%; max-height: 100%; object-fit: cover;}
  .head_name {width: 96%; height: auto; line-height: 1.5; margin: 2vw 2%; color: #222222; font-size: 4.2vw; font-weight: 600;}
  .head_num {
    width: 96%; height: 6vw; margin: 2vw 2%; display: flex; flex-wrap: nowrap; flex-direction: row;
    .hn_box {
      width: 40%; height: 6vw; display: flex; flex-wrap: nowrap; flex-direction: row; font-size: 3.2vw;
      .hn_lab {width: 18vw; height: 6vw; line-height: 6vw; text-align: center; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; font-size: 3.2vw;}
      .hn_num {width: 20vw; height: 6vw; line-height: 6vw; text-align: center; background: #FFF3EF; color: #FC201F;}
      .hn_lab1 {width: 18vw; height: 6vw; line-height: 6vw; text-align: center; background: #ABA8BA; color: #fff; font-size: 3.2vw;}
      .hn_num1 {width: 20vw; height: 6vw; line-height: 6vw; text-align: center; background: #F7F7F9; color: #9592A3;}
    }
  }
}
.bg {background: #000;}
.tsftop2 {position: relative; z-index: 99;}
.slider_box {width: 100%; height: 100%; background: #222; display: flex; align-items: center;}
.details_title {display: flex; align-items: center; justify-content: center; .turn {width: 6vw; height: 6vw; img {width: 100%; height: 100%; display: block;}}}
.tag {
  display: flex; justify-content: center;
  .van-tag--primary {height: 5vw; line-height: 5vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; font-size: 3.2vw;}
  .van-tag--success {height: 5vw; line-height: 5vw; background: #383736; color: #fff; font-size: 3.2vw; border-radius: 0;} 
}
.mark_box {
  width: 100%; height: 12vw; margin: 0 auto; display: flex; align-items: center; justify-content: center;
  .mark {width: 90%; height: 8vw; margin: 2vw 5%; display: inline-block; padding: 1.2vw 2vw; background: rgba(0, 0, 0, 0.5); font-size: 3vw; color: #00ff9b;
    .van-icon {margin-right: 2vw;} :deep(.van-icon__image) {display: inline-block; margin-top: -5px;} .over {color: #db0601;} }
  .downtime {display: flex; align-items: center; font-size: 3vw; color: #fff; .van-count-down {color: #fff;}}
}
.cell_box {width: 100%; height: 18vw; margin: 0 auto; display: flex; justify-content: space-between; background: #fff; padding: 2vw 0 0 2vw;
  .role {position: relative; display: flex; align-items: center;
    .avatar {width: 12vw; height: 12vw; border-radius: 50%; overflow: hidden; margin-right: 3vw; img {width: 100%; height: 100%; display: block;} }
    .text {.name {color: #000; font-size: 4vw; line-height: 1.5;} .time {color: #848484; font-size: 3.2vw; line-height: 1.5;} }
  }
  .cell_name {display: block; font-size: 24px; font-weight: 600; color: #000; padding-top: 15px;}
  .cell_btn {line-height: 4.6; color: #ABA8BA; font-size: 3.2vw; margin-right: 1.5vw;}
  .img_go {width: 2.5vw; height: 3vw; vertical-align: middle; margin-top: -1vw;}
}
.main {width: 100%; height: auto; margin: 0 auto; background: #fff;}
.h2 {width: 95%; height: 5vw; line-height: 5vw; margin: 2vw 2.5%; color: #000; font-size: 4.2vw; font-weight: 600; padding: 3.5vw 0; margin-bottom: 4vw;}
.main_con {width: 96%; height: auto; margin: 2.5vw 2%; color: #000;}
.panel_box {
  padding: 2vw 1.5vw; margin: 0 auto; width: 100%; padding-bottom: 20px; color: #000; background: #fff;
  .panel_list {padding: 0 2vw;} .panel_title {font-size: 4.28vw; font-weight: 600;} .ctitle {font-size: 3.5vw; :deep(.van-cell__title) { flex: none; } }
  :deep(.van-grid-item__text) {color: #FF2222  !important;}
  --van-cell-vertical-padding: 1vw; --van-cell-horizontal-padding: 0; --van-cell-text-color: #000; --van-cell-value-color: #000; --van-grid-item-content-padding: 3vw 0;
  --van-grid-item-content-background-color: rgba(0, 238, 166, 0);
}
.h1 {width: 96%; height: 10vw; margin: 0 2%; color: #000; font-size: 4.2vw; font-weight: 600; padding-top: 1vw;}
.creator {
  width: 100%; height: auto; margin: 0 auto; background: #fff; 
  .cre_con {width: 96%; height: auto; line-height: 1.6; margin: 0 2%; color: #000; font-size: 3.7vw; padding: 1vw 0 3vw 0;}}
.issuer {
  width: 100%; height: auto; margin: 0 auto; background: #fff;
  .iu_top {
    width: 96%; height: auto; margin: 0 2%; color: #000; display: flex; flex-wrap: nowrap;
    .iu_name {flex: 1; height: auto; line-height: 1.2; text-align: left; font-size: 4.2vw; font-weight: 600;} 
    .iu_more {flex: 1; height: auto; line-height: 1.2; font-size: 4vw; text-align: right; .img {width: 3.7vw; height: 3.7vw; vertical-align: middle; margin-top: -1vw;}} }
  .iu_bot {width: 96%; height: auto; line-height: 1.5; margin: 1.2vw 2%; color: #000; font-size: 3.4vw;}
}
.universe {width: 100%; height: auto; margin: 0 auto; background: #fff; .universe_con {width: 96%; height: auto; color: #000; margin: 0 2%; padding: 1vw 0 3vw 0;}}
.invest {
  width: 100%; height: auto; margin: 0 auto; background: #fff; 
  .invest_con {width: 96%; height: auto; line-height: 1.6; margin: 0 2%; color: #000; font-size: 3.4vw; padding: 1vw 0 3vw 0;}
}
.about {
  width: 100%; height: auto; margin: 0 auto; background: #fff; 
  .about_con {width: 96%; height: auto; line-height: 1.6; margin: 0 2%; color: #000; font-size: 3.7vw; padding: 1vw 0 3vw 0; text-indent: 7vw;}
}
.notice {
  width: 100%; margin: 0 auto; padding: 1vw 3vw; 
  .title {color: #000; font-size: 4.2vw; font-weight: 600;}
  .notice_text {
    line-height: 1.6; background: transparent !important; color: #999; font-size: 3.7vw; 
    /deep/p {
      color: #999 !important; background: transparent !important;
      img {width: auto !important; height: auto !important; max-width: 100% !important; max-height: 100% !important; object-fit: contain;}
      video {width: auto !important; height: auto !important; max-width: 100% !important; max-height: 100% !important; object-fit: contain;} 
    }
    /deep/ * {color: #999 !important; background: transparent !important; word-break: break-word; max-width: 100% !important; width: auto !important;}
  }
}
// 抽签购
.draw {
  width: 100%; height: auto; margin: 0 auto; background: #fff;
  .top {width: 94%; height: 10vw; line-height: 10vw; margin: 1vw 3%; display: flex; display: flex; flex-wrap: nowrap;}
  .top_lt {width: 50%; height: 10vw; line-height: 10vw; font-size: 4.2vw; font-weight: 600;}
  .top_rt {width: 50%; height: 10vw; line-height: 4.2; color: #FF2222; font-size: 3.2vw;}
  .draw_mid {
    width: 92%; height: auto; margin: 4vw 4%; background: #F7F7F9; border-radius: 2.2vw;
    .mid_tit {width: 100%; height: 5vw; line-height: 5vw; margin: 2vw auto; text-align: center; color: #9A999E; font-size: 3.2vw;}
    .mid_icon {width: 92%; height: auto; margin: 2vw 4%; display: flex; flex-wrap: wrap; flex-direction: row;}
    .icon {width: 10%; height: 8.2vw; line-height: 8.2vw; margin: 1vw; img {width: 8.2vw; height: 8.2vw; vertical-align: middle; border-radius: 50%;}}
    .mid_bot {width: 100%; height: 10vw; line-height: 10vw; margin: 2vw auto; text-align: center; color: #9A999E; font-size: 3.2vw;}
    .not {color: #FF2222;}
  }
}
.red {color: #F66D2A;}
:deep(.van-dialog__message) {color: #000; font-size: 3.6vw;}
.notices{
  width: 100%; margin: 0 auto; padding: 1vw 5vw; 
  .title {color: #000; font-size: 4vw; font-weight: 600;}
  .notice_text {
    color: #999; background: transparent !important;
    /deep/p {
      color: #999 !important; background: transparent !important;
      img {width: auto !important; height: auto !important; max-width: 100% !important; max-height: 100% !important; object-fit: contain;}
      video {width: auto !important; height: auto !important; max-width: 100% !important; max-height: 100% !important; object-fit: contain;} 
    }
    /deep/ * {color: #999 !important; background: transparent !important; word-break: break-word; max-width: 100% !important; width: auto !important;}
  }
}
.submit {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {justify-content: space-between !important;
    .default {font-size: 48px; background-clip: text; color: #000;}
    .van-submit-bar__button {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);}
  }
}
.submits {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {
    justify-content: space-between !important;
    .default {font-size: 48px; background-clip: text; color: #000;}
    .van-submit-bar__button {background: #ABABB2;}
    .btn_bg {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);}
  }
}
.submit_in {
  --van-submit-bar-button-width: 65%;
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {
    justify-content: space-between !important;
    .default {font-size: 48px; background-clip: text; color: #000;}
    .van-submit-bar__button {background: #ABABB2;}
    .btn_bg {background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%);}
  }
}

.good_tab {display: flex; .title {margin-right: 3vw; margin-bottom: 2vw; color: #000; span {line-height: 8vw; display: inline-block; height: 8vw;} } }
.good_tabs {padding: 0 50px; margin-bottom: 15px; .title {color: #000; span {display: inline-block;} } }
//面板
.panel_boxs {width: 96%; height: auto; margin: 0 2%; background: #fff; color: #000; padding: 0 3vw; }
.bbox {
  width: 100%; height: auto; margin: 0 auto; background: #fff; padding: 2.5vw 0;
  .bbox_tit {width: 96%; margin: 0 2% 2vw 2%; color: #000; line-height: 1;}
  .bbox_txt {width: 96%; height: auto; margin: 0 2%; color: #000;}
}
.celljl {padding: 0 30px; width: 87vw; margin: 0 auto; color: #999;
  .title {font-size: 28px; font-weight: 500; color: #000; line-height: 1;}
  .titles {width: 110px; height: 12px; line-height: 0; img {width: 100%; height: 100%; object-fit: cover;} }
  .value {font-size: 20px; font-weight: 400; color: #999;}
}
//抽奖记录详情
.cellxq {padding: 0 50px; position: relative;
  :deep(.van-cell) {background: linear-gradient(154deg, #3d3d3d 0%, #212121 100%); padding: 0 30px 10px;}
  .title {font-size: 24px; font-weight: 500; color: #999;}
  .titles {font-size: 20px; font-weight: 400; color: #999; line-height: 1;}
  .value {font-size: 28px; font-weight: 500; color: #54fba3; padding-right: 55px; padding-top: 20px;}
  .valueo {width: 46px; height: 24px; background: linear-gradient(to bottom, #0dafda, #58ffa1); border-top-left-radius: 15px; border-bottom-right-radius: 5px; text-align: center;
  color: #fff; line-height: 1; font-size: 20px; position: absolute; bottom: 30px; right: 0px;}
  .valuet {width: 46px; height: 24px; background: #ffbc3a; border-top-left-radius: 15px; border-bottom-right-radius: 5px; text-align: center; color: #fff; line-height: 1;
  font-size: 20px; position: absolute; bottom: 30px; right: 0px;}
  .valuef {width: 46px; height: 24px; background: #3aff7c; border-top-left-radius: 15px; border-bottom-right-radius: 5px; text-align: center; color: #fff; line-height: 1;
  font-size: 20px; position: absolute; bottom: 30px; right: 0px;}
  .values {width: 46px; height: 24px; background: #953aff; border-top-left-radius: 15px; border-bottom-right-radius: 5px; text-align: center; color: #fff; line-height: 1;
  font-size: 20px; position: absolute; bottom: 30px; right: 0px;}
}
.abtn {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {justify-content: space-between !important;
    .van-submit-bar__text {text-align: left;}
    .default {width: 25%; font-size: 35px; background: #000; background-clip: text; color: transparent;}
    .peo {width: 45%; text-align: center; font-size: 3.6vw;  color: #666;}
    .van-submit-bar__price {font-size: 35px; background: #000; background-clip: text; color: transparent;}
    .van-submit-bar__button {width: 30%; display: flex; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); --van-button-round-border-radius: 1vw; font-size: 3.2vw;
     --van-submit-bar-button-height: 9vw; --van-button-danger-color: #fff; justify-content: center;}
  }
}
.abtns {
  .van-submit-bar {--van-submit-bar-background-color: #fff;}
  :deep(.van-submit-bar__bar) {justify-content: space-between !important;
    .van-submit-bar__text {text-align: left;}
    .default {width: 25%; font-size: 35px; background: #000; background-clip: text; color: transparent;}
    .peo {width: 50%; font-size: 3.6vw; padding-left: 2vw; color: #666;}
    .van-submit-bar__price {font-size: 35px; background: #000; background-clip: text; color: transparent;}
    .van-submit-bar__button {width: 25%; display: flex; justify-content: center; background: #CDCDCD; color: #fff; --van-button-round-border-radius: 1vw; font-size: 3.2vw;
     --van-submit-bar-button-height: 9vw;}
     .red {color: #F66D2A;}
  }
}
.btn_boxs {
  width: 100%; position: fixed; margin: 0 auto; background: #FFFFFF; bottom: 0; z-index: 999; display: flex; padding: 1.5vw 2vw; font-weight: 500;
  .box_lt {flex: 6; .van-button--warning {width: 90%; height: 13.6vw; line-height: 13.6vw; margin: 2vw 5%; background: #FFF3F3; color: #FC2222; border-radius: 2vw;}}
  .box_mm {flex: 6; .van-button--warning {width: 90%; height: 13.6vw; line-height: 13.6vw; margin: 2vw 5%; background: #FFF3F3; color: #FC2222; border-radius: 2vw;}}
  .box_rt {flex: 6; .van-button--success {width: 90%; height: 13.6vw; line-height: 13.6vw; margin: 2vw 5%; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); border-radius: 2vw;}}
}
.empty {width: 632px; height: 376px; margin: 0 auto; margin-top: 230px; img {width: 100%; height: 100%; object-fit: cover;}}
// 底部图标
.bsn {width: 92%; height: 14vw; margin: 4vw 4%; text-align: center; img {width: 50vw; height: 14vw; vertical-align: middle;}}
// 弹窗
.popup {
  :deep(.van-popup) {background: transparent; border: none;}
  .popupo {width: 618px; height: 658px; background: #fff; text-align: center;
    .buy {width: 100%; height: 12vw; line-height: 12vw; margin: 2vw auto; display: flex;}
    .buy_lt {flex: 6; font-size: 32px; font-weight: 400; color: #333; padding-left: 10vw;}
    .buy_rt {flex: 1; text-align: right; padding-right: 1vw;}
    .img_close {width: 5.3vw; height: 5.3vw; text-align: center;}
    .buyo {width: 298px; height: 286px; margin: 0 auto; img {width: 100%; height: 100%; object-fit: cover;}}
    .text {font-size: 32px; font-weight: 400; color: #333; padding-top: 18px;}
    
    .buys {padding: 30px 60px 0; .van-button--success {height: 15vw; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff;} border-radius: 3vw;}
  }
}
// 全部编号
.det_top {width: 94%; height: 42px; line-height: 42px; margin: 20px 3%; color: #000; display: flex;}
.top_lt {flex: 4; color: #222222; font-size: 4.8vw; font-weight: 500; }
.top_rt {flex: 2; width: 3vw; height: 3vw; line-height: 1; text-align: right;  color: #000; font-size: 2.7vw; margin-right: 1vw;}
.det_mid {width: 94%; height: 60px; line-height: 50px; margin: 2vw 3%; display: flex;}
.mid_lt {flex: 4; width: 100%; height: 60px; line-height: 60px; color: #ABABB2;}
.mid_rt {flex: 6; width: 100%; height: 60px; text-align: right; color: #8A8B8F ;}
.nav li {display: inline; width: 96%; text-decoration: none; padding: 15px 20px; background: #F5F6F7; font-size: 3vw;}
.nav .cur {width: 80%; height: 50px; background: #ffffff; color: #45474A; font-size: 3.2vw; font-weight: 700;}
.nav_txt{width: 94%; height: 18vw; margin: 5vw 3%; display: flex; border-bottom: #E8E8E8 solid 1px;}
.nav_txt li {width: 96%; height: 40px; line-height: 40px;}
.nav_icon {height: 20px; line-height: 20px; color: #000; font-size: 3.2vw;}
.nav_img {width: 4.5vw; height: 4.5vw; vertical-align: middle; border-radius: 50%;}
.nav_num {height: 20px; line-height: 20px; color: #000; font-size: 4vw;}
.small {color: #A2A2A8; font-size: 3.2vw;}
.nav_lt {flex: 6;}
.nav_mm {flex: 3.5; text-align: right; margin-top: 3.2vw;}
.nav_rt {flex: 0.5; text-align: left; line-height: 3.6;}
.ck {width: 32px; height: 32px; margin-top: 5px;}
.go_img {width: 1.8vw; height: 2.8vw; vertical-align: middle; margin-top: 10px;}
.nav_tit {width: 90%; height: 30px; background: #FFECEA; color: #FC493B; font-size: 3.2vw; padding: 2px 10px; margin-top: -25px;}
.nav_price {color: #26221D;}
// 首发购买弹出层
.buy_box {width: 92%; height: 40vh; margin: 0 4%;}
.buy_top {width: 100%; height: 30vw; margin: 2vw auto 0 auto; display: flex; color: #000; border-bottom: #EDEEF0 solid 1px;}
.buy_top_lt {flex: 2; .img_pay {width: 26vw; height: 26vw;}}
.buy_top_rt {flex: 4; }
.buy_tit { width: 60%; height: 10vw; font-size: 4.2vw; font-weight: 600;} 
.buy_price {width: 60%; height: 15vw; font-size: 5vw; font-weight: 600;}
.buy_mm {width: 100%; height: 10vw; margin: 0 auto; display: flex;}
.buy_mm_lt {flex: 8; height: 10vw; line-height: 10vw; color: #000; span {color: #B2B2B2; font-size: 3vw; margin-left: 1vw;}}
.buy_mm_rt {
  flex: 4; width: 90%; height: 10vw; line-height: 10vw; text-align: right; 
  .mm_box {width: 40vw; height: 10vw; line-height: 10vw; margin: 2vw auto; text-align: right; background:#fff; display: flex; padding-left: 18vw;}
  .min {width: 5vw; height: 5vw; line-height: 1; text-align: center; color: #000; font-size: 4vw; border: #ccc solid 1px;}
  .add {width: 5vw; height: 5vw; line-height: 1; text-align: center; color: #000; font-size: 4vw; border: #ccc solid 1px;}
  .input {width: 10vw; height: 5vw; line-height: 1; text-align: center; color: #000;}
}
.buy_bot {
  width: 100%; height: auto; 
  .van-button--primary {width: 92%; height: 14vw; margin: 5vw 4% 0 4%; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff; border-radius: 3vw;}  
}
// 批量开盲盒
.box_open {width: 85vw; height: 110vw; margin: 0 auto; background: url("~@/assets/img/box_bg.png") no-repeat; background-size: 100% 100%;}
.box_close {width: 100%; height: 20rpx; margin: 0 auto; text-align: right; }
.go_cl {width: 5vw; height: 5vw; line-height: 5vw; text-align: center; vertical-align: middle;}
// 开启一个
.open_tit {width: 100%; height: 10vw; line-height: 10vw; margin: 0 auto; text-align: center; color: #FF2222; font-size: 5vw; font-weight: 500;}
.open {width: 90%; height: auto; margin: 0 5%;}
.open_no {width: 5vw; height: 5vw; line-height: 3; color: #FC5D40; font-size: 4vw; margin-left: 3vw;}
.open_img {width: 36vw; height: 40vw; margin: 4vw auto; text-align: center; background: url("~@/assets/img/box_img.png") no-repeat; background-size: 100% 100%; }
.img_open {width: 21.2vw; height: 21.2vw; margin: 0 auto; text-align: center; vertical-align: middle; border-radius: 2.2vw; margin-top: 1.5vw; margin-left: 1.8vw;}
.open_txt {width: 100%; height: 4.8vw; line-height: 4.8vw; margin: 0 auto; text-align: center; color: #fff; font-size: 4vw; margin-top: 5vw;}
// 开启多个
.box_tit {width: 100%; height: 10vw; line-height: 10vw; margin: 0 auto; text-align: center; color: #FF2222; font-size: 6.4vw; font-weight: 500;}
.box_res {width: 92%; height: 35vw; margin: 8vw 4%; background: linear-gradient(134deg, #B75B4F 0%, #71261B 100%); border-radius: 3vw;}
.box_li {width: 92%; height: 35vw; margin: 0 4%; white-space: nowrap; overflow-x: auto;}
.box_no {width: 3.7vw; height: 3.2vw; line-height: 1.5; color: #FC5D40; font-size: 3.2vw; margin-left: 1.8vw;}
.box_img {width: 23vw; height: 25vw; margin: 5vw 1vw; background: url("~@/assets/img/box_img.png") no-repeat; background-size: 100% 100%; display: inline-block;}
.img_box {width: 13.1vw; height: 13.1vw; margin: 0 auto; text-align: center; vertical-align: middle; border-radius: 2.2vw; margin-top: 1vw; margin-left: 5vw;}
.box_txt {width: 100%; height: 4.8vw; line-height: 4.8vw; margin: 0 auto; text-align: center; color: #fff; font-size: 3vw; margin-top: 2.5vw;}
.box_btn {width: 80%; height: 14.4vw; margin: 11vw 10%; text-align: center; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); font-size: 4.3vw; border-radius: 7.2vw;}
.open_box {
  :deep(.van-popup) {background: transparent; border: none;}
  .open_item {width: 80vw; height: auto; text-align: center;}
  .top_img {width: 45.6vw; height: 30vw; margin-bottom: -10vw;}
  .open_con {
    width: 100%; height: auto; margin: 0 auto; background: #fff; padding-top: 10vw; padding-bottom: 2vw;
    .open_tit {width: 92%; height: auto; line-height: 1.5; margin: 5vw 4%; text-align: center; color: #000; font-size: 4vw; font-weight: 600;}
    .van-button--success {width: 60%; height: 10vw; margin: 5vw 20%; background: linear-gradient(180deg, #FFA81D 0%, #EF1111 100%); color: #fff;}
  }
}
</style>
