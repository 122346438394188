//第一步 获取所有tabber文件夹下的vue文件
const tabbar_files = require.context("@/views/tabbar", true, /\.vue$/);
// console.log("看看底部栏有哪些文件", tabbar_files.keys());
//第一步 新建一个tabber 数组接收所有数据
const tabbar = {};
//第一步 新建一个children 数组接收所有数据
let children = [];
//第一步 新建一个children 数组接收所有数据
tabbar_files.keys().forEach((key) => {
  // console.log("循环中的key", key);
  // console.log("循环中默认值", tabbar_files(key).default);
  // replace 正则截取 文件名
  tabbar[key.replace(/(\.\/|\.vue)/g, "")] = tabbar_files(key).default;
});
// console.log("处理好后的文件", tabbar);

Object.keys(tabbar).forEach((item) => {
  // console.log("循环打印item", item);
  children.push({
    name: item === "index" ? "/" : item,
    path: item === "index" ? "/" : "/" + item,
    component: () => import(`@/views/tabbar/${item}.vue`),
  });
});

// console.log("tabbar组件路由配置", children);
//导出tabbar组件路由配置
export default children;
